import React from "react";
import "./App.css";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import Profile from "./Profil";
//import Creator from "./Creator"
import { Creator } from "./Creator";
import { CreatorTest } from "./Creator copy";
import { Report } from "./Report";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { CreatorProvider } from "../contexts/CreatorContext";
import { DataProvider } from "../contexts/DataContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Discriptors from "./Discriptors";
import ManageUser from "./ManageUser";
import Confirmation from "./Confirmation";
import EmailVerification from "./EmailVerification"
import Organize from "./Organize"

function App() {
  return (
    <div
      className="w-100 d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Router>
        <AuthProvider>
          <DataProvider>
            <CreatorProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute
                  path="/update-profile"
                  component={UpdateProfile}
                />
                <PrivateRoute path="/organize" component={Organize} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/manageUsers" component={ManageUser} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute path="/creator" component={Creator} />
                <PrivateRoute path="/creatorTest" component={CreatorTest} />
                <PrivateRoute path="/report" component={Report} />
                <PrivateRoute path="/discriptors" component={Discriptors} />
                <Route path="/confirmation" component={Confirmation} />
                <Route path="/emailVerification" component={EmailVerification} />
              </Switch>
            </CreatorProvider>
          </DataProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
