import React, { useState } from "react";
import NavbarComponent from "./NavbarComponent";
import { Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";

export default function EmailVerification() {
  const { verifyEmail } = useAuth();
  const [emailSent, setEmailSent] = useState(false);

  const test = {metadata: {
    firebaseStorageDownloadTokens: "c7dfc4c3-0d91-4e1a-b6a1-d4e03a320ef1", institution: "Rad-Standard-Admin"
  }}

  console.log(test.metadata.institution)

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />

      <div className="w-100 text-center mt-2">
        <p>Ihre E-Mail Adresse ist noch nicht bestätigt.</p>

        <Button
          onClick={() => {
            verifyEmail();
            setEmailSent(true);
            setTimeout(() => setEmailSent(false), 3000);
          }}
        >
          Bestätigungslink jetzt senden?
        </Button>
        
        {emailSent ? (
          <Alert className="mt-3" variant="success">Bestätigungslink versendet</Alert>
        ):''}
      </div>
    </div>
  );
}
