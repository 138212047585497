import React, { useState } from "react";
import {
  Card,
  Button,
  Alert,
  OverlayTrigger,
  Popover,
  Form,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Table } from "react-bootstrap";
import { firestore } from "../firebase";

import { Link, useHistory } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";
import MultiSelectSort from "./testSortable";

export default function Organize() {
  const [error, setError] = useState("");
  const [newtemplatename, setNewTemplateName] = useState();
  const [newdiscriptorname, setNewDiscriptorName] = useState();
  const [newmodulename, setNewModuleName] = useState();

  const { currentUser, logout } = useAuth();
  const history = useHistory();

  const { templateData, moduleData, discriptorData } = useData();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Ausloggen fehlgeschlagen");
    }
  }

  function deletetemplate(name) {
    console.log(name);
    firestore
      .collection("template")
      .doc(name)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  function deletediscriptor(name) {
    console.log(name);
    firestore
      .collection("discriptors")
      .doc(name)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  function deletemodule(name) {
    console.log(name);
    firestore
      .collection("module")
      .doc(name)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  function updatetemplatename(name) {
    console.log(name);
    firestore
      .collection("template")
      .doc(name)
      .update({ name: newtemplatename })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating name: ", error);
      });
  }

  function updatediscriptorname(name) {
    console.log(name);
    firestore
      .collection("discriptors")
      .doc(name)
      .update({ name: newdiscriptorname })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating name: ", error);
      });
  }

  function updatemodulename(name) {
    console.log(name);
    firestore
      .collection("module")
      .doc(name)
      .update({ name: newmodulename })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating name: ", error);
      });
  }

  console.log(currentUser.uid);

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />

      <div
        className="w-80 align-items-center mt-4"
        style={{ height: "80vh", overflow: "auto" }}
      >
        <Tabs
          defaultActiveKey="templates"
          className="d-flex justify-content-center"
        >
          <Tab eventKey="templates" title="Templates">
            <div className="w-100 d-flex justify-content-center mt-4">
              <Table striped bordered hover style={{ maxWidth: "1000px" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {templateData
                    ? templateData.map((templateelement) => {
                        if (templateelement.authorid === currentUser.uid) {
                          return (
                            <tr>
                              {" "}
                              <td>{templateelement.name}</td>
                              <td style={{ width: "150px" }}>
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  rootClose="true"
                                  overlay={
                                    <Popover style={{ minWidth: "350px" }}>
                                      <Popover.Content>
                                        <Form>
                                          <InputGroup>
                                            <Form.Control
                                              type="input"
                                              onChange={(e) =>
                                                setNewTemplateName(
                                                  e.target.value
                                                )
                                              }
                                            ></Form.Control>
                                            <InputGroup.Append>
                                              <Button
                                                onClick={() => {
                                                  updatetemplatename(
                                                    templateelement.id
                                                  );
                                                  setNewTemplateName();
                                                }}
                                              >
                                                ändern
                                              </Button>
                                            </InputGroup.Append>
                                          </InputGroup>
                                        </Form>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button>umbennen</Button>
                                </OverlayTrigger>
                              </td>
                              <td style={{ width: "150px" }}>
                                <Button
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Sind Sie sicher, dass Sie dieses Modul löschen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden."
                                      )
                                    ) {
                                      deletetemplate(templateelement.id);
                                    }
                                  }}
                                >
                                  löschen
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      })
                    : ""}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="modules" title="Module">

          <div className="w-100 d-flex justify-content-center mt-4">
              <Table striped bordered hover style={{ maxWidth: "1000px" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {moduleData
                    ? moduleData.map((templateelement) => {
                        if (templateelement.authorid === currentUser.uid) {
                          return (
                            <tr>
                              {" "}
                              <td>{templateelement.name}</td>
                              <td style={{ width: "150px" }}>
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  rootClose="true"
                                  overlay={
                                    <Popover style={{ minWidth: "350px" }}>
                                      <Popover.Content>
                                        <Form>
                                          <InputGroup>
                                            <Form.Control
                                              type="input"
                                              onChange={(e) =>
                                                setNewModuleName(
                                                  e.target.value
                                                )
                                              }
                                            ></Form.Control>
                                            <InputGroup.Append>
                                              <Button
                                                onClick={() => {
                                                  updatemodulename(
                                                    templateelement.id
                                                  );
                                                  setNewModuleName();
                                                }}
                                              >
                                                ändern
                                              </Button>
                                            </InputGroup.Append>
                                          </InputGroup>
                                        </Form>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button>umbennen</Button>
                                </OverlayTrigger>
                              </td>
                              <td style={{ width: "150px" }}>
                                <Button
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Sind Sie sicher, dass Sie dieses Modul löschen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden."
                                      )
                                    ) {
                                      deletemodule(templateelement.id);
                                    }
                                  }}
                                >
                                  löschen
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      })
                    : ""}
                </tbody>
              </Table>
            </div>

          </Tab>
          <Tab eventKey="discriptors" title="Diskriptoren">
            <div className="w-100 d-flex justify-content-center mt-4">
              <Table striped bordered hover style={{ maxWidth: "1000px" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {discriptorData
                    ? discriptorData.map((templateelement) => {
                        return (
                          <tr>
                            {" "}
                            <td>{templateelement.name}</td>
                            <td style={{ width: "150px" }}>
                            <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  rootClose="true"
                                  overlay={
                                    <Popover style={{ minWidth: "350px" }}>
                                      <Popover.Content>
                                        <Form>
                                          <InputGroup>
                                            <Form.Control
                                              type="input"
                                              onChange={(e) =>
                                                setNewDiscriptorName(
                                                  e.target.value
                                                )
                                              }
                                            ></Form.Control>
                                            <InputGroup.Append>
                                              <Button
                                                onClick={() => {
                                                  updatediscriptorname(
                                                    templateelement.id
                                                  );
                                                  setNewDiscriptorName();
                                                }}
                                              >
                                                ändern
                                              </Button>
                                            </InputGroup.Append>
                                          </InputGroup>
                                        </Form>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button>umbennen</Button>
                                </OverlayTrigger>
                            </td>
                            <td style={{ width: "150px" }} >
                              <Button onClick={() => {
                                    if (
                                      window.confirm(
                                        "Sind Sie sicher, dass Sie dieses Template löschen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden."
                                      )
                                    ) {
                                      deletediscriptor(templateelement.id);
                                    }
                                  }}>löschen</Button>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
