import React, { useState } from "react";
import {
  Navbar,
  Form,
  FormControl,
  Button,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Link, useHistory } from "react-router-dom";

/*
/*<div className='navbarContainer'>
            <div className='item'><h3>Rad-Standard</h3></div>
            <div className='item'><Link  to='/dashboard'>
                  Dashboard
                </Link></div>
            <div className='item'><Link  to='/report'>
                  Befunden
                </Link></div>
            <div className='item'><Link  to='/creator'>
                  Template erstellen/bearbeiten
                </Link></div>
                
             
            <div className='item'>Hallo, <Link to='/dashboard'>
            {currentUser.email} 
                </Link></div>
            <div className='item'><button onClick={logout}>Logout</button></div>
      </div>*/

export default function NavbarComponent() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const { userData } = useData();
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Ausloggen fehlgeschlagen");
    }
  }
  if (currentUser) {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">Rad-Standard</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {currentUser.role ? <Nav.Link href="/">Home</Nav.Link> : ""}
            {currentUser.role ? (
              <Nav.Link href="/creatorTest">Creator Testumgebung</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role ? (
              <Nav.Link href="/creator">Creator</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role ? (
              <Nav.Link href="/report">Befundung</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role ? (
              <Nav.Link href="/discriptors">Diskriptoren</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role ? (
              <Nav.Link href="/organize">Meine Daten</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role ? (
              <Nav.Link href="/profile">{userData.firstname}</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role === undefined ? (
              <Nav.Link href="/confirmation">Info</Nav.Link>
            ) : (
              ""
            )}
            {currentUser.role === "globalAdmin" ? (
              <Nav.Link href="/manageUsers">Manage User</Nav.Link>
            ) : (
              ""
            )}

            <Button variant="link" onClick={handleLogout}>
              Ausloggen
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  } else {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">Rad-Standard</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto"></Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
