import React, { useState, useEffect } from "react";
import { useState as useStateHook, none, Downgraded, postpone } from "@hookstate/core";
import {
  Card,
  Button,
  Form,
  Alert,
  OverlayTrigger,
  Popover,
  ButtonGroup,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useGlobalState } from "./GlobalState";
import DiscriptorComponent from "./DiscriptorComponent";
import { firestore } from "../firebase";
import { Document, Page, pdfjs } from "react-pdf";
import { render } from "@testing-library/react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import { useGlobalState } from "./GlobalState.tsx";

export const DecisionTreeOutputRender = (props) => {
  //const globalstate = useGlobalState();

  // console.log(globalstate);
  //Ich brauch wohl useState um den State zu mutieren, das funktioniert aber wohl nicht. Mit dem Beispiel vergleichen!!

  const number = props.number;

  const state = useStateHook(props.componentState.component);
  console.log(props.componentState.name.get());

  return (
    <>
      {props.componentState.renderoutput.get() !== undefined &&
      props.componentState.renderoutput.length > 0 &&
      props.componentState.outputName.get() ? (
        <b>{props.componentState.name.get()}</b>
      ) : (
        ""
      )}

      <div
        className="w-100 text-left mt-2"
        id={props.componentState.name.get()}
      >
        <NodeListEditorTest nodes={state} number={number} />
      </div>
    </>
  );
};

function NodeListEditorTest(props) {
  // scoped state is optional for performance
  // could have used props.nodes everywhere instead

  const globalstate = useStateHook(useGlobalState());


  const state = useStateHook(props.nodes);


  console.log(state);

  // if(document.getElementById("test").innerText.length > 0) {
  //   state.renderoutput.set(true)
  // }
  // else {
  //   state.renderoutput.set(false)
  // }
  console.log(document.getElementById("TextOutput"));

  const [headline, setHeadline] = useState();

  const split = (expression, operator) => {
    const result = [];
    let braces = 0;
    let currentChunk = "";
    console.log(expression);
    for (let i = 0; i < expression.length; ++i) {
      const curCh = expression[i];
      if (curCh == "(") {
        braces++;
      } else if (curCh == ")") {
        braces--;
      }
      if (braces == 0 && operator == curCh) {
        result.push(currentChunk);
        currentChunk = "";
      } else currentChunk += curCh;
    }
    if (currentChunk != "") {
      result.push(currentChunk);
    }
    return result;
  };
  // this will only take strings containing * operator [ no + ]

  const parseDividedSeparatedExpression = (expression) => {
    const numbersString = split(expression, "/");
    const numbers = numbersString.map((noStr) => {
      console.log("Division " + noStr);
      // if (noStr[0] == '(') {
      //   console.log("TRUE")
      //   const expr = noStr.substr(1, noStr.length - 2);
      //   console.log(expr)
      //   // recursive call to the main function
      //   return parsePlusSeparatedExpression(expr);
      // }
      return +noStr;
    });
    const initialValue = numbers[0];
    if (numbers.length === 0) {
      numbers.push(0);
    }
    const result = numbers.reduce((acc, no) => acc / no);
    return result;
  };

  const parseMultiplicationSeparatedExpression = (expression) => {
    const numbersString = split(expression, "*");
    const numbers = numbersString.map((noStr) => {
      console.log("Multiplication " + noStr);
      //parseDividedSeparatedExpression(noStr)
      console.log(noStr.length);
      console.log(noStr.indexOf(")") + 1);
      if (noStr[0] == "(" && noStr.length === noStr.indexOf(")") + 1) {
        //console.log("TRUE")
        const expr = noStr.substr(1, noStr.indexOf(")") - 1);
        // recursive call to the main function
        return parsePlusSeparatedExpression(expr);
      } else if (noStr[0] == "(" && noStr.length !== noStr.indexOf(")") + 1) {
        return parsePlusSeparatedExpression(noStr);
      }
      return +noStr, parseDividedSeparatedExpression(noStr);
    });
    const initialValue = 1.0;
    const result = numbers.reduce((acc, no) => acc * no, initialValue);
    return result;
  };
  // both * -
  const parseMinusSeparatedExpression = (expression) => {
    const numbersString = split(expression, "-");
    console.log(numbersString);
    const numbers = numbersString.map((noStr) => {
      console.log("Substraction " + noStr);
      return parseMultiplicationSeparatedExpression(noStr);
    });
    const initialValue = numbers[0];
    const result = numbers.slice(1).reduce((acc, no) => acc - no, initialValue);
    return result;
  };
  // * - +
  const parsePlusSeparatedExpression = (expression) => {
    const numbersString = split(expression, "+");
    const numbers = numbersString.map((noStr) => {
      console.log("Addition " + noStr);
      return parseMinusSeparatedExpression(noStr);
    });
    const initialValue = 0.0;
    const result = numbers.reduce((acc, no) => acc + no, initialValue);
    return result;
  };

  const parse = (value) => {
    //const expressionNode = value;
    //const resultNode = document.getElementById('result');
    const expression = value;
    let array = expression.split("");
    if (array.length > 0) {
      array.map((element, i) => {
        if (element === "(" && !isNaN(parseInt(array[i - 1]))) {
          array.splice(i, 0, "*");
        } else if (element === ")" && !isNaN(parseInt(array[i + 1]))) {
          array.splice(i + 1, 0, "*");
        }

        if (element === "(" && array.lastIndexOf(")") < i) {
          array.splice(array.length, 0, ")");
        }
      });
    }
    const result = parsePlusSeparatedExpression(
      JSON.stringify(array).replace(/[,"\[\]]/g, ""),
      "+"
    );
    console.log(result);
    return String(result);
  };

  useEffect(() => {
    console.log(globalstate);
    console.log(state.length);
    if (state.length > 0) {
      state.map((nodeState) => {
        console.log(nodeState);
        // if (nodeState.input.get() !== undefined) {
        //   console.log(
        //     document.getElementById(
        //       nodeState.input.get() + nodeState.input.path
        //     ) !== null
        //   );
        //   console.log(nodeState.input.get());
        console.log(document.getElementById("TestFrag10,component,0,input"));
        if (
          document.getElementById(
            nodeState.input.get() + nodeState.input.path
          ) !== null
        ) {
          console.log("Ebene 1");
          console.log(globalstate[nodeState.path[0]].renderoutput.get());
          console.log(
            document.getElementById(
              nodeState.input.get() + nodeState.input.path
            ).innerText.length > 0 &&
              globalstate[nodeState.path[0]].renderoutput.get() === undefined
          );
          console.log(
            document.getElementById(
              nodeState.input.get() + nodeState.input.path
            )
          );

          if (
            document.getElementById(
              nodeState.input.get() + nodeState.input.path
            ).innerText.length > 0 &&
            globalstate[nodeState.path[0]].renderoutput.get() === undefined
          ) {
            globalstate[nodeState.path[0]].renderoutput.set([
              nodeState.input.get() + nodeState.input.path,
            ]);
          }
          // Diese Statement verursacht Fehler 107, rausfinden warum, dann könnte es funktionieren
          else if (
            document.getElementById(
              nodeState.input.get() + nodeState.input.path
            ).innerText.length > 0 &&
            globalstate[nodeState.path[0]].renderoutput.get() !== undefined
          ) {
            if (globalstate[nodeState.path[0]].renderoutput.length === 0) {
              console.log("Ebene 2");
              globalstate[nodeState.path[0]].renderoutput.merge([
                nodeState.input.get() + nodeState.input.path,
              ]);
            } else if (globalstate[nodeState.path[0]].renderoutput.length > 0) {
              if (
                globalstate[nodeState.path[0]].renderoutput.some(
                  (element) =>
                    element.get() ===
                    nodeState.input.get() + nodeState.input.path
                )
              ) {
                console.log("nothing to do");
              } else {
                globalstate[nodeState.path[0]].renderoutput.merge([
                  nodeState.input.get() + nodeState.input.path,
                ]);
              }
            }
          } else if (
            document.getElementById(
              nodeState.input.get() + nodeState.input.path
            ).innerText.length === 0
          ) {
            console.log("InnerText === 0");
            if (
              globalstate[nodeState.path[0]].renderoutput.get() === undefined
            ) {
              globalstate[nodeState.path[0]].renderoutput.set([]);
            } else if (globalstate[nodeState.path[0]].renderoutput.length > 0) {
              globalstate[nodeState.path[0]].renderoutput.map((element) => {
                console.log(element);
                if (
                  element.get() ===
                  nodeState.input.get() + nodeState.input.path
                ) {
                  element.set(none);
                }
              });
            }
          }
        } else if (
          document.getElementById(
            nodeState.input.get() + nodeState.input.path
          ) === null
        ) {
          if (globalstate[nodeState.path[0]].renderoutput.get() === undefined) {
            globalstate[nodeState.path[0]].renderoutput.set([]);
          } else if (globalstate[nodeState.path[0]].renderoutput.length > 0) {
            globalstate[nodeState.path[0]].renderoutput.map((element) => {
              console.log(element);
              if (
                element.get() ===
                nodeState.input.get() + nodeState.input.path
              ) {
                element.set(none);
              }
            });
          }
        }
        // }

        if (globalstate[nodeState.path[0]].renderoutput.get() !== undefined) {
          globalstate[nodeState.path[0]].renderoutput.map(
            (renderoutputelement) => {
              if (document.getElementById(renderoutputelement.get()) === null) {
                renderoutputelement.set(none);
              }
            }
          );
        }
      });
    }

    return () => {};
  }, [globalstate]);

  // useEffect(() => {
  //   console.log("useEffect");
  //   if (state.length > 0) {
  //     if (
  //       state.map((element) => {
  //         console.log(element.path);
  //         console.log(globalstate[element.path[0]]);
  //         if (
  //           document.getElementById(
  //             element.input.get() + element.input.path
  //           ) !== null
  //         ) {
  //           console.log("HIER: gutes Zeichen");
  //           console.log(document.getElementById(
  //             element.input.get() + element.input.path
  //           ).innerText)

  //           const children = Array.from(document.getElementById(
  //             element.input.get() + element.input.path
  //           ).childNodes)

  //           if(globalstate[element.path[0]].check.get() !== undefined){

  //             console.log(globalstate[element.path[0]].check.get().join(":"))
  //             console.log(children.some(childelement => childelement.innerText.length !== globalstate[element.path[0]].check.get().join(":").length))

  //           }

  //           if (
  //             document.getElementById(element.input.get() + element.input.path)
  //               .innerText.length > 0 && children.some(childelement => childelement.innerText !== element.input.get()+":")
  //           ) {
  //             console.log("True");
  //             //console.log(children.some(element => element.innerText !== null))
  //             // document.getElementById(element.input.get()+element.input.path).innerText !== element.input.get()+":"
  //             //document.getElementById(element.input.get()+element.input.path).childElementCount > 0
  //             if (
  //               document.getElementById(
  //                 element.input.get() + element.input.path
  //               ).innerText !==
  //               element.input.get() + ":" && element.outputInput.get() && children.some(childelement => childelement.innerText !== element.input.get()+":")
  //             ) {
  //               setHeadline(element.input.get() + ":");
  //             } else {
  //               // console.log("--------HHHHHIIIERIRIEIEIEIEIE--------------")
  //               // if(globalstate[element.path[0]].check.get() !== undefined && globalstate[element.path[0]].check.length > 0) {
  //               //   globalstate[element.path[0]].check.map((checkelement) => {
  //               //     if(checkelement.get() === element.input.get()){
  //               //       checkelement.set(none)
  //               //     }})
  //               // }
  //               console.log("Hier sollte es enterfernt werden, wird es aber nicht")
  //               setHeadline();
  //             }

  //             if (globalstate[element.path[0]].check.get() === undefined) {
  //               return globalstate[element.path[0]].check.set([]);
  //             } else if (globalstate[element.path[0]].check.length >= 0) {
  //               console.log(
  //                 !globalstate[element.path[0]].check.some(
  //                   (checkelement) => checkelement.get() === element.input.get()
  //                 )
  //               );

  //               if (
  //                 !globalstate[element.path[0]].check.some(
  //                   (checkelement) => checkelement.get() === element.input.get()
  //                 ) && children.some(childelement => childelement.id !== "headline" && childelement.innerText.length >0)
  //               ) {
  //                 console.log("----Success---");
  //                 globalstate[element.path[0]].check.merge([
  //                   element.input.get(),
  //                 ]);
  //               }
  //             }

  //             //globalstate[element.path[0]].check.merge([element.input.get()])
  //             return element.renderoutput.set(true);
  //           } else {
  //             const children = Array.from(document.getElementById(
  //               element.input.get() + element.input.path
  //             ).childNodes)
  //             console.log("False");
  //             setHeadline();
  //             if(globalstate[element.path[0]].check.get() !== undefined && globalstate[element.path[0]].check.length > 0) {

  //               globalstate[element.path[0]].check.map((checkelement) => {
  //                 if(checkelement.get() === element.input.get()){
  //                   checkelement.set(none)
  //                 }
  //               })
  //             }

  //             return element.renderoutput.set(false);
  //           }

  //           // });
  //         } else if (
  //           document.getElementById(
  //             element.input.get() + element.input.path
  //           ) === null
  //         ) {
  //           setHeadline();
  //           if (globalstate[element.path[0]].check.get() !== undefined) {
  //             globalstate[element.path[0]].check.map((checkelement) => {
  //               if (checkelement.get() === element.input.get()) {
  //                 checkelement.set(none);
  //                 console.log("Entfernt")
  //               }
  //             });
  //           }

  //           return element.renderoutput.set(false);
  //         }

  //       })
  //     ) {
  //       return "";
  //     }
  //   }
  // }, [globalstate]);

  // useEffect(() => {
  //   if (state.length > 0) {
  //     state.map((nodeState) => {
  //       console.log(globalstate[nodeState.path[0]])

  //       console.log(
  //         document.getElementById(
  //           nodeState.input.get() + nodeState.input.path
  //         ) !== null
  //       );
  //       if (
  //         document.getElementById(
  //           nodeState.input.get() + nodeState.input.path
  //         ) !== null &&
  //         document.getElementById(nodeState.input.get() + nodeState.input.path)
  //           .innerText.length > 0
  //       ) {
  //         console.log("Ebene1");
  //         if (globalstate[nodeState.path[0]].check.get() === undefined) {
  //           globalstate[nodeState.path[0]].check.set([]);
  //         } else if (
  //           document.getElementById(
  //             nodeState.input.get() + nodeState.input.path
  //           ).innerText.length > 0
  //         ) {
  //           if (
  //             !globalstate[nodeState.path[0]].check.some(
  //               (checkelement) => checkelement.get() === nodeState.input.get()
  //             )
  //           ) {
  //             globalstate[nodeState.path[0]].check.merge([
  //               nodeState.input.get(),
  //             ]);
  //           }
  //         }
  //         else if(document.getElementById(
  //           nodeState.input.get() + nodeState.input.path
  //         ).innerText.length === 0){
  //           globalstate[nodeState.path[0]].check.map((checkelement) => {
  //             if(checkelement.get() === nodeState.input.get()){
  //               checkelement.set(none)
  //             }
  //           })
  //         }

  //         const children = Array.from(
  //           document.getElementById(nodeState.input.get() + nodeState.input.path)
  //             .childNodes
  //         );

  //         console.log(children.some(childelement => childelement.id !== "headline" && childelement.innerText.length !== nodeState.input.get()+":"))

  //         if (globalstate[nodeState.path[0]].check.get() !== undefined) {
  //           console.log(document.getElementById(nodeState.input.get() + nodeState.input.path).innerText)
  //           console.log(globalstate[nodeState.path[0]].check.get().join(":"));
  //           // console.log(
  //           //   children.some(
  //           //     (childelement) =>
  //           //       childelement.innerText.length !==
  //           //       globalstate[nodeState.path[0]].check.get().join(":").length
  //           //   )
  //           // );
  //           // if(document.getElementById(nodeState.input.get() + nodeState.input.path).innerText.length === children.some(
  //           //   (childelement) =>
  //           //     childelement.innerText.length !==
  //           //     globalstate[nodeState.path[0]].check.get().join(":").length
  //           // )){
  //             children.map((childelement) => {if(childelement.innerText === nodeState.input.get()+":"){
  //               console.log(childelement.innerText)
  //               globalstate[nodeState.path[0]].check.map((checkelement) => {
  //                 if(checkelement.get() === childelement.innerText){
  //                   checkelement.set(none)
  //                 }
  //               })
  //             }
  //               })
  //             globalstate[nodeState.path[0]].check.map((checkelement) => {
  //               if(checkelement.get() === nodeState.input.get()){
  //                   console.log("---Hier---")
  //                 //   if(document.getElementById(nodeState.input.get() + nodeState.input.path).innerText.length ===
  //                 // globalstate[nodeState.path[0]].check.get().join(":").length || document.getElementById(nodeState.input.get() + nodeState.input.path).innerText === nodeState.input.get()+":")

  //                 if(children.some(childelement => childelement.id !== "headline" && childelement.innerText === nodeState.input.get()+":"))
  //                 {
  //                   console.log("---Hier2---")
  //                   checkelement.set(none)
  //                 }

  //                 }
  //             })
  //           // }
  //         }
  //       }
  //     });
  //   }

  //   return () => {
  //     // state.map((nodeState) => {

  //     //       globalstate[nodeState.path[0]].check.map((checkelement) => {
  //     //         console.log(checkelement.get())
  //     //         console.log(nodeState.input.get())
  //     //         if(checkelement.get() === nodeState.input.get()){
  //     //           checkelement.set(none)
  //     //         }
  //     //       })

  //     // })

  //   };
  // }, [globalstate]);

  // useEffect(() => {
  //   console.log("on mount")
  //   return () => {
  //     state.map((nodeState) =>{
  //       console.log("unmount")
  //       if(globalstate[nodeState.path[0]].check.get() !== undefined){
  //         globalstate[nodeState.path[0]].check.map((checkelement) => {
  //           if(checkelement.get() === nodeState.input.get()){
  //             checkelement.set(none)
  //           }
  //         })
  //       }
  //     })
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log("rerender");
  //   return () => {
  //     console.log("return before rerender");
  //   };
  // });

  // useEffect(() => {
  //   console.log("mount/unmount")
  //   if(state.length > 0 ){
  //     if (
  //       state.map((element) => {
  //         console.log(element)
  //         if (document.getElementById(element.input.get()) !== null) {
  //           if (document.getElementById(element.input.get()) === null){
  //           return element.renderoutput.set(false)
  //         }
  //       }})
  //     ) {
  //       return "";
  //     }
  //   }

  //   return () => {
  //     console.log("unmount")
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log("useEffect")
  //   if(state.length > 0){
  //     state.map((nodeState) => {
  //       if(document.getElementById(nodeState.input.get()+nodeState.input.path) !== null && document.getElementById(nodeState.input.get()+nodeState.input.path).innerText.length > 0){

  //         setHeadline(nodeState.input.get()+":")
  //         // const children = Array.from(
  //         //             document.getElementById(nodeState.input.get() + nodeState.input.path)
  //         //               .childNodes
  //         //           );
  //         // children.map((childelement) => {
  //         //   console.log(childelement)
  //         //   if(childelement.id !== "headline" && childelement.innerText !== undefined && childelement.innerText.length > 0){
  //         //     console.log("Mit Überschrift")
  //         //     setHeadline(nodeState.input.get()+":")

  //         //   }
  //         //   else {
  //         //     console.log("Ohne Überschrift")
  //         //   setHeadline()
  //         //   }
  //         // })

  //         // if(document.getElementById(nodeState.input.get()+nodeState.input.path) !== null && document.getElementById(nodeState.input.get()+nodeState.input.path).innerText.length > 0){
  //         //   setHeadline(nodeState.input.get()+":")
  //         // }
  //         // else {
  //         //   setHeadline()
  //         // }
  //         }
  //       })
  //     }

  //   return () => {

  //   }
  // })

  return (
    <>
      {state.ornull &&
        state.ornull.map((nodeState, i) => {
          console.log(nodeState);
          let renderoutput = [];
          let childrender = [];

          // if(nodeState.buttons.get() !== undefined){
          //   nodeState.buttons.map((button, i) => {
          //     if(button.endpoint.get() && button["button" + i].get()) {
          //       renderoutput = renderoutput.concat(<>
          //       <div>{button.reportoutput.get()}</div>
          //       <NodeListEditorTest nodes={button.children} />
          //       </>
          //       )
          //     }
          //   })
          // }
          //&& document.getElementById(nodeState.input.get()) !== null

          // if(nodeState.outputInput.get() && nodeState.renderoutput.get() && document.getElementById(nodeState.input.get()+nodeState.input.path).innerText !== nodeState.input.get()+":"){
          //   let newrenderoutput = renderoutput.concat(<div><u>{nodeState.input.get()+":"}</u></div>)
          //   if(newrenderoutput !== renderoutput){
          //     renderoutput = newrenderoutput
          //   }
          //   // if(renderoutput === renderoutput.concat(<div><u>{nodeState.input.get()+":"}</u></div>)){
          //   //   renderoutput = renderoutput.concat(<div><u>{nodeState.input.get()+":"}</u></div>)

          //   // }
          //   // if(document.getElementById(nodeState.input.get()+nodeState.input.path).innerText.length > 0 ){
          //   //   console.log("Überschrift wird ausgegeben")
          //   //   renderoutput = renderoutput.concat(<div><u>{nodeState.input.get()+":"}</u></div>)
          //   // }
          // }

          if (
            nodeState.id.get() === "DecisionTree" &&
            nodeState.children.length > 0
          ) {
            nodeState.children.map((child) => {
              if (child.id.get() === "Findings") {
                child.findings.map((finding) => {
                  console.log(finding);
                  // if (
                  //   finding.showAlways.get() !== undefined &&
                  //   finding.showAlways.get()
                  // ) {
                  //   // let test = "";
                  //   // const syntax = finding.outputsyntax.get().split(" ");
                  //   // syntax.map((outputelement) => {
                  //   //   if (finding.finding.get() !== undefined) {
                  //   //     finding.finding.map((discriptors) => {
                  //   //       if (discriptors.name.get() === outputelement) {
                  //   //         test = test.concat(discriptors.output.get());
                  //   //       } else if (
                  //   //         discriptors.discriptoranchor.get() === outputelement
                  //   //       ) {
                  //   //         if (discriptors.outputs.get() !== []) {
                  //   //           let concat = "";
                  //   //           discriptors.outputs.map((element2) => {
                  //   //             console.log(element2.outputsyntax.get());
                  //   //             let syntax = null;
                  //   //             if (element2.outputsyntax.get() !== undefined) {
                  //   //               syntax = element2.outputsyntax
                  //   //                 .get()
                  //   //                 .split(" ");
                  //   //             }
                  //   //             console.log(syntax);
                  //   //             element2.data.map((element3, i) => {
                  //   //               if (
                  //   //                 element3[element3.input.get() + i].get()
                  //   //               ) {
                  //   //                 concat = concat.concat(
                  //   //                   element3.input.get() + " "
                  //   //                 );
                  //   //               }
                  //   //             });

                  //   //             if (syntax !== null) {
                  //   //               syntax.map((syntax) => {
                  //   //                 element2.data.map((element3, i) => {
                  //   //                   if (
                  //   //                     element3.selection.get() ===
                  //   //                       "manualinput" &&
                  //   //                     element3.manualinput.get() !== "" &&
                  //   //                     syntax === i + 1 + "v"
                  //   //                   ) {
                  //   //                     concat = concat.concat(
                  //   //                       element3.manualinput.get()
                  //   //                     );
                  //   //                   } else if (
                  //   //                     element3.selection.get() ===
                  //   //                       "manualinput" &&
                  //   //                     element3.manualinput.get() !== "" &&
                  //   //                     syntax === i + 1 + "d"
                  //   //                   ) {
                  //   //                     concat = concat.concat(
                  //   //                       element3.input.get()
                  //   //                     );
                  //   //                   }
                  //   //                 });

                  //   //                 if (
                  //   //                   element2.data.some((dataelement) => {
                  //   //                     return (
                  //   //                       dataelement.selection.get() ===
                  //   //                         "manualinput" &&
                  //   //                       dataelement.manualinput.get() !== ""
                  //   //                     );
                  //   //                   })
                  //   //                 ) {
                  //   //                   if (syntax === "(") {
                  //   //                     concat = concat.concat("(");
                  //   //                   } else if (syntax === "x") {
                  //   //                     concat = concat.concat("x");
                  //   //                   } else if (syntax === ")") {
                  //   //                     concat = concat.concat(")");
                  //   //                   } else if (syntax === ",") {
                  //   //                     concat = concat.concat(",");
                  //   //                   } else if (syntax === ":") {
                  //   //                     concat = concat.concat(":");
                  //   //                   } else if (syntax === ";") {
                  //   //                     concat = concat.concat(";");
                  //   //                   } else if (syntax === "-") {
                  //   //                     concat = concat.concat("-");
                  //   //                   } else if (syntax === "") {
                  //   //                     concat = concat.concat(" ");
                  //   //                   } else if (syntax === ".") {
                  //   //                     concat = concat.concat(".");
                  //   //                   } else if (syntax.startsWith("%")) {
                  //   //                     console.log(syntax);
                  //   //                     const split = syntax.split("$");
                  //   //                     console.log(split);
                  //   //                     let calc = [];
                  //   //                     split.map((splitelement, isplit) => {
                  //   //                       console.log(isNaN(splitelement));
                  //   //                       if (isplit >= 3) {
                  //   //                         element2.data.map((element3, i) => {
                  //   //                           //console.log(element3, i)
                  //   //                           if (splitelement == i + 1 + "v") {
                  //   //                             console.log(
                  //   //                               element3.manualinput.get()
                  //   //                             );
                  //   //                             calc.push(
                  //   //                               element3.manualinput.get()
                  //   //                             );
                  //   //                           } else if (
                  //   //                             splitelement === "*" ||
                  //   //                             splitelement === ":" ||
                  //   //                             splitelement === "-" ||
                  //   //                             splitelement === "+"
                  //   //                           ) {
                  //   //                             if (
                  //   //                               calc[calc.length - 1] !==
                  //   //                               splitelement
                  //   //                             ) {
                  //   //                               calc.push(splitelement);
                  //   //                             }
                  //   //                           } else if (
                  //   //                             !isNaN(splitelement) &&
                  //   //                             calc[calc.length - 1] !==
                  //   //                               parseFloat(splitelement)
                  //   //                           ) {
                  //   //                             calc.push(
                  //   //                               parseFloat(splitelement)
                  //   //                             );
                  //   //                           }
                  //   //                         });
                  //   //                       }
                  //   //                     });
                  //   //                     console.log(calc);
                  //   //                     let result = null;
                  //   //                     calc.map((calcelement, i) => {
                  //   //                       if (i < calc.length) {
                  //   //                         console.log(calcelement);
                  //   //                         if (i === 0) {
                  //   //                           console.log(parseFloat(calc[i]));
                  //   //                           result = parseInt(calc[i]);
                  //   //                         } else if (calcelement === "*") {
                  //   //                           result =
                  //   //                             result *
                  //   //                             parseFloat(calc[i + 1]);
                  //   //                         } else if (calcelement === ":") {
                  //   //                           result =
                  //   //                             result /
                  //   //                             parseFloat(calc[i + 1]);
                  //   //                         } else if (calcelement === "+") {
                  //   //                           result =
                  //   //                             result +
                  //   //                             parseFloat(calc[i + 1]);
                  //   //                         } else if (calcelement === "-") {
                  //   //                           result =
                  //   //                             result -
                  //   //                             parseFloat(calc[i + 1]);
                  //   //                         }
                  //   //                       }
                  //   //                     });

                  //   //                     console.log(result);
                  //   //                     console.log(split[1]);
                  //   //                     element2.data.map((element3, i) => {
                  //   //                       console.log(i + 1 + "v" === split[1]);
                  //   //                       if (i + 1 + "v" === split[1]) {
                  //   //                         if (
                  //   //                           element3.manualinput.get() !==
                  //   //                             Math.round(result / 1000) &&
                  //   //                           !isNaN(result)
                  //   //                         ) {
                  //   //                           element3.manualinput.set(
                  //   //                             Math.round(result / 1000)
                  //   //                           );
                  //   //                         }
                  //   //                       }
                  //   //                     });
                  //   //                   }
                  //   //                 }
                  //   //               });
                  //   //             }
                  //   //             // concat = concat.concat(element2.get()+" ");
                  //   //           });

                  //   //           test = test.concat(concat);
                  //   //         }
                  //   //       }
                  //   //     });
                  //   //     if (outputelement === "(") {
                  //   //       test = test.concat("(");
                  //   //     } else if (outputelement === ")") {
                  //   //       test = test.concat(")");
                  //   //     } else if (outputelement === ",") {
                  //   //       test = test.concat(",");
                  //   //     } else if (outputelement === ":") {
                  //   //       test = test.concat(":");
                  //   //     } else if (outputelement === ";") {
                  //   //       test = test.concat(";");
                  //   //     } else if (outputelement === "-") {
                  //   //       test = test.concat("-");
                  //   //     } else if (outputelement === "") {
                  //   //       test = test.concat(" ");
                  //   //     } else if (outputelement === ".") {
                  //   //       test = test.concat(".");
                  //   //     }
                  //   //   }
                  //   // });
                  //   let output = [];
                  //   {
                  //     finding.finding.map((finding, index) => {
                  //       if (
                  //         finding.outputs.get() !== undefined &&
                  //         finding.outputs.length > 0
                  //       ) {
                  //         finding.outputs.map((element) => {
                  //           console.log(element);

                  //           // element.data.map((element3, i) => {
                  //           //   console.log(element.data.get());
                  //           //   if (
                  //           //     element3[element3.input.get() + i].get()
                  //           //   ) {
                  //           //     output = output.concat(
                  //           //       element3.input.get() + " "
                  //           //     );
                  //           //   }
                  //           // });

                  //           if (
                  //             element.selectedoutputArray.get() !== undefined &&
                  //             element.selectedoutputArray.length > 0
                  //           ) {
                  //             element.selectedoutputArray.map(
                  //               (outputelement) => {
                  //                 console.log("Ebene 1");
                  //                 if (outputelement.type.get() === "value") {
                  //                   console.log("Ebene 2 Value");

                  //                   return (output = output.concat(
                  //                     outputelement.manualinput.get()
                  //                   ));
                  //                   // element.data.map((dataelement, indexdata) => {
                  //                   //   console.log(dataelement.input.get())
                  //                   //   console.log(outputelement.label.get())
                  //                   //   console.log(dataelement.input.get() === outputelement.label.get())
                  //                   //   if(indexdata === outputelement.index.get()){
                  //                   //     console.log("Ebene 3 Input")
                  //                   //     return output = output.concat(dataelement.manualinput.get())
                  //                   //   }
                  //                   // })
                  //                 } else if (
                  //                   outputelement.type.get() === "manualvalue"
                  //                 ) {
                  //                   return (output = output.concat(
                  //                     outputelement.manualinput.get()
                  //                   ));
                  //                 } else if(outputelement.type.get() === "finding"){
                  //                   console.log("FINDING gefunden")
                  //                   element.data.map((element3, i) => {
                  //                     console.log(element.data.get());
                  //                     if (
                  //                       element3[element3.input.get() + i].get()
                  //                     ) {
                  //                       output = output.concat(
                  //                         element3.input.get() + " "
                  //                       );
                  //                     }
                  //                   });
                  //                 }
                  //                 else if (
                  //                   outputelement.type.get() === "name" ||
                  //                   outputelement.type.get() === "output"
                  //                 ) {
                  //                   console.log("Ebene 2 Name");
                  //                   return (output = output.concat(
                  //                     outputelement.label.get()
                  //                   ));
                  //                 } else if (
                  //                   outputelement.type.get() === "formula"
                  //                 ) {
                  //                   if (
                  //                     element.selectedformulaArray.get() !==
                  //                       undefined &&
                  //                     element.selectedformulaArray.length > 0
                  //                   ) {
                  //                     let result = null;
                  //                     let rechnung = "";
                  //                     element.selectedformulaArray.map(
                  //                       (formulaelement, i) => {
                  //                         if (
                  //                           formulaelement.type.get() ===
                  //                           "value"
                  //                         ) {
                  //                           rechnung = rechnung.concat(
                  //                             JSON.stringify(
                  //                               formulaelement.label.get()
                  //                             )
                  //                           );
                  //                         } else if (
                  //                           formulaelement.type.get() ===
                  //                           "operator"
                  //                         ) {
                  //                           rechnung = rechnung.concat(
                  //                             JSON.stringify(
                  //                               formulaelement.label.get()
                  //                             )
                  //                           );
                  //                         } else if (
                  //                           formulaelement.type.get() ===
                  //                           "manualvalue"
                  //                         ) {
                  //                           rechnung = rechnung.concat(
                  //                             JSON.stringify(
                  //                               formulaelement.manualinput.get()
                  //                             )
                  //                           );
                  //                         }
                  //                         return result;
                  //                       }
                  //                     );
                  //                     const ergebnis = parse(
                  //                       rechnung.replace(/"/g, "")
                  //                     );
                  //                     const ergebnisgerundet =
                  //                       Math.round(ergebnis * 10) / 10;

                  //                     let ergebniskomma = ergebnisgerundet;

                  //                     if (!isNaN(ergebniskomma)) {
                  //                       ergebniskomma = JSON.stringify(
                  //                         ergebnisgerundet
                  //                       ).replace(".", ",");
                  //                     }

                  //                     output = output.concat(ergebniskomma);
                  //                   }
                  //                 }
                  //               }
                  //             );
                  //           }
                  //         });
                  //       }
                  //       return output;
                  //     });
                  //   }

                  //   renderoutput = renderoutput.concat(
                  //     <>
                  //       {/* id={nodeState.input.get()+nodeState.input.path} */}
                  //       <div>{output}</div>
                  //     </>
                  //   );
                  // } else

                  // if (
                  //   finding.checked.get() &&
                  //   finding.outputsyntax.get() !== undefined &&
                  //   finding.outputsyntax.get() !== ""
                  // ) {
                  //   console.log("GREAT SUCCESS");
                  //   let test = "";
                  //   const syntax = finding.outputsyntax.get().split(" ");
                  //   console.log(syntax);
                  //   syntax.map((outputelement) => {
                  //     if (finding.finding.get() !== undefined) {
                  //       finding.finding.map((discriptors) => {
                  //         if (discriptors.name.get() === outputelement) {
                  //           test = test.concat(discriptors.output.get());
                  //         } else if (
                  //           discriptors.discriptoranchor.get() === outputelement
                  //         ) {
                  //           if (discriptors.outputs.get() !== []) {
                  //             let concat = "";
                  //             discriptors.outputs.map((element2) => {
                  //               console.log(element2.outputsyntax.get());
                  //               let syntax = null;
                  //               if (element2.outputsyntax.get() !== undefined) {
                  //                 syntax = element2.outputsyntax
                  //                   .get()
                  //                   .split(" ");
                  //               }
                  //               console.log(syntax);

                  //               element2.outputs.map((element3, i) => {
                  //                 if (
                  //                   element3[element3.input.get() + i].get()
                  //                 ) {
                  //                   concat = concat.concat(
                  //                     element3.input.get() + " Ü"
                  //                   );
                  //                 }
                  //               });

                  //               if (syntax !== null) {
                  //                 syntax.map((syntax) => {
                  //                   console.log(concat);

                  //                   element2.data.map((element3, i) => {
                  //                     if (
                  //                       element3.selection.get() ===
                  //                         "manualinput" &&
                  //                       element3.manualinput.get() !== "" &&
                  //                       syntax === i + 1 + "v"
                  //                     ) {
                  //                       concat = concat.concat(
                  //                         element3.manualinput.get()
                  //                       );
                  //                     } else if (
                  //                       element3.selection.get() ===
                  //                         "manualinput" &&
                  //                       element3.manualinput.get() !== "" &&
                  //                       syntax === i + 1 + "d"
                  //                     ) {
                  //                       concat = concat.concat(
                  //                         element3.input.get()
                  //                       );
                  //                     }
                  //                   });

                  //                   if (syntax === "(") {
                  //                     concat = concat.concat("(");
                  //                   } else if (syntax === "x") {
                  //                     concat = concat.concat("x");
                  //                   } else if (syntax === ")") {
                  //                     concat = concat.concat(")");
                  //                   } else if (syntax === ",") {
                  //                     concat = concat.concat(",");
                  //                   } else if (syntax === ":") {
                  //                     concat = concat.concat(":");
                  //                   } else if (syntax === ";") {
                  //                     concat = concat.concat(";");
                  //                   } else if (syntax === "-") {
                  //                     concat = concat.concat("-");
                  //                   } else if (syntax === "") {
                  //                     concat = concat.concat(" ");
                  //                   } else if (syntax === ".") {
                  //                     concat = concat.concat(".");
                  //                   }
                  //                 });
                  //               }
                  //               // concat = concat.concat(element2.get()+" ");
                  //             });

                  //             test = test.concat(concat);
                  //           }
                  //         }
                  //       });
                  //       if (outputelement === "(") {
                  //         test = test.concat("(");
                  //       } else if (outputelement === ")") {
                  //         test = test.concat(")");
                  //       } else if (outputelement === ",") {
                  //         test = test.concat(",");
                  //       } else if (outputelement === ":") {
                  //         test = test.concat(":");
                  //       } else if (outputelement === ";") {
                  //         test = test.concat(";");
                  //       } else if (outputelement === "-") {
                  //         test = test.concat("-");
                  //       } else if (outputelement === "") {
                  //         test = test.concat(" ");
                  //       } else if (outputelement === ".") {
                  //         test = test.concat(".");
                  //       }
                  //     }
                  //   });

                  //   renderoutput = renderoutput.concat(
                  //     <>
                  //       {/* id={nodeState.input.get()+nodeState.input.path} */}
                  //       <div>{test}</div>
                  //     </>
                  //   );
                  // } else 
                  if (
                    finding.checked.get() &&
                    finding.selectedoutputArray.get() !== undefined &&
                    finding.selectedoutputArray.length > 0
                  ) {
                    console.log("REAL GREAT SUCCESS");
                    let test = "";
                    //const syntax = finding.outputsyntax.get().split(" ");
                    //console.log(syntax);
                    finding.selectedoutputArray.map((outputelement) => {
                      console.log(outputelement);
                      if (outputelement.type.get() === "output") {
                        console.log("OUTPUT");
                        test = test.concat(outputelement.label.get());
                      } 
                      else if (outputelement.type.get() === "finding") {
                        console.log("FINDING");
                        if (finding.finding.get() !== undefined) {
                          finding.finding.map((discriptors) => {
                            // if (discriptors.name.get() === outputelement.discriptorname) {
                            //   test = test.concat(discriptors.output.get());
                            // } else
                            console.log(
                              discriptors.discriptorname.get() ===
                                outputelement.value.value
                            );
                            if (
                              discriptors.discriptorname.get() ===
                              outputelement.value.value
                            ) {
                              if (discriptors.outputs.get() !== []) {
                                let concat = "";
                                discriptors.outputs.map(
                                  (element2, index, arr) => {
                                    let syntax = null;
                                    if (
                                      element2.outputsyntax.get() !== undefined
                                    ) {
                                      syntax = element2.outputsyntax.get();
                                    }
                                    console.log(syntax);
                                    let selected = 0;
                                    let selectedarray = [];

                                    element2.data.map((element3, i) => {
                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        console.log(element3.input.get());
                                        selected = selected + 1;
                                        console.log(selected);
                                      }
                                    });

                                    element2.data.map((element3, i) => {
                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        console.log(element3.input.get());
                                        selectedarray.push(i);
                                      }
                                    });

                                    console.log(selectedarray);

                                    element2.data.map((element3, i) => {
                                      console.log(element2.data.get());

                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "singleselection"
                                      ) {
                                        concat = concat.concat(
                                          element3.input.get()
                                        );
                                      } else if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        if (
                                          element3[
                                            element3.input.get() + i
                                          ].get()
                                        ) {
                                          if (selected === 1) {
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected === 2 &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            console.log(
                                              selectedarray.indexOf(i)
                                            );
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected === 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element2.lastseperator.get() +
                                                element3.input.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 1
                                              ] &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 2
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element3.input.get() +
                                                element2.seperator.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 2
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element2.lastseperator.get() +
                                                element3.input.get()
                                            );
                                          }
                                          // console.log(selected)
                                          // if (selected === 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element3.input.get()
                                          //   );
                                          // }
                                          // else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 2 && selected > 1 && element2.data.length - selected < 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element3.input.get() + element2.seperator.get()
                                          //   );
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 1 && selected > 1 && element2.data.length - selected === 1 && selected !== 2
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   selected === 2 && i === element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // }
                                          // else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(element3.input.get());
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i === element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // }
                                        }
                                      }
                                    });

                                    if (
                                      element2.selectedoutputArray.get() !==
                                        undefined &&
                                      element2.selectedoutputArray.length > 0
                                    ) {
                                      let output = "";
                                      element2.selectedoutputArray.map(
                                        (outputelement) => {
                                          console.log("Ebene 1");
                                          if (
                                            outputelement.type.get() === "value"
                                          ) {
                                            console.log("Ebene 2 Value");

                                            return (output = output.concat(
                                              outputelement.manualinput.get()
                                            ));
                                            // element.data.map((dataelement, indexdata) => {
                                            //   console.log(dataelement.input.get())
                                            //   console.log(outputelement.label.get())
                                            //   console.log(dataelement.input.get() === outputelement.label.get())
                                            //   if(indexdata === outputelement.index.get()){
                                            //     console.log("Ebene 3 Input")
                                            //     return output = output.concat(dataelement.manualinput.get())
                                            //   }
                                            // })
                                          } else if (
                                            outputelement.type.get() ===
                                            "manualvalue"
                                          ) {
                                            return (output = output.concat(
                                              outputelement.manualinput.get()
                                            ));
                                          } else if (
                                            outputelement.type.get() ===
                                              "name" ||
                                            outputelement.type.get() ===
                                              "output"
                                          ) {
                                            console.log("Ebene 2 Name");
                                            return (output = output.concat(
                                              outputelement.label.get()
                                            ));
                                          } else if (
                                            outputelement.type.get() ===
                                            "formula"
                                          ) {
                                            if (
                                              element2.selectedformulaArray.get() !==
                                                undefined &&
                                              element2.selectedformulaArray
                                                .length > 0
                                            ) {
                                              let result = null;
                                              let rechnung = "";
                                              element2.selectedformulaArray.map(
                                                (formulaelement, i) => {
                                                  if (
                                                    formulaelement.type.get() ===
                                                    "value"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.label.get()
                                                      )
                                                    );
                                                  } else if (
                                                    formulaelement.type.get() ===
                                                    "operator"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.label.get()
                                                      )
                                                    );
                                                  } else if (
                                                    formulaelement.type.get() ===
                                                    "manualvalue"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.manualinput.get()
                                                      )
                                                    );
                                                  }
                                                  return result;
                                                }
                                              );
                                              const ergebnis = parse(
                                                rechnung.replace(/"/g, "")
                                              );
                                              const ergebnisgerundet =
                                                Math.round(ergebnis * 10) / 10;

                                              let ergebniskomma = ergebnisgerundet;

                                              if (!isNaN(ergebniskomma)) {
                                                ergebniskomma = JSON.stringify(
                                                  ergebnisgerundet
                                                ).replace(".", ",");
                                              }

                                              output = output.concat(
                                                ergebniskomma
                                              );
                                            }
                                          }
                                        }
                                      );
                                      return (concat = concat.concat(
                                        output
                                      ));
                                    }

                                    // if (syntax !== null) {
                                    //   syntax.map((syntax) => {
                                    //     console.log(concat);

                                    //     element2.data.map((element3, i) => {
                                    //       if (
                                    //         element3.selection.get() ===
                                    //           "manualinput" &&
                                    //         element3.manualinput.get() !== "" &&
                                    //         syntax === i + 1 + "v"
                                    //       ) {
                                    //         concat = concat.concat(
                                    //           element3.manualinput.get()
                                    //         );
                                    //       } else if (
                                    //         element3.selection.get() ===
                                    //           "manualinput" &&
                                    //         element3.manualinput.get() !== "" &&
                                    //         syntax === i + 1 + "d"
                                    //       ) {
                                    //         concat = concat.concat(
                                    //           element3.input.get()
                                    //         );
                                    //       }
                                    //     });

                                    //     if (syntax === "(") {
                                    //       concat = concat.concat("(");
                                    //     } else if (syntax === "x") {
                                    //       concat = concat.concat("x");
                                    //     } else if (syntax === ")") {
                                    //       concat = concat.concat(")");
                                    //     } else if (syntax === ",") {
                                    //       concat = concat.concat(",");
                                    //     } else if (syntax === ":") {
                                    //       concat = concat.concat(":");
                                    //     } else if (syntax === ";") {
                                    //       concat = concat.concat(";");
                                    //     } else if (syntax === "-") {
                                    //       concat = concat.concat("-");
                                    //     } else if (syntax === "") {
                                    //       concat = concat.concat(" ");
                                    //     } else if (syntax === ".") {
                                    //       concat = concat.concat(".");
                                    //     }

                                    //     concat = concat + " ";
                                    //   });
                                    // }
                                    // concat = concat.concat(element2.get()+" ");
                                  }
                                );

                                // concat = concat.slice(0, -1);

                                // concat =
                                //   concat.charAt(0).toUpperCase() +
                                //   concat.slice(1);

                                test = test.concat(concat);
                              }
                            }
                          });
                        }
                      } 
                      else if (outputelement.type.get() === "condition") {

                        finding.finding.map((findingelement) => {
                          finding.condition.map((conditionelement) => {
                            if (conditionelement.logic.get() !== undefined) {
                              if (
                                conditionelement.logic.conditionaloutput.get() ===
                                outputelement.label.get()
                              ) {
                                if (
                                  conditionelement.logic.selectedfinding.get() ===
                                  findingelement.discriptorname.get()
                                ) {
                                  findingelement.outputs[0].data.map(
                                    (findingbutton) => {
                                      console.log(
                                        findingbutton[
                                          conditionelement.logic.findingbutton.get()
                                        ].get()
                                      );
                                      if (
                                        findingbutton[
                                          conditionelement.logic.findingbutton.get()
                                        ].get()
                                      ) {
                                        test = test.concat(
                                          conditionelement.logic.conditionaloutput.get()
                                        );
                                      }
                                    }
                                  );
                                }
                              }
                            }
                          });
                        });
                      }
                    });

                    test = test.charAt(0).toUpperCase() + test.slice(1);


                    renderoutput = renderoutput.concat(
                      <div>
                        {/* id={nodeState.input.get()+nodeState.input.path} */}
                        {test}
                      </div>
                    );
                  } else if (
                    finding.showAlways.get() &&
                    finding.selectedoutputArray.get() !== undefined &&
                    finding.selectedoutputArray.length > 0
                  ) {
                    console.log("REAL GREAT SUCCESS");
                    let test = "";
                    //const syntax = finding.outputsyntax.get().split(" ");
                    //console.log(syntax);
                    finding.selectedoutputArray.map((outputelement) => {
                      console.log(outputelement);
                      if (outputelement.type.get() === "output") {
                        console.log("OUTPUT");
                        test = test.concat(outputelement.label.get());
                      } else if (outputelement.type.get() === "finding") {
                        console.log("FINDING");
                        if (finding.finding.get() !== undefined) {
                          finding.finding.map((discriptors) => {
                            // if (discriptors.name.get() === outputelement.discriptorname) {
                            //   test = test.concat(discriptors.output.get());
                            // } else
                            console.log(
                              discriptors.discriptorname.get() ===
                                outputelement.value.value
                            );
                            if (
                              discriptors.discriptorname.get() ===
                              outputelement.value.value
                            ) {
                              if (discriptors.outputs.get() !== []) {
                                let concat = "";
                                discriptors.outputs.map(
                                  (element2, index, arr) => {
                                    let syntax = null;
                                    if (
                                      element2.outputsyntax.get() !== undefined
                                    ) {
                                      syntax = element2.outputsyntax.get();
                                    }
                                    console.log(syntax);
                                    let selected = 0;
                                    let selectedarray = [];

                                    element2.data.map((element3, i) => {
                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        console.log(element3.input.get());
                                        selected = selected + 1;
                                        console.log(selected);
                                      }
                                    });

                                    element2.data.map((element3, i) => {
                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        console.log(element3.input.get());
                                        selectedarray.push(i);
                                      }
                                    });

                                    console.log(selectedarray);

                                    element2.data.map((element3, i) => {
                                      console.log(element2.data.get());

                                      if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "singleselection"
                                      ) {
                                        concat = concat.concat(
                                          element3.input.get()
                                        );
                                      } else if (
                                        element3[
                                          element3.input.get() + i
                                        ].get() &&
                                        element3.selection.get() ===
                                          "multiselection"
                                      ) {
                                        if (
                                          element3[
                                            element3.input.get() + i
                                          ].get()
                                        ) {
                                          if (selected === 1) {
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected === 2 &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            console.log(
                                              selectedarray.indexOf(i)
                                            );
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected === 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element2.lastseperator.get() +
                                                element3.input.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 1
                                              ] &&
                                            i !==
                                              selectedarray[
                                                selectedarray.length - 2
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element3.input.get() +
                                                element2.seperator.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 2
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element3.input.get()
                                            );
                                          } else if (
                                            selected > 2 &&
                                            i ===
                                              selectedarray[
                                                selectedarray.length - 1
                                              ]
                                          ) {
                                            concat = concat.concat(
                                              element2.lastseperator.get() +
                                                element3.input.get()
                                            );
                                          }
                                          // console.log(selected)
                                          // if (selected === 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element3.input.get()
                                          //   );
                                          // }
                                          // else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 2 && selected > 1 && element2.data.length - selected < 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element3.input.get() + element2.seperator.get()
                                          //   );
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 1 && selected > 1 && element2.data.length - selected === 1 && selected !== 2
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   selected === 2 && i === element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // }
                                          // else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i < element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(element3.input.get());
                                          // } else if (
                                          //   element2.seperator.get() !== undefined &&
                                          //   element2.lastseperator.get() !== undefined &&
                                          //   i === element2.data.length - 1
                                          // ) {
                                          //   concat = concat.concat(
                                          //     element2.lastseperator.get() +
                                          //       element3.input.get()
                                          //   );
                                          // }
                                        }
                                      }
                                    });

                                    if (
                                      element2.selectedoutputArray.get() !==
                                        undefined &&
                                      element2.selectedoutputArray.length > 0
                                    ) {
                                      let output = "";
                                      element2.selectedoutputArray.map(
                                        (outputelement) => {
                                          console.log("Ebene 1");
                                          if (
                                            outputelement.type.get() === "value"
                                          ) {
                                            console.log("Ebene 2 Value");

                                            return (output = output.concat(
                                              outputelement.manualinput.get()
                                            ));
                                            // element.data.map((dataelement, indexdata) => {
                                            //   console.log(dataelement.input.get())
                                            //   console.log(outputelement.label.get())
                                            //   console.log(dataelement.input.get() === outputelement.label.get())
                                            //   if(indexdata === outputelement.index.get()){
                                            //     console.log("Ebene 3 Input")
                                            //     return output = output.concat(dataelement.manualinput.get())
                                            //   }
                                            // })
                                          } else if (
                                            outputelement.type.get() ===
                                            "manualvalue"
                                          ) {
                                            return (output = output.concat(
                                              outputelement.manualinput.get()
                                            ));
                                          } else if (
                                            outputelement.type.get() ===
                                              "name" ||
                                            outputelement.type.get() ===
                                              "output"
                                          ) {
                                            console.log("Ebene 2 Name");
                                            return (output = output.concat(
                                              outputelement.label.get()
                                            ));
                                          } else if (
                                            outputelement.type.get() ===
                                            "formula"
                                          ) {
                                            if (
                                              element2.selectedformulaArray.get() !==
                                                undefined &&
                                              element2.selectedformulaArray
                                                .length > 0
                                            ) {
                                              let result = null;
                                              let rechnung = "";
                                              element2.selectedformulaArray.map(
                                                (formulaelement, i) => {
                                                  if (
                                                    formulaelement.type.get() ===
                                                    "value"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.label.get()
                                                      )
                                                    );
                                                  } else if (
                                                    formulaelement.type.get() ===
                                                    "operator"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.label.get()
                                                      )
                                                    );
                                                  } else if (
                                                    formulaelement.type.get() ===
                                                    "manualvalue"
                                                  ) {
                                                    rechnung = rechnung.concat(
                                                      JSON.stringify(
                                                        formulaelement.manualinput.get()
                                                      )
                                                    );
                                                  }
                                                  return result;
                                                }
                                              );
                                              const ergebnis = parse(
                                                rechnung.replace(/"/g, "")
                                              );
                                              const ergebnisgerundet =
                                                Math.round(ergebnis * 10) / 10;

                                              let ergebniskomma = ergebnisgerundet;

                                              if (!isNaN(ergebniskomma)) {
                                                ergebniskomma = JSON.stringify(
                                                  ergebnisgerundet
                                                ).replace(".", ",");
                                              }

                                              output = output.concat(
                                                ergebniskomma
                                              );
                                            }
                                          }
                                        }
                                      );
                                      return (concat = concat.concat(
                                        output
                                      ));
                                    }

                                    // if (syntax !== null) {
                                    //   syntax.map((syntax) => {
                                    //     console.log(concat);

                                    //     element2.data.map((element3, i) => {
                                    //       if (
                                    //         element3.selection.get() ===
                                    //           "manualinput" &&
                                    //         element3.manualinput.get() !== "" &&
                                    //         syntax === i + 1 + "v"
                                    //       ) {
                                    //         concat = concat.concat(
                                    //           element3.manualinput.get()
                                    //         );
                                    //       } else if (
                                    //         element3.selection.get() ===
                                    //           "manualinput" &&
                                    //         element3.manualinput.get() !== "" &&
                                    //         syntax === i + 1 + "d"
                                    //       ) {
                                    //         concat = concat.concat(
                                    //           element3.input.get()
                                    //         );
                                    //       }
                                    //     });

                                    //     if (syntax === "(") {
                                    //       concat = concat.concat("(");
                                    //     } else if (syntax === "x") {
                                    //       concat = concat.concat("x");
                                    //     } else if (syntax === ")") {
                                    //       concat = concat.concat(")");
                                    //     } else if (syntax === ",") {
                                    //       concat = concat.concat(",");
                                    //     } else if (syntax === ":") {
                                    //       concat = concat.concat(":");
                                    //     } else if (syntax === ";") {
                                    //       concat = concat.concat(";");
                                    //     } else if (syntax === "-") {
                                    //       concat = concat.concat("-");
                                    //     } else if (syntax === "") {
                                    //       concat = concat.concat(" ");
                                    //     } else if (syntax === ".") {
                                    //       concat = concat.concat(".");
                                    //     }

                                    //     concat = concat + " ";
                                    //   });
                                    // }
                                    // concat = concat.concat(element2.get()+" ");
                                  }
                                );

                                // concat = concat.slice(0, -1);

                                // concat =
                                //   concat.charAt(0).toUpperCase() +
                                //   concat.slice(1);

                                test = test.concat(concat);
                              }
                            }
                          });
                        }
                      } else if (outputelement.type.get() === "condition") {

                        finding.finding.map((findingelement) => {
                          finding.condition.map((conditionelement) => {
                            if (conditionelement.logic.get() !== undefined) {
                              if (
                                conditionelement.logic.conditionaloutput.get() ===
                                outputelement.label.get()
                              ) {
                                if (
                                  conditionelement.logic.selectedfinding.get() ===
                                  findingelement.discriptorname.get()
                                ) {
                                  findingelement.outputs[0].data.map(
                                    (findingbutton) => {
                                      console.log(
                                        findingbutton[
                                          conditionelement.logic.findingbutton.get()
                                        ].get()
                                      );
                                      if (
                                        findingbutton[
                                          conditionelement.logic.findingbutton.get()
                                        ].get()
                                      ) {
                                        test = test.concat(
                                          conditionelement.logic.conditionaloutput.get()
                                        );
                                      }
                                    }
                                  );
                                }
                              }
                            }
                          });
                        });
                      }
                    });

                    test = test.charAt(0).toUpperCase() + test.slice(1);


                    renderoutput = renderoutput.concat(
                      <div>
                        {/* id={nodeState.input.get()+nodeState.input.path} */}
                        {test}
                      </div>
                    );
                  }

                  // {
                  //   console.log("REAL GREAT SUCCESS");
                  //   let test = "";
                  //   //const syntax = finding.outputsyntax.get().split(" ");
                  //   //console.log(syntax);
                  //   finding.selectedoutputArray.map((outputelement) => {
                  //     console.log(outputelement);
                  //     if (outputelement.type.get() === "output") {
                  //       console.log("OUTPUT");
                  //       test = test.concat(outputelement.label.get());
                  //     } else if (outputelement.type.get() === "finding") {
                  //       console.log("FINDING");
                  //       if (finding.finding.get() !== undefined) {
                  //         finding.finding.map((discriptors) => {
                  //           // if (discriptors.name.get() === outputelement.discriptorname) {
                  //           //   test = test.concat(discriptors.output.get());
                  //           // } else
                  //           if (
                  //             discriptors.discriptorname.get() ===
                  //             outputelement.value.value
                  //           ) {
                  //             if (discriptors.outputs.get() !== []) {
                  //               let concat = "";
                  //               discriptors.outputs.map((element2) => {
                  //                 console.log(element2.outputsyntax.get());
                  //                 let syntax = null;

                  //                 element2.data.map((element3, i) => {
                  //                   if (
                  //                     element3[element3.input.get() + i].get()
                  //                   ) {
                  //                     concat = concat.concat(
                  //                       element3.input.get()
                  //                     );
                  //                   }
                  //                 });

                  //                 if (
                  //                   element2.outputsyntax.get() !== undefined
                  //                 ) {
                  //                   syntax = element2.outputsyntax
                  //                     .get()
                  //                     .split(" ");
                  //                 }
                  //                 console.log(syntax);

                  //                 if (syntax !== null) {
                  //                   syntax.map((syntax) => {
                  //                     console.log(concat);

                  //                     element2.data.map((element3, i) => {
                  //                       if (
                  //                         element3.selection.get() ===
                  //                           "manualinput" &&
                  //                         element3.manualinput.get() !== "" &&
                  //                         syntax === i + 1 + "v"
                  //                       ) {
                  //                         concat = concat.concat(
                  //                           element3.manualinput.get()
                  //                         );
                  //                       } else if (
                  //                         element3.selection.get() ===
                  //                           "manualinput" &&
                  //                         element3.manualinput.get() !== "" &&
                  //                         syntax === i + 1 + "d"
                  //                       ) {
                  //                         concat = concat.concat(
                  //                           element3.input.get()
                  //                         );
                  //                       }
                  //                     });

                  //                     if (syntax === "(") {
                  //                       concat = concat.concat("(");
                  //                     } else if (syntax === "x") {
                  //                       concat = concat.concat("x");
                  //                     } else if (syntax === ")") {
                  //                       concat = concat.concat(")");
                  //                     } else if (syntax === ",") {
                  //                       concat = concat.concat(",");
                  //                     } else if (syntax === ":") {
                  //                       concat = concat.concat(":");
                  //                     } else if (syntax === ";") {
                  //                       concat = concat.concat(";");
                  //                     } else if (syntax === "-") {
                  //                       concat = concat.concat("-");
                  //                     } else if (syntax === "") {
                  //                       concat = concat.concat(" ");
                  //                     } else if (syntax === ".") {
                  //                       concat = concat.concat(".");
                  //                     }
                  //                   });

                  //                   concat = concat + " ";
                  //                 }
                  //               });

                  //               // concat = concat.slice(0, -1);
                  //               // console.log(concat.charAt(0).toUpperCase());
                  //               // concat =
                  //               //   concat.charAt(0).toUpperCase() +
                  //               //   concat.slice(1);

                  //               test = test.concat(concat);
                  //             }
                  //           }
                  //         });
                  //       }
                  //     }
                  //   });

                  //   renderoutput = renderoutput.concat(
                  //     <>
                  //       {/* id={nodeState.input.get()+nodeState.input.path} */}
                  //       {test}
                  //     </>
                  //   );
                  // }
                });
              }
            });
          } else if (nodeState.id.get() === "Findings") {
            nodeState.findings.map((finding) => {
              if (
                finding.checked.get() &&
                finding.selectedoutputArray.get() !== undefined &&
                finding.selectedoutputArray.length > 0
              ) {
                console.log("REAL GREAT SUCCESS");
                let test = "";
                finding.selectedoutputArray.map((outputelement) => {
                  console.log(outputelement);
                  if (outputelement.type.get() === "output") {
                    console.log("OUTPUT");
                    test = test.concat(outputelement.label.get());
                  } 
                  else if (outputelement.type.get() === "finding") {
                    console.log("FINDING");
                    if (finding.finding.get() !== undefined) {
                      finding.finding.map((discriptors) => {
                        
                        console.log(
                          discriptors.discriptorname.get() ===
                            outputelement.value.value
                        );
                        if (
                          discriptors.discriptorname.get() ===
                          outputelement.value.value
                        ) {
                          if (discriptors.outputs.get() !== []) {
                            let concat = "";
                            discriptors.outputs.map(
                              (element2, index, arr) => {
                                let syntax = null;
                                if (
                                  element2.outputsyntax.get() !== undefined
                                ) {
                                  syntax = element2.outputsyntax.get();
                                }
                                console.log(syntax);
                                let selected = 0;
                                let selectedarray = [];

                                element2.data.map((element3, i) => {
                                  if (
                                    element3[
                                      element3.input.get() + i
                                    ].get() &&
                                    element3.selection.get() ===
                                      "multiselection"
                                  ) {
                                    console.log(element3.input.get());
                                    selected = selected + 1;
                                    console.log(selected);
                                  }
                                });

                                element2.data.map((element3, i) => {
                                  if (
                                    element3[
                                      element3.input.get() + i
                                    ].get() &&
                                    element3.selection.get() ===
                                      "multiselection"
                                  ) {
                                    console.log(element3.input.get());
                                    selectedarray.push(i);
                                  }
                                });

                                console.log(selectedarray);

                                element2.data.map((element3, i) => {
                                  console.log(element2.data.get());

                                  if (
                                    element3[
                                      element3.input.get() + i
                                    ].get() &&
                                    element3.selection.get() ===
                                      "singleselection"
                                  ) {
                                    concat = concat.concat(
                                      element3.input.get()
                                    );
                                  } else if (
                                    element3[
                                      element3.input.get() + i
                                    ].get() &&
                                    element3.selection.get() ===
                                      "multiselection"
                                  ) {
                                    if (
                                      element3[
                                        element3.input.get() + i
                                      ].get()
                                    ) {
                                      if (selected === 1) {
                                        concat = concat.concat(
                                          element3.input.get()
                                        );
                                      } else if (
                                        selected === 2 &&
                                        i !==
                                          selectedarray[
                                            selectedarray.length - 1
                                          ]
                                      ) {
                                        console.log(
                                          selectedarray.indexOf(i)
                                        );
                                        concat = concat.concat(
                                          element3.input.get()
                                        );
                                      } else if (
                                        selected === 2 &&
                                        i ===
                                          selectedarray[
                                            selectedarray.length - 1
                                          ]
                                      ) {
                                        concat = concat.concat(
                                          element2.lastseperator.get() +
                                            element3.input.get()
                                        );
                                      } else if (
                                        selected > 2 &&
                                        i !==
                                          selectedarray[
                                            selectedarray.length - 1
                                          ] &&
                                        i !==
                                          selectedarray[
                                            selectedarray.length - 2
                                          ]
                                      ) {
                                        concat = concat.concat(
                                          element3.input.get() +
                                            element2.seperator.get()
                                        );
                                      } else if (
                                        selected > 2 &&
                                        i ===
                                          selectedarray[
                                            selectedarray.length - 2
                                          ]
                                      ) {
                                        concat = concat.concat(
                                          element3.input.get()
                                        );
                                      } else if (
                                        selected > 2 &&
                                        i ===
                                          selectedarray[
                                            selectedarray.length - 1
                                          ]
                                      ) {
                                        concat = concat.concat(
                                          element2.lastseperator.get() +
                                            element3.input.get()
                                        );
                                      }
                                       
                                    }
                                  }
                                });

                                if (
                                  element2.selectedoutputArray.get() !==
                                    undefined &&
                                  element2.selectedoutputArray.length > 0
                                ) {
                                  let output = "";
                                  element2.selectedoutputArray.map(
                                    (outputelement) => {
                                      console.log("Ebene 1");
                                      if (
                                        outputelement.type.get() === "value"
                                      ) {
                                        console.log("Ebene 2 Value");

                                        return (output = output.concat(
                                          outputelement.manualinput.get()
                                        ));
                                       
                                      } else if (
                                        outputelement.type.get() ===
                                        "manualvalue"
                                      ) {
                                        return (output = output.concat(
                                          outputelement.manualinput.get()
                                        ));
                                      } else if (
                                        outputelement.type.get() ===
                                          "name" ||
                                        outputelement.type.get() ===
                                          "output"
                                      ) {
                                        console.log("Ebene 2 Name");
                                        return (output = output.concat(
                                          outputelement.label.get()
                                        ));
                                      } else if (
                                        outputelement.type.get() ===
                                        "formula"
                                      ) {
                                        if (
                                          element2.selectedformulaArray.get() !==
                                            undefined &&
                                          element2.selectedformulaArray
                                            .length > 0
                                        ) {
                                          let result = null;
                                          let rechnung = "";
                                          element2.selectedformulaArray.map(
                                            (formulaelement, i) => {
                                              if (
                                                formulaelement.type.get() ===
                                                "value"
                                              ) {
                                                rechnung = rechnung.concat(
                                                  JSON.stringify(
                                                    formulaelement.label.get()
                                                  )
                                                );
                                              } else if (
                                                formulaelement.type.get() ===
                                                "operator"
                                              ) {
                                                rechnung = rechnung.concat(
                                                  JSON.stringify(
                                                    formulaelement.label.get()
                                                  )
                                                );
                                              } else if (
                                                formulaelement.type.get() ===
                                                "manualvalue"
                                              ) {
                                                rechnung = rechnung.concat(
                                                  JSON.stringify(
                                                    formulaelement.manualinput.get()
                                                  )
                                                );
                                              }
                                              return result;
                                            }
                                          );
                                          const ergebnis = parse(
                                            rechnung.replace(/"/g, "")
                                          );
                                          const ergebnisgerundet =
                                            Math.round(ergebnis * 10) / 10;

                                          let ergebniskomma = ergebnisgerundet;

                                          if (!isNaN(ergebniskomma)) {
                                            ergebniskomma = JSON.stringify(
                                              ergebnisgerundet
                                            ).replace(".", ",");
                                          }

                                          output = output.concat(
                                            ergebniskomma
                                          );
                                        }
                                      }
                                    }
                                  );
                                  return (concat = concat.concat(
                                    output
                                  ));
                                }

                              }
                            );


                            test = test.concat(concat);
                          }
                        }
                      });
                    }
                  }
                  
                  else if (outputelement.type.get() === "condition") {

                    finding.finding.map((findingelement) => {
                      finding.condition.map((conditionelement) => {
                        if (conditionelement.logic.get() !== undefined) {
                          if (
                            conditionelement.logic.conditionaloutput.get() ===
                            outputelement.label.get()
                          ) {
                            if (
                              conditionelement.logic.selectedfinding.get() ===
                              findingelement.discriptorname.get()
                            ) {
                              findingelement.outputs[0].data.map(
                                (findingbutton) => {
                                  console.log(
                                    findingbutton[
                                      conditionelement.logic.findingbutton.get()
                                    ].get()
                                  );
                                  if (
                                    findingbutton[
                                      conditionelement.logic.findingbutton.get()
                                    ].get()
                                  ) {
                                    test = test.concat(
                                      conditionelement.logic.conditionaloutput.get()
                                    );
                                  }
                                }
                              );
                            }
                          }
                        }
                      });
                    });
                  }
                });

                test = test.charAt(0).toUpperCase() + test.slice(1);

                renderoutput = renderoutput.concat(
                  <div>
                    {/* id={nodeState.input.get()+nodeState.input.path} */}
                    {test}
                  </div>
                );
              } else if (
                finding.showAlways.get() &&
                finding.selectedoutputArray.get() !== undefined &&
                finding.selectedoutputArray.length > 0
              ) {
                console.log("REAL GREAT SUCCESS");
                let test = "";
                //const syntax = finding.outputsyntax.get().split(" ");
                //console.log(syntax);
                finding.selectedoutputArray.map((outputelement) => {
                  console.log(outputelement);
                  if (outputelement.type.get() === "output") {
                    console.log("OUTPUT");
                    test = test.concat(outputelement.label.get());
                  } else if (outputelement.type.get() === "finding") {
                    console.log("FINDING");
                    if (finding.finding.get() !== undefined) {
                      finding.finding.map((discriptors) => {
                        // if (discriptors.name.get() === outputelement.discriptorname) {
                        //   test = test.concat(discriptors.output.get());
                        // } else
                        if (
                          discriptors.discriptorname.get() ===
                          outputelement.value.value
                        ) {
                          if (discriptors.outputs.get() !== []) {
                            let concat = "";
                            discriptors.outputs.map((element2, index, arr) => {
                              let syntax = null;
                              if (element2.outputsyntax.get() !== undefined) {
                                syntax = element2.outputsyntax.get();
                              }
                              console.log(syntax);
                              element2.data.map((element3, i) => {
                                console.log(element2.data.get());
                                if (element3[element3.input.get() + i].get()) {
                                  concat = concat.concat(element3.input.get());
                                }
                              });

                              if (
                                element2.selectedoutputArray.get() !==
                                  undefined &&
                                element2.selectedoutputArray.length > 0
                              ) {
                                let output = "";
                                element2.selectedoutputArray.map(
                                  (outputelement) => {
                                    console.log("Ebene 1");
                                    if (outputelement.type.get() === "value") {
                                      console.log("Ebene 2 Value");

                                      return (output = output.concat(
                                        outputelement.manualinput.get()
                                      ));
                                      // element.data.map((dataelement, indexdata) => {
                                      //   console.log(dataelement.input.get())
                                      //   console.log(outputelement.label.get())
                                      //   console.log(dataelement.input.get() === outputelement.label.get())
                                      //   if(indexdata === outputelement.index.get()){
                                      //     console.log("Ebene 3 Input")
                                      //     return output = output.concat(dataelement.manualinput.get())
                                      //   }
                                      // })
                                    } else if (
                                      outputelement.type.get() === "manualvalue"
                                    ) {
                                      return (output = output.concat(
                                        outputelement.manualinput.get()
                                      ));
                                    } else if (
                                      outputelement.type.get() === "name" ||
                                      outputelement.type.get() === "output"
                                    ) {
                                      console.log("Ebene 2 Name");
                                      return (output = output.concat(
                                        outputelement.label.get()
                                      ));
                                    } else if (
                                      outputelement.type.get() === "formula"
                                    ) {
                                      if (
                                        element2.selectedformulaArray.get() !==
                                          undefined &&
                                        element2.selectedformulaArray.length > 0
                                      ) {
                                        let result = null;
                                        let rechnung = "";
                                        element2.selectedformulaArray.map(
                                          (formulaelement, i) => {
                                            if (
                                              formulaelement.type.get() ===
                                              "value"
                                            ) {
                                              rechnung = rechnung.concat(
                                                JSON.stringify(
                                                  formulaelement.label.get()
                                                )
                                              );
                                            } else if (
                                              formulaelement.type.get() ===
                                              "operator"
                                            ) {
                                              rechnung = rechnung.concat(
                                                JSON.stringify(
                                                  formulaelement.label.get()
                                                )
                                              );
                                            } else if (
                                              formulaelement.type.get() ===
                                              "manualvalue"
                                            ) {
                                              rechnung = rechnung.concat(
                                                JSON.stringify(
                                                  formulaelement.manualinput.get()
                                                )
                                              );
                                            }
                                            return result;
                                          }
                                        );
                                        const ergebnis = parse(
                                          rechnung.replace(/"/g, "")
                                        );
                                        const ergebnisgerundet =
                                          Math.round(ergebnis * 10) / 10;

                                        let ergebniskomma = ergebnisgerundet;

                                        if (!isNaN(ergebniskomma)) {
                                          ergebniskomma = JSON.stringify(
                                            ergebnisgerundet
                                          ).replace(".", ",");
                                        }

                                        output = output.concat(ergebniskomma);
                                      }
                                    }
                                  }
                                );
                                return (concat = concat.concat(output));
                              }

                              // if (syntax !== null) {
                              //   syntax.map((syntax) => {
                              //     console.log(concat);

                              //     element2.data.map((element3, i) => {
                              //       if (
                              //         element3.selection.get() ===
                              //           "manualinput" &&
                              //         element3.manualinput.get() !== "" &&
                              //         syntax === i + 1 + "v"
                              //       ) {
                              //         concat = concat.concat(
                              //           element3.manualinput.get()
                              //         );
                              //       } else if (
                              //         element3.selection.get() ===
                              //           "manualinput" &&
                              //         element3.manualinput.get() !== "" &&
                              //         syntax === i + 1 + "d"
                              //       ) {
                              //         concat = concat.concat(
                              //           element3.input.get()
                              //         );
                              //       }
                              //     });

                              //     if (syntax === "(") {
                              //       concat = concat.concat("(");
                              //     } else if (syntax === "x") {
                              //       concat = concat.concat("x");
                              //     } else if (syntax === ")") {
                              //       concat = concat.concat(")");
                              //     } else if (syntax === ",") {
                              //       concat = concat.concat(",");
                              //     } else if (syntax === ":") {
                              //       concat = concat.concat(":");
                              //     } else if (syntax === ";") {
                              //       concat = concat.concat(";");
                              //     } else if (syntax === "-") {
                              //       concat = concat.concat("-");
                              //     } else if (syntax === "") {
                              //       concat = concat.concat(" ");
                              //     } else if (syntax === ".") {
                              //       concat = concat.concat(".");
                              //     }

                              //     concat = concat + " ";
                              //   });
                              // }
                              // concat = concat.concat(element2.get()+" ");
                            });

                            // concat = concat.slice(0, -1);

                            // concat =
                            //   concat.charAt(0).toUpperCase() +
                            //   concat.slice(1);

                            test = test.concat(concat);
                          }
                        }
                      });
                    }
                  } else if (outputelement.type.get() === "condition") {

                    finding.finding.map((findingelement) => {
                      finding.condition.map((conditionelement) => {
                        if (conditionelement.logic.get() !== undefined) {
                          if (
                            conditionelement.logic.conditionaloutput.get() ===
                            outputelement.label.get()
                          ) {
                            if (
                              conditionelement.logic.selectedfinding.get() ===
                              findingelement.discriptorname.get()
                            ) {
                              findingelement.outputs[0].data.map(
                                (findingbutton) => {
                                  console.log(
                                    findingbutton[
                                      conditionelement.logic.findingbutton.get()
                                    ].get()
                                  );
                                  if (
                                    findingbutton[
                                      conditionelement.logic.findingbutton.get()
                                    ].get()
                                  ) {
                                    test = test.concat(
                                      conditionelement.logic.conditionaloutput.get()
                                    );
                                  }
                                }
                              );
                            }
                          }
                        }
                      });
                    });
                  }
                });

                test = test.charAt(0).toUpperCase() + test.slice(1);


                renderoutput = renderoutput.concat(
                  <div>
                    {/* id={nodeState.input.get()+nodeState.input.path} */}
                    {test}
                  </div>
                );
              }

              // {
              //   console.log("REAL GREAT SUCCESS");
              //   let test = "";
              //   //const syntax = finding.outputsyntax.get().split(" ");
              //   //console.log(syntax);
              //   finding.selectedoutputArray.map((outputelement) => {
              //     console.log(outputelement);
              //     if (outputelement.type.get() === "output") {
              //       console.log("OUTPUT");
              //       test = test.concat(outputelement.label.get());
              //     } else if (outputelement.type.get() === "finding") {
              //       console.log("FINDING");
              //       if (finding.finding.get() !== undefined) {
              //         finding.finding.map((discriptors) => {
              //           // if (discriptors.name.get() === outputelement.discriptorname) {
              //           //   test = test.concat(discriptors.output.get());
              //           // } else
              //           if (
              //             discriptors.discriptorname.get() ===
              //             outputelement.value.value
              //           ) {
              //             if (discriptors.outputs.get() !== []) {
              //               let concat = "";
              //               discriptors.outputs.map((element2) => {
              //                 console.log(element2.outputsyntax.get());
              //                 let syntax = null;

              //                 element2.data.map((element3, i) => {
              //                   if (
              //                     element3[element3.input.get() + i].get()
              //                   ) {
              //                     concat = concat.concat(
              //                       element3.input.get() + " "
              //                     );
              //                   }
              //                 });

              //                 if (
              //                   element2.outputsyntax.get() !== undefined
              //                 ) {
              //                   syntax = element2.outputsyntax
              //                     .get()
              //                     .split(" ");
              //                 }
              //                 console.log(syntax);

              //                 if (syntax !== null) {
              //                   syntax.map((syntax) => {
              //                     console.log(concat);

              //                     element2.data.map((element3, i) => {
              //                       if (
              //                         element3.selection.get() ===
              //                           "manualinput" &&
              //                         element3.manualinput.get() !== "" &&
              //                         syntax === i + 1 + "v"
              //                       ) {
              //                         concat = concat.concat(
              //                           element3.manualinput.get()
              //                         );
              //                       } else if (
              //                         element3.selection.get() ===
              //                           "manualinput" &&
              //                         element3.manualinput.get() !== "" &&
              //                         syntax === i + 1 + "d"
              //                       ) {
              //                         concat = concat.concat(
              //                           element3.input.get()
              //                         );
              //                       }
              //                     });

              //                     if (syntax === "(") {
              //                       concat = concat.concat("(");
              //                     } else if (syntax === "x") {
              //                       concat = concat.concat("x");
              //                     } else if (syntax === ")") {
              //                       concat = concat.concat(")");
              //                     } else if (syntax === ",") {
              //                       concat = concat.concat(",");
              //                     } else if (syntax === ":") {
              //                       concat = concat.concat(":");
              //                     } else if (syntax === ";") {
              //                       concat = concat.concat(";");
              //                     } else if (syntax === "-") {
              //                       concat = concat.concat("-");
              //                     } else if (syntax === "") {
              //                       concat = concat.concat(" ");
              //                     } else if (syntax === ".") {
              //                       concat = concat.concat(".");
              //                     }
              //                   });

              //                   concat = concat + " ";
              //                 }
              //               });

              //               // concat = concat.slice(0, -1);
              //               // console.log(concat.charAt(0).toUpperCase());
              //               // concat =
              //               //   concat.charAt(0).toUpperCase() +
              //               //   concat.slice(1);

              //               test = test.concat(concat);
              //             }
              //           }
              //         });
              //       }
              //     }
              //   });

              //   renderoutput = renderoutput.concat(
              //     <>
              //       {/* id={nodeState.input.get()+nodeState.input.path} */}
              //       {test}
              //     </>
              //   );
              // }
            });
          }
          // else if (nodeState.id.get() === "Findings") {
          //   console.log("Bei Findings");
          //   console.log(nodeState.input.get());
          //   nodeState.findings.map((finding) => {
          //     if (
          //       finding.checked.get() &&
          //       finding.outputsyntax.get() !== undefined &&
          //       finding.outputsyntax.get() !== ""
          //     ) {
          //       console.log("GREAT SUCCESS");
          //       let test = "";
          //       const syntax = finding.outputsyntax.get().split(" ");
          //       console.log(syntax);
          //       syntax.map((outputelement) => {
          //         if (finding.finding.get() !== undefined) {
          //           finding.finding.map((discriptors) => {
          //             if (discriptors.name.get() === outputelement) {
          //               test = test.concat(discriptors.output.get());
          //             } else if (
          //               discriptors.discriptoranchor.get() === outputelement
          //             ) {
          //               if (discriptors.outputs.get() !== []) {
          //                 let concat = "";
          //                 discriptors.outputs.map((element2) => {
          //                   console.log(element2.outputsyntax.get());
          //                   let syntax = null;
          //                   if (element2.outputsyntax.get() !== undefined) {
          //                     syntax = element2.outputsyntax.get().split(" ");
          //                   }
          //                   console.log(syntax);
          //                   element2.data.map((element3, i) => {
          //                     if (element3[element3.input.get() + i].get()) {
          //                       concat = concat.concat(element3.input.get());
          //                     }
          //                   });

          //                   if (syntax !== null) {
          //                     syntax.map((syntax) => {
          //                       console.log(concat);

          //                       element2.data.map((element3, i) => {
          //                         if (
          //                           element3.selection.get() ===
          //                             "manualinput" &&
          //                           element3.manualinput.get() !== "" &&
          //                           syntax === i + 1 + "v"
          //                         ) {
          //                           concat = concat.concat(
          //                             element3.manualinput.get()
          //                           );
          //                         } else if (
          //                           element3.selection.get() ===
          //                             "manualinput" &&
          //                           element3.manualinput.get() !== "" &&
          //                           syntax === i + 1 + "d"
          //                         ) {
          //                           concat = concat.concat(
          //                             element3.input.get()
          //                           );
          //                         }
          //                       });

          //                       if (syntax === "(") {
          //                         concat = concat.concat("(");
          //                       } else if (syntax === "x") {
          //                         concat = concat.concat("x");
          //                       } else if (syntax === ")") {
          //                         concat = concat.concat(")");
          //                       } else if (syntax === ",") {
          //                         concat = concat.concat(",");
          //                       } else if (syntax === ":") {
          //                         concat = concat.concat(":");
          //                       } else if (syntax === ";") {
          //                         concat = concat.concat(";");
          //                       } else if (syntax === "-") {
          //                         concat = concat.concat("-");
          //                       } else if (syntax === "") {
          //                         concat = concat.concat(" ");
          //                       } else if (syntax === ".") {
          //                         concat = concat.concat(".");
          //                       }
          //                     });
          //                   }
          //                   // concat = concat.concat(element2.get()+" ");
          //                 });

          //                 test = test.concat(concat);
          //               }
          //             }
          //           });
          //           if (outputelement === "(") {
          //             test = test.concat("(");
          //           } else if (outputelement === ")") {
          //             test = test.concat(")");
          //           } else if (outputelement === ",") {
          //             test = test.concat(",");
          //           } else if (outputelement === ":") {
          //             test = test.concat(":");
          //           } else if (outputelement === ";") {
          //             test = test.concat(";");
          //           } else if (outputelement === "-") {
          //             test = test.concat("-");
          //           } else if (outputelement === "") {
          //             test = test.concat(" ");
          //           } else if (outputelement === ".") {
          //             test = test.concat(".");
          //           }
          //         }
          //       });

          //       renderoutput = renderoutput.concat(
          //         <>
          //           {/* id={nodeState.input.get()+nodeState.input.path} */}
          //           <div>{test}</div>
          //         </>
          //       );
          //     } else if (
          //       finding.showAlways.get() &&
          //       finding.outputsyntax.get() !== undefined &&
          //       finding.outputsyntax.get() !== ""
          //     ) {
          //       let test = "";
          //       const syntax = finding.outputsyntax.get().split(" ");
          //       console.log(syntax + "test");
          //       syntax.map((outputelement) => {
          //         if (finding.finding.get() !== undefined) {
          //           finding.finding.map((discriptors) => {
          //             if (discriptors.name.get() === outputelement) {
          //               test = test.concat(discriptors.output.get());
          //             } else if (
          //               discriptors.discriptoranchor.get() === outputelement
          //             ) {
          //               if (discriptors.outputs.get() !== []) {
          //                 let concat = "";
          //                 discriptors.outputs.map((element2) => {
          //                   console.log(element2.outputsyntax.get());
          //                   let syntax = null;
          //                   if (element2.outputsyntax.get() !== undefined) {
          //                     syntax = element2.outputsyntax.get().split(" ");
          //                   }
          //                   console.log(syntax);
          //                   element2.data.map((element3, i) => {
          //                     if (element3[element3.input.get() + i].get()) {
          //                       concat = concat.concat(element3.input.get());
          //                     }
          //                   });

          //                   if (syntax !== null) {
          //                     syntax.map((syntax) => {
          //                       console.log(concat + "Hier");

          //                       element2.data.map((element3, i) => {
          //                         if (
          //                           element3.selection.get() ===
          //                             "manualinput" &&
          //                           element3.manualinput.get() !== "" &&
          //                           syntax === i + 1 + "v"
          //                         ) {
          //                           concat = concat.concat(
          //                             element3.manualinput.get()
          //                           );
          //                         } else if (
          //                           element3.selection.get() ===
          //                             "manualinput" &&
          //                           element3.manualinput.get() !== "" &&
          //                           syntax === i + 1 + "d"
          //                         ) {
          //                           concat = concat.concat(
          //                             element3.input.get()
          //                           );
          //                         }
          //                       });

          //                       if (syntax === "(") {
          //                         concat = concat.concat("(");
          //                       } else if (syntax === "x") {
          //                         concat = concat.concat("x");
          //                       } else if (syntax === ")") {
          //                         concat = concat.concat(")");
          //                       } else if (syntax === ",") {
          //                         concat = concat.concat(",");
          //                       } else if (syntax === ":") {
          //                         concat = concat.concat(":");
          //                       } else if (syntax === ";") {
          //                         concat = concat.concat(";");
          //                       } else if (syntax === "-") {
          //                         concat = concat.concat("-");
          //                       } else if (syntax === "") {
          //                         concat = concat.concat(" ");
          //                       } else if (syntax === ".") {
          //                         concat = concat.concat(".");
          //                       }
          //                     });
          //                   }
          //                   // concat = concat.concat(element2.get()+" ");
          //                 });

          //                 test = test.concat(concat);
          //               }
          //             }
          //           });
          //           if (outputelement === "(") {
          //             test = test.concat("(");
          //           } else if (outputelement === ")") {
          //             test = test.concat(")");
          //           } else if (outputelement === ",") {
          //             test = test.concat(",");
          //           } else if (outputelement === ":") {
          //             test = test.concat(":");
          //           } else if (outputelement === ";") {
          //             test = test.concat(";");
          //           } else if (outputelement === "-") {
          //             test = test.concat("-");
          //           } else if (outputelement === "") {
          //             test = test.concat(" ");
          //           } else if (outputelement === ".") {
          //             test = test.concat(".");
          //           }
          //         }
          //       });

          //       renderoutput = renderoutput.concat(
          //         <>
          //           {/* id={nodeState.input.get()+nodeState.input.path} */}
          //           <div>{test}</div>
          //         </>
          //       );
          //     }
          //   });
          // }

          if (nodeState.buttons.get() !== undefined) {
            nodeState.buttons.map((button, i) => {
              console.log(button);
              if (
                button["button" + i].get() &&
                button.outputsyntax.get() !== undefined &&
                button.outputsyntax.get() !== ""
              ) {
                let test = "";
                const syntax = button.outputsyntax.get().split(" ");
                syntax.map((outputelement) => {
                  if (button.finding.get() !== undefined) {
                    button.finding.map((discriptors) => {
                      if (discriptors.name.get() === outputelement) {
                        test = test.concat(discriptors.output.get());
                      } else if (
                        discriptors.discriptoranchor.get() === outputelement
                      ) {
                        if (discriptors.outputs.get() !== []) {
                          let concat = "";
                          discriptors.outputs.map((element2) => {
                            element2.data.map((element3, i) => {
                              if (element3[element3.input.get() + i].get()) {
                                concat = concat.concat(
                                  element3.input.get()
                                );
                              }
                            });
                            // concat = concat.concat(element2.get()+" ");
                          });

                          test = test.concat(concat);
                        }
                      }
                    });
                  }
                });

                // const outputposition = button.outputsyntax
                //   .get()
                //   .search("output");
                // let anchors = [];
                // console.log(anchors);

                // if (button.finding.get() !== undefined) {
                //   button.finding.map((discriptors) => {
                //     if(discriptors.discriptoranchor.get()!== undefined){
                //       const position = button.outputsyntax
                //       .get()
                //       .search(discriptors.discriptoranchor.get());
                //     discriptors.merge({ position: position });
                //     }
                //     else if(discriptors.name.get() === "output") {
                //       const position = button.outputsyntax
                //       .get()
                //       .search("output");
                //     discriptors.merge({ position: position });
                //     }

                //   anchors.push({
                //   name: discriptors.name.get(),
                //   position: position,
                // });
                //   });
                // }

                //const output = button.outputvalue.get();
                //button.finding.attach(Downgraded);

                // const findings = button.finding

                // const sortedfindings = findings.sort(function (a, b) {
                //     return a.position.get() - b.position.get();
                //   })

                // console.log(sortedfindings);
                //  let test = "";

                // button.finding.map((element) => {
                //   // if (element.output !== undefined) {
                //   //     console.log(element.output);
                //   //     test = test.concat(element.output+" ");
                //   //   } else if (element.outputs !== []) {
                //   //     let concat = "";

                //   //     element.outputs.map((element2) => {
                //   //       element2.data.map((element3, i) => {
                //   //         console.log(element3[element3.input+i])
                //   //         if(element3[element3.input+i]){
                //   //           concat = concat.concat(element3.input+" ")
                //   //         }
                //   //       })
                //   //       // concat = concat.concat(element2.get()+" ");
                //   //     });

                //   if (element.output.get() !== undefined) {
                //     console.log(element.output.get());
                //     test = test.concat(element.output.get() + " ");
                //   } else if (element.outputs.get() !== []) {
                //     let concat = "";

                //     element.outputs.map((element2) => {
                //       element2.data.map((element3, i) => {
                //         console.log(element3[element3.input.get() + i].get());
                //         if (element3[element3.input.get() + i].get()) {
                //           concat = concat.concat(element3.input.get() + " ");
                //         }
                //       });
                //       // concat = concat.concat(element2.get()+" ");
                //     });

                //     test = test.concat(concat);
                //   }
                // });

                test = test.charAt(0).toUpperCase() + test.slice(1);


                renderoutput = (
                  <>
                    {/* id={nodeState.input.get()+nodeState.input.path} */}
                    <div>{test}</div>
                  </>
                );

                childrender = <NodeListEditorTest nodes={button.children} />;
              } else if (button.endpoint.get() && button["button" + i].get()) {
                console.log("Button Endpoint mit Children");
                renderoutput = renderoutput.concat(
                  <>
                    {/* id={nodeState.input.get()+nodeState.input.path} */}
                    <div>{button.reportoutput.get()}</div>
                    {button.children.get() !== undefined &&
                    button.children.length > 0 ? (
                      <NodeListEditorTest nodes={button.children} />
                    ) : (
                      ""
                    )}
                  </>
                );
              } else if (
                button["button" + i].get() &&
                button.children.get() !== undefined &&
                button.children.length > 0
              ) {
                console.log("HIER");
                console.log(button.children.get());
                childrender = childrender.concat(
                  <NodeListEditorTest nodes={button.children} />
                );
              } else if (
                !button["button" + i].get() &&
                button.children.get() !== undefined &&
                button.children.length > 0
              ) {
                button.children.map((element) => {
                  element.renderoutput.set(false);
                });
              }
              // else if (button["button" + i].get()) {
              //   renderoutput = renderoutput.concat(<NodeListEditorTest nodes={button.children} />);
              // }
            });
          }
          if (renderoutput.length > 0 || childrender.length > 0) {
            // if(globalstate[nodeState.path[0]].check.get() === undefined){
            //   globalstate[nodeState.path[0]].check.set([])
            // }

            // else if (
            //   !globalstate[nodeState.path[0]].check.some(
            //     (checkelement) => checkelement.get() === nodeState.input.get()
            //   )
            // ) {
            //   console.log("----Success---");
            //   globalstate[nodeState.path[0]].check.merge([
            //     nodeState.input.get(),
            //   ]);
            // }

            // let head = null;

            // if (globalstate[nodeState.path[0]].check.get() !== undefined) {
            //   if (globalstate[nodeState.path[0]].check.length > 0) {
            //     console.log(globalstate[nodeState.path[0]].check.get());
            //     globalstate[nodeState.path[0]].check.map((checkelement) => {
            //       //&& document.getElementById(nodeState.input.get() + nodeState.input.path).innerText !== checkelement.get()
            //       if (checkelement.get() === nodeState.input.get()) {
            //         console.log(checkelement.get());
            //         head = <u id="headline">{checkelement.get() + ":"}</u>;
            //       }
            //     });
            //   }
            // }

            // console.log(head);
            // console.log(childrender)
            // console.log(renderoutput)

            return (
              <>
                {document.getElementById(
                  nodeState.input.get() + nodeState.input.path
                ) !== null &&
                document.getElementById(
                  nodeState.input.get() + nodeState.input.path
                ).innerText.length > 0 &&
                document.getElementById(
                  nodeState.input.get() + nodeState.input.path
                ).innerText !==
                  nodeState.input.get() + ":" &&
                nodeState.outputInput.get() ? (
                  <u>{nodeState.input.get() + ":"}</u>
                ) : (
                  ""
                )}
                {/* <u id="headline">{headline}</u> */}
                <div id={nodeState.input.get() + nodeState.input.path}>
                  {/* {head} */}
                  {renderoutput}
                  {childrender}
                </div>

                
              </>
            );
          }
          // else if (renderoutput.length === 0) {
          //   if (globalstate[nodeState.path[0]].check.get() !== undefined) {
          //     globalstate[nodeState.path[0]].check.map((checkelement) => {
          //       if (checkelement.get() === nodeState.input.get()) {
          //         checkelement.set(none);
          //       }
          //     });
          //   }
          // }
        })}
    </>
  );
}
