import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()

  return (
    <Route
      {...rest}
      render={props => {
        if(currentUser) {
          console.log(currentUser)
          console.log(currentUser.emailVerified)
          if(!currentUser.emailVerified){
            return  <Redirect to="/emailVerification" />
          }
          else if(currentUser.role === undefined){
            return  <Redirect to="/confirmation" />
          }
          else{
            return <Component {...props} />
          }
          
        }
        
        else if(!currentUser){
          return <Redirect to="/login" />
        }
        
      }}
    ></Route>
  )
}