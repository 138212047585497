import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';
import '@hookstate/devtools'
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  
    <App />,
  
  document.getElementById('root')
);

