import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Link, useHistory } from "react-router-dom";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const institutionnameRef = useRef();
  const institutionaddressRef = useRef();
  const institutionpostcodeRef = useRef();
  const institutioncityRef = useRef();
  const institutionRef = useRef();
  const { signup, signupuser, addinstitution } = useAuth();
  const { institutions } = useData()
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwort stimmt nicht überein");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      signupuser(
        firstnameRef.current.value,
        lastnameRef.current.value,
        addressRef.current.value,
        postcodeRef.current.value,
        cityRef.current.value,
        institutionRef.current.value
      );
      history.push("/");
    } catch {
      setError("Fehler beim Erstellen des Accounts");
    }

    setLoading(false);
  }

  function handleInstitution(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      addinstitution(
        institutionnameRef.current.value,
        institutionaddressRef.current.value,
        institutionpostcodeRef.current.value,
        institutioncityRef.current.value
      );
    } catch {
      setError("Fehler beim Erstellen der Institution");
    }

    setLoading(false);
  }

  console.log(institutionRef);
  console.log(firstnameRef);
  console.log(passwordRef);
  

  return (
    <div className="d-flex align-items-center">
      <div className="w-100" style={{ maxWidth: "500px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Anmelden</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Control
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  required
                />
              </Form.Group>

              <InputGroup className="mb-3">
                <Form.Control
                  ref={firstnameRef}
                  placeholder="Vorname"
                  required
                />
                <Form.Control
                  ref={lastnameRef}
                  placeholder="Nachname"
                  required
                />
              </InputGroup>
              <Form.Group id="address">
                <Form.Control ref={addressRef} placeholder="Adresse" required />
              </Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  ref={postcodeRef}
                  placeholder="Postleitzahl"
                  required
                />
                <Form.Control ref={cityRef} placeholder="Stadt" required />
              </InputGroup>

              <Form.Label>Instiution</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  as="select"
                  ref={institutionRef}
                  placeholder="Institution"
                >
                  <option>Auswählen..</option>
                  {institutions.map((element) => {
                    return <option>{element.name}</option>;
                  })}
                </Form.Control>
                <InputGroup.Append>
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover id="popover-contained">
                        <Popover.Content>
                          <Form onSubmit={handleInstitution}>
                            <Form.Group id="name">
                              <Form.Control
                                ref={institutionnameRef}
                                placeholder="Name der Institution"
                                required
                              />
                            </Form.Group>
                            <Form.Group id="address">
                              <Form.Control
                                ref={institutionaddressRef}
                                placeholder="Adresse"
                                required
                              />
                            </Form.Group>
                            <InputGroup className="mb-3">
                              <Form.Control
                                ref={institutionpostcodeRef}
                                placeholder="Postleitzahl"
                                required
                              />
                              <Form.Control
                                ref={institutioncityRef}
                                placeholder="Stadt"
                                required
                              />
                            </InputGroup>
                            <Button onClick={handleInstitution}>
                              hinzufügen
                            </Button>
                          </Form>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Button variant="outline-secondary">hinzufügen</Button>
                  </OverlayTrigger>
                </InputGroup.Append>
              </InputGroup>

              <Form.Group id="password">
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Passwort"
                  required
                />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Passwort bestätigen"
                  required
                />
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit">
                Registrieren
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Bereits registriert? <Link to="/login">Anmelden</Link>
        </div>
      </div>
    </div>
  );
}
