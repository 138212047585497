import React, { useState, useRef } from "react";
import { useState as useStateHook, none, Downgraded } from "@hookstate/core";
import {
  Card,
  Button,
  Form,
  Alert,
  OverlayTrigger,
  Popover,
  ButtonGroup,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  Image,
  Accordion,
  Table,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faMinus,
  faCog,
  faUpload,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { useGlobalState } from "./GlobalState";
import DiscriptorComponent from "./DiscriptorComponent";
import { firestore } from "../firebase";
import { Document, Page, pdfjs } from "react-pdf";
import { useData } from "../contexts/DataContext";
import { storage } from "../firebase";
import MultiSelectSort from "./testSortable";
import { useAuth } from "../contexts/AuthContext";
import validator from "validator";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import { useGlobalState } from "./GlobalState.tsx";

export const DecisionTreeRender = (props) => {
 
  const number = props.number;

  const state = useStateHook(props.componentState);
  console.log(state);

  return (
    <div className="w-100 text-center mt-2">
      <NodeEditor nodes={state} number={number} />
    </div>
  );
};

function NodeEditor(props) {
  // scoped state is optional for performance
  // could have used props.nodes everywhere instead

  const state = useStateHook(props.nodes);
  const { discriptorData } = useData();

  return (
    
            <div style={{ paddingLeft: 2 }}>
              {state.children.get() !== undefined
                ? state.children.map((child) => {
                    if (child.id.get() === "Findings") {
                      return <FindingModule findings={child} />;
                    }
                  })
                : ""}
              

              <div
                className={
                  state.id.get() === "Findings"
                    ? "d-flex justify-content-spacearound"
                    : ""
                }
              >
                
                <div className="d-flex">
                  {state.buttons.get() !== undefined
                    ? state.buttons.map((button, i) => {
                        let data1 = [];
                        if (button.usediscriptor.get()) {
                          console.log(button.discriptordata);
                          return (
                            <>
                              <div
                                style={{
                                  maxWidth: "100%",
                                  position: "relative",
                                }}
                              >
                                {button.helpmodule.get() !== undefined ? (
                                  <>
                                    <MyVerticallyCenteredModal
                                      show={button.showModal.get()}
                                      onHide={() => button.showModal.set(false)}
                                      element={button}
                                    />{" "}
                                    <Button
                                      onClick={() => {
                                        if (
                                          button.showModal.get() === undefined
                                        ) {
                                          button.showModal.set(true);
                                        } else if (
                                          button.showModal.get() === true ||
                                          button.showModal.get() === false
                                        ) {
                                          button.showModal.set(
                                            !button.showModal.get()
                                          );
                                        }
                                      }}
                                      variant="dark"
                                      style={{
                                        fontSize: "0.7rem",
                                        width: "18px",
                                        height: "18px",
                                        padding: "0",
                                        borderRadius: "100%",
                                        position: "absolute",
                                        right: "-5px",
                                        top: "-5px",
                                        border: "none",
                                        backgroundColor: "lightgray",
                                        color: "#545b62",
                                      }}
                                      size="sm"
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        icon={faQuestion}
                                      ></FontAwesomeIcon>
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                                <Button
                                  className="mt-1 mr-1 mb-1"
                                  value={button.buttonvalue.get()}
                                  onClick={() => {
                                    if (button["button" + i].get()) {
                                      button["button" + i].set(false);
                                    } else {
                                      const totallength = state.buttons.get()
                                        .length;
                                      console.log(totallength);
                                      var number;
                                      for (
                                        number = 0;
                                        number < totallength;
                                        number++
                                      ) {
                                        console.log("HIER", number);
                                        const merged = "button" + number;
                                        console.log(
                                          state.buttons[number][merged].get()
                                        );
                                        state.buttons[number][merged].set(
                                          false
                                        );
                                        console.log(state.buttons);
                                      }
                                      button["button" + i].set(
                                        !button["button" + i].get()
                                      );
                                    }
                                  }}
                                >
                                  {button.buttonname.get()}
                                </Button>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                maxWidth: "100%",
                                position: "relative",
                              }}
                            >
                              {button.helpmodule.get() !== undefined ? (
                                <>
                                  <MyVerticallyCenteredModal
                                    show={button.showModal.get()}
                                    onHide={() => button.showModal.set(false)}
                                    element={button}
                                  />
                                  <Button
                                    onClick={() => {
                                      if (
                                        button.showModal.get() === undefined
                                      ) {
                                        button.showModal.set(true);
                                      } else if (
                                        button.showModal.get() === true ||
                                        button.showModal.get() === false
                                      ) {
                                        button.showModal.set(
                                          !button.showModal.get()
                                        );
                                      }
                                    }}
                                    variant="dark"
                                    style={{
                                      fontSize: "0.7rem",
                                      width: "18px",
                                      height: "18px",
                                      padding: "0",
                                      borderRadius: "100%",
                                      position: "absolute",
                                      right: "-5px",
                                      top: "-5px",
                                      border: "none",
                                      backgroundColor: "lightgray",
                                      color: "#545b62",
                                    }}
                                    size="sm"
                                  >
                                    <FontAwesomeIcon
                                      size="xs"
                                      icon={faQuestion}
                                    ></FontAwesomeIcon>
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                              <Button
                                className="mt-1 mr-1 mb-1"
                                value={button.buttonvalue.get()}
                                variant={
                                  button["button" + i].get()
                                    ? "dark"
                                    : "primary"
                                }
                                onClick={() => {
                                  if (button["button" + i].get()) {
                                    button["button" + i].set(false);
                                  } else {
                                    const totallength = state.buttons.get()
                                      .length;
                                    console.log(totallength);
                                    var number;
                                    for (
                                      number = 0;
                                      number < totallength;
                                      number++
                                    ) {
                                      console.log("HIER", number);
                                      const merged = "button" + number;
                                      console.log(
                                        state.buttons[number][merged].get()
                                      );
                                      state.buttons[number][merged].set(false);
                                      console.log(state.buttons);
                                    }
                                    button["button" + i].set(
                                      !button["button" + i].get()
                                    );
                                  }
                                }}
                              >
                                {button.buttonname.get()}
                              </Button>
                            </div>
                          );
                        }
                      })
                    : ""}
                </div>

                {state.buttons.get() !== undefined
                  ? state.buttons.map((button, i) => {
                      let output = null;

                      if (
                        button.children.get() !== undefined &&
                        button.children.length > 0
                      ) {
                        let nodes = [];
                        let findings = [];
                        if (button.children.length > 0) {
                          button.children.map((child) => {
                            if (child.id.get() === "DecisionTree") {
                              console.log(child);
                              nodes = nodes.concat(
                                <div
                                  className="w-100"
                                  style={{
                                    display: button["button" + i].get()
                                      ? ""
                                      : "none",
                                  }}
                                >
                                  <NodeEditor nodes={child} />
                                </div>
                              );
                            } else if (child.id.get() === "Findings") {
                              findings = findings.concat(
                                <FindingModule findings={child} />
                              );

                              // child.findings.map((finding) => {
                              //   findings = findings.concat(
                              //     <div className="mr-1 mb-1">
                              //       <FindingModule finding={finding} />
                              //     </div>
                              //   );
                              // });
                            }
                          });
                        }
                        let output = [];
                        output.push(
                          <div
                            className="m-1"
                            style={{
                              flexWrap: "wrap",
                              display: button["button" + i].get()
                                ? "flex"
                                : "none",
                            }}
                          >
                            {findings}
                          </div>
                        );
                        output.push(nodes);
                        return output;
                      }

                      // if (button.children.get() !== undefined && button.children.length > 0 ) {
                      //   console.log(button.children.get());
                      //   button.children.map((child) => {
                      //     return (output = (
                      //       <div
                      //         style={{
                      //           display: button["button" + i].get()
                      //             ? ""
                      //             : "none",
                      //         }}
                      //       >
                      //         <NodeListEditorTest nodes={child} />
                      //       </div>
                      //     ));
                      //   })

                      // }
                    })
                  : ""}

                {/* {nodeState.buttons.get() !== undefined
                    ? nodeState.buttons.map((button, i) => {
                        console.log(button.get());
                        let output = [];
                        if (button.finding.get() !== undefined) {
                          button.finding.map((element) => {
                            if (element.discriptorname.get() !== undefined) {
                              output = output.concat(
                                <DiscriptorComponent
                                  data={element.outputs}
                                  findings={element}
                                />
                              );
                            }
                          });
                        }
                        console.log(output);
                        return output;
                      })
                    : ""} */}
              </div>

              {/* })} */}
            </div>
          
  );
}

// function NodeListEditorTest(props) {
//   // scoped state is optional for performance
//   // could have used props.nodes everywhere instead

//   const state = useStateHook(props.nodes);

//   return (
//     <Card>
//       <Card.Body>
//         <div style={{ paddingLeft: 20, borderLeft: "solid", marginTop: 10 }}>
//           {state.ornull &&
//             state.ornull.map((nodeState, i) => {
//               console.log(nodeState.buttons.get())
//               if (nodeState.buttons.get() !== undefined) {
//                 nodeState.buttons.map((element, i) => {
//                   if (element.children !== undefined) {
//                     return (
//                       <div key={i}>
//                         <NodeNameEditor nameState={nodeState} />
//                         <NodeListEditorTest nodes={element.children} />
//                       </div>
//                     );
//                   }
//                 });
//               }
//               console.log(state.path);

//               return (
//                 <div key={i}>
//                   <NodeNameEditor nameState={nodeState} />
//                   <NodeListEditorTest nodes={nodeState.children} />
//                 </div>
//               );

//             })}
//         </div>
//       </Card.Body>
//     </Card>
//   );
// }

// function MultiselectionModule(props) {
//   const state = useStateHook(props.state);
//   console.log("MULTI: ", state);

//   const [buttonname, SetButtonName] = useState();
//   const [buttonvalue, SetButtonValue] = useState();
//   const [error, SetError] = useState();
//   const [orientation, SetOrientation] = useState();

//   return (
//     <>
//       {error && <Alert variant="danger">{error}</Alert>}
//       <Form>
//         <InputGroup>
//           <Form.Control
//             onChange={(e) => SetButtonName(e.target.value)}
//             type="input"
//             placeholder="Button Bezeichnung "
//           />
//           <Form.Control
//             type="input"
//             placeholder="Button output"
//             onChange={(e) => SetButtonValue(e.target.value)}
//           />
//           <InputGroup.Append>
//             <Button
//               onClick={() => {
//                 console.log(buttonname);
//                 console.log(buttonvalue);
//                 if (
//                   buttonname !== undefined &&
//                   buttonname !== "" &&
//                   buttonvalue !== undefined &&
//                   buttonvalue !== ""
//                 ) {
//                   if (
//                     state.buttons.some(
//                       (element) => element.buttonname.get() === buttonname
//                     )
//                   ) {
//                     SetError("Name bereits vergeben");
//                     setTimeout(() => SetError(), 2000);
//                   } else {
//                     state.buttons.set((buttons) =>
//                       (buttons || []).concat({
//                         ["button" + state.buttons.get().length]: false,
//                         buttonname: buttonname,
//                         buttonvalue: buttonvalue,
//                       })
//                     );
//                   }
//                 } else {
//                   SetError(
//                     "Fehler beim erstellen des Buttons. Bitte Name und Output eingeben."
//                   );
//                   setTimeout(() => SetError(), 2000);
//                 }
//               }}
//             >
//               add
//             </Button>
//           </InputGroup.Append>
//         </InputGroup>
//       </Form>

//       <div className="m-2">
//         {state.buttons.map((element, i) => (
//           <OverlayTrigger
//             trigger="click"
//             placement="top"
//             overlay={
//               <Popover
//                 id="popover-contained"
//                 onClick={(e) => e.stopPropagation}
//               >
//                 <Popover.Content>
//                   <Form>
//                     <InputGroup>
//                       <Form.Control
//                         onChange={(e) => element.buttonname.set(e.target.value)}
//                         type="input"
//                         placeholder="Button Bezeichnung"
//                         defaultValue={element.buttonname.get()}
//                       />
//                       {/*
//                       <Form.Control
//                         onChange={(e) =>
//                           element.buttonvalue.set(e.target.value)
//                         }
//                         type="input"
//                         placeholder="Button Output"
//                         defaultValue={element.buttonvalue.get()}
//                       /> */}
//                     </InputGroup>
//                   </Form>
//                   <ButtonGroup
//                     vertical
//                     className="m-1 d-flex justify-content-center"
//                   >
//                     <Button>Endpunkt</Button>
//                     <Button>Textausgabe</Button>
//                     <Button
//                       onClick={() => {
//                         if (element.children === undefined) {
//                           element.set({
//                             children: [
//                               {
//                                 id: "DecisionTree",
//                                 input: "",
//                                 outputvalue: "",
//                                 pdfPath: "",
//                                 imagePath: "",
//                                 endpoint: false,
//                                 output: false,
//                                 children: [],
//                               },
//                             ],
//                           });
//                         } else if (element.children !== undefined) {
//                           element.children.set((nodes) =>
//                             (nodes || []).concat([
//                               {
//                                 id: "DecisionTree",
//                                 input: "",
//                                 outputvalue: "",
//                                 pdfPath: "",
//                                 imagePath: "",
//                                 endpoint: false,
//                                 output: false,
//                                 children: [],
//                               },
//                             ])
//                           );
//                         }
//                       }}
//                     >
//                       + Knoten
//                     </Button>
//                     <Button>+ Klassifikation</Button>
//                     <Button
//                       variant={orientation ? "outline-primary" : "primary"}
//                       onClick={() => {
//                         let check = !orientation;
//                         SetOrientation(!orientation);
//                         console.log(check, orientation);
//                         if (check) {
//                           if (element.orientation.get() === undefined) {
//                             element.merge({ orientation: true });
//                           }
//                         } else if (
//                           !check &&
//                           element.orientation.get() !== undefined
//                         ) {
//                           element.orientation.set(none);
//                         }
//                       }}
//                     >
//                       + Diskriptor
//                     </Button>
//                     <Button>+ Hilfe</Button>
//                     <Button onClick={() => element.set(none)}>löschen</Button>
//                   </ButtonGroup>
//                 </Popover.Content>
//               </Popover>
//             }
//           >
//             <Button
//               className="m-1"
//               value={element.buttonvalue.get()}
//               onClick={() =>
//                 element["button" + i].set(!element["button" + i].get())
//               }
//             >
//               {element.buttonname.get()}
//             </Button>
//           </OverlayTrigger>
//         ))}
//       </div>
//     </>
//   );
// }

// function OrientationModule(props) {
//   const state = useStateHook(props.state);

//   return (
//     <>
//       <ButtonGroup vertical>
//         <Button>kranial</Button>
//         <Button>kaudal</Button>
//         <Button>rechts</Button>
//         <Button>links</Button>
//         <Button>anterior</Button>
//         <Button>posterior</Button>
//       </ButtonGroup>
//     </>
//   );
// }

function MyVerticallyCenteredModal(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [src, setSrc] = useState();
  console.log(props.element);
  /*To Prevent right click on screen*/
  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  let pdfurl = null;
  if (props.element.fileupload.get() !== undefined) {
    pdfurl = props.element.fileupload.pdfPath.get();
    //pdfurl = `https://cors-anywhere.herokuapp.com/${props.element.fileupload.pdfPath.get()}`;
  }

  let pdflinkurl = null;
  if (props.element.pdflink.get() !== undefined) {
    pdflinkurl = props.element.pdflink.link.get();
    //pdflinkurl = `https://cors-anywhere.herokuapp.com/${props.element.pdflink.link.get()}`;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      dialogClassName="modal-90w-90h"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.element.helpmodule.get() === "fileupload" &&
          props.element.fileupload.get() !== undefined &&
          props.element.fileupload.discription.get() !== undefined ? (
            <p>{props.element.fileupload.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "imagelink" &&
          props.element.imagelink.get() !== undefined &&
          props.element.imagelink.discription.get() !== undefined ? (
            <p>{props.element.imagelink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "pdflink" &&
          props.element.pdflink.get() !== undefined &&
          props.element.pdflink.discription.get() !== undefined ? (
            <p>{props.element.pdflink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "youtubevideolink" &&
          props.element.youtubevideolink.get() !== undefined &&
          props.element.youtubevideolink.discription.get() !== undefined ? (
            <p>{props.element.youtubevideolink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "webpagelink" &&
          props.element.webpagelink.get() !== undefined &&
          props.element.webpagelink.discription.get() !== undefined ? (
            <p>{props.element.webpagelink.discription.get()}</p>
          ) : (
            ""
          )}
          {/* {props.element.helpmodule.get() === "helpText" &&
          props.element.helpText.get() !== undefined &&
          props.element.helpText.discription.get() !== undefined ? (
            <p>{props.element.helpText.discription.get()}</p>
          ) : (
            ""
          )} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "70vh" }}>
        {props.element.helpmodule.get() === "fileupload" &&
        props.element.fileupload.get() !== undefined &&
        props.element.fileupload.imagePath.get() !== undefined ? (
          <a
            href={props.element.fileupload.link.get()}
            rel="noreferrer"
            target="_blank"
          >
            <Image
              alt={props.element.fileupload.discription.get()}
              style={{ maxHeight: "500px", maxWidth: "100%" }}
              noshow={storage
                .ref(props.element.fileupload.imagePath2.get())
                .getDownloadURL()
                .then((url) => setSrc(url))}
              src={src}
            ></Image>
          </a>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "fileupload" &&
        props.element.fileupload.get() !== undefined &&
        props.element.fileupload.pdfPath.get() !== undefined ? (
          <>
            <div className="d-flex justify-content-space-around ">
              <ButtonGroup>
                <Button variant="dark" disabled>
                  Seite {pageNumber || (numPages ? 1 : "--")} von{" "}
                  {numPages || "--"}
                </Button>

                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="dark"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="dark"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
            <div
              className="main"
              style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
            >
              <Document
                file={storage
                  .ref(props.element.fileupload.pdfPath2.get())
                  .getDownloadURL()}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "imagelink" &&
        props.element.imagelink.get() !== undefined &&
        props.element.imagelink.link.get() !== undefined ? (
          <a
            href={props.element.imagelink.link.get()}
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt={props.element.imagelink.discription.get()}
              style={{ maxHeight: "500px" }}
              src={props.element.imagelink.link.get()}
            ></img>
          </a>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "pdflink" &&
        props.element.pdflink.get() !== undefined &&
        props.element.pdflink.link.get() !== undefined ? (
          <>
            <a
              href={props.element.pdflink.link.get()}
              rel="noreferrer"
              target="_blank"
            >
              {props.element.pdflink.link.get()}
            </a>

            <div className="d-flex justify-content-space-around ">
              <ButtonGroup>
                <Button variant="dark" disabled>
                  Seite {pageNumber || (numPages ? 1 : "--")} von{" "}
                  {numPages || "--"}
                </Button>

                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="dark"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="dark"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
            <div
              className="main"
              style={{ maxHeight: "450px", overflow: "auto" }}
            >
              <Document file={pdflinkurl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "youtubevideolink" &&
        props.element.youtubevideolink.get() !== undefined &&
        props.element.youtubevideolink.link.get() !== undefined ? (
          <div className="d-flex justify-content-center">
            <div>
              <iframe
                width="560"
                height="315"
                src={props.element.youtubevideolink.link.get()}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "webpagelink" &&
        props.element.webpagelink.get() !== undefined &&
        props.element.webpagelink.link.get() !== undefined ? (
          <>
            <iframe
              src={props.element.webpagelink.link.get()}
              frameborder="0"
              width="100%"
              style={{ height: "65vh", overflow: "auto" }}
            ></iframe>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "helpText" &&
        props.element.helpText.get() !== undefined ? (
          <p>{props.element.helpText.get()}</p>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col sm={10} style={{ overflow: "auto", maxHeight: "8vh" }}>
              {props.element.helpmodule.get() === "fileupload" &&
              props.element.fileupload.get() !== undefined &&
              props.element.fileupload.helpText.get() !== undefined ? (
                <p>{props.element.fileupload.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "imagelink" &&
              props.element.imagelink.get() !== undefined &&
              props.element.imagelink.helpText.get() !== undefined ? (
                <p>{props.element.imagelink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "pdflink" &&
              props.element.pdflink.get() !== undefined &&
              props.element.pdflink.helpText.get() !== undefined ? (
                <p>{props.element.pdflink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "youtubevideolink" &&
              props.element.youtubevideolink.get() !== undefined &&
              props.element.youtubevideolink.helpText.get() !== undefined ? (
                <p>{props.element.youtubevideolink.helpText.get()}</p>
              ) : (
                ""
              )}
              {/* {props.element.helpmodule.get() === "webpagelink" &&
              props.element.webpagelink.get() !== undefined &&
              props.element.webpagelink.link.get() !== undefined &&
              props.element.webpagelink.helpText.get() !== undefined ? (
                <p>{props.element.webpagelink.helpText.get()}</p>
              ) : (
                ""
              )} */}
              {props.element.helpmodule.get() === "webpagelink" &&
              props.element.webpagelink.get() !== undefined &&
              props.element.webpagelink.link.get() !== undefined &&
              props.element.webpagelink.helpText.get() !== undefined ? (
                <a
                  href={props.element.webpagelink.link.get()}
                  rel="noreferrer"
                  target="_blank"
                >
                  {props.element.webpagelink.link.get()}
                </a>
              ) : (
                ""
              )}
            </Col>
            <Col sm={2}>
              <Button onClick={props.onHide}>Close</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

export function FindingModule(props) {
  const { discriptorData } = useData();
  const [findingdiscriptorname, setFindingDiscriptorName] = useState();
  const [findingdiscriptoranchor, setFindingDiscriptorAnchor] = useState();
  const [findingdiscriptordata, setFindingDiscriptorData] = useState();
  const [discriptorname, setDiscriptorName] = useState();
  const [selectedFinding, setSelectedFinding] = useState();
  const [newOutput, setNewOutput] = useState();

  const [
    selectedDiscriptorModality,
    SetSelectedDiscriptorModality,
  ] = useState();
  const [selectedDiscriptorRegion, SetSelectedDiscriptorRegion] = useState();
  const [usefilter, setUseFilter] = useState(false);
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectionError, setSelectionError] = useState(false);

  const findings = useStateHook(props.findings);
  findings.attach(Downgraded);
  console.log(findings);
  console.log(discriptorData);

  return (
    <div
      className="d-flex justify-content-spacearound w-100"
      style={{ flexWrap: "wrap" }}
    >
      {findings.get() !== undefined
        ? findings.findings.map((finding, i) => {
            //console.log(finding);
            console.log(finding.showAlways.get());
            if (finding.showAlways.get()) {
              let outeroutput = [];
              let inneroutput = [];
              finding.finding.map((element, i2) => {
                //console.log(finding.get());
                //console.log(element.get());
                if (element.name.get() === undefined) {
                  return (outeroutput = (
                    <>
                      {/* {boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset"} */}
                      {}
                      <div
                        className="mb-1 pt-1 pr-1 pl-1 pb-2"
                        style={{
                          position: "relative",
                          width: "100%",
                          boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
                          borderRadius: "5px",
                          backgroundColor: "rgb(90,90,90)",
                          display:
                            finding.finding.length === 1 &&
                            finding.finding[0].name.get() === "output"
                              ? "none"
                              : "",
                        }}
                      >
                        {finding.helpmodule.get() !== undefined ? (
                          <>
                            <MyVerticallyCenteredModal
                              show={finding.showModal.get()}
                              onHide={() => finding.showModal.set(false)}
                              element={finding}
                            />{" "}
                            <Button
                              onClick={() => {
                                if (finding.showModal.get() === undefined) {
                                  finding.showModal.set(true);
                                } else if (
                                  finding.showModal.get() === true ||
                                  finding.showModal.get() === false
                                ) {
                                  finding.showModal.set(
                                    !finding.showModal.get()
                                  );
                                }
                              }}
                              variant="dark"
                              style={{
                                fontSize: "0.7rem",
                                width: "13px",
                                height: "13px",
                                padding: "0",
                                borderRadius: "100%",
                                position: "absolute",
                                right: "-6px",
                                bottom: "-6px",
                                border: "none",
                                backgroundColor: "lightgray",
                                color: "#545b62",
                              }}
                              size="sm"
                            >
                              <FontAwesomeIcon
                                icon={faQuestion}
                                style={{
                                  fontSize: "0.5rem",
                                  width: "9px",
                                  height: "9px",
                                  padding: "0",
                                  borderRadius: "100%",
                                  position: "absolute",
                                  left: "2px",
                                  bottom: "2px",
                                  border: "none",
                                  color: "#545b62",
                                  pointerEvents: "none",
                                }}
                              ></FontAwesomeIcon>
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          variant="danger"
                          style={{
                            width: "12px",
                            height: "12px",
                            padding: "0",
                            borderRadius: "100%",
                            position: "absolute",
                            right: "-5px",
                            top: "-5px",
                            border: "none",
                          }}
                          size="sm"
                          onClick={() => {
                            if (findings.findings.length > 1) {
                              finding.set(none);
                              findings.findings.map((element, i) =>
                                element.number.set(i)
                              );
                            } else if (findings.findings.get().length === 1) {
                              findings.set(none);
                              findings.findings.map((element, i) =>
                                element.number.set(i)
                              );
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: "0.5rem",
                              width: "10px",
                              height: "10px",
                              padding: "0",
                              borderRadius: "100%",
                              position: "absolute",
                              left: "1px",
                              bottom: "1px",
                              border: "none",
                              color: "white",
                              pointerEvents: "none",
                            }}
                            icon={faMinus}
                          ></FontAwesomeIcon>
                        </Button>
                        <OverlayTrigger
                          trigger="click"
                          placement="auto"
                          rootClose={modalShow ? false : true}
                          overlay={
                            <Popover
                              style={{
                                minWidth: "500px",
                                minHeight: "500px",
                                overflow: "auto",
                              }}
                              onClick={(e) => e.stopPropagation}
                            >
                              <Popover.Content>
                                <Form>
                                  <MultiSelectSort
                                    finding={finding.selectedoutputArray}
                                    selectedoutputArray={
                                      finding.selectedoutputArray.get() !==
                                      undefined
                                        ? finding.selectedoutputArray.get()
                                        : []
                                    }
                                    outputArray={
                                      finding.outputsyntaxArray.get() !==
                                      undefined
                                        ? finding.outputsyntaxArray.get()
                                        : []
                                    }
                                  />
                                  <br />
                                  <InputGroup>
                                    <Form.Control
                                      placeholder="neuer Text"
                                      id="FormOutput"
                                      onChange={(e) => {
                                        if (
                                          finding.outputsyntaxArray.get() ===
                                          undefined
                                        ) {
                                          setNewOutput(
                                            Object.assign({
                                              value: e.target.value + "0",
                                              label: e.target.value,
                                              type: "output",
                                            })
                                          );
                                        } else {
                                          setNewOutput(
                                            Object.assign({
                                              value:
                                                e.target.value +
                                                finding.outputsyntaxArray
                                                  .length,
                                              label: e.target.value,
                                              type: "output",
                                            })
                                          );
                                        }
                                      }}
                                    ></Form.Control>
                                    <InputGroup.Append>
                                      <Button
                                        onClick={() => {
                                          console.log(newOutput);
                                          if (newOutput !== undefined) {
                                            if (
                                              finding.outputsyntaxArray.get() ===
                                              undefined
                                            ) {
                                              finding.selectedoutputArray.merge(
                                                [newOutput]
                                              );

                                              finding.outputsyntaxArray.merge([
                                                newOutput,
                                              ]);
                                            } else if (
                                              finding.outputsyntaxArray.some(
                                                (element) =>
                                                  element.get() === newOutput
                                              )
                                            ) {
                                              if (
                                                window.confirm(
                                                  "Wollen Sie diesen Text wirklich mehrfach verwenden?"
                                                )
                                              ) {
                                                finding.selectedoutputArray.merge(
                                                  [
                                                    {
                                                      value:
                                                        newOutput.value +
                                                        finding
                                                          .outputsyntaxArray
                                                          .length,
                                                      label: newOutput.label,
                                                      type: "output",
                                                    },
                                                  ]
                                                );

                                                finding.outputsyntaxArray.merge(
                                                  [
                                                    {
                                                      value:
                                                        newOutput.value +
                                                        finding
                                                          .outputsyntaxArray
                                                          .length,
                                                      label: newOutput.label,
                                                      type: "output",
                                                    },
                                                  ]
                                                );
                                              }
                                            } else {
                                              finding.selectedoutputArray.merge(
                                                [newOutput]
                                              );

                                              finding.outputsyntaxArray.merge([
                                                newOutput,
                                              ]);
                                            }

                                            setNewOutput();
                                            document.getElementById(
                                              "FormOutput"
                                            ).value = "";
                                          } else {
                                            setError(true);
                                            setTimeout(
                                              () => setError(false),
                                              3000
                                            );
                                          }
                                        }}
                                      >
                                        +
                                      </Button>
                                      <Button
                                    onClick={() => {
                                      console.log(newOutput);
                                      if (newOutput !== undefined) {
                                        let changedOutput = newOutput

                                        if (
                                          finding.outputsyntaxArray.get() ===
                                          undefined
                                        ) {
                                          changedOutput.type = "condition"
                                          finding.selectedoutputArray.merge([
                                            changedOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            changedOutput,
                                          ]);
                                        } else if (
                                          finding.outputsyntaxArray.some(
                                            (element) =>
                                              element.get() === newOutput
                                          )
                                        ) {
                                          if (
                                            window.confirm(
                                              "Wollen Sie diesen Text wirklich mehrfach verwenden?"
                                            )
                                          ) {
                                            finding.selectedoutputArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "condition",
                                              },
                                            ]);

                                            finding.outputsyntaxArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "condition",
                                              },
                                            ]);
                                          }
                                        } else {
                                          changedOutput.type = "condition"

                                          finding.selectedoutputArray.merge([
                                            changedOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            changedOutput,
                                          ]);
                                        }

                                        setNewOutput();
                                        document.getElementById(
                                          "FormOutput"
                                        ).value = "";
                                      } else {
                                        setError(true);
                                        setTimeout(() => setError(false), 3000);
                                      }
                                    }}
                                  >+ <FontAwesomeIcon icon={faLink}></FontAwesomeIcon></Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                  <br />
                                  {error ? (
                                    <Alert variant="danger">
                                      Bitte Text eingeben
                                    </Alert>
                                  ) : (
                                    ""
                                  )}

                                  <Form.Row>
                                    <Form.Group as={Col} xs={0}>
                                      <InputGroup>
                                        <Form.Control
                                          id="DiscriptorSelection"
                                          onChange={(e) => {
                                            setFindingDiscriptorName(
                                              e.target.value
                                            );
                                            setDiscriptorName(e.target.value);
                                            // setNewOutput(
                                            //   Object.assign({
                                            //     value: e.target.value,
                                            //     label: e.target.value,
                                            //     type: "finding",
                                            //   })
                                            // )
                                          }}
                                          as="select"
                                          placeholder="Template/Modulbezeichnung"
                                        >
                                          <option>auswählen...</option>
                                          {discriptorData
                                            ? discriptorData.map((element) => {
                                                let output = [];
                                                if (usefilter) {
                                                  if (
                                                    selectedDiscriptorModality !==
                                                      undefined &&
                                                    selectedDiscriptorModality !==
                                                      "" &&
                                                    selectedDiscriptorRegion !==
                                                      undefined &&
                                                    selectedDiscriptorRegion !==
                                                      ""
                                                  ) {
                                                    if (
                                                      element.modality ===
                                                        selectedDiscriptorModality &&
                                                      element.region ===
                                                        selectedDiscriptorRegion
                                                    )
                                                      output = output.concat(
                                                        <option
                                                          value={element.name}
                                                        >
                                                          {element.name}
                                                        </option>
                                                      );
                                                  } else if (
                                                    selectedDiscriptorModality !==
                                                      undefined &&
                                                    selectedDiscriptorModality !==
                                                      "" &&
                                                    (selectedDiscriptorRegion ===
                                                      undefined ||
                                                      selectedDiscriptorRegion ===
                                                        "")
                                                  ) {
                                                    if (
                                                      element.modality ===
                                                      selectedDiscriptorModality
                                                    ) {
                                                      output = output.concat(
                                                        <option
                                                          value={element.name}
                                                        >
                                                          {element.name}
                                                        </option>
                                                      );
                                                    }
                                                  } else if (
                                                    (selectedDiscriptorModality ===
                                                      undefined ||
                                                      selectedDiscriptorModality ===
                                                        "") &&
                                                    selectedDiscriptorRegion !==
                                                      undefined &&
                                                    selectedDiscriptorRegion !==
                                                      ""
                                                  ) {
                                                    if (
                                                      element.region ===
                                                      selectedDiscriptorRegion
                                                    ) {
                                                      output = output.concat(
                                                        <option
                                                          value={element.name}
                                                        >
                                                          {element.name}
                                                        </option>
                                                      );
                                                    }
                                                  }
                                                } else {
                                                  output = output.concat(
                                                    <option
                                                      value={element.name}
                                                    >
                                                      {element.name}
                                                    </option>
                                                  );
                                                }
                                                return output;
                                              })
                                            : ""}
                                        </Form.Control>
                                        <InputGroup.Append>
                                          <Button
                                            onClick={() => {
                                              if (
                                                finding.outputsyntaxArray.get() ===
                                                undefined
                                              ) {
                                                finding.outputsyntaxArray.set(
                                                  []
                                                );
                                              }
                                              if (
                                                findingdiscriptorname !==
                                                undefined
                                              ) {
                                                //finding.outputsyntaxArray.map(element => console.log(element.label.get() === newOutput.label))
                                                //console.log(newOutput.label)
                                                if (
                                                  finding.outputsyntaxArray
                                                    .length > 0 &&
                                                  finding.outputsyntaxArray.some(
                                                    (element) =>
                                                      element.label.get() ===
                                                      discriptorname
                                                  )
                                                ) {
                                                  if (
                                                    window.confirm(
                                                      "Wollen Sie diesen Discriptor wirklich mehrfach verwenden?"
                                                    )
                                                  ) {
                                                    console.log("confirmed");
                                                    discriptorData.map(
                                                      (discriptor) => {
                                                        if (
                                                          discriptor.name ===
                                                          discriptorname
                                                        ) {
                                                          finding.finding.merge(
                                                            [
                                                              Object.assign(
                                                                {
                                                                  discriptorname:
                                                                    findingdiscriptorname +
                                                                    finding
                                                                      .outputsyntaxArray
                                                                      .length,
                                                                },
                                                                findingdiscriptoranchor,
                                                                {
                                                                  outputs: [].concat(
                                                                    JSON.parse(
                                                                      JSON.stringify(
                                                                        discriptor.data
                                                                      )
                                                                    )
                                                                  ),
                                                                }
                                                              ),
                                                            ]
                                                          );

                                                          finding.outputsyntaxArray.merge(
                                                            [
                                                              {
                                                                value:
                                                                  discriptorname +
                                                                  finding
                                                                    .outputsyntaxArray
                                                                    .length,
                                                                label: discriptorname,
                                                                type: "finding",
                                                              },
                                                            ]
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }
                                                } else {
                                                  discriptorData.map(
                                                    (discriptor) => {
                                                      if (
                                                        discriptor.name ===
                                                        discriptorname
                                                      ) {
                                                        finding.finding.merge([
                                                          Object.assign(
                                                            {
                                                              discriptorname:
                                                                findingdiscriptorname +
                                                                finding
                                                                  .outputsyntaxArray
                                                                  .length,
                                                            },
                                                            findingdiscriptoranchor,
                                                            {
                                                              outputs: [].concat(
                                                                JSON.parse(
                                                                  JSON.stringify(
                                                                    discriptor.data
                                                                  )
                                                                )
                                                              ),
                                                            }
                                                          ),
                                                        ]);

                                                        if (
                                                          finding.selectedoutputArray.get() ===
                                                          undefined
                                                        ) {
                                                          finding.selectedoutputArray.merge(
                                                            [
                                                              {
                                                                value:
                                                                  discriptorname +
                                                                  "0",
                                                                label: discriptorname,
                                                                type: "finding",
                                                              },
                                                            ]
                                                          );
                                                        } else {
                                                          finding.selectedoutputArray.merge(
                                                            [
                                                              {
                                                                value:
                                                                  discriptorname +
                                                                  finding
                                                                    .outputsyntaxArray
                                                                    .length,
                                                                label: discriptorname,
                                                                type: "finding",
                                                              },
                                                            ]
                                                          );
                                                        }
                                                      }

                                                      if (
                                                        finding.outputsyntaxArray.get() ===
                                                        undefined
                                                      ) {
                                                        finding.outputsyntaxArray.merge(
                                                          [
                                                            {
                                                              value:
                                                                discriptorname +
                                                                "0",
                                                              label: discriptorname,
                                                              type: "finding",
                                                            },
                                                          ]
                                                        );
                                                      } else {
                                                        finding.outputsyntaxArray.merge(
                                                          [
                                                            {
                                                              value:
                                                                discriptorname +
                                                                finding
                                                                  .outputsyntaxArray
                                                                  .length,
                                                              label: discriptorname,
                                                              type: "finding",
                                                            },
                                                          ]
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                              } else {
                                                setSelectionError(true);
                                                setTimeout(
                                                  () =>
                                                    setSelectionError(false),
                                                  3000
                                                );
                                              }

                                              document.getElementById(
                                                "DiscriptorSelection"
                                              ).selectedIndex = 0;
                                              setDiscriptorName();
                                              setFindingDiscriptorName();
                                            }}
                                          >
                                            +
                                          </Button>
                                          <InputGroup.Text>
                                            Filter
                                          </InputGroup.Text>
                                          <InputGroup.Checkbox
                                            aria-label="Checkbox for following text input"
                                            onClick={(e) => {
                                              setUseFilter(!usefilter);
                                              // if(!usefilter){
                                              //   SetSelectedDiscriptorModality()
                                              //   SetSelectedDiscriptorRegion()
                                              // }
                                            }}
                                          />
                                        </InputGroup.Append>
                                      </InputGroup>
                                    </Form.Group>
                                  </Form.Row>
                                  <Form.Row>
                                    <Form.Group
                                      as={Col}
                                      style={{
                                        display: usefilter ? "" : "none",
                                      }}
                                    >
                                      <Form.Control
                                        onChange={(e) =>
                                          SetSelectedDiscriptorModality(
                                            e.target.value
                                          )
                                        }
                                        as="select"
                                        placeholder="Modalität"
                                      >
                                        <option>Modalität auswählen...</option>
                                        <option value="allgemein">
                                          allgemein
                                        </option>
                                        <option value="CT">CT</option>
                                        <option value="MRT">MRT</option>
                                        <option value="Röntgen">Röntgen</option>
                                        <option value="Sonographie">
                                          Sonographie
                                        </option>
                                        <option value="PET-CT">PET-CT</option>
                                        <option value="IR">IR</option>
                                      </Form.Control>
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      style={{
                                        display: usefilter ? "" : "none",
                                      }}
                                    >
                                      <Form.Control
                                        onChange={(e) =>
                                          SetSelectedDiscriptorRegion(
                                            e.target.value
                                          )
                                        }
                                        as="select"
                                        placeholder="Region"
                                      >
                                        <option>Region auswählen...</option>
                                        <option value="allgemein">
                                          allgemein
                                        </option>
                                        <option value="Ganzkörper">
                                          Ganzkörper
                                        </option>
                                        <option value="Kopf">Kopf</option>
                                        <option value="Hals">Hals</option>
                                        <option value="Kopf/Hals">
                                          Kopf/Hals
                                        </option>
                                        <option value="Thorax">Thorax</option>
                                        <option value="Abdomen">Abdomen</option>
                                        <option value="Thorax/Abdomen">
                                          Thorax/Abdomen
                                        </option>
                                        <option value="Becken">Becken</option>
                                        <option value="Abdomen/Becken">
                                          Abdomen/Becken
                                        </option>
                                        <option value="Obere Extremität">
                                          Obere Extremität
                                        </option>
                                        <option value="Untere Extremität">
                                          Untere Extremität
                                        </option>
                                      </Form.Control>
                                    </Form.Group>
                                  </Form.Row>

                                  <br />
                                  {selectionError ? (
                                    <Alert variant="danger">
                                      Bitte Discriptor auswählen
                                    </Alert>
                                  ) : (
                                    ""
                                  )}

                                  <Table striped bordered hover size="sm">
                                    {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
                                    <tbody>
                                      {finding.outputsyntaxArray.get() !==
                                        undefined &&
                                      finding.outputsyntaxArray.length > 0
                                        ? finding.outputsyntaxArray.map(
                                            (element, index) => {
                                              return (
                                                <tr style={{ height: "50px" }}>
                                                  <td style={{ width: "30px" }}>
                                                    {index + 1}
                                                  </td>
                                                  <td>
                                                    {element.type.get() ===
                                                    "finding" ? (
                                                      element.label.get()
                                                    ) : (
                                                      <Form.Control
                                                        onChange={(e) => {
                                                          element.label.set(
                                                            e.target.value
                                                          );
                                                          element.merge({
                                                            value:
                                                              e.target.value +
                                                              index,
                                                          });
                                                          if (
                                                            finding.selectedoutputArray.get() !==
                                                              undefined &&
                                                            finding
                                                              .selectedoutputArray
                                                              .length > 0
                                                          ) {
                                                            finding.selectedoutputArray.map(
                                                              (
                                                                selectedelement
                                                              ) => {
                                                                if (
                                                                  selectedelement.label.get() ===
                                                                  element.label.get()
                                                                ) {
                                                                  selectedelement.label.set(
                                                                    e.target
                                                                      .value
                                                                  );

                                                                  selectedelement.merge(
                                                                    {
                                                                      value:
                                                                        e.target
                                                                          .value +
                                                                        index,
                                                                    }
                                                                  );
                                                                }
                                                              }
                                                            );
                                                          }
                                                        }}
                                                        defaultValue={element.label.get()}
                                                      ></Form.Control>
                                                    )}
                                                  </td>
                                                  <td style={{ width: "30px" }}>
                                                    <Button
                                                      onClick={() => {
                                                        if (
                                                          finding.selectedoutputArray.get() !==
                                                            undefined &&
                                                          finding
                                                            .selectedoutputArray
                                                            .length > 0
                                                        ) {
                                                          finding.selectedoutputArray.map(
                                                            (
                                                              selectedelement
                                                            ) => {
                                                              if (
                                                                selectedelement.label.get() ===
                                                                element.label.get()
                                                              ) {
                                                                selectedelement.set(
                                                                  none
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }

                                                        finding.finding.map(
                                                          (finding) => {
                                                            console.log(
                                                              finding
                                                            );
                                                            if (
                                                              finding.discriptorname.get() ===
                                                              element.value
                                                                .value
                                                            ) {
                                                              finding.set(none);
                                                            }
                                                          }
                                                        );

                                                        element.set(none);
                                                      }}
                                                    >
                                                      -
                                                    </Button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        : ""}
                                    </tbody>
                                  </Table>

                                  <br />
                                  <Button
                                    variant={
                                      finding.showAlways.get()
                                        ? "dark"
                                        : "primary"
                                    }
                                    onClick={() => {
                                      if (
                                        finding.showAlways.get() === undefined
                                      ) {
                                        finding.showAlways.set(true);
                                      } else if (
                                        finding.showAlways.get() !== undefined
                                      ) {
                                        finding.showAlways.set(
                                          !finding.showAlways.get()
                                        );
                                      }
                                    }}
                                  >
                                    direkt anzeigen
                                  </Button>
                                  <br />
                                  <Button
                                    className="mt-2"
                                    onClick={() => setModalShow(true)}
                                  >
                                    + Hilfe
                                  </Button>
                                  {/* </OverlayTrigger> */}
                                  <FileUploadModal
                                    show={modalShow}
                                    element={finding}
                                    onHide={() => setModalShow(false)}
                                  ></FileUploadModal>
                                  <br />
                                  <Form.Control
                                    type="input"
                                    defaultValue={finding.name.get()}
                                    onChange={(e) =>
                                      finding.name.set(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          {/* <OverlayTrigger
                          trigger="click"
                          placement="auto"
                          rootClose={true}
                          overlay={
                            <Popover
                              style={{ minWidth: "500px", minHeight: "500px" }}
                            >
                              <Popover.Content>
                                <Form>
                                  <Form.Control
                                    placeholder="Output Syntax"
                                    defaultValue={
                                      finding.outputsyntax.get() !== undefined
                                        ? finding.outputsyntax.get()
                                        : ""
                                    }
                                    onChange={(e) =>
                                      finding.merge({
                                        outputsyntax: e.target.value,
                                      })
                                    }
                                  ></Form.Control>
                                  <br />
                                  <Form.Control
                                    placeholder="output value"
                                    defaultValue={finding.finding
                                      .map((element) => {
                                        if (element.name.get() === "output") {
                                          return element.output.get();
                                        }
                                      })
                                      .join("")}
                                    onChange={(e) => {
                                      finding.finding.map((element) => {
                                        if (element.name.get() === "output") {
                                          element.output.set(e.target.value);
                                        }
                                      });
                                    }}
                                  ></Form.Control>
                                  <br />
                                  <Button
                                    variant={
                                      finding.showAlways.get()
                                        ? "dark"
                                        : "primary"
                                    }
                                    onClick={() => {
                                      if (
                                        finding.showAlways.get() === undefined
                                      ) {
                                        finding.showAlways.set(true);
                                      } else if (
                                        finding.showAlways.get() !== undefined
                                      ) {
                                        finding.showAlways.set(
                                          !finding.showAlways.get()
                                        );
                                      }
                                    }}
                                  >
                                    direkt anzeigen
                                  </Button>
                                  <br />
                                  <br />
                                  <InputGroup>
                                    <Form.Control
                                      onChange={(e) => {
                                        setFindingDiscriptorName({
                                          discriptorname: e.target.value,
                                        });
                                        setDiscriptorName(e.target.value);
                                      }}
                                      as="select"
                                      placeholder="Template/Modulbezeichnung"
                                    >
                                      <option>auswählen...</option>
                                      {discriptorData
                                        ? discriptorData.map((element) => {
                                            return (
                                              <option value={element.name}>
                                                {element.name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </Form.Control>

                                    <Form.Control
                                      placeholder="discriptor anchor"
                                      onChange={(e) => {
                                        setFindingDiscriptorAnchor({
                                          discriptoranchor: e.target.value,
                                        });
                                      }}
                                    ></Form.Control>
                                    <InputGroup.Append>
                                      <Button
                                        onClick={() => {
                                          if (
                                            findingdiscriptoranchor !==
                                              undefined &&
                                            findingdiscriptorname !== undefined
                                          ) {
                                            discriptorData.map((discriptor) => {
                                              if (
                                                discriptor.name ===
                                                discriptorname
                                              ) {
                                                finding.finding.merge([
                                                  Object.assign(
                                                    findingdiscriptorname,
                                                    findingdiscriptoranchor,
                                                    {
                                                      outputs: [].concat(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            discriptor.data
                                                          )
                                                        )
                                                      ),
                                                    }
                                                  ),
                                                ]);
                                              }
                                            });
                                          }
                                        }}
                                      >
                                        +
                                      </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                  <br />
                                  {finding.finding.get() !== undefined ? (
                                    <Card>
                                      <Card.Body>
                                        {finding.finding.map((element) => {
                                          if (
                                            element.discriptorname.get() !==
                                            undefined
                                          ) {
                                            return (
                                              <>
                                                <InputGroup>
                                                  <Form.Control
                                                    onChange={(e) => {
                                                      element.discriptorname.set(
                                                        e.target.value
                                                      );

                                                      discriptorData.map(
                                                        (discriptor) => {
                                                          if (
                                                            discriptor.name ===
                                                            e.target.value
                                                          ) {
                                                            element.outputs.set(
                                                              [].concat(
                                                                JSON.parse(
                                                                  JSON.stringify(
                                                                    discriptor.data
                                                                  )
                                                                )
                                                              )
                                                            );
                                                          }
                                                        }
                                                      );

                                                      //   discriptorData.map(
                                                      //     (discriptor) => {
                                                      //       if (
                                                      //         discriptor.name ===
                                                      //         e.target.value
                                                      //       ) {
                                                      //         element.outputs.set(
                                                      //           discriptor.data
                                                      //         );
                                                      //       }
                                                      //     }
                                                      //   );
                                                      // }}
                                                    }}
                                                    defaultValue={element.discriptorname.get()}
                                                    as="select"
                                                    placeholder="Template/Modulbezeichnung"
                                                  >
                                                    <option>
                                                      auswählen...
                                                    </option>
                                                    {discriptorData
                                                      ? discriptorData.map(
                                                          (element) => {
                                                            return (
                                                              <option
                                                                value={
                                                                  element.name
                                                                }
                                                              >
                                                                {element.name}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      : ""}
                                                  </Form.Control>
                                                  <br />
                                                  <Form.Control
                                                    defaultValue={element.discriptoranchor.get()}
                                                    onChange={(e) =>
                                                      element.discriptoranchor.set(
                                                        e.target.value
                                                      )
                                                    }
                                                  ></Form.Control>
                                                  <InputGroup.Append>
                                                    <Button
                                                      onClick={() =>
                                                        element.set(none)
                                                      }
                                                    >
                                                      -
                                                    </Button>
                                                  </InputGroup.Append>
                                                </InputGroup>
                                                <br />
                                              </>
                                            );
                                          }
                                        })}
                                      </Card.Body>
                                    </Card>
                                  ) : (
                                    ""
                                  )}
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                        > */}
                          <Button
                            style={{
                              width: "14px",
                              height: "14px",
                              padding: "0",
                              borderRadius: "100%",
                              position: "absolute",
                              left: "-6px",
                              top: "-6px",
                              border: "none",
                              backgroundColor: "white",
                            }}
                            size="sm"
                            active
                          >
                            <FontAwesomeIcon
                              style={{
                                fontSize: "0.7rem",
                                width: "12px",
                                height: "12px",
                                padding: "0",
                                borderRadius: "100%",
                                position: "absolute",
                                left: "1px",
                                bottom: "1px",
                                border: "none",
                                backgroundColor: "white",
                                color: "#545b62",
                                pointerEvents: "none",
                              }}
                              icon={faCog}
                            ></FontAwesomeIcon>
                          </Button>
                        </OverlayTrigger>
                        {
                          (inneroutput = inneroutput.concat(
                            <DiscriptorComponent
                              key={JSON.stringify(i) + JSON.stringify(i2)}
                              data={element.outputs}
                              number={finding.number.get()}
                            />
                          ))
                        }
                      </div>
                    </>
                  ));
                }
              });

              return outeroutput;
            } else {
              return (
                <>
                  <div style={{ position: "relative", maxWidth: "100%" }}>
                    <Button
                      variant="danger"
                      style={{
                        width: "12px",
                        height: "12px",
                        padding: "0",
                        borderRadius: "100%",
                        position: "absolute",
                        left: "-3px",
                        top: "3px",
                        border: "none",
                      }}
                      size="sm"
                      onClick={() => {
                        if (findings.findings.get().length > 1) {
                          finding.set(none);
                          findings.findings.map((element, i) =>
                            element.number.set(i)
                          );
                        } else if (findings.findings.get().length === 1) {
                          findings.set(none);
                          findings.findings.map((element, i) =>
                            element.number.set(i)
                          );
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "0.5rem",
                          width: "10px",
                          height: "10px",
                          padding: "0",
                          borderRadius: "100%",
                          position: "absolute",
                          left: "1px",
                          bottom: "1px",
                          border: "none",
                          color: "white",
                          pointerEvents: "none",
                        }}
                        icon={faMinus}
                      ></FontAwesomeIcon>
                    </Button>
                    {finding.helpmodule.get() !== undefined ? (
                      <>
                        <MyVerticallyCenteredModal
                          show={finding.showModal.get()}
                          onHide={() => finding.showModal.set(false)}
                          element={finding}
                        />{" "}
                        <Button
                          onClick={() => {
                            if (finding.showModal.get() === undefined) {
                              finding.showModal.set(true);
                            } else if (
                              finding.showModal.get() === true ||
                              finding.showModal.get() === false
                            ) {
                              finding.showModal.set(!finding.showModal.get());
                            }
                          }}
                          variant="dark"
                          style={{
                            fontSize: "0.7rem",
                            width: "13px",
                            height: "13px",
                            padding: "0",
                            borderRadius: "100%",
                            position: "absolute",
                            right: "4px",
                            top: "2px",
                            border: "none",
                            backgroundColor: "lightgray",
                            color: "#545b62",
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon
                            icon={faQuestion}
                            style={{
                              fontSize: "0.5rem",
                              width: "9px",
                              height: "9px",
                              padding: "0",
                              borderRadius: "100%",
                              position: "absolute",
                              left: "2px",
                              bottom: "2px",
                              border: "none",
                              color: "#545b62",
                              pointerEvents: "none",
                            }}
                          ></FontAwesomeIcon>
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    <OverlayTrigger
                      trigger="click"
                      placement="auto"
                      rootClose={modalShow ? false : true}
                      overlay={
                        <Popover
                          style={{
                            minWidth: "500px",
                            minHeight: "500px",
                            maxHeight: "700px",
                            overflow: "auto",
                          }}
                          onClick={(e) => e.stopPropagation}
                        >
                          <Popover.Content>
                            <Form>
                              <MultiSelectSort
                                finding={finding.selectedoutputArray}
                                selectedoutputArray={
                                  finding.selectedoutputArray.get() !==
                                  undefined
                                    ? finding.selectedoutputArray.get()
                                    : []
                                }
                                outputArray={
                                  finding.outputsyntaxArray.get() !== undefined
                                    ? finding.outputsyntaxArray.get()
                                    : []
                                }
                              />
                              <br />
                              <InputGroup>
                                <Form.Control
                                  placeholder="neuer Text"
                                  id="FormOutput"
                                  onChange={(e) => {
                                    if (
                                      finding.outputsyntaxArray.get() ===
                                      undefined
                                    ) {
                                      setNewOutput(
                                        Object.assign({
                                          value: e.target.value + "0",
                                          label: e.target.value,
                                          type: "output",
                                        })
                                      );
                                    } else {
                                      setNewOutput(
                                        Object.assign({
                                          value:
                                            e.target.value +
                                            finding.outputsyntaxArray.length,
                                          label: e.target.value,
                                          type: "output",
                                        })
                                      );
                                    }
                                  }}
                                ></Form.Control>
                                <InputGroup.Append>
                                  <Button
                                    onClick={() => {
                                      console.log(newOutput);
                                      if (newOutput !== undefined) {
                                        if (
                                          finding.outputsyntaxArray.get() ===
                                          undefined
                                        ) {
                                          finding.selectedoutputArray.merge([
                                            newOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            newOutput,
                                          ]);
                                        } else if (
                                          finding.outputsyntaxArray.some(
                                            (element) =>
                                              element.get() === newOutput
                                          )
                                        ) {
                                          if (
                                            window.confirm(
                                              "Wollen Sie diesen Text wirklich mehrfach verwenden?"
                                            )
                                          ) {
                                            finding.selectedoutputArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "output",
                                              },
                                            ]);

                                            finding.outputsyntaxArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "output",
                                              },
                                            ]);
                                          }
                                        } else {
                                          finding.selectedoutputArray.merge([
                                            newOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            newOutput,
                                          ]);
                                        }

                                        setNewOutput();
                                        document.getElementById(
                                          "FormOutput"
                                        ).value = "";
                                      } else {
                                        setError(true);
                                        setTimeout(() => setError(false), 3000);
                                      }
                                    }}
                                  >
                                    +
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      console.log(newOutput);
                                      if (newOutput !== undefined) {
                                        let changedOutput = newOutput

                                        if (
                                          finding.outputsyntaxArray.get() ===
                                          undefined
                                        ) {
                                          changedOutput.type = "condition"
                                          finding.selectedoutputArray.merge([
                                            changedOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            changedOutput,
                                          ]);
                                        } else if (
                                          finding.outputsyntaxArray.some(
                                            (element) =>
                                              element.get() === newOutput
                                          )
                                        ) {
                                          if (
                                            window.confirm(
                                              "Wollen Sie diesen Text wirklich mehrfach verwenden?"
                                            )
                                          ) {
                                            finding.selectedoutputArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "condition",
                                              },
                                            ]);

                                            finding.outputsyntaxArray.merge([
                                              {
                                                value:
                                                  newOutput.value +
                                                  finding.outputsyntaxArray
                                                    .length,
                                                label: newOutput.label,
                                                type: "condition",
                                              },
                                            ]);
                                          }
                                        } else {
                                          changedOutput.type = "condition"

                                          finding.selectedoutputArray.merge([
                                            changedOutput,
                                          ]);

                                          finding.outputsyntaxArray.merge([
                                            changedOutput,
                                          ]);
                                        }

                                        setNewOutput();
                                        document.getElementById(
                                          "FormOutput"
                                        ).value = "";
                                      } else {
                                        setError(true);
                                        setTimeout(() => setError(false), 3000);
                                      }
                                    }}
                                  >+ <FontAwesomeIcon icon={faLink}></FontAwesomeIcon></Button>

                                </InputGroup.Append>
                              </InputGroup>
                              <br />
                              {error ? (
                                <Alert variant="danger">
                                  Bitte Text eingeben
                                </Alert>
                              ) : (
                                ""
                              )}

                              <Form.Row>
                                <Form.Group as={Col} xs={0}>
                                  <InputGroup>
                                    <Form.Control
                                      id="DiscriptorSelection"
                                      onChange={(e) => {
                                        setFindingDiscriptorName(
                                          e.target.value
                                        );
                                        setDiscriptorName(e.target.value);
                                        // setNewOutput(
                                        //   Object.assign({
                                        //     value: e.target.value,
                                        //     label: e.target.value,
                                        //     type: "finding",
                                        //   })
                                        // )
                                      }}
                                      as="select"
                                      placeholder="Template/Modulbezeichnung"
                                    >
                                      <option>auswählen...</option>
                                      {discriptorData
                                        ? discriptorData.map((element) => {
                                            let output = [];
                                            if (usefilter) {
                                              if (
                                                selectedDiscriptorModality !==
                                                  undefined &&
                                                selectedDiscriptorModality !==
                                                  "" &&
                                                selectedDiscriptorRegion !==
                                                  undefined &&
                                                selectedDiscriptorRegion !== ""
                                              ) {
                                                if (
                                                  element.modality ===
                                                    selectedDiscriptorModality &&
                                                  element.region ===
                                                    selectedDiscriptorRegion
                                                )
                                                  output = output.concat(
                                                    <option
                                                      value={element.name}
                                                    >
                                                      {element.name}
                                                    </option>
                                                  );
                                              } else if (
                                                selectedDiscriptorModality !==
                                                  undefined &&
                                                selectedDiscriptorModality !==
                                                  "" &&
                                                (selectedDiscriptorRegion ===
                                                  undefined ||
                                                  selectedDiscriptorRegion ===
                                                    "")
                                              ) {
                                                if (
                                                  element.modality ===
                                                  selectedDiscriptorModality
                                                ) {
                                                  output = output.concat(
                                                    <option
                                                      value={element.name}
                                                    >
                                                      {element.name}
                                                    </option>
                                                  );
                                                }
                                              } else if (
                                                (selectedDiscriptorModality ===
                                                  undefined ||
                                                  selectedDiscriptorModality ===
                                                    "") &&
                                                selectedDiscriptorRegion !==
                                                  undefined &&
                                                selectedDiscriptorRegion !== ""
                                              ) {
                                                if (
                                                  element.region ===
                                                  selectedDiscriptorRegion
                                                ) {
                                                  output = output.concat(
                                                    <option
                                                      value={element.name}
                                                    >
                                                      {element.name}
                                                    </option>
                                                  );
                                                }
                                              }
                                            } else {
                                              output = output.concat(
                                                <option value={element.name}>
                                                  {element.name}
                                                </option>
                                              );
                                            }
                                            return output;
                                          })
                                        : ""}
                                    </Form.Control>
                                    <InputGroup.Append>
                                      <Button
                                        onClick={() => {
                                          if (
                                            finding.outputsyntaxArray.get() ===
                                            undefined
                                          ) {
                                            finding.outputsyntaxArray.set([]);
                                          }
                                          if (
                                            findingdiscriptorname !== undefined
                                          ) {
                                            //finding.outputsyntaxArray.map(element => console.log(element.label.get() === newOutput.label))
                                            //console.log(newOutput.label)
                                            if (
                                              finding.outputsyntaxArray.length >
                                                0 &&
                                              finding.outputsyntaxArray.some(
                                                (element) =>
                                                  element.label.get() ===
                                                  discriptorname
                                              )
                                            ) {
                                              if (
                                                window.confirm(
                                                  "Wollen Sie diesen Discriptor wirklich mehrfach verwenden?"
                                                )
                                              ) {
                                                console.log("confirmed");
                                                discriptorData.map(
                                                  (discriptor) => {
                                                    if (
                                                      discriptor.name ===
                                                      discriptorname
                                                    ) {
                                                      finding.finding.merge([
                                                        Object.assign(
                                                          {
                                                            discriptorname:
                                                              findingdiscriptorname +
                                                              finding
                                                                .outputsyntaxArray
                                                                .length,
                                                          },
                                                          findingdiscriptoranchor,
                                                          {
                                                            outputs: [].concat(
                                                              JSON.parse(
                                                                JSON.stringify(
                                                                  discriptor.data
                                                                )
                                                              )
                                                            ),
                                                          }
                                                        ),
                                                      ]);

                                                      finding.outputsyntaxArray.merge(
                                                        [
                                                          {
                                                            value:
                                                              discriptorname +
                                                              finding
                                                                .outputsyntaxArray
                                                                .length,
                                                            label: discriptorname,
                                                            type: "finding",
                                                          },
                                                        ]
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                            } else {
                                              discriptorData.map(
                                                (discriptor) => {
                                                  if (
                                                    discriptor.name ===
                                                    discriptorname
                                                  ) {
                                                    finding.finding.merge([
                                                      Object.assign(
                                                        {
                                                          discriptorname:
                                                            findingdiscriptorname +
                                                            finding
                                                              .outputsyntaxArray
                                                              .length,
                                                        },
                                                        findingdiscriptoranchor,
                                                        {
                                                          outputs: [].concat(
                                                            JSON.parse(
                                                              JSON.stringify(
                                                                discriptor.data
                                                              )
                                                            )
                                                          ),
                                                        }
                                                      ),
                                                    ]);

                                                    if (
                                                      finding.selectedoutputArray.get() ===
                                                      undefined
                                                    ) {
                                                      finding.selectedoutputArray.merge(
                                                        [
                                                          {
                                                            value:
                                                              discriptorname +
                                                              "0",
                                                            label: discriptorname,
                                                            type: "finding",
                                                          },
                                                        ]
                                                      );
                                                    } else {
                                                      finding.selectedoutputArray.merge(
                                                        [
                                                          {
                                                            value:
                                                              discriptorname +
                                                              finding
                                                                .outputsyntaxArray
                                                                .length,
                                                            label: discriptorname,
                                                            type: "finding",
                                                          },
                                                        ]
                                                      );
                                                    }

                                                    if (
                                                      finding.outputsyntaxArray.get() ===
                                                      undefined
                                                    ) {
                                                      finding.outputsyntaxArray.merge(
                                                        [
                                                          {
                                                            value:
                                                              discriptorname +
                                                              "0",
                                                            label: discriptorname,
                                                            type: "finding",
                                                          },
                                                        ]
                                                      );
                                                    } else {
                                                      finding.outputsyntaxArray.merge(
                                                        [
                                                          {
                                                            value:
                                                              discriptorname +
                                                              finding
                                                                .outputsyntaxArray
                                                                .length,
                                                            label: discriptorname,
                                                            type: "finding",
                                                          },
                                                        ]
                                                      );
                                                    }
                                                  }
                                                }
                                              );
                                            }
                                          } else {
                                            setSelectionError(true);
                                            setTimeout(
                                              () => setSelectionError(false),
                                              3000
                                            );
                                          }

                                          document.getElementById(
                                            "DiscriptorSelection"
                                          ).selectedIndex = 0;
                                          setDiscriptorName();
                                          setFindingDiscriptorName();
                                        }}
                                      >
                                        +
                                      </Button>
                                      <InputGroup.Text>Filter</InputGroup.Text>
                                      <InputGroup.Checkbox
                                        aria-label="Checkbox for following text input"
                                        onClick={(e) => {
                                          setUseFilter(!usefilter);
                                          // if(!usefilter){
                                          //   SetSelectedDiscriptorModality()
                                          //   SetSelectedDiscriptorRegion()
                                          // }
                                        }}
                                      />
                                    </InputGroup.Append>
                                  </InputGroup>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  style={{ display: usefilter ? "" : "none" }}
                                >
                                  <Form.Control
                                    onChange={(e) =>
                                      SetSelectedDiscriptorModality(
                                        e.target.value
                                      )
                                    }
                                    as="select"
                                    placeholder="Modalität"
                                  >
                                    <option>Modalität auswählen...</option>
                                    <option value="allgemein">allgemein</option>
                                    <option value="CT">CT</option>
                                    <option value="MRT">MRT</option>
                                    <option value="Röntgen">Röntgen</option>
                                    <option value="Sonographie">
                                      Sonographie
                                    </option>
                                    <option value="PET-CT">PET-CT</option>
                                    <option value="IR">IR</option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  style={{ display: usefilter ? "" : "none" }}
                                >
                                  <Form.Control
                                    onChange={(e) =>
                                      SetSelectedDiscriptorRegion(
                                        e.target.value
                                      )
                                    }
                                    as="select"
                                    placeholder="Region"
                                  >
                                    <option>Region auswählen...</option>
                                    <option value="allgemein">allgemein</option>
                                    <option value="Ganzkörper">
                                      Ganzkörper
                                    </option>
                                    <option value="Kopf">Kopf</option>
                                    <option value="Hals">Hals</option>
                                    <option value="Kopf/Hals">Kopf/Hals</option>
                                    <option value="Thorax">Thorax</option>
                                    <option value="Abdomen">Abdomen</option>
                                    <option value="Thorax/Abdomen">
                                      Thorax/Abdomen
                                    </option>
                                    <option value="Becken">Becken</option>
                                    <option value="Abdomen/Becken">
                                      Abdomen/Becken
                                    </option>
                                    <option value="Obere Extremität">
                                      Obere Extremität
                                    </option>
                                    <option value="Untere Extremität">
                                      Untere Extremität
                                    </option>
                                  </Form.Control>
                                </Form.Group>
                              </Form.Row>

                              <br />
                              {selectionError ? (
                                <Alert variant="danger">
                                  Bitte Discriptor auswählen
                                </Alert>
                              ) : (
                                ""
                              )}

                              <Table striped bordered hover size="sm">
                                {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
                                <tbody>
                                  {finding.outputsyntaxArray.get() !==
                                    undefined &&
                                  finding.outputsyntaxArray.length > 0
                                    ? finding.outputsyntaxArray.map(
                                        (element, index) => {
                                          return (
                                            <tr style={{ height: "50px" }}>
                                              <td style={{ width: "30px" }}>
                                                {index + 1}
                                              </td>
                                              <td>
                                                {element.type.get() ===
                                                "finding" ? (
                                                  element.label.get()
                                                ) : (
                                                  <Form.Control
                                                    onChange={(e) => {
                                                      element.label.set(
                                                        e.target.value
                                                      );
                                                      element.merge({
                                                        value:
                                                          e.target.value +
                                                          index,
                                                      });
                                                      if (
                                                        finding.selectedoutputArray.get() !==
                                                          undefined &&
                                                        finding
                                                          .selectedoutputArray
                                                          .length > 0
                                                      ) {
                                                        finding.selectedoutputArray.map(
                                                          (selectedelement) => {
                                                            if (
                                                              selectedelement.label.get() ===
                                                              element.label.get()
                                                            ) {
                                                              selectedelement.label.set(
                                                                e.target.value
                                                              );

                                                              selectedelement.merge(
                                                                {
                                                                  value:
                                                                    e.target
                                                                      .value +
                                                                    index,
                                                                }
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }
                                                    }}
                                                    defaultValue={element.label.get()}
                                                  ></Form.Control>
                                                )}
                                              </td>
                                              <td style={{ width: "30px" }}>
                                                <ButtonGroup>
                                                  <Button
                                                    onClick={() => {
                                                      if (
                                                        finding.selectedoutputArray.get() !==
                                                          undefined &&
                                                        finding
                                                          .selectedoutputArray
                                                          .length > 0
                                                      ) {
                                                        finding.selectedoutputArray.map(
                                                          (selectedelement) => {
                                                            if (
                                                              selectedelement.label.get() ===
                                                              element.label.get()
                                                            ) {
                                                              selectedelement.set(
                                                                none
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }

                                                      finding.finding.map(
                                                        (finding) => {
                                                          console.log(finding);
                                                          if (
                                                            finding.discriptorname.get() ===
                                                            element.value.value
                                                          ) {
                                                            finding.set(none);
                                                          }
                                                        }
                                                      );

                                                      element.set(none);
                                                    }}
                                                  >
                                                    -
                                                  </Button>
                                                  <OverlayTrigger
                                                    trigger="click"
                                                    placement="auto"
                                                    overlay={
                                                      <Popover
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                        style={{
                                                          minWidth: "600px",
                                                          minHeight: "400px",
                                                        }}
                                                      >
                                                        <Popover.Content>
                                                          <Button
                                                            onClick={() => {
                                                              finding.condition.merge(
                                                                [
                                                                  {
                                                                    name:
                                                                      "condition0",
                                                                    if: [],
                                                                    then: [],
                                                                  },
                                                                ]
                                                              );
                                                            }}
                                                          >
                                                            Kondition hinzufügen
                                                          </Button>
                                                          <Table className="mt-2">
                                                            <thead>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Auswahl Eingabe
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Wenn
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Auswahl Ausgabe
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Dann
                                                              </td>
                                                            </thead>
                                                            <tbody>
                                                              {finding.condition.get() !==
                                                              undefined
                                                                ? finding.condition.map(
                                                                    (
                                                                      condition
                                                                    ) => {
                                                                      return (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              maxWidth:
                                                                                "150px",
                                                                            }}
                                                                          >
                                                                            <Form>
                                                                              <InputGroup>
                                                                                <Form.Control
                                                                                  as="select"
                                                                                  defaultValue={condition.inputselection.get() !== undefined ? condition.inputselection.value.value : ""}
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    finding.outputsyntaxArray.map(
                                                                                      (
                                                                                        element
                                                                                      ) => {
                                                                                        if (
                                                                                          e
                                                                                            .target
                                                                                            .value ===
                                                                                          element
                                                                                            .value
                                                                                            .value
                                                                                        ) {
                                                                                          condition.inputselection.set(
                                                                                            element.get()
                                                                                          );
                                                                                          condition.if.set([])
                                                                                        }
                                                                                      }
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {finding.outputsyntaxArray.map(
                                                                                    (
                                                                                      element
                                                                                    ) => {
                                                                                      return (
                                                                                        <option
                                                                                          value={
                                                                                            element
                                                                                              .value
                                                                                              .value
                                                                                          }
                                                                                        >
                                                                                          {element.label.get()}
                                                                                        </option>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                </Form.Control>
                                                                              </InputGroup>
                                                                            </Form>
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              maxWidth:
                                                                                "150px",
                                                                                overflow: "auto",
                                                                                maxHeight: "40px"
                                                                            }}
                                                                          >
                                                                            {/*  entsprechend die Auswahlmöglichkeiten anzeigen und bei Click in das if-Array hinzufügen */}
                                                                            {finding.outputsyntaxArray.map(
                                                                              (
                                                                                element
                                                                              ) => {
                                                                                let output = [];

                                                                                if (
                                                                                  condition.inputselection.get() !==
                                                                                    undefined &&
                                                                                  element.type.get() ===
                                                                                    "finding" &&
                                                                                  condition
                                                                                    .inputselection
                                                                                    .value
                                                                                    .value ===
                                                                                    element
                                                                                      .value
                                                                                      .value
                                                                                ) {
                                                                                  discriptorData.map(
                                                                                    (
                                                                                      discriptor
                                                                                    ) => {
                                                                                      // console.log(discriptor)

                                                                                      if (
                                                                                        discriptor.name ===
                                                                                        condition.inputselection.label.get()
                                                                                      ) {
                                                                                        discriptor.data[0].data.map(
                                                                                          (
                                                                                            dataelement,
                                                                                            i
                                                                                          ) => {
                                                                                            output = output.concat(
                                                                                              <Button
                                                                                                variant={condition.if.some((element) => element.get() === dataelement.input +
                                                                                                  i)? "dark" : "primary"}
                                                                                                onClick={(
                                                                                                  e
                                                                                                ) => {
                                                                                                  if (
                                                                                                    !condition.if.some(
                                                                                                      (
                                                                                                        ifelement
                                                                                                      ) =>
                                                                                                        ifelement.get() ===
                                                                                                        e
                                                                                                          .target
                                                                                                          .value
                                                                                                    )
                                                                                                  ) {
                                                                                                    condition.if.merge(
                                                                                                      [
                                                                                                        e
                                                                                                          .target
                                                                                                          .value,
                                                                                                      ]
                                                                                                    );
                                                                                                  } else if (condition.if.some(
                                                                                                    (
                                                                                                      ifelement
                                                                                                    ) =>
                                                                                                      ifelement.get() ===
                                                                                                      e
                                                                                                        .target
                                                                                                        .value
                                                                                                  )){
                                                                                                    condition.if.map((element) => {
                                                                                                      if(element.get() === e.target.value){
                                                                                                        element.set(none)
                                                                                                      }
                                                                                                    })
                                                                                                  }
                                                                                                }}
                                                                                                value={
                                                                                                  dataelement.input +
                                                                                                  i
                                                                                                }
                                                                                              >
                                                                                                {
                                                                                                  dataelement.input
                                                                                                }
                                                                                              </Button>
                                                                                            );
                                                                                          }
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  );
                                                                                }
                                                                                return (
                                                                                  <ButtonGroup>
                                                                                    {
                                                                                      output
                                                                                    }
                                                                                  </ButtonGroup>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              maxWidth:
                                                                                "150px",
                                                                            }}
                                                                          >
                                                                            <Form>
                                                                              <InputGroup>
                                                                                <Form.Control 
                                                                                as = "select"
                                                                                defaultValue={condition.outputselection.get() !== undefined ? condition.outputselection.value.value : ""}

                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  finding.outputsyntaxArray.map(
                                                                                    (
                                                                                      element
                                                                                    ) => {
                                                                                      if (
                                                                                        e
                                                                                          .target
                                                                                          .value ===
                                                                                        element
                                                                                          .value
                                                                                          .value
                                                                                      ) {
                                                                                        condition.outputselection.set(
                                                                                          element.get()
                                                                                        );
                                                                                        
                                                                                      }
                                                                                    }
                                                                                  );
                                                                                }}
                                                                              >
                                                                                {finding.outputsyntaxArray.map(
                                                                                  (
                                                                                    element
                                                                                  ) => {
                                                                                    if(element.type.get() === "condition"){
                                                                                      return (
                                                                                        <option
                                                                                          value={
                                                                                            element
                                                                                              .value
                                                                                              .value
                                                                                          }
                                                                                        >
                                                                                          {element.label.get()}
                                                                                        </option>
                                                                                      );
                                                                                    }
                                                                                    
                                                                                  }
                                                                                )}
                                                                                
                                                                                </Form.Control>
                                                                              </InputGroup>
                                                                            </Form>
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              maxWidth:
                                                                                "150px",
                                                                            }}
                                                                          ></td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )
                                                                : ""}
                                                            </tbody>
                                                          </Table>
                                                        </Popover.Content>
                                                      </Popover>
                                                    }
                                                  >
                                                    <Button onClick={() => {}}>
                                                      <FontAwesomeIcon
                                                        icon={faLink}
                                                      ></FontAwesomeIcon>
                                                    </Button>
                                                  </OverlayTrigger>
                                                </ButtonGroup>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : ""}
                                </tbody>
                              </Table>

                              <br />
                              <Button
                                variant={
                                  finding.showAlways.get() ? "dark" : "primary"
                                }
                                onClick={() => {
                                  if (finding.showAlways.get() === undefined) {
                                    finding.showAlways.set(true);
                                  } else if (
                                    finding.showAlways.get() !== undefined
                                  ) {
                                    finding.showAlways.set(
                                      !finding.showAlways.get()
                                    );
                                  }
                                }}
                              >
                                direkt anzeigen
                              </Button>
                              <br />
                              <Button
                                className="mt-2"
                                onClick={() => setModalShow(true)}
                              >
                                + Hilfe
                              </Button>
                              {/* </OverlayTrigger> */}
                              <FileUploadModal
                                show={modalShow}
                                element={finding}
                                onHide={() => setModalShow(false)}
                              ></FileUploadModal>
                              <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  type="input"
                                  defaultValue={finding.name.get()}
                                  onChange={(e) =>
                                    finding.name.set(e.target.value)
                                  }
                                ></Form.Control>
                              </InputGroup>
                            </Form>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button
                        style={{
                          width: "14px",
                          height: "14px",
                          padding: "0",
                          borderRadius: "100%",
                          position: "absolute",
                          left: "-4px",
                          bottom: "3px",
                          border: "none",
                          backgroundColor: "white",
                        }}
                        size="sm"
                        active
                      >
                        <FontAwesomeIcon
                          style={{
                            fontSize: "0.7rem",
                            width: "12px",
                            height: "12px",
                            padding: "0",
                            borderRadius: "100%",
                            position: "absolute",
                            left: "1px",
                            bottom: "1px",
                            border: "none",
                            backgroundColor: "white",
                            color: "#545b62",
                            pointerEvents: "none",
                          }}
                          icon={faCog}
                        ></FontAwesomeIcon>
                      </Button>
                    </OverlayTrigger>
                    <Button
                      onClick={() => {
                        if (finding.checked.get() === undefined) {
                          finding.checked.set(true);
                        } else {
                          finding.checked.set(!finding.checked.get());
                        }
                      }}
                      className="mt-1 mr-2 mb-1"
                      variant={finding.checked.get() ? "dark" : "primary"}
                    >
                      {/* {findings.findings.map((element) => console.log(element.name.get(), finding.name.get()))}
                      //momentan deaktiviert weil noch keine Lösung für die richtige nummerierung der gleichnamigen findings gefunden
                      {findings.findings.some((element, i) => element.name.get() === finding.name.get() && i !== finding.number.get()) ? finding.name.get() + " " + (i + 1) : finding.name.get()} */}
                      {finding.name.get()}
                    </Button>
                  </div>

                  {finding.checked.get() ? (
                    <div
                      className="w-100 pt-1 pr-1 pl-1 pb-2"
                      style={{
                        boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
                        backgroundColor: "rgb(90,90,90)",
                        borderRadius: "5px",
                        display:
                          finding.finding.length === 1 &&
                          finding.finding[0].name.get() === "output"
                            ? "none"
                            : "",
                      }}
                    >
                      {finding.checked.get()
                        ? finding.finding.map((element, i2) => {
                            if (
                              element.name.get() === undefined &&
                              finding.number.get() === i
                            ) {
                              return (
                                <DiscriptorComponent
                                  key={JSON.stringify(i) + JSON.stringify(i2)}
                                  data={element.outputs}
                                  number={finding.number.get()}
                                />
                              );
                            }
                          })
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            }
          })
        : ""}

      {findings.get() !== undefined ? (
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose={true}
          overlay={
            <Popover>
              <Popover.Content>
                <Form>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        setSelectedFinding(e.target.value);
                      }}
                    >
                      <option value="auswählen...">auswählen...</option>
                      {findings.findings.map((finding) => {
                        let output = null;

                        let options = [];

                        if (finding.name.get() !== undefined) {
                          options = (
                            <option
                              value={finding.name.get() + finding.number.get()}
                            >
                              {finding.name.get()}
                            </option>
                          );
                        }

                        return <>{options}</>;
                      })}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button
                        onClick={() => {
                          findings.findings.map((finding, i) => {
                            if (
                              finding.name.get() + finding.number.get() ===
                              selectedFinding
                            ) {
                              let copy = JSON.parse(
                                JSON.stringify(finding.get())
                              );
                              copy.number = findings.findings.length;
                              // finding.attach(Downgraded);
                              // finding.finding.attach(Downgraded);
                              // console.log(finding.get());
                              // let findingmap = [];
                              // finding.finding.map((element) => {
                              //   element.attach(Downgraded);
                              //   if (element.name.get() === "output") {
                              //     findingmap.push({
                              //       name: element.name.get(),
                              //       output: element.output.get(),
                              //     });
                              //   } else {
                              //     let outputs = [];
                              //     discriptorData.map((discriptor) => {
                              //       console.log(discriptor);
                              //       if (
                              //         discriptor.name ===
                              //         element.discriptorname.get()
                              //       ) {
                              //         //outputs.push({data: discriptor.data[0].data});
                              //         outputs = JSON.parse(
                              //           JSON.stringify(discriptor.data)
                              //         );
                              //       }
                              //     });
                              //     console.log(outputs);
                              //     findingmap.push({
                              //       discriptorname: element.discriptorname.get(),
                              //       discriptoranchor: element.discriptoranchor.get(),
                              //       outputs: outputs,
                              //     });
                              //   }
                              // });
                              // console.log(findingmap);
                              findings.findings.set((nodes) =>
                                (nodes || []).concat([
                                  copy,
                                  // {
                                  //   name: finding.name.get(),
                                  //   finding: findingmap,
                                  //   number: findings.findings.length,
                                  //   outputsyntax: finding.outputsyntax.get(),
                                  //   showAlways: finding.showAlways.get(),
                                  // },
                                ])
                              );
                            }
                            finding.number.set(i);
                          });
                        }}
                      >
                        +
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Popover.Content>
            </Popover>
          }
        >
          <Button className="mt-1 mr-1 mb-1" variant="dark">
            + Finding
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}
    </div>
  );
}

function FileUploadModal(props) {
  const [file, SetFile] = useState();
  const { currentUser } = useAuth();
  const { fileupload, userData } = useData();
  const fileref = useRef();

  const element = props.element;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      dialogClassName="modal-90w-90h"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.element.fileDiscription.get()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px" }}>
        <>
          <div
            className="m-2 p-2"
            style={{
              borderBottom: "solid 1px lightgray",
            }}
          >
            <Tabs
              defaultActiveKey={
                element.helpmodule.get() !== undefined
                  ? element.helpmodule.get()
                  : "fileupload"
              }
              id="uncontrolled-tab-example"
              defaultValue={
                element.helpmodule.get() !== undefined
                  ? element.helpmodule.get()
                  : ""
              }
            >
              <Tab eventKey="fileupload" title="Datei hochladen">
                <br />
                <Form>
                  {/* <Form.Label>Datei hochladen</Form.Label> */}

                  <Form.Control
                    defaultValue={
                      element.fileupload.get()
                        ? element.fileupload.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.fileupload.merge({
                        discription: e.target.value,
                      })
                    }
                  />
                  <br />
                  <Form.Control
                    type="url"
                    defaultValue={
                      element.fileupload.get()
                        ? element.fileupload.link.get()
                        : ""
                    }
                    placeholder="weiterführender Link"
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.fileupload.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                  />
                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.fileupload.get() !== undefined
                        ? element.fileupload.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.fileupload.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
                <br />
                <ButtonGroup>
                  <Button
                    onClick={() => fileref.current.click()}
                    variant="dark"
                  >
                    Datei auswählen
                  </Button>
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => SetFile(e.target.files[0])}
                    style={{ display: "none" }}
                    ref={fileref}
                  />
                  <Button
                    onClick={async () => {
                      const userId = currentUser.uid;
                      const userInstitution = currentUser.institution;
                      let discription = null;
                      let fileLink = null;
                      console.log(element.fileupload.discription.get());
                      if (element.fileupload.discription.get() !== undefined) {
                        discription = {
                          discription: element.fileupload.discription.get(),
                        };
                      }

                      if (element.fileupload.link.get() !== undefined) {
                        fileLink = {
                          fileLink: element.fileupload.link.get(),
                        };
                      }

                      const customMetadata = Object.assign(
                        discription,
                        fileLink
                      );

                      console.log(customMetadata);

                      fileupload(file, customMetadata);

                      if (
                        file.type === "image/png" ||
                        file.type === "image/jpg" ||
                        file.type === "image/jpeg"
                      ) {
                        // gets the functions from storage refences the image storage in firebase by the children
                        // gets the download url then sets the image from firebase as the value for the imgUrl key:

                        storage
                          .ref(`/images/${userInstitution}/${userId}/`)
                          .child(file.name)
                          .getDownloadURL()
                          .then(async (fireBaseUrl) => {
                            console.log(fireBaseUrl);
                            //imagePath = await fireBaseUrl;
                            // let newurl = fireBaseUrl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // console.log(newurl)
                            // console.log(storage.refFromURL(fireBaseUrl))
                            console.log(
                              `/images/${userInstitution}/${userId}/${file.name}`
                            );
                            element.fileupload.merge({
                              imagePath: await fireBaseUrl,
                              imagePath2: `/images/${userInstitution}/${userId}/${file.name}`,
                            });
                          });
                      } else if (file.type === "application/pdf") {
                        // gets the functions from storage refences the image storage in firebase by the children
                        // gets the download url then sets the image from firebase as the value for the imgUrl key:

                        // storage
                        //   .ref(`/pdf/${userInstitution}/${userId}/`)
                        //   .child(file.name)
                        //   .getDownloadURL()
                        //   .then(async (fireBaseUrl) => {
                        //     console.log(fireBaseUrl);
                        //     //imagePath = await fireBaseUrl;
                        //     // let newurl = fireBaseUrl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // console.log(newurl)
                        //     // console.log(storage.refFromURL(fireBaseUrl))
                        //     console.log(
                        //       `/pdfs/${userInstitution}/${userId}/${file.name}`
                        //     );
                        element.fileupload.merge({
                          pdfPath: "test",
                          pdfPath2: `/pdfs/${userInstitution}/${userId}/${file.name}`,
                          // });
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} size="sm" />
                  </Button>
                </ButtonGroup>
              </Tab>
              <Tab eventKey="imagelink" title="Bild verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.imagelink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des Bildes"
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.imagelink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.imagelink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="pdflink" title="PDF verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.pdflink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des PDFs"
                    defaultValue={
                      element.pdflink.get() ? element.pdflink.link.get() : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.pdflink.get()
                        ? element.pdflink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.pdflink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.pdflink.get()
                        ? element.pdflink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.pdflink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="youtubevideolink" title="Youtube Video verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.youtubevideolink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des Youtube Videos"
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.youtubevideolink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.youtubevideolink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="webpagelink" title="Webseite verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.webpagelink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL der Webseite"
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.webpagelink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.webpagelink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="helpText" title="Hilfetext hinzufügen">
                <div className="m-2 p-2">
                  <br />

                  <Form.Control
                    as="textarea"
                    placeholder="Hilfetext"
                    defaultValue={
                      element.helpText.get() ? element.helpText.get() : ""
                    }
                    onChange={(e) => element.helpText.set(e.target.value)}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>

          <p>Hilfemodul auswählen</p>
          <ToggleButtonGroup
            type="radio"
            name="options"
            defaultValue={element.helpmodule.get()}
            checked="true"
          >
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="fileupload"
            >
              Datei
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="imagelink"
            >
              Bild
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="pdflink"
            >
              PDF
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="youtubevideolink"
            >
              Youtube
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="webpagelink"
            >
              Link
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="helpText"
            >
              Hilfetext
            </ToggleButton>
          </ToggleButtonGroup>
        </>

        {/* <Button
          onClick={async () => {
            fileupload(file);

            if (
              file.type === "image/png" ||
              file.type === "image/jpg" ||
              file.type === "image/jpeg"
            ) {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref("images")
                .child(file.name)
                .getDownloadURL()
                .then(async (fireBaseUrl) => {
                  console.log(fireBaseUrl);
                  //imagePath = await fireBaseUrl;
                  props.element.imagePath.set(await fireBaseUrl);
                });
            } else if (file.type === "application/pdf") {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref("pdfs")
                .child(file.name)
                .getDownloadURL()
                .then(async (fireBaseUrl) => {
                  console.log(fireBaseUrl);
                  //imagePath = await fireBaseUrl;
                  props.element.pdfPath.set(await fireBaseUrl);
                });
            }
          }}
        >
          <FontAwesomeIcon icon={faUpload} size="sm" />
        </Button>
        <input
          type="file"
          name="file"
          onChange={(e) => SetFile(e.target.files[0])}
          style={{ display: "none" }}
          ref={fileref}
        />
        <Button
          //name={this.props.indices}
          onClick={() => fileref.current.click()}
        >
          Datei auswählen
        </Button> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
