import {
    useState as useStateHook,
    none
  } from "@hookstate/core";
  import { useState, useRef, useEffect } from "react";
  import {
    useGlobalState,
  } from "./GlobalState.ts";
  import {
    Button,
    Accordion,
    Card,
    Popover,
    OverlayTrigger,
    Form,
    Alert,
  } from "react-bootstrap";
  import {
    faUpload,
    faQuestion,
    
  } from "@fortawesome/free-solid-svg-icons";
  import { DecisionTreeRender } from "./DecisionTreeRender.js";
  import { firestore } from "../firebase";
  import { useData } from "../contexts/DataContext";

  
  export const ReportPreview = () => {
    const state = useStateHook(useGlobalState());
    const { userData, templateData } = useData();
    const [templatename, SetTemplateName] = useState();
    const [selectedTemplate, SetSelectedTemplate] = useState()
    const [error, SetError] = useState();
    const [success, SetSuccess] = useState();
    //   const [templateData, SetTemplateData]= useState();
  
    console.log(state);
  
    return (
      <>
        <div className="w-100 text-center">

          <div>
            {state.map(
              (accordionState, i) => (
                console.log(accordionState),
                (
                  <Accordion>
                    <Card style={{ borderBottom: "1px solid rgba(0,0,0,.125)", borderTop:"none", borderLeft:"none", borderRight:"none" }}>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        {/* <OverlayTrigger
                          trigger="click"
                          placement="auto"
                          rootClose={true}
                          overlay={
                            <Popover
                              id="popover-contained"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Popover.Content>
                                <AccordionEditor
                                  key={i}
                                  accordionState={accordionState}
                                />
                              </Popover.Content>
                            </Popover>
                          }
                        > */}
                          <Button 
                          // onClick={(e) => e.stopPropagation()}
                          >
                            {accordionState.name.get()}
                          </Button>
                        {/* </OverlayTrigger> */}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {accordionState.component.map((component) => {
                            if (component.id.get() === "DecisionTree") {
                              return (
                                <>
                                  {/* <Button onClick={() => component.set(none)}>
                                    löschen
                                  </Button> */}
                                  {/* {component.imagePath.get() !== undefined && component.imagePath.get() !== ''?  (<Button value={component.imagePath.get()}>Help</Button>):('')} */}

                                  <DecisionTreeRender
                                    componentState={component}
                                    number={component.number.get()}
                                  />
                                </>
                              );
                            }
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )
              )
            )}
          </div>
        </div>
      </>
    );
  };
  function AccordionEditor(props) {
    const accordionState = useStateHook(props.accordionState);
    console.log(accordionState);
    return (
      <div className="inline-block mb-2">
       <div className="inline-block mt-2">
        <Form>
          <Form.Control
            value={accordionState.name.get()}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => accordionState.name.set(e.target.value)}
          />
        </Form>
      </div>
  
        <div className="justify-content-right mt-2">
        <Button
        className="mr-1 mb-1"
          onClick={(e) => {
            e.stopPropagation();
            accordionState.component.merge([
              {
                id: "DecisionTree",
                number: accordionState.component.length,
                input: "",
                // outputvalue: "",
                // pdfPath: "",
                // imagePath: "",
                // endpoint: false,
                // output: false,
                buttons: [],
                children: [],
              },
            ]);
          }}
        >
          Knoten hinzufügen
        </Button>

        <Button className="mr-1 mb-1" onClick={() => accordionState.set(none)}>
          Modul löschen
        </Button>
  
      </div>
      </div>
    );
  }

  