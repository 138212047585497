import React, {useEffect, useState, useReducer} from "react";
import Select, { components } from "react-select";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { useState as useStateHook } from "@hookstate/core";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle(props => (
    <components.MultiValueLabel {...props} />
  ));


const SortableSelect = SortableContainer(Select);


export default function MultiSelectSort(props) {
  console.log(props.outputArray);

  //const [, forceUpdate] = useReducer(x => x + 1, 0);

//   console.log("Rerender")

//   useEffect(() => {
//       forceUpdate()
//       return () => {
          
//       }
//   }, [props.outputArray])

//   useEffect(() => {
//     setSelected([...props.selectedoutputArray])
//     return () => {
        
//     }
// }, [props.selectedoutputArray])

  // const options = [
  //     { value: "apple", label: "Apple", isFixed: true },
  //     { value: "orange", label: "Orange" },
  //     { value: "grape", label: "Grape" },
  //   ];

  const state = useStateHook(props.finding);
  console.log(state)
  //console.log(props.selectedoutputArray.value)
  console.log(props.selectedoutputArray)

  const values = JSON.parse(JSON.stringify(props.selectedoutputArray))

  //const [selected, setSelected] = useState([]);

//   console.log(selected === props.selectedoutputArray)
//   console.log(selected)
//   console.log(props.selectedoutputArray)

//   if(selected !== [...props.selectedoutputArray]){
//     setSelected([...props.selectedoutputArray])
//   }
//   console.log(selected)
  const onChange = (selectedOptions) => {
    state.set(JSON.parse(JSON.stringify(selectedOptions)));
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(props.selectedoutputArray, oldIndex, newIndex);
    //setSelected(newValue);
    state.set(JSON.parse(JSON.stringify(newValue)));

    // console.log(
    //   "Values sorted:",
    //   newValue.map((i) => i.value)
    // );
  };

//   console.log(selected);

  return (
    <SortableSelect
      useDragHandle
      classNamePrefix={"my-custom-react-select"}  
      placeholder={'auswählen...'}
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={props.outputArray}
      value={values}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
      }}
      closeMenuOnSelect={false}
    />
  );
}
