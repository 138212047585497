import React, { useContext, useState, useEffect } from "react";
import { auth, firestore, storage } from "../firebase";
import { useAuth } from "./AuthContext";

const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [userData, setUserData] = useState();
  const [institutions, setInstitutions] = useState();
  const [loading, setLoading] = useState(true);
  const [templateData, SetTemplateData] = useState();
  const [moduleData, SetModuleData] = useState();
  const [discriptorData, SetDiscriptorData] = useState();
  const [imagePath, SetImagePath] = useState(null);
  const [pdfPath, SetPdfPath] = useState(null);
  const { currentUser } = useAuth();

  function addinstitution(
    institutionname,
    institutionaddress,
    institutionpostcode,
    institutioncity
  ) {
    return firestore.collection("institution").add({
      name: institutionname,
      address: institutionaddress,
      postcode: institutionpostcode,
      city: institutioncity,
    });
  }

  function fileupload(file, customMeta) {
    const userId = currentUser.uid;
    const userInstitution = userData.institution;
    console.log(userId, userInstitution);
    let imagePath = null;
    let pdfPath = null;

    SetPdfPath(null);
    SetImagePath(null);

    function validateSize(file) {
      //let file = event.target.files[0];
      let size = 10000000;
      let err = "";
      console.log(file.size);
      if (file.size > size) {
        err =
          "Datei zu groß (" +
          Math.round(file.size / 1000000, 2) +
          " MB), maximal 10 MB";
        alert(err);
        return false;
      } else return true;
    }

    function validateType(file) {
      //let file = event.target.files[0];
      let err = "";
      if (
        file.type === "application/pdf" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        return true;
      } else if (
        file.type !== "application/pdf" ||
        file.type !== "image/png" ||
        file.type !== "image/jpg" ||
        file.type !== "image/jpeg"
      ) {
        err = "Bitte PDF oder Bilddatei (.png .jpeg .jpg) verwenden";
        alert(err);
        return false;
      }
    }

    if (validateSize && validateType) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        const customMetadataFix = {
          institution: userInstitution,
          userId: userId,
        };

        const customMetadata = Object.assign(customMetadataFix, customMeta);

        const metadata = {
          customMetadata: customMetadata,
        };

        const uploadTask = storage
          .ref(`/images/${userInstitution}/${userId}/${file.name}`)
          .put(file, metadata);

        //initiates the firebase side uploading
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot);
            let percentage =
              (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
            console.log(percentage);
          },
          (err) => {
            //catches the errors
            console.log(err);
          }
          // async () => {
          //   // gets the functions from storage refences the image storage in firebase by the children
          //   // gets the download url then sets the image from firebase as the value for the imgUrl key:
          //   storage
          //     .ref("images")
          //     .child(file.name)
          //     .getDownloadURL()
          //     .then(async (fireBaseUrl) => {
          //       console.log(fireBaseUrl);
          //       //imagePath = await fireBaseUrl;
          //       SetImagePath(await fireBaseUrl)
          //     });
          // }
        );
      } else if (file.type === "application/pdf") {
        const uploadTask = storage.ref(`/pdfs/${userInstitution}/${userId}/${file.name}`).put(file);
        //initiates the firebase side uploading
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot);
          },
          (err) => {
            //catches the errors
            console.log(err);
          }
          // () => {
          //   // gets the functions from storage refences the image storage in firebase by the children
          //   // gets the download url then sets the image from firebase as the value for the imgUrl key:
          //   storage
          //     .ref("pdfs")
          //     .child(file.name)
          //     .getDownloadURL()
          //     .then(async (fireBaseUrl) => {
          //       console.log(fireBaseUrl);
          //       //pdfPath = await fireBaseUrl;
          //       SetPdfPath(await fireBaseUrl)
          //     });
          // }
        );
      }
    }
  }

  function getUserData() {
    if (auth.currentUser !== null) {

      return firestore
        .collection("user")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          console.log(doc.data());
          setUserData(doc.data());
        });

      // return new Promise((resolve, reject) => {
      //   let error = false
      //   let data = null
      //   try{
      //      firestore
      //   .collection("user")
      //   .doc(auth.currentUser.uid)
      //   .onSnapshot(async (doc) => {
      //     console.log(doc.data());
      //     setUserData(doc.data());
      //     data=doc.data()
      //   });
      //   }
      //   catch(err){
      //     error = true
      //   }
        
      //   if(!error){
      //     resolve(data)
      //   }
      //   else{
      //     reject("Error!")
      //   }

      // })
    }
  }

  // async function getUserData() {
  //   const userdata = await firestore
  //   .collection("user")
  //   .doc(auth.currentUser.id)
  //   .onSnapshot(doc => {
  //     console.log(doc.data())
  //     // let userdata = doc.data()
  //     // console.log(userdata)
  //     // setUserData(userdata)
  //   })

  //setUserData(institutions)
  // .then (function (queryDocumentSnapshot) {
  //   setUserData(queryDocumentSnapshot.data())
  // })
  //};



  useEffect(() => {
    let mounted = true;
    if (auth.currentUser !== null) {
      firestore
        .collection("user")
        .doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          console.log(doc.data());
          setUserData(doc.data());
          setLoading(false);
        });

      firestore.collection("institution").onSnapshot((snap) => {
        let institutions = [];
        snap.forEach((element) => {
          institutions.push({ ...element.data(), id: element.id });
        });
        setInstitutions(institutions);
        console.log(institutions);
        setLoading(false);
      });

      firestore.collection("template").onSnapshot((snap) => {
        let TemplateData = [];
          snap.forEach((element) => {
            if(element.data().institution === currentUser.institution){
              TemplateData.push({ ...element.data(), id: element.id });
            }
          });
          SetTemplateData(TemplateData);
          console.log(TemplateData);
          setLoading(false);
        
      });
        

      firestore.collection("module").onSnapshot((snap) => {
        let ModuleData = [];
        snap.forEach((element) => {
          if(element.data().institution === currentUser.institution){
          ModuleData.push({ ...element.data(), id: element.id });
          }
        });
        SetModuleData(ModuleData);
        console.log(ModuleData);
        setLoading(false);
      });

      firestore.collection("discriptors").onSnapshot((snap) => {
        let DiscriptorData = [];
        snap.forEach((element) => {
          console.log(currentUser.institution)
          if(element.data().institution === currentUser.institution){
          DiscriptorData.push({ ...element.data(), id: element.id });
          }
        });
        SetDiscriptorData(DiscriptorData);
        console.log(DiscriptorData);
        setLoading(false);
      });
    } else {
      //setLoading(false)
      firestore.collection("institution").get().then((snap) => {
        let institutions = [];
        snap.forEach((element) => {
          institutions.push({ ...element.data(), id: element.id });
        });
        setInstitutions(institutions);
        console.log(institutions);
        setLoading(false);
      });

    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  console.log(userData);
  console.log(templateData);
  console.log(discriptorData);
  console.log(imagePath);
  console.log(pdfPath);

  const value = {
    institutions,
    userData,
    templateData,
    moduleData,
    discriptorData,
    imagePath,
    pdfPath,
    addinstitution,
    fileupload,
    getUserData
  };

  return (
    <DataContext.Provider value={value}>
      {!loading && children}
    </DataContext.Provider>
  );
}
