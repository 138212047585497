import React, { useState } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import NavbarComponent from './NavbarComponent'
import MultiSelectSort from './testSortable'

export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Ausloggen fehlgeschlagen")
    }
  }

  return (
    <div className="w-100 text-center mt-2">
        <NavbarComponent />

        <div className="w-100 d-flex justify-content-center align-items-center" style={{height:"80vh"}}>
        <div style={{maxWidth:"700px"}}>
          <h3>Herzlich Willkommen bei Rad-Standard</h3>
          <p>Es handelt sich um eine frühe Testversion dieses Programms. 
            Entsprechend ist die Benutzeroberfläche noch nicht optimal und es werden mit Sicherheit Fehler auftreten.
            <br />
            Bitte speichern Sie hier keinesfalls Patientendaten!
            </p>
           
        </div>
        </div>
        
        {/*<div className='SelectionBar'>
          <SelectionBar 
          selectedoptions={this.state.selectedoptions}
          myChangeHandler1={this.myChangeHandler1} 
          reset={this.reset}
          />
        </div>

       
        <div className='mainLeft'>
            <h4>
              Alle verfügbare Templates
            </h4>
            {templatecardsall}
        </div>

        <div className='mainRight'>
            <h4>
              Meine Templates
            </h4>
                {templatecardsmine}
  </div>*/}

    
      
    </div>
  )
}