import React, { useContext, useState, useEffect } from "react";
import { auth, firestore, functions } from "../firebase";
import { Redirect } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState()

  // function addInstitution(data) {
  //   const add = functions.httpsCallable("addInstitution")
  //   add({email: data.email, institution: data.institution}).then(result => console.log(result))
  // }

  function addClaims(data) {
    const addClaims = functions.httpsCallable("addClaims");
    addClaims({
      email: data.email,
      role: data.role,
      institution: data.institution,
    }).then(firestore.collection("user").doc(data.id).update({
      role: data.role}))
    
    // firestore.collection("user").doc(data.id).update({
    //   role: data.role
    

  }

  function signup(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      
  }

  function signupuser(
    firstname,
    lastname,
    address,
    postcode,
    city,
    institution
  ) {
    const userId = auth.currentUser.uid;
    console.log(userId);

    return firestore.collection("user").doc(userId).set({
      firstname: firstname,
      lastname: lastname,
      address: address,
      postcode: postcode,
      city: city,
      institution: institution,
      authid: userId,
      email: auth.currentUser.email,
    });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
    
    // .then(
    //   auth.onAuthStateChanged((user) => {
    //     console.log(user);
    //     if (user !== null) {
    //       console.log(user.emailVerified);
    //       if (user.emailVerified) {
    //         console.log("verified");
    //         setCurrentUser(user);
    //       } else if (!user.emailVerified) {
    //         console.log("unverified");
    //         user.sendEmailVerification();
    //         alert("Bitte Email verifizieren");
    //         //<Redirect path="/login" />;
    //       }

    //       user.getIdTokenResult().then((idTokenResult) => {
    //         user.role = idTokenResult.claims.role;
    //         user.institution = idTokenResult.claims.institution;
    //         console.log(idTokenResult.claims);
    //       });
    //     }
    //     else {
    //       console.log("no user")
    //     }
    //   })
    // );
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function verifyEmail() {
    console.log("reached")
    return currentUser.sendEmailVerification()

  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      auth.onAuthStateChanged((user) => {
        console.log(user);

        if (user !== null) {
          user.getIdTokenResult().then((idTokenResult) => {
            user.role = idTokenResult.claims.role;
            user.institution = idTokenResult.claims.institution;
            console.log(idTokenResult.claims);
          });
          setCurrentUser(user)
          console.log(user)
          // if (user.emailVerified) {
          //   console.log("verified");
          //   setCurrentUser(user);
          // } 
          // else if (!user.emailVerified) {
          //   console.log("unverified");
          //   //user.sendEmailVerification();
          //   setAuthError("unverfiedEmail");
          //   //alert("Bitte Email verifizieren");
          //   <Redirect path="/login" />;
          // }
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }

    return () => {
      console.log("unmount");
      mounted = false;
    };
  }, []);

  const value = {
    currentUser,
    authError,
    verifyEmail,
    login,
    signup,
    signupuser,
    // addInstitution,
    addClaims,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
