import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Link, useHistory } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";
import { firestore } from "../firebase";

export default function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const institutionnameRef = useRef();
  const institutionaddressRef = useRef();
  const institutionpostcodeRef = useRef();
  const institutioncityRef = useRef();
  const institutionRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const { userData, addinstitution, institutions } = useData();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }
    if (
      firstnameRef.current.value !== userData.firstname ||
      lastnameRef.current.value !== userData.lastname ||
      addressRef.current.value !== userData.address ||
      postcodeRef.current.value !== userData.postcode ||
      cityRef.current.value !== userData.city ||
      institutionRef.current.value !== userData.institution
    ) {
      promises.push(
        updateUserData(
          firstnameRef,
          lastnameRef,
          addressRef,
          postcodeRef,
          cityRef,
          institutionRef
        )
      );
    }

    function updateUserData(firstnameRef) {
      var docRef = firestore.collection("user").doc(currentUser.uid);

      console.log(docRef.get());

      return docRef
        .update({
          firstname: firstnameRef.current.value,
          lastname: lastnameRef.current.value,
          address: addressRef.current.value,
          postcode: postcodeRef.current.value,
          city: cityRef.current.value,
          institution: institutionRef.current.value,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }

    Promise.all(promises)
      .then(() => {
        history.push("/profile");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleInstitution(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      addinstitution(
        institutionnameRef.current.value,
        institutionaddressRef.current.value,
        institutionpostcodeRef.current.value,
        institutioncityRef.current.value
      );
    } catch {
      setError("Fehler beim Erstellen der Institution");
    }

    setLoading(false);
  }

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />
      <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Profil aktualisieren</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-3">
                <Form.Control
                  ref={firstnameRef}
                  defaultValue={userData.firstname}
                  placeholder="Vorname"
                  required
                />
                <Form.Control
                  ref={lastnameRef}
                  defaultValue={userData.lastname}
                  placeholder="Nachname"
                  required
                />
              </InputGroup>
              <Form.Group id="address">
                <Form.Control
                  ref={addressRef}
                  defaultValue={userData.address}
                  placeholder="Adresse"
                  required
                />
              </Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  ref={postcodeRef}
                  defaultValue={userData.postcode}
                  placeholder="Postleitzahl"
                  required
                />
                <Form.Control
                  ref={cityRef}
                  defaultValue={userData.city}
                  placeholder="Stadt"
                  required
                />
              </InputGroup>

              <Form.Label>Instiution</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  as="select"
                  defaultValue={userData.institution}
                  ref={institutionRef}
                  placeholder="Institution"
                >
                  <option>Auswählen..</option>
                  {institutions.map((element) => {
                    return <option>{element.name}</option>;
                  })}
                </Form.Control>
                <InputGroup.Append>
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover id="popover-contained">
                        <Popover.Title as="h3" style={{textAlign: "center"}}>Insitution hinzufügen</Popover.Title>
                        <Popover.Content>
                          <Form onSubmit={handleInstitution}>
                            <Form.Group id="name">
                              <Form.Control
                                ref={institutionnameRef}
                                placeholder="Name der Institution"
                                required
                              />
                            </Form.Group>
                            <Form.Group id="address">
                              <Form.Control
                                ref={institutionaddressRef}
                                placeholder="Adresse"
                                required
                              />
                            </Form.Group>
                            <InputGroup className="mb-3">
                              <Form.Control
                                ref={institutionpostcodeRef}
                                placeholder="Postleitzahl"
                                required
                              />
                              <Form.Control
                                ref={institutioncityRef}
                                placeholder="Stadt"
                                required
                              />
                            </InputGroup>
                            <Button onClick={handleInstitution}>
                              hinzufügen
                            </Button>
                          </Form>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Button variant="outline-secondary">hinzufügen</Button>
                  </OverlayTrigger>
                </InputGroup.Append>
              </InputGroup>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  defaultValue={currentUser.email}
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>Password wiederholen</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder=""
                />
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit">
                Update
              </Button>
            </Form>
          </Card.Body>
          <Link to="/">Abbrechen</Link>
        </Card>
      </div>
    </div>
  );
}
