import { useState as useStateHook, none } from "@hookstate/core";
import { useState, useRef, useEffect } from "react";
import { useGlobalState } from "./GlobalState.ts";
import NavbarComponent from "./NavbarComponent";
import {
  Button,
  Accordion,
  Card,
  Popover,
  OverlayTrigger,
  Form,
  Alert,
  Col,
  InputGroup,
} from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import { DecisionTreeRender } from "./DecisionTreeRender.js";
import { DecisionTreeOutputRender } from "./DecisionTreeOutputRender.js";
import { firestore } from "../firebase";
import { useData } from "../contexts/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faCopy } from "@fortawesome/free-solid-svg-icons";

export const Report = () => {
  const state = useStateHook(useGlobalState());
  const { userData, templateData, moduleData } = useData();
  const [reportename, SetReportName] = useState();
  const [selectedTemplate, SetSelectedTemplate] = useState();
  const [selectedModule, SetSelectedModule] = useState();
  const [error, SetError] = useState();
  const [success, SetSuccess] = useState();
  const [showload, setShowLoad] = useState(false);
  const [showloadmodule, setShowLoadModule] = useState(false);
  const [usefilter, setUseFilter] = useState(false);
  const [modality, setModality] = useState();
  const [region, setRegion] = useState();
  const [showsave, setShowSave] = useState(false);
  const [onlyCurrentUser, setOnlyCurrentUser] = useState(false);
  const [localFile, setLocalFile] = useState();
  const [showLocalLoad, setShowLocalLoad] = useState(false);
  const [usetemplatefilter, setUseTemplateFilter] = useState(false);
  const [usemodulefilter, setUseModuleFilter] = useState(false);

  //   const [templateData, SetTemplateData]= useState();

  console.log(state);

  useEffect(() => {
    console.log("changed")
    window.onbeforeunload = function ()
 {
     return "";
 };
    return () => {
      
    }
  })

  function saveAsReport() {
    SetError();
    SetSuccess();
    const data = state.value;
    const authorid = userData.authid;
    const institution = userData.institution;

    let namecheck = null;
    firestore
      .collection("report")
      .doc(reportename)
      .get()
      .then((doc) => {
        namecheck = doc.data();
        console.log(namecheck);
        if (namecheck !== undefined) {
          SetError("Bezeichnung bereits vergeben");
          setTimeout(() => SetError(), 5000);
        } else {
          try {
            firestore.collection("report").doc(reportename).set({
              data: data,
              name: reportename,
              authorid: authorid,
              institution: institution,
            });

            SetSuccess("Erfolgreich gespeichert");
            setTimeout(() => SetSuccess(), 1500);
          } catch (error) {
            SetError("Speichern Fehlgeschlagen " + error);
            setTimeout(() => SetError(), 5000);
          }
        }
      });
  }

  function export2rst() {
    const originalData = state.value;
    const current = new Date();
    const date = `${current.getHours()}_${current.getMinutes()}_${current.getDate()}_${
      current.getMonth() + 1
    }_${current.getFullYear()}`;

    const a = document.createElement("a");
    a.href = URL.createObjectURL(
      new Blob([JSON.stringify(originalData, null, 2)], {
        type: "rst",
      })
    );
    a.setAttribute("download", `${date}_data.rst`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // function saveAsModule() {
  //   SetError("");
  //   const data = state.value;
  //   const authorid = userData.authid;
  //   const institution = userData.institution;
  //   console.log(JSON.stringify(state.value));
  //   try {
  //     firestore.collection("state").doc(templatename).set({
  //       data: data,
  //       name: templatename,
  //       authorid: authorid,
  //       institution: institution,
  //     });
  //   } catch (error) {
  //     SetError("Speichern Fehlgeschlagen " + error);
  //   }
  // }

  function loadModule() {

    moduleData.map((module) => {
      console.log(module)
      if(module.id === selectedModule){
        state.merge([module.data])
      }
    })

    // firestore
    //   .collection("module")
    //   .doc(selectedModule)
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.data().data);
    //     state.merge([doc.data().data]);
    //     console.log(JSON.stringify([doc.data().data[0]]));
    //   });

      setShowLoadModule(false)
  }

  //   function loadData() {
  //     firestore
  //       .collection("state")
  //       .onSnapshot((snap) => {
  //         let TemplateData = [];
  //         snap.forEach((element) => {
  //             TemplateData.push({ ...element.data(), id: element.id });
  //         });
  //         SetTemplateData(TemplateData);
  //         console.log(TemplateData);
  //       });
  //   }

  function load() {

    templateData.map((template) => {
      console.log(template)
      if(template.id === selectedTemplate){
        state.set(template.data)
      }
    })

    // Funktioniert nicht beim laden eines neuen Templates wen bereits ein Template eingeladen ist.. vermutlich wegen promis/async
    // firestore
    //   .collection("template")
    //   .doc(selectedTemplate)
    //   .get()
    //   .then((doc) => {
    //     state.set(doc.data().data);
    //   });

      setShowLoad(false)
  }

  //   useEffect(() => {
  //     loadData()

  //   }, [])

  console.log(templateData);
  //console.log(document.getElementById("test").innerText.length > 0)

  return (
    <>
      <div className="w-100 text-center mt-2">
        <NavbarComponent />
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <div className="w-100 mt-3 ml-2 d-flex justify-content-left">
          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetReportName(e.target.value)}
                        type="input"
                        placeholder="Template/Modulbezeichnung"
                      />
                    </Form.Group>
                  </Form>
                  <Button className="ml-1 mt-1 mb-1" onClick={saveAsReport}>
                    Befund speichern
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button className="ml-1" onClick={(e) => e.stopPropagation}>
              Befund speichern
            </Button>
          </OverlayTrigger> */}

          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetSelectedTemplate(e.target.value)}
                        as="select"
                        placeholder="Template/Modulbezeichnung"
                      >
                        <option>auswählen...</option>
                        {templateData
                          ? templateData.map((element) => {
                              let output = [];
                              if (usefilter) {
                                if (
                                  modality !== undefined &&
                                  modality !== "" &&
                                  region !== undefined &&
                                  region !== ""
                                ) {
                                  if (
                                    element.modality === modality &&
                                    element.region === region
                                  )
                                    output = output.concat(
                                      <option value={element.name}>
                                        {element.name}
                                      </option>
                                    );
                                } else if (
                                  modality !== undefined &&
                                  modality !== "" &&
                                  (region === undefined || region === "")
                                ) {
                                  if (element.modality === modality) {
                                    output = output.concat(
                                      <option value={element.name}>
                                        {element.name}
                                      </option>
                                    );
                                  }
                                } else if (
                                  (modality === undefined || modality === "") &&
                                  region !== undefined &&
                                  region !== ""
                                ) {
                                  if (element.region === region) {
                                    output = output.concat(
                                      <option value={element.name}>
                                        {element.name}
                                      </option>
                                    );
                                  }
                                }
                              } else {
                                output = output.concat(
                                  <option value={element.name}>
                                    {element.name}
                                  </option>
                                );
                              }
                              return output;
                            })
                          : ""}
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <Button className="ml-1" onClick={load}>
                    Template laden
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button
              className="ml-1"
              onClick={(e) => {
                setShowLoad(!showload);
              }}
            >
              Template laden
            </Button>
          </OverlayTrigger> */}

          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetSelectedModule(e.target.value)}
                        as="select"
                        placeholder="Template/Modulbezeichnung"
                      >
                        <option>auswählen...</option>
                        {moduleData
                          ? moduleData.map((element) => {
                              return (
                                <option value={element.name}>
                                  {element.name}
                                </option>
                              );
                            })
                          : ""}
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <Button className="ml-1" onClick={loadModule}>
                    Modul laden
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button className="ml-1" onClick={(e) => e.stopPropagation}>
              Modul Laden
            </Button>
          </OverlayTrigger> */}

          <Button
            className="ml-1"
            variant={showload ? "dark" : "primary"}
            onClick={(e) => {
              setShowLoad(!showload);
              if (!showload) {
                setModality();
                setRegion();
                setShowLoadModule(false);
                setShowLocalLoad(false);
              }
            }}
          >
            Template laden
          </Button>
          <Button
            className="ml-1"
            variant={showloadmodule ? "dark" : "primary"}
            onClick={(e) => {
              setShowLoadModule(!showloadmodule);
              if (!showloadmodule) {
                setModality();
                setRegion();
                setShowLoad(false);
                setShowLocalLoad(false);
              }
            }}
          >
            Modul Laden
          </Button>
          <Button
            className="ml-1"
            variant={showLocalLoad ? "dark" : "primary"}
            onClick={() => {
              setShowLocalLoad(!showLocalLoad);
              setShowLoadModule(false);
              setShowLoad(false);
            }}
          >
            Lokale Datei Laden
          </Button>
          <Button className="ml-1" onClick={() => export2rst()}>
            Befund lokal speichern
          </Button>
        </div>

        <div
          className="w-100 mt-1 mb-1"
          style={{
            display: showLocalLoad ? "" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div
            className="h-100 d-flex align-items-center justify-content-center"
            style={{ minHeight: "100px" }}
          >
            <div>
              <Form>
                <InputGroup>
                  <Form.File
                    style={{
                      backgroundColor: "white",
                      padding: "5px",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Form.File
                      onChange={(e) => {
                        setLocalFile(e.target.files[0]);
                        console.log(e.target.files[0]);
                      }}
                    />
                  </Form.File>
                  <InputGroup.Append>
                    <Button
                      onClick={(e) => {
                        if (localFile !== undefined) {
                          console.log(localFile);
                          const type = localFile.name.endsWith(".rst");
                          console.log(type);

                          function handleFileSelect(file) {
                            const reader = new FileReader();
                            reader.onload = handleFileLoad;
                            reader.readAsText(file);
                          }

                          function handleFileLoad(event) {
                            console.log(event.target.result);
                            //document.getElementById('fileContent').textContent = event.target.result;
                            state.set(JSON.parse(event.target.result));
                          }

                          if (localFile.name.endsWith(".rst")) {
                            handleFileSelect(localFile);
                          } else {
                            SetError("Bitte .rst Datei auswählen");
                            setTimeout(() => SetError(), 3000);
                          }
                        }
                      }}
                    >
                      Lokale Datei laden
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </div>
        </div>

        <div
          className="w-100 mt-1 mb-1 justify-content-center align-items-center"
          style={{
            display: showload ? "flex" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-3 w-100">
            <Form>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Filter</InputGroup.Text>
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    onClick={(e) => {
                      setUseTemplateFilter(!usetemplatefilter);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  onChange={(e) => SetSelectedTemplate(e.target.value)}
                  as="select"
                  placeholder="Template/Modulbezeichnung"
                  style={{ minWidth: "200px" }}
                >
                  <option>auswählen...</option>
                  {templateData
                    ? templateData.map((element) => {
                        let output = [];
                        if (usetemplatefilter && !onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (element.modality === modality) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (element.region === region) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === "" || modality === undefined) &&
                            (region === "" || region === undefined)
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (usetemplatefilter && onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region &&
                              element.authorid === userData.authid
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (
                              element.modality === modality &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.region === region &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === "" || modality === undefined) &&
                            (region === "" || region === undefined) &&
                            element.authorid === userData.authid
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (onlyCurrentUser && !usetemplatefilter) {
                          if (element.authorid === userData.authid) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else {
                          output = output.concat(
                            <option value={element.id}>{element.name}</option>
                          );
                        }
                        return output;
                      })
                    : ""}
                </Form.Control>
                <Form.Control
                  onChange={(e) => setModality(e.target.value)}
                  as="select"
                  placeholder="Modalität"
                  style={{ display: usetemplatefilter ? "" : "none" }}
                >
                  <option value="">Modalität auswählen...</option>
                  <option value="CT">CT</option>
                  <option value="MRT">MRT</option>
                  <option value="Röntgen">Röntgen</option>
                  <option value="Sonographie">Sonographie</option>
                  <option value="PET-CT">PET-CT</option>
                  <option value="IR">IR</option>
                </Form.Control>

                <Form.Control
                  onChange={(e) => setRegion(e.target.value)}
                  as="select"
                  placeholder="Region"
                  style={{ display: usetemplatefilter ? "" : "none" }}
                >
                  <option value="">Region auswählen...</option>
                  <option value="Ganzkörper">Ganzkörper</option>
                  <option value="Kopf">Kopf</option>
                  <option value="Hals">Hals</option>
                  <option value="Kopf/Hals">Kopf/Hals</option>
                  <option value="Thorax">Thorax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                  <option value="Becken">Becken</option>
                  <option value="Abdomen/Becken">Abdomen/Becken</option>
                  <option value="Obere Extremität">Obere Extremität</option>
                  <option value="Untere Extremität">Untere Extremität</option>
                </Form.Control>

                <InputGroup.Append>
                  <Button onClick={load}>Template laden</Button>
                  <Button
                    variant={onlyCurrentUser ? "dark" : "primary"}
                    onClick={(e) => {
                      setOnlyCurrentUser(!onlyCurrentUser);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  >
                    Nur meine
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>

        <div
          className="w-100 mt-1 mb-1 justify-content-center align-items-center"
          style={{
            display: showloadmodule ? "flex" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-3 w-100">
            <Form>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Filter</InputGroup.Text>
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    onClick={(e) => {
                      setUseModuleFilter(!usemodulefilter);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  onChange={(e) => SetSelectedModule(e.target.value)}
                  as="select"
                  placeholder="Template/Modulbezeichnung"
                  style={{ minWidth: "200px" }}
                >
                  <option>auswählen...</option>
                  {moduleData
                    ? moduleData.map((element) => {
                        let output = [];
                        if (usemodulefilter && !onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (element.modality === modality) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (element.region === region) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (modality === "" && region === "") {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (usemodulefilter && onlyCurrentUser) {
                          console.log("reached");
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region &&
                              element.authorid === userData.authid
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (
                              element.modality === modality &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.region === region &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            modality === "" &&
                            region === "" &&
                            element.authorid === userData.authid
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (onlyCurrentUser && !usemodulefilter) {
                          if (element.authorid === userData.authid) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else {
                          output = output.concat(
                            <option value={element.id}>{element.name}</option>
                          );
                        }
                        return output;
                      })
                    : ""}
                </Form.Control>

                <Form.Control
                  onChange={(e) => setModality(e.target.value)}
                  as="select"
                  placeholder="Modalität"
                  style={{ display: usemodulefilter ? "" : "none" }}
                >
                  <option value="">Modalität auswählen...</option>
                  <option value="CT">CT</option>
                  <option value="MRT">MRT</option>
                  <option value="Röntgen">Röntgen</option>
                  <option value="Sonographie">Sonographie</option>
                  <option value="PET-CT">PET-CT</option>
                  <option value="IR">IR</option>
                </Form.Control>

                <Form.Control
                  onChange={(e) => setRegion(e.target.value)}
                  as="select"
                  placeholder="Region"
                  style={{ display: usemodulefilter ? "" : "none" }}
                >
                  <option value="">Region auswählen...</option>
                  <option value="Ganzkörper">Ganzkörper</option>
                  <option value="Kopf">Kopf</option>
                  <option value="Hals">Hals</option>
                  <option value="Kopf/Hals">Kopf/Hals</option>
                  <option value="Thorax">Thorax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                  <option value="Becken">Becken</option>
                  <option value="Abdomen/Becken">Abdomen/Becken</option>
                  <option value="Obere Extremität">Obere Extremität</option>
                  <option value="Untere Extremität">Untere Extremität</option>
                </Form.Control>
                <InputGroup.Append>
                  <Button onClick={loadModule}>Modul laden</Button>
                  <Button
                    variant={onlyCurrentUser ? "dark" : "primary"}
                    onClick={(e) => {
                      setOnlyCurrentUser(!onlyCurrentUser);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  >
                    Nur meine
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>

        <div className="d-flex justifiy-content-spacebetween">
          <div
            className="w-50 mt-2 ml-2"
            style={{
              maxHeight: "82vh",
              minHeight: "82vh",
              overflow: "auto",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "lightgray",
              borderRadius: "5px",
            }}
          >
            {state.map(
              (accordionState, i) => (
                console.log(accordionState),
                (
                  <Accordion>
                    <Card style={{ borderBottom: "1px solid rgba(0,0,0,.125)", borderTop:"none", borderLeft:"none", borderRight:"none" }}>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="w-100" style={{ position: "relative" }}>
                          <Button className="justifiy-content-center" active>
                            {accordionState.name.get()}
                          </Button>

                          <Button
                            variant="danger"
                            style={{
                              width: "15px",
                              height: "15px",
                              padding: "0",
                              borderRadius: "100%",
                              position: "absolute",
                              right: "-1.1rem",
                              top: "-0.6rem",
                              border: "none",
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                width: "13px",
                                height: "13px",
                                padding: "0",
                                borderRadius: "100%",
                                position: "absolute",
                                right: "1px",
                                top: "1px",
                                border: "none",
                                color: "white",
                              }}
                              onClick={() => {
                                accordionState.set(none);
                              }}
                              icon={faMinus}
                            ></FontAwesomeIcon>
                          </Button>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {accordionState.component.map((component) => {
                            console.log(component.id.get());
                            if (component.id.get() === "DecisionTree") {
                              return (
                                <>
                                  <DecisionTreeRender
                                    componentState={component}
                                    number={component.number.get()}
                                  />
                                </>
                              );
                            }
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )
              )
            )}
          </div>
          <div className="w-50 mt-2 ml-2">
            <Card
              className="mr-2 "
              style={{ textAlign: "left", height: "50vh", overflow: "auto" }}
            >
              <Card.Body>
                <div className="d-flex w-100" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: "-13px",
                      top: "-13px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        var textToCopy = document.getElementById("TextOutput");

                        //you can target the same element using querySelector() as well
                        //example below:
                        //var textToCopy = document.querySelector('.CopyMeClass');

                        //check and see if the user had a text selection range
                        var currentRange;
                        if (document.getSelection().rangeCount > 0) {
                          //the user has a text selection range, store it
                          currentRange = document.getSelection().getRangeAt(0);
                          //remove the current selection
                          window.getSelection().removeRange(currentRange);
                        } else {
                          //they didn't have anything selected
                          currentRange = false;
                        }

                        //create a selection range
                        var CopyRange = document.createRange();
                        //choose the element we want to select the text of
                        CopyRange.selectNode(textToCopy);
                        //select the text inside the range
                        window.getSelection().addRange(CopyRange);
                        //copy the text to the clipboard
                        document.execCommand("copy");

                        //remove our selection range
                        window.getSelection().removeRange(CopyRange);

                        //return the old selection range
                        if (currentRange) {
                          window.getSelection().addRange(currentRange);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>

                {state.map((accordionState, i) => (
                  <div id="TextOutput">
                   {/* {accordionState.component.some(element => element.renderoutput.get()) && accordionState.outputName.get()? <div>
                      <b>{accordionState.name.get()}</b>
                    </div> : ""} */}
                    
                    <div style={{ textAlign: "left" }}>
                      <DecisionTreeOutputRender
                        componentState={accordionState}
                      />
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
            {/* <ContentEditable
              className="editable"
              tagName="pre"
              html="<p>Test</p>"
              // html={props.html} // innerHTML of the editable div
              // disabled={props.editable} // use true to disable edition
              // onChange={props.handleChange} // handle innerHTML change
              // onBlur={props.sanitize}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

function AccordionEditor(props) {
  const accordionState = useStateHook(props.accordionState);
  console.log(accordionState);
  return (
    <div className="inline-block mb-2">
      <div className="inline-block mt-2">
        <input
          value={accordionState.name.get()}
          onChange={(e) => accordionState.name.set(e.target.value)}
        />
      </div>

      <div className="justify-content-right mt-2">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            accordionState.component.merge([
              {
                id: "DecisionTree",
                number: accordionState.component.length,
                input: "",
                outputvalue: "",
                pdfPath: "",
                imagePath: "",
                endpoint: false,
                output: false,
                children: [],
              },
            ]);
          }}
        >
          Add Node
        </Button>

        <Button className="ml-1" onClick={() => accordionState.set(none)}>
          X
        </Button>
      </div>
    </div>
  );
}
