import React from "react";
import NavbarComponent from "./NavbarComponent";

export default function Confirmation() {
  

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />

      <div className="w-100 text-center mt-2">
        <p>Dieser Account ist noch nicht bestätigt.</p>
        <p>
          Bitte setzen Sie sich mit dem Admin ihrer Institution in Verbindung.
        </p>
      </div>
    </div>
  );
}
