import React, { useState } from "react";
import { Card, Button, Alert, Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Link, useHistory } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";
import { auth, firestore, storage } from "../firebase";

export default function ManageUser() {
  const [error, setError] = useState("");
  const [role, setRole] = useState();
  const [email, setEmail] = useState();
  const [institution, setInstitution] = useState();
  const [users, setUsers] = useState([]);
  const { currentUser, logout, addInstitution, addClaims } = useAuth();
  const { userData } = useData();
  const history = useHistory();

  let users2 = [];

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Ausloggen fehlgeschlagen");
    }
  }

  function loadAllUsers() {
    if (currentUser.role === "globalAdmin") {
      users2 = [];

      firestore
        .collection("user")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            console.log(doc.data());

            users2 = users2.concat({
              lastname: doc.data().lastname,
              firstname: doc.data().firstname,
              role: doc.data().role,
              email: doc.data().email,
              institution: doc.data().institution,
              authid: doc.data().authid
            });
          });
          console.log(users2);
          setUsers(users2);
        });
    }
  }

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />

      <div
        className="w-100 d-flex align-items-center justify-content-center"
        style={{ minHeight: "400px" }}
      >
        <Card className="mt-4">
          <Card.Body>
            <h2 className="w-100 text-center mb-4 ">User Management</h2>
            <Button className="m-1" onClick={loadAllUsers}>
              User Laden
            </Button>
            <div
              className="d-flex justify-content-spacearound"
              style={{ flexWrap: "true" }}
            >
              {users.length > 0
                ? users.map((user) => {
                    return (
                      <Card className="m-1" style={{ width: "15rem" }}>
                        <Card.Body>
                          <strong>
                            {user.firstname + " " + user.lastname}
                          </strong>
                          <p>{user.email}</p>
                          <p>{user.institution}</p>
                          <p>{user.role}</p>
                          <Form>
                            <Form.Control
                              as="select"
                              onChange={(e) => setRole(e.target.value)}
                            >
                              <option>auswählen</option>
                              <option value="user">User</option>
                              <option value="institutionAdmin">
                                Institution Admin
                              </option>
                              <option value="globalAdmin">Global Admin</option>
                            </Form.Control>
                          </Form>
                          <Button
                            className="m-1"
                            onClick={() => {
                              if (role !== undefined) {
                                console.log(user.authid)
                                addClaims({
                                  email: user.email,
                                  role: role,
                                  institution: user.institution,
                                  id: user.authid
                                });
                                loadAllUsers()
                              } else {
                                console.log(role);
                                console.log(email);
                                console.log(institution);
                              }
                            }}
                          >
                            Add Claims
                          </Button>
                        </Card.Body>
                      </Card>
                    );
                  })
                : ""}
            </div>

            {/* <Form.Control
              as="select"
              onChange={(e) => setEmail(e.target.value)}
            >
              <option>auswählen</option>
              {users.length > 0
                ? users.map((element) => {
                  console.log(element)
                    return (
                      <option value={element.email}>{element.email}</option>
                    );
                  })
                : ""}
            </Form.Control>
            <Form.Control
              value={
                users.length > 0
                  ? users.map((element) => {
                      if (element.email === email) {
                        return element.institution;
                      }
                    }).join("")
                  : ""
              }
              onChange={(e) => {
                setInstitution(e.target.value);
              }}
            />
            <Form>
              <Form.Control
                as="select"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="user">User</option>
                <option value="institutionAdmin">Institution Admin</option>
                <option value="globalAdmin">Global Admin</option>
              </Form.Control>
            </Form>
            <Button
            className="m-1"
              onClick={() => {
                if (
                  role !== undefined &&
                  email !== undefined &&
                  institution !== undefined
                ) {
                  addClaims({
                    email: email,
                    role: role,
                    institution: institution,
                  });
                } else {
                  console.log(role);
                  console.log(email);
                  console.log(institution);
                }
              }}
            >
              Add Claims
            </Button>
            <br /> */}
          </Card.Body>

          <Button variant="link" onClick={handleLogout}>
            Ausloggen
          </Button>
        </Card>
      </div>
    </div>
  );
}
