import {
  useState as useStateHook,
  State,
  none,
  createState,
} from "@hookstate/core";
import { useState, useRef, useEffect } from "react";
import {
  useGlobalState,
  AccordionData,
  Node,
  Component,
} from "./GlobalState.ts";
import NavbarComponent from "./NavbarComponent";
import { ReportPreview } from "./ReportPreview";
import {
  Button,
  Accordion,
  Card,
  Popover,
  OverlayTrigger,
  Form,
  Alert,
  Col,
  InputGroup,
  ButtonGroup,
  FormGroup,
} from "react-bootstrap";
import { DecisionTree } from "./DecisionTree.js";
import { DecisionTreeOutputRender } from "./DecisionTreeOutputRender.js";

import { firestore } from "../firebase";
import { useData } from "../contexts/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faCopy,
  faCheck
} from "@fortawesome/free-solid-svg-icons";

export const Creator = () => {
  
  const state = useStateHook(useGlobalState());
  //const globaloutputstate = useStateHook(useGlobalOutputState())
  const { userData, templateData, moduleData } = useData();
  const [templatename, SetTemplateName] = useState();
  const [selectedTemplate, SetSelectedTemplate] = useState();
  const [selectedModule, SetSelectedModule] = useState();
  const [error, SetError] = useState();
  const [success, SetSuccess] = useState();
  const [splitview, SetSplitview] = useState();
  const [preview, SetPreview] = useState();
  const [showload, setShowLoad] = useState(false);
  const [showloadmodule, setShowLoadModule] = useState(false);
  const [usetemplatefilter, setUseTemplateFilter] = useState(false);
  const [usemodulefilter, setUseModuleFilter] = useState(false);
  const [modality, setModality] = useState();
  const [region, setRegion] = useState();
  const [showsave, setShowSave] = useState(false);
  const [onlyCurrentUser, setOnlyCurrentUser] = useState(false);
  const [localFile, setLocalFile] = useState();
  const [showLocalLoad, setShowLocalLoad] = useState(false);
  const [statechange, setStateChange] = useState(false)

  //   const [templateData, SetTemplateData]= useState();

  console.log(state);

  useEffect(() => {
    console.log("changed")
    window.onbeforeunload = function ()
 {
     return "";
 };
    return () => {
      
    }
  })



  // function saveAsTemplate() {
  //   SetError();
  //   SetSuccess();
  //   const data = state.value;
  //   const authorid = userData.authid;
  //   const institution = userData.institution;
  //   console.log(JSON.stringify(state.value));

  //   let namecheck = null;
  //   console.log(templatename);
  //   firestore
  //     .collection("state")
  //     .doc(templatename)
  //     .get()
  //     .then((doc) => {
  //       namecheck = doc.data();
  //       console.log(namecheck);
  //       if (namecheck !== undefined) {
  //         const confirm = window.confirm(
  //           "Bezeichnung bereits vergeben. Template überschreiben?"
  //         );
  //         if (confirm && namecheck.authorid === userData.authid) {
  //           try {
  //             firestore.collection("state").doc(templatename).set({
  //               data: data,
  //               name: templatename,
  //               authorid: authorid,
  //               institution: institution,
  //             });

  //             SetSuccess("Erfolgreich gespeichert");
  //             setTimeout(() => SetSuccess(), 1500);
  //           } catch (error) {
  //             SetError("Speichern Fehlgeschlagen " + error);
  //             setTimeout(() => SetError(), 5000);
  //           }
  //         } else if (confirm && namecheck.authorid === userData.authid) {
  //           SetError(
  //             "Sie sind nicht die/der Autor*in dieses Templates und können es dehalb nicht überschreiben"
  //           );
  //         } else {
  //           SetError("Fehler. Template nicht gespeichert.");
  //           setTimeout(() => SetError(), 5000);
  //         }
  //       } else {
  //         try {
  //           firestore.collection("state").doc(templatename).set({
  //             data: data,
  //             name: templatename,
  //             authorid: authorid,
  //             institution: institution,
  //           });

  //           SetSuccess("Erfolgreich gespeichert");
  //           setTimeout(() => SetSuccess(), 1500);
  //         } catch (error) {
  //           SetError("Speichern Fehlgeschlagen " + error);
  //           setTimeout(() => SetError(), 5000);
  //         }
  //       }
  //     });
  // }

  // function saveAsModule() {
  //   SetError("");
  //   const data = state.value;
  //   const authorid = userData.authid;
  //   const institution = userData.institution;
  //   console.log(JSON.stringify(state.value));
  //   try {
  //     firestore.collection("state").doc(templatename).set({
  //       data: data,
  //       name: templatename,
  //       authorid: authorid,
  //       institution: institution,
  //     });
  //   } catch (error) {
  //     SetError("Speichern Fehlgeschlagen " + error);
  //   }
  // }

  function saveAsTemplate() {
    SetError();
    SetSuccess();
    const data = state.value;
    const authorid = userData.authid;
    const institution = userData.institution;

    let namecheck = null;
    let id = null

    templateData.map((templateelement) => {
      if(templateelement.name === templatename ){
        namecheck = templateelement
      }
    })

    
      if (templateData.some((element) => element.name === templatename)) {
        const confirm = window.confirm(
          "Bezeichnung bereits vergeben. Template überschreiben?"
        );
        if (confirm && namecheck.authorid === userData.authid) {
          if (modality && region) {
            
            try {
              firestore.collection("template").doc(namecheck.id).set({
                data: data,
                name: templatename,
                authorid: authorid,
                institution: institution,
                modality: modality,
                region: region,
              });
              setShowSave(false)
              SetSuccess("Erfolgreich gespeichert");
              setTimeout(() => SetSuccess(), 2000);
             

            } catch (error) {
              SetError("Speichern Fehlgeschlagen " + error);
              setTimeout(() => SetError(), 5000);
            }
          } else if (modality && !region) {
            SetError("Bitte Region auswählen");
            setTimeout(() => SetError(), 5000);
          } else if (!modality && region) {
            SetError("Bitte Modalität auswählen");
            setTimeout(() => SetError(), 5000);
          } else if (!modality && !region) {
            SetError("Bitte Modalität und Region auswählen");
            setTimeout(() => SetError(), 5000);
          }
        } else if (confirm && namecheck.authorid !== userData.authid) {
          SetError(
            "Sie sind nicht die/der Autor*in dieses Moduls und können es dehalb nicht überschreiben"
          );
          setTimeout(() => SetError(), 5000);
        } else {
          SetError("Fehler. Template nicht gespeichert.");
          setTimeout(() => SetError(), 5000);
        }
      } else {
        if (modality && region) {
          try {
            firestore.collection("template").doc().set({
              data: data,
              name: templatename,
              authorid: authorid,
              institution: institution,
              modality: modality,
              region: region,
            });

            setShowSave(false)
            SetSuccess("Erfolgreich gespeichert");
            setTimeout(() => SetSuccess(), 1500);
            
          } catch (error) {
            SetError("Speichern Fehlgeschlagen " + error);
            setTimeout(() => SetError(), 5000);
          }
        } else if (modality && !region) {
          SetError("Bitte Region auswählen");
          setTimeout(() => SetError(), 5000);
        } else if (!modality && region) {
          SetError("Bitte Modalität auswählen");
          setTimeout(() => SetError(), 5000);
        } else if (!modality && !region) {
          SetError("Bitte Modalität und Region auswählen");
          setTimeout(() => SetError(), 5000);
        }
      }

  }

  function loadTemplate() {
    
      templateData.map((template) => {
        console.log(template)
        if(template.id === selectedTemplate){
          console.log(template.modality)
          state.set(template.data)
          SetTemplateName(template.name)
          setModality(template.modality)
          setRegion(template.region)
        }
      })

        // Funktioniert nicht beim laden eines neuen Templates wen bereits ein Template eingeladen ist.. vermutlich wegen promis/async
    // firestore
    //   .collection("template")
    //   .doc(selectedTemplate)
    //   .get()
    //   .then((doc) => {
    //     state.set(doc.data().data);
    //   });

      setShowLoad(false)
  }

  function loadModule() {

    moduleData.map((module) => {
      console.log(module)
      if(module.id === selectedModule){
        state.merge([module.data])
      }
    })

    // firestore
    //   .collection("module")
    //   .doc(selectedModule)
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.data().data);
    //     state.merge([doc.data().data]);
    //     console.log(JSON.stringify([doc.data().data[0]]));
    //   });

      setShowLoadModule(false)
  }

    // useEffect(() => {
    //   setStateChange(true)


    // }, [state])
console.log(statechange)
    useEffect(() => {
      setStateChange(true)
      console.log("State changed")
      return () => {
        setStateChange(false)
      }
    }, [state])

  console.log(templateData);

  return (
    <>
      <div className="w-100 text-center mt-2">
        <NavbarComponent />
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <div className="w-100 mt-3 ml-2 d-flex justify-content-right">
          <Button
            onClick={() =>
              state.merge([
                {
                  name: "Modul",
                  component: [],
                },
              ])
            }
          >
            Modul hinzufügen
          </Button>

          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetTemplateName(e.target.value)}
                        type="input"
                        placeholder="Template/Modulbezeichnung"
                      />
                      <br />
                      <Form.Label>Modalität</Form.Label>
                      <Form.Control
                        onClick={(e) => e.stopPropagation()}
                        as="select"
                        onChange={(e) => {
                          setModality(e.target.value);
                        }}
                      >
                        <option>auswählen...</option>
                        <option value="CT">CT</option>
                        <option value="MRT">MRT</option>
                        <option value="Röntgen">Röntgen</option>
                        <option value="Sonographie">Sonographie</option>
                        <option value="PET-CT">PET-CT</option>
                        <option value="IR">IR</option>
                      </Form.Control>
                      <br />
                      <Form.Label>Region</Form.Label>
                      <Form.Control
                        onClick={(e) => e.stopPropagation()}
                        as="select"
                        onChange={(e) => {
                          setRegion(e.target.value);
                        }}
                      >
                        <option>auswählen...</option>
                        <option value="Ganzkörper">Ganzkörper</option>
                        <option value="Kopf">Kopf</option>
                        <option value="Hals">Hals</option>
                        <option value="Kopf/Hals">Kopf/Hals</option>
                        <option value="Thorax">Thorax</option>
                        <option value="Abdomen">Abdomen</option>
                        <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                        <option value="Becken">Becken</option>
                        <option value="Abdomen/Becken">Abdomen/Becken</option>
                        <option value="Obere Extremität">
                          Obere Extremität
                        </option>
                        <option value="Untere Extremität">
                          Untere Extremität
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                  <Button className="ml-1 mt-1 mb-1" onClick={saveAsTemplate}>
                    Template speichern
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button className="ml-1" onClick={(e) => e.stopPropagation}>
              Speichern
            </Button>
          </OverlayTrigger> */}

          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetSelectedTemplate(e.target.value)}
                        as="select"
                        placeholder="Template/Modulbezeichnung"
                      >
                        <option>auswählen...</option>
                        {templateData
                          ? templateData.map((element) => {
                              return (
                                <option value={element.name}>
                                  {element.name}
                                </option>
                              );
                            })
                          : ""}
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <Button className="ml-1" onClick={loadTemplate}>
                    Template laden
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button className="ml-1" onClick={(e) => e.stopPropagation}>
              Template Laden
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetSelectedModule(e.target.value)}
                        as="select"
                        placeholder="Template/Modulbezeichnung"
                      >
                        <option>auswählen...</option>
                        {moduleData
                          ? moduleData.map((element) => {
                              return (
                                <option value={element.name}>
                                  {element.name}
                                </option>
                              );
                            })
                          : ""}
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <Button className="ml-1" onClick={loadModule}>
                    Modul laden
                  </Button>
                </Popover.Content>
              </Popover>
            }
          >
            <Button className="ml-1" onClick={(e) => e.stopPropagation}>
              Modul Laden
            </Button>
          </OverlayTrigger> */}
          <Button
            className="ml-1"
            variant={showsave ? "dark" : "primary"}
            onClick={() => {
              setShowSave(!showsave);
              if (!showsave) {
                setShowLoad(false);
                setShowLoadModule(false);
              }
            }}
          >
            Speichern
          </Button>

          <Button
            className="ml-1"
            variant={showload ? "dark" : "primary"}
            onClick={() => {
              setShowLoad(!showload);
              if (!showload) {
                setModality();
                setRegion();
                setShowLoadModule(false);
                setShowSave(false);
              }
            }}
          >
            Template laden
          </Button>
          <Button
            className="ml-1"
            variant={showloadmodule ? "dark" : "primary"}
            onClick={(e) => {
              setShowLoadModule(!showloadmodule);
              if (!showloadmodule) {
                setModality();
                setRegion();
                setShowLoad(false);
                setShowSave(false);
              }
            }}
          >
            Modul Laden
          </Button>
          <Button
            className="ml-1"
            variant={splitview ? "dark" : "primary"}
            onClick={() => {
              SetSplitview(!splitview);
              if (preview) SetPreview(false);
            }}
          >
            Splitview
          </Button>
          <Button
            className="ml-1"
            variant={preview ? "dark" : "primary"}
            onClick={() => {
              SetPreview(!preview);
              if (splitview) {
                SetSplitview(false);
              }
            }}
          >
            Preview
          </Button>
        </div>

        <div
          className="w-100 mt-1 mb-1 justify-content-center align-items-center"
          style={{
            display: showsave ? "flex" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-3">
            <Form>
              <InputGroup>
                <Form.Control
                  onChange={(e) => SetTemplateName(e.target.value)}
                  type="input"
                  placeholder="Templatebezeichnung"
                  defaultValue={templatename}
                  style={{ minWidth: "250px" }}
                />
                <br />

                <Form.Control
                  onClick={(e) => e.stopPropagation()}
                  as="select"
                  defaultValue={modality !== "" ? modality : ""}
                  onChange={(e) => {
                    setModality(e.target.value);
                  }}
                >
                  <option>auswählen...</option>
                  <option value="CT">CT</option>
                  <option value="MRT">MRT</option>
                  <option value="Röntgen">Röntgen</option>
                  <option value="Sonographie">Sonographie</option>
                  <option value="PET-CT">PET-CT</option>
                  <option value="IR">IR</option>
                </Form.Control>
                <br />

                <Form.Control
                  onClick={(e) => e.stopPropagation()}
                  as="select"
                  defaultValue={region}
                  onChange={(e) => {
                    setRegion(e.target.value);
                  }}
                >
                  <option>auswählen...</option>
                  <option value="Ganzkörper">Ganzkörper</option>
                  <option value="Kopf">Kopf</option>
                  <option value="Hals">Hals</option>
                  <option value="Kopf/Hals">Kopf/Hals</option>
                  <option value="Thorax">Thorax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                  <option value="Becken">Becken</option>
                  <option value="Abdomen/Becken">Abdomen/Becken</option>
                  <option value="Obere Extremität">Obere Extremität</option>
                  <option value="Untere Extremität">Untere Extremität</option>
                </Form.Control>
                <InputGroup.Append>
                  <Button onClick={saveAsTemplate}>Template speichern</Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>

        <div
          className="w-100 mt-1 mb-1 justify-content-center align-items-center"
          style={{
            display: showload ? "flex" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-3 w-100">
            <Form>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Filter</InputGroup.Text>
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    onClick={(e) => {
                      setUseTemplateFilter(!usetemplatefilter);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  onChange={(e) => SetSelectedTemplate(e.target.value)}
                  as="select"
                  placeholder="Template/Modulbezeichnung"
                  style={{ minWidth: "200px" }}
                >
                  <option>auswählen...</option>
                  {templateData
                    ? templateData.map((element) => {
                        let output = [];
                        if (usetemplatefilter && !onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (element.modality === modality) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (element.region === region) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === "" || modality === undefined) &&
                            (region === "" || region === undefined)
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (usetemplatefilter && onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region &&
                              element.authorid === userData.authid
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (
                              element.modality === modality &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.region === region &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === "" || modality === undefined) &&
                            (region === "" || region === undefined) &&
                            element.authorid === userData.authid
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (onlyCurrentUser && !usetemplatefilter) {
                          if (element.authorid === userData.authid) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else {
                          output = output.concat(
                            <option value={element.id}>{element.name}</option>
                          );
                        }
                        return output;
                      })
                    : ""}
                </Form.Control>
                <Form.Control
                  onChange={(e) => setModality(e.target.value)}
                  as="select"
                  placeholder="Modalität"
                  style={{ display: usetemplatefilter ? "" : "none" }}
                >
                  <option value="">Modalität auswählen...</option>
                  <option value="CT">CT</option>
                  <option value="MRT">MRT</option>
                  <option value="Röntgen">Röntgen</option>
                  <option value="Sonographie">Sonographie</option>
                  <option value="PET-CT">PET-CT</option>
                  <option value="IR">IR</option>
                </Form.Control>

                <Form.Control
                  onChange={(e) => setRegion(e.target.value)}
                  as="select"
                  placeholder="Region"
                  style={{ display: usetemplatefilter ? "" : "none" }}
                >
                  <option value="">Region auswählen...</option>
                  <option value="Ganzkörper">Ganzkörper</option>
                  <option value="Kopf">Kopf</option>
                  <option value="Hals">Hals</option>
                  <option value="Kopf/Hals">Kopf/Hals</option>
                  <option value="Thorax">Thorax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                  <option value="Becken">Becken</option>
                  <option value="Abdomen/Becken">Abdomen/Becken</option>
                  <option value="Obere Extremität">Obere Extremität</option>
                  <option value="Untere Extremität">Untere Extremität</option>
                </Form.Control>

                <InputGroup.Append>
                  <Button onClick={loadTemplate}>Template laden</Button>
                  <Button
                    variant={onlyCurrentUser ? "dark" : "primary"}
                    onClick={(e) => {
                      setOnlyCurrentUser(!onlyCurrentUser);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  >
                    Nur meine
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>

        <div
          className="w-100 mt-1 mb-1 justify-content-center align-items-center"
          style={{
            display: showloadmodule ? "flex" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-3 w-100">
            <Form>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Filter</InputGroup.Text>
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    onClick={(e) => {
                      setUseModuleFilter(!usemodulefilter);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  onChange={(e) => SetSelectedModule(e.target.value)}
                  as="select"
                  placeholder="Template/Modulbezeichnung"
                  style={{ minWidth: "200px" }}
                >
                  <option>auswählen...</option>
                  {moduleData
                    ? moduleData.map((element) => {
                        let output = [];
                        if (usemodulefilter && !onlyCurrentUser) {
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (element.modality === modality) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (element.region === region) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (modality === "" && region === "") {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (usemodulefilter && onlyCurrentUser) {
                          console.log("reached");
                          if (
                            modality !== undefined &&
                            modality !== "" &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.modality === modality &&
                              element.region === region &&
                              element.authorid === userData.authid
                            )
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                          } else if (
                            modality !== undefined &&
                            modality !== "" &&
                            (region === undefined || region === "")
                          ) {
                            if (
                              element.modality === modality &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            (modality === undefined || modality === "") &&
                            region !== undefined &&
                            region !== ""
                          ) {
                            if (
                              element.region === region &&
                              element.authorid === userData.authid
                            ) {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                          } else if (
                            modality === "" &&
                            region === "" &&
                            element.authorid === userData.authid
                          ) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else if (onlyCurrentUser && !usemodulefilter) {
                          if (element.authorid === userData.authid) {
                            output = output.concat(
                              <option value={element.id}>
                                {element.name}
                              </option>
                            );
                          }
                        } else {
                          output = output.concat(
                            <option value={element.id}>{element.name}</option>
                          );
                        }
                        return output;
                      })
                    : ""}
                </Form.Control>

                <Form.Control
                  onChange={(e) => setModality(e.target.value)}
                  as="select"
                  placeholder="Modalität"
                  style={{ display: usemodulefilter ? "" : "none" }}
                >
                  <option value="">Modalität auswählen...</option>
                  <option value="CT">CT</option>
                  <option value="MRT">MRT</option>
                  <option value="Röntgen">Röntgen</option>
                  <option value="Sonographie">Sonographie</option>
                  <option value="PET-CT">PET-CT</option>
                  <option value="IR">IR</option>
                </Form.Control>

                <Form.Control
                  onChange={(e) => setRegion(e.target.value)}
                  as="select"
                  placeholder="Region"
                  style={{ display: usemodulefilter ? "" : "none" }}
                >
                  <option value="">Region auswählen...</option>
                  <option value="Ganzkörper">Ganzkörper</option>
                  <option value="Kopf">Kopf</option>
                  <option value="Hals">Hals</option>
                  <option value="Kopf/Hals">Kopf/Hals</option>
                  <option value="Thorax">Thorax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                  <option value="Becken">Becken</option>
                  <option value="Abdomen/Becken">Abdomen/Becken</option>
                  <option value="Obere Extremität">Obere Extremität</option>
                  <option value="Untere Extremität">Untere Extremität</option>
                </Form.Control>
                <InputGroup.Append>
                  <Button onClick={loadModule}>Modul laden</Button>
                  <Button
                    variant={onlyCurrentUser ? "dark" : "primary"}
                    onClick={(e) => {
                      setOnlyCurrentUser(!onlyCurrentUser);
                      // if(!usefilter){
                      //   SetSelectedDiscriptorModality()
                      //   SetSelectedDiscriptorRegion()
                      // }
                    }}
                  >
                    Nur meine
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>

        <div className="d-flex justifiy-content-spacebetween">
          <div
            className={
              splitview ? "w-50 text-center mt-2 ml-2" : "w-100 text-center mt-2 mr-2 ml-2"
            }
            style={{
              display: preview ? "none" : "",
              maxHeight: "82vh",
              minHeight: "82vh",
              overflow: "auto",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "lightgray",
              borderRadius: "5px",
            }}
          >
            {state.map((accordionState, i) => {
              let index = i;
              return (
                <Accordion>
                  <Card
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,.125)",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    <Accordion.Toggle
                      as={Card.Header}
                      style={{ position: "relative" }}
                      eventKey="0"
                    >
                      {/* <ButtonGroup
                        vertical
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "0px",
                          height: "30px",
                          border: "none"
                        }}
                      > */}
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          position: "absolute",
                          right: "10px",
                          top: "15px",
                          height: "16px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const statecopy = JSON.parse(
                            JSON.stringify(state.value)
                          );
                          var currentPosition = index;
                          var futurePosition = index - 1;
                          var temp = statecopy[currentPosition];
                          console.log(temp);
                          var i;
                          for (i = currentPosition; i >= futurePosition; i--) {
                            if (i - 1 >= 0) {
                              statecopy[i] = statecopy[i - 1];
                            }
                          }
                          statecopy[futurePosition] = temp;
                          state.set(statecopy);
                        }}
                      >
                        <FontAwesomeIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            const statecopy = JSON.parse(
                              JSON.stringify(state.value)
                            );
                            var currentPosition = index;
                            var futurePosition = index - 1;
                            var temp = statecopy[currentPosition];
                            console.log(temp);
                            var i;
                            for (
                              i = currentPosition;
                              i >= futurePosition;
                              i--
                            ) {
                              if (i - 1 >= 0) {
                                statecopy[i] = statecopy[i - 1];
                              }
                            }
                            statecopy[futurePosition] = temp;
                            state.set(statecopy);
                          }}
                          style={{
                            color: "darkgray",
                            position: "absolute",
                            right: "7px",
                            top: "0px",
                          }}
                          icon={faSortUp}
                        ></FontAwesomeIcon>
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                          height: "16px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const statecopy = JSON.parse(
                            JSON.stringify(state.value)
                          );
                          var currentPosition = index;
                          var futurePosition = index + 1;
                          var temp = statecopy[currentPosition];
                          console.log(temp);
                          var i;
                          for (i = currentPosition; i <= futurePosition; i++) {
                            if (i + 1 < statecopy.length) {
                              statecopy[i] = statecopy[i + 1];
                            }
                          }
                          if (futurePosition < statecopy.length) {
                            statecopy[futurePosition] = temp;
                          }
                          state.set(statecopy);
                        }}
                      >
                        <FontAwesomeIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            const statecopy = JSON.parse(
                              JSON.stringify(state.value)
                            );
                            var currentPosition = index;
                            var futurePosition = index + 1;
                            var temp = statecopy[currentPosition];
                            console.log(temp);
                            var i;
                            for (
                              i = currentPosition;
                              i <= futurePosition;
                              i++
                            ) {
                              if (i + 1 < statecopy.length) {
                                statecopy[i] = statecopy[i + 1];
                              }
                            }
                            if (futurePosition < statecopy.length) {
                              statecopy[futurePosition] = temp;
                            }
                            state.set(statecopy);
                          }}
                          style={{
                            color: "darkgray",
                            position: "absolute",
                            right: "7px",
                            bottom: "0px",
                          }}
                          icon={faSortDown}
                        ></FontAwesomeIcon>
                      </Button>
                      {/* </ButtonGroup> */}
                      <OverlayTrigger
                        trigger="click"
                        placement="auto"
                        rootClose={true}
                        overlay={
                          <Popover
                            id="popover-contained"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Popover.Content>
                              <AccordionEditor
                                key={i}
                                accordionState={accordionState}
                              />
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Button onClick={(e) => e.stopPropagation()}>
                          {accordionState.name.get()}
                        </Button>
                      </OverlayTrigger>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {accordionState.component.map((component) => {
                          console.log("----------HIER---------------");
                          console.log(accordionState);
                          console.log(component);
                          console.log(component.id.get());
                          if (component.id.get() === "DecisionTree") {
                            return (
                              <>
                                {/* <Button className="mt-2" onClick={() => component.set(none)}>
                                    löschen
                                  </Button> */}
                                <DecisionTree
                                  componentState={component}
                                  number={component.number.get()}
                                />
                              </>
                            );
                          }
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            })}
          </div>

          <div
            className={
              splitview
                ? "w-50 text-center mt-2 ml-2 mr-2"
                : preview
                ? "w-50 text-center mt-2 ml-2"
                : ""
            }
            style={{
              display: !preview && !splitview ? "none" : "",
              maxHeight: "82vh",
              minHeight: "82vh",
              overflow: "auto",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "lightgray",
              borderRadius: "5px",
            }}
          >
            <ReportPreview />
          </div>
          <div
            className={
              splitview
                ? "w-50 text-center mt-2 ml-2"
                : preview
                ? "w-50 text-center mt-2 ml-2 mr-2"
                : ""
            }
            style={{
              display: preview ? "" : "none",
              maxHeight: "82vh",
              minHeight: "82vh",
              overflow: "auto",
              // borderStyle: "solid",
              // borderWidth: "1px",
              // borderColor: "lightgray",
              // borderRadius: "5px",
            }}
          >
            <Card
              
              id="allTextoutput"
              style={{ textAlign: "left", height: "50vh", overflow: "auto" }}
            >
              <Card.Body>
                <div className="d-flex w-100" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: "-13px",
                      top: "-13px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        var textToCopy = document.getElementById("TextOutput");

                        //you can target the same element using querySelector() as well
                        //example below:
                        //var textToCopy = document.querySelector('.CopyMeClass');

                        //check and see if the user had a text selection range
                        var currentRange;
                        if (document.getSelection().rangeCount > 0) {
                          //the user has a text selection range, store it
                          currentRange = document.getSelection().getRangeAt(0);
                          //remove the current selection
                          window.getSelection().removeRange(currentRange);
                        } else {
                          //they didn't have anything selected
                          currentRange = false;
                        }

                        //create a selection range
                        var CopyRange = document.createRange();
                        //choose the element we want to select the text of
                        CopyRange.selectNode(textToCopy);
                        //select the text inside the range
                        window.getSelection().addRange(CopyRange);
                        //copy the text to the clipboard
                        document.execCommand("copy");

                        //remove our selection range
                        window.getSelection().removeRange(CopyRange);

                        //return the old selection range
                        if (currentRange) {
                          window.getSelection().addRange(currentRange);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>
                <div id="TextOutput">
                {state.map((accordionState, i) => (
                  
                    <div style={{ textAlign: "left" }}>
                      <DecisionTreeOutputRender
                        componentState={accordionState}
                      />
                  
                  </div>
                ))}
                </div>

                {/* {state.map((accordionState) => {
                  if(accordionState.check.get() !== undefined){
                    return accordionState.check.map((element => {
                      return <div style={{display: "none"}}>{element.get()}</div>
                    }))
                  }
                  
                })} */}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

function AccordionEditor(props) {
  const accordionState = useStateHook(props.accordionState);
  console.log(accordionState);
  //const globaloutputstate = useStateHook(useGlobalOutputState())

  const { userData, moduleData } = useData();
  const [modulename, setModulename] = useState();
  const [error, SetError] = useState();
  const [success, SetSuccess] = useState();
  const [modality, setModality] = useState();
  const [region, setRegion] = useState();

  function saveAsModule() {
    SetError();
    SetSuccess();
    const data = accordionState.value;
    const authorid = userData.authid;
    const institution = userData.institution;

    let namecheck = null;

    if (moduleData !== undefined && moduleData.length > 0){
      moduleData.map((element) => {
        if(element.name === modulename ){
          namecheck = element
        }
      })
    } 

    if (moduleData.some(element => element.name === modulename)) {
      const confirm = window.confirm(
        "Bezeichnung bereits vergeben. Modul überschreiben?"
      );
      if (confirm && namecheck.authorid === userData.authid) {
        if (modality && region) {
          try {
            firestore.collection("module").doc(namecheck.id).set({
              data: data,
              name: modulename,
              authorid: authorid,
              institution: institution,
              modality: modality,
              region: region,
            });

            SetSuccess("Erfolgreich gespeichert");
            setTimeout(() => SetSuccess(), 2000);
          } catch (error) {
            SetError("Speichern Fehlgeschlagen " + error);
            setTimeout(() => SetError(), 5000);
          }
        } else if (modality && !region) {
          SetError("Bitte Region auswählen");
          setTimeout(() => SetError(), 5000);
        } else if (!modality && region) {
          SetError("Bitte Modalität auswählen");
          setTimeout(() => SetError(), 5000);
        } else if (!modality && !region) {
          SetError("Bitte Modalität und Region auswählen");
          setTimeout(() => SetError(), 5000);
        }
      } else if (confirm && namecheck.authorid === userData.authid) {
        SetError(
          "Sie sind nicht die/der Autor*in dieses Moduls und können es dehalb nicht überschreiben"
        );
        setTimeout(() => SetError(), 5000);
      } else {
        SetError("Fehler. Template nicht gespeichert.");
        setTimeout(() => SetError(), 5000);
      }
    } else {
      if (modality && region) {
        try {
          firestore.collection("module").doc().set({
            data: data,
            name: modulename,
            authorid: authorid,
            institution: institution,
            modality: modality,
            region: region,
          });

          SetSuccess("Erfolgreich gespeichert");
          setTimeout(() => SetSuccess(), 1500);
        } catch (error) {
          SetError("Speichern Fehlgeschlagen " + error);
          setTimeout(() => SetError(), 5000);
        }
      } else if (modality && !region) {
        SetError("Bitte Region auswählen");
        setTimeout(() => SetError(), 5000);
      } else if (!modality && region) {
        SetError("Bitte Modalität auswählen");
        setTimeout(() => SetError(), 5000);
      } else if (!modality && !region) {
        SetError("Bitte Modalität und Region auswählen");
        setTimeout(() => SetError(), 5000);
      }
    }

    // firestore
    //   .collection("module")
    //   .doc(modulename)
    //   .get()
    //   .then((doc) => {
    //     namecheck = doc.data();
    //     console.log(namecheck);
    //     if (namecheck !== undefined) {
    //       const confirm = window.confirm(
    //         "Bezeichnung bereits vergeben. Modul überschreiben?"
    //       );
    //       if (confirm && namecheck.authorid === userData.authid) {
    //         if (modality && region) {
    //           try {
    //             firestore.collection("module").doc(modulename).set({
    //               data: data,
    //               name: modulename,
    //               authorid: authorid,
    //               institution: institution,
    //               modality: modality,
    //               region: region,
    //             });

    //             SetSuccess("Erfolgreich gespeichert");
    //             setTimeout(() => SetSuccess(), 2000);
    //           } catch (error) {
    //             SetError("Speichern Fehlgeschlagen " + error);
    //             setTimeout(() => SetError(), 5000);
    //           }
    //         } else if (modality && !region) {
    //           SetError("Bitte Region auswählen");
    //           setTimeout(() => SetError(), 5000);
    //         } else if (!modality && region) {
    //           SetError("Bitte Modalität auswählen");
    //           setTimeout(() => SetError(), 5000);
    //         } else if (!modality && !region) {
    //           SetError("Bitte Modalität und Region auswählen");
    //           setTimeout(() => SetError(), 5000);
    //         }
    //       } else if (confirm && namecheck.authorid === userData.authid) {
    //         SetError(
    //           "Sie sind nicht die/der Autor*in dieses Moduls und können es dehalb nicht überschreiben"
    //         );
    //         setTimeout(() => SetError(), 5000);
    //       } else {
    //         SetError("Fehler. Template nicht gespeichert.");
    //         setTimeout(() => SetError(), 5000);
    //       }
    //     } else {
    //       if (modality && region) {
    //         try {
    //           firestore.collection("module").doc(modulename).set({
    //             data: data,
    //             name: modulename,
    //             authorid: authorid,
    //             institution: institution,
    //             modality: modality,
    //             region: region,
    //           });

    //           SetSuccess("Erfolgreich gespeichert");
    //           setTimeout(() => SetSuccess(), 1500);
    //         } catch (error) {
    //           SetError("Speichern Fehlgeschlagen " + error);
    //           setTimeout(() => SetError(), 5000);
    //         }
    //       } else if (modality && !region) {
    //         SetError("Bitte Region auswählen");
    //         setTimeout(() => SetError(), 5000);
    //       } else if (!modality && region) {
    //         SetError("Bitte Modalität auswählen");
    //         setTimeout(() => SetError(), 5000);
    //       } else if (!modality && !region) {
    //         SetError("Bitte Modalität und Region auswählen");
    //         setTimeout(() => SetError(), 5000);
    //       }
    //     }
    //   });
  }


  return (
    <div className="inline-block mb-2">
      <div className="inline-block mt-2">
        <Form>
          <Form.Control
            value={accordionState.name.get()}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => accordionState.name.set(e.target.value)}
          />
        </Form>
      </div>

      <div className="justify-content-right mt-2">
        <Button
          className="mr-1 mb-1"
          onClick={(e) => {
            e.stopPropagation();
            accordionState.component.merge([
              {
                id: "DecisionTree",
                number: accordionState.component.length,
                input: "",
                // outputvalue: "",
                // pdfPath: "",
                // imagePath: "",
                // endpoint: false,
                // output: false,
                buttons: [],
                children: [],
              },
            ]);
          }}
        >
          Knoten hinzufügen
        </Button>

        <Button className="mr-1 mb-1" onClick={() => accordionState.set(none)}>
          Modul löschen
        </Button>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose={true}
          overlay={
            <Popover>
              <Popover.Content>
                <Form>
                  <Form.Control
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setModulename(e.target.value);
                    }}
                  />
                  <br />
                  <Form.Label>Modalität</Form.Label>
                  <Form.Control
                    onClick={(e) => e.stopPropagation()}
                    as="select"
                    onChange={(e) => {
                      setModality(e.target.value);
                    }}
                  >
                    <option>auswählen...</option>
                    <option value="allgemein">allgemein</option>
                    <option value="CT">CT</option>
                    <option value="MRT">MRT</option>
                    <option value="Röntgen">Röntgen</option>
                    <option value="Sonographie">Sonographie</option>
                    <option value="PET-CT">PET-CT</option>
                    <option value="IR">IR</option>
                  </Form.Control>
                  <br />
                  <Form.Label>Region</Form.Label>
                  <Form.Control
                    onClick={(e) => e.stopPropagation()}
                    as="select"
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                  >
                    <option>auswählen...</option>
                    <option value="allgemein">allgemein</option>
                    <option value="Ganzkörper">Ganzkörper</option>
                    <option value="Kopf">Kopf</option>
                    <option value="Hals">Hals</option>
                    <option value="Kopf/Hals">Kopf/Hals</option>
                    <option value="Thorax">Thorax</option>
                    <option value="Abdomen">Abdomen</option>
                    <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                    <option value="Becken">Becken</option>
                    <option value="Abdomen/Becken">Abdomen/Becken</option>
                    <option value="Obere Extremität">Obere Extremität</option>
                    <option value="Untere Extremität">Untere Extremität</option>
                  </Form.Control>
                </Form>
                <br />
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    saveAsModule();
                  }}
                >
                  Modul speichern
                </Button>
                <br />
                {error && (
                  <Alert className="mt-2" variant="danger">
                    {error}
                  </Alert>
                )}
                {success && (
                  <Alert className="mt-2" variant="success">
                    {success}
                  </Alert>
                )}

                
              </Popover.Content>
            </Popover>
          }
        >
          <Button className="mr-1 mb-1" onClick={(e) => e.stopPropagation()}>Modul speichern</Button>
        </OverlayTrigger>

        <Form className="mr-1 mb-1">
                  <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      Überschrift anzeigen?
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                  <Button 
                  variant={accordionState.outputName.get() ? "dark" : "primary"}
                  onClick={() => {
                    if(accordionState.outputName.get() === undefined){
                      accordionState.outputName.set(true)
                    }
                    else if (accordionState.outputName.get() !== undefined){
                      accordionState.outputName.set(!accordionState.outputName.get())
                    }

                    // Falls ich mich entscheide outputRender komplett neu zu machen, korrespondierend zu DecisionTreeOutputRender copy

                    // if(accordionState.outputName.get() === undefined){
                    //   if (globaloutputstate.length === 0) {
                    //     globaloutputstate.merge([
                    //       {
                    //         hookstatepath: accordionState.path,
                    //         output: accordionState.name.get(),
                    //       },
                    //     ]);
                    //   }
                    //   else if (
                    //     !globaloutputstate.some(
                    //       (element) =>
                    //         JSON.stringify(element.hookstatepath.get()) ===
                    //         JSON.stringify(accordionState.path)
                    //     )
                    //   ) {
                    //     globaloutputstate.merge([
                    //       {
                    //         hookstatepath: accordionState.path,
                    //         output: accordionState.name.get(),
                    //       },
                    //     ]);
                    //   }
                    // } else if(accordionState.outputName.get()){
                    //   if (globaloutputstate.length === 0) {
                    //     globaloutputstate.merge([
                    //       {
                    //         hookstatepath: accordionState.path,
                    //         output: accordionState.name.get(),
                    //       },
                    //     ]);
                    //   }
                    //   else if (
                    //     !globaloutputstate.some(
                    //       (element) =>
                    //         JSON.stringify(element.hookstatepath.get()) ===
                    //         JSON.stringify(accordionState.path)
                    //     )
                    //   ) {
                    //     globaloutputstate.merge([
                    //       {
                    //         hookstatepath: accordionState.path,
                    //         output: accordionState.name.get(),
                    //       },
                    //     ]);
                    //   }
                    // } else if(!accordionState.outputName.get()){
                    //   globaloutputstate.map((globaloutputelement) => {
                    //     if(JSON.stringify(globaloutputelement.hookstatepath.get() === JSON.stringify(accordionState.path))){
                    //       globaloutputelement.set(none)
                    //     }
                    //   })
                    // }

                   

                    // console.log(globaloutputstate)

                  }}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button>
                  </InputGroup.Append>
                  </InputGroup>
                </Form>
      </div>
    </div>
  );
}
