import React, { useState, useRef, useEffect } from "react";
import { useState as useStateHook, none, Downgraded } from "@hookstate/core";
import {
  Card,
  Button,
  Form,
  Alert,
  OverlayTrigger,
  Popover,
  ButtonGroup,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Table,
} from "react-bootstrap";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faMinus,
  faCog,
  faUpload,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import { storage } from "../firebase";
import { Document, Page, pdfjs } from "react-pdf";
import MultiSelectSort from "./testSortable";
import { FindingModule } from "./DecisionTreeRender";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import { useGlobalState } from "./GlobalState.tsx";

export const DecisionTree = (props) => {
  // const globalstate = useGlobalState();

  // console.log(globalstate);
  //Ich brauch wohl useState um den State zu mutieren, das funktioniert aber wohl nicht. Mit dem Beispiel vergleichen!!

  const number = props.number;

  const state = useStateHook(props.componentState);
  console.log(state);

  return (
    <div className="w-100 text-center mt-2">
      <NodeListEditorTest nodes={state} number={number} />
    </div>
  );
};

function NodeNameEditor(props) {
  // scoped state is optional for performance
  // could have used props.nameState everywhere instead

  console.log(props.nameState);
  const state = useStateHook(props.nameState);
  const [multiselection, SetMultiselection] = useState(false);
  const [orientation, SetOrientation] = useState(false);
  console.log(state);

  return (
    <div className="mt-2 mb-2">
      <Button className="m-1" onClick={() => state.set(none)}>
        löschen
      </Button>

      {/* {state.imagePath.get() !== undefined && state.imagePath.get() !== "" ? (
        <Button value={state.imagePath.get()}>Help</Button>
      ) : (
        ""
      )} */}

      <Form>
        <InputGroup>
          <Form.Control
            onChange={(e) => state.input.set(e.target.value)}
            type="input"
            placeholder="Frage/Bezeichnung"
            defaultValue={state.input.get()}
            // style={{
            //   display: !state.endpoint.get() ? "inline-block" : "none",
            // }}
          />

          {/* <Form.Control
            style={{
              display: state.endpoint.get() ? "inline-block" : "none",
            }}
            placeholder="Output"
            value={state.outputvalue.get()}
            onChange={(e) => state.outputvalue.set(e.target.value)}
          /> */}

          <InputGroup.Append>
            {/* <Button
              variant={state.endpoint.get() ? "outline-primary" : "primary"}
              style={{ display: state.path.length > 3 ? "" : "none" }}
              onClick={() => state.endpoint.set(!state.endpoint.get())}
            >
              Endpunkt
            </Button> */}

            {/* <Button
              variant={multiselection ? "outline-primary" : "primary"}
              style={{ display: state.endpoint.get() ? "none" : "" }}
              onClick={() => {
                let check = !multiselection;
                SetMultiselection(!multiselection);
                console.log(check, multiselection);
                if (check) {
                  if (
                    state.buttons.get() === undefined ||
                    state.buttons.get() === []
                  ) {
                    state.merge({ buttons: [] });
                  }
                } else if (
                  (!check && state.buttons.get() !== undefined) ||
                  (!check && state.buttons.get() === [])
                ) {
                  state.buttons.set(none);
                }
              }}
            >
              + Button
            </Button> */}
            <InputGroup.Text>Output?</InputGroup.Text>
            <Button
              variant={state.outputInput.get() ? "dark" : "primary"}
              onClick={() => {
                if (state.outputInput.get() === undefined) {
                  state.outputInput.set(true);
                } else if (state.outputInput.get() !== undefined) {
                  state.outputInput.set(!state.outputInput.get());
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} size="xs"></FontAwesomeIcon>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
      {/* <input
          defaultValue={state.input.get()}
          placeholder=""
          onChange={(e) => state.input.set(e.target.value)}
        />

        <input
          style={{
            display:
              state.endpoint.get() || state.output.get()
                ? "inline-block"
                : "none",
          }}
          placeholder="Output"
          value={state.outputvalue.get()}
          onChange={(e) => state.outputvalue.set(e.target.value)}
        /> */}

      {/* <Button
        variant={state.output.get() ? "outline-primary" : "primary"}
        className="mr-2"
        // className={state.output.get() ? "btn btn-outline-primary" : "btn-primary"}
        //   style={{ backgroundColor: state.output.get() ? "lightblue" : "" }}
        onClick={() => state.output.set(!state.output.get())}
      >
        Textausgabe
      </Button> */}
    </div>
  );
}

// function NodeListEditor(props) {
//   // scoped state is optional for performance
//   // could have used props.nodes everywhere instead

//   const state = useStateHook(props.nodes);

//   return (
//     <Card>
//       <Card.Body>
//         <div style={{ paddingLeft: 20, borderLeft: "solid" }}>
//           {state.ornull &&
//             state.ornull.map((nodeState, i) => {
//               if (
//                 nodeState.number.get() === props.number &&
//                 !nodeState.endpoint.get()
//               ) {
//                 return (
//                   <div key={i}>
//                     <NodeNameEditor nameState={nodeState} />

//                     {nodeState.buttons.get() !== undefined ? (
//                       <MultiselectionModule state={nodeState} />
//                     ) : (
//                       ""
//                     )}

//                     <NodeListEditor nodes={nodeState.children} />
//                   </div>
//                 );
//               } else if (
//                 nodeState.number.get() === props.number &&
//                 nodeState.endpoint.get()
//               ) {
//                 return (
//                   <div key={i}>
//                     <NodeNameEditor nameState={nodeState} />
//                     <NodeListEditorEndpoint nodes={nodeState.children} />
//                   </div>
//                 );
//               }
//             })}
//           <div className="mt-2">
//             <Button
//               style={{
//                 display: state.path.length > 2 ? "inline-block" : "none",
//               }}
//               onClick={() =>
//                 state.set((nodes) =>
//                   (nodes || []).concat([
//                     {
//                       id: "DecisionTree",
//                       input: "",
//                       outputvalue: "",
//                       pdfPath: "",
//                       imagePath: "",
//                       endpoint: false,
//                       output: false,
//                       children: [],
//                     },
//                   ])
//                 )
//               }
//             >
//               Add Node
//             </Button>
//           </div>
//         </div>
//       </Card.Body>
//     </Card>
//   );
// }

// function NodeListEditorEndpoint(props) {
//   // scoped state is optional for performance
//   // could have used props.nodes everywhere instead

//   const state = useStateHook(props.nodes);

//   return (
//     <Card>
//       <Card.Body>
//         <div style={{ paddingLeft: 20, borderLeft: "solid", marginTop: 10 }}>
//           {state.ornull &&
//             state.ornull.map((nodeState, i) => {
//               console.log(state.path);
//               return (
//                 <div key={i}>
//                   <NodeNameEditor nameState={nodeState} />
//                   <NodeListEditor nodes={nodeState.children} />
//                 </div>
//               );
//             })}
//         </div>
//       </Card.Body>
//     </Card>
//   );
// }

function NodeListEditorTest(props) {
  // scoped state is optional for performance
  // could have used props.nodes everywhere instead

  const state = useStateHook(props.nodes);
  console.log(props.nodes);
  const { discriptorData } = useData();
  const [findingdiscriptorname, setFindingDiscriptorName] = useState();
  const [findingdiscriptoranchor, setFindingDiscriptorAnchor] = useState();
  const [findingdiscriptordata, setFindingDiscriptorData] = useState();
  const [discriptorname, setDiscriptorName] = useState();

  return (
    <Card
    //style={{ display: props.nodes.get().length > 0 ? "" : "none" }}
    >
      <Card.Body>
        <div
          className="d-flex justify-content-spacearound w-100"
          style={{ paddingLeft: 20, flexWrap: "wrap", borderColor: "red" }}
        >
          {/* {state.id.get() === "Findings"
            ? state.findings.map((finding) => {
                return <FindingModule finding={finding} />;
              })
            : ""} */}

          {/* {state.id.get() === "Findings" ? (
            <FindingModule finding={state.findings} />
          ) : (
            ""
          )} */}

          {state.id.get() === "DecisionTree" ? (
            <div className="w-100">
              <NodeNameEditor nameState={state} />
              {state.buttons.get() !== undefined ? (
                <MultiselectionModule state={state} />
              ) : (
                ""
              )}
              {state.children.get() !== undefined
                ? state.children.map((child) => {
                    if (child.id.get() === "Findings") {
                      return <FindingModule findings={child} />;
                    }
                  })
                : ""}
              {state.buttons.map((button, i) => {
                console.log(button);
                if (
                  button.children.get() !== undefined &&
                  button.children.length > 0
                ) {
                  let nodes = [];
                  let findings = [];
                  if (button.children.length > 0) {
                    button.children.map((child) => {
                      if (child.id.get() === "DecisionTree") {
                        console.log(child);
                        nodes = nodes.concat(
                          <div
                            className="w-100"
                            style={{
                              display: button["button" + i].get() ? "" : "none",
                            }}
                          >
                            <NodeListEditorTest nodes={child} />
                          </div>
                        );
                      } else if (child.id.get() === "Findings") {
                        findings = findings.concat(
                          <FindingModule findings={child} />
                        );

                        // child.findings.map((finding) => {
                        //   findings = findings.concat(
                        //     <div className="mr-1 mb-1">
                        //       <FindingModule finding={finding} />
                        //     </div>
                        //   );
                        // });
                      }
                    });
                  }
                  let output = [];
                  output.push(
                    <div
                      className="m-1"
                      style={{
                        flexWrap: "wrap",
                        display: button["button" + i].get() ? "flex" : "none",
                      }}
                    >
                      {findings}
                    </div>
                  );
                  output.push(nodes);
                  return output;
                }
              })}
              {/* {state.children.map((child) => {
                console.log(child)
                return <NodeListEditorTest nodes={child} />
              })}
              {state.children.length > 0 ? (
                <NodeListEditorTest nodes={state.children} />
              ) : (
                ""
              )} */}
            </div>
          ) : (
            ""
          )}

          {/* {state.buttons.get() !== undefined &&
          state.buttons.get().length > 0
            ? state.buttons.map((element, i) => {
              <MultiselectionModule state={element} />

            }):""} */}

          {/* {state.buttons.get() !== undefined && state.buttons.get().length > 0
            ? state.buttons.map((element, i) => {
                if (
                  element.children.get() !== undefined &&
                  element.children.get().length > 0
                ) {
                  <div key={i} className="w-100">
                    <NodeNameEditor nameState={state} />
                    {state.buttons.get() !== undefined ? (
                      <MultiselectionModule state={state} />
                    ) : (
                      ""
                    )}
                    <div
                      className="w-100"
                      style={{
                        display: element["button" + i].get() ? "" : "none",
                      }}
                    >
                      <NodeListEditorTest nodes={element.children} />
                    </div>
                  </div>;
                }
              })
            : ""} */}
          {/* 
              if (
                nodeState.buttons.get() !== undefined &&
                nodeState.buttons.get().length > 0
              ) {
                console.log("condition 1")
                let output = [];
                nodeState.buttons.map((element, i) => {
                  if (
                    element.children.get() !== undefined &&
                    element.children.get().length > 0
                  ) {
                    output = output.concat(
                      <div
                        className="w-100"
                        style={{
                          display: element["button" + i].get() ? "" : "none",
                        }}
                      >
                        <NodeListEditorTest nodes={element.children} />
                      </div>
                    );

                    return output;
                  }
                });
                if (output.length > 0) {
                  console.log(output);
                  return (
                    <div key={i} className="w-100">
                      <NodeNameEditor nameState={nodeState} />
                      {nodeState.buttons.get() !== undefined ? (
                        <MultiselectionModule state={nodeState} />
                      ) : (
                        ""
                      )}
                      {output}
                    </div>
                  );
                }
              }

              if (nodeState.findings.get() !== undefined) {
                console.log("condition 2")
                let output = [];
                console.log(nodeState.findings.get());
                nodeState.findings.map((finding) => {
                  return (output = output.concat(
                    <div className="m-2">
                      <FindingModule finding={finding} />
                    </div>
                  ));
                });
                return output;
              } else {
                <p>Fehler</p>;
              } */}

          {/* {state.ornull &&
            state.ornull.map((nodeState, i) => {
              console.log(nodeState);


              if (
                nodeState.number.get() === props.number &&
                !nodeState.endpoint.get() &&
                nodeState.id.get() === "DecisionTree"
              
              ) {
                console.log("condition 3")
                return (
                  <div key={i} className="w-100">
                    <NodeNameEditor nameState={nodeState} />
                    {nodeState.buttons.get() !== undefined ? (
                      <MultiselectionModule state={nodeState} />
                    ) : (
                      ""
                    )}
                    <NodeListEditorTest nodes={nodeState.children} />
                  </div>
                );
              }

              if (
                nodeState.buttons.get() !== undefined &&
                nodeState.buttons.get().length > 0
              ) {
                console.log("condition 1")
                let output = [];
                nodeState.buttons.map((element, i) => {
                  if (
                    element.children.get() !== undefined &&
                    element.children.get().length > 0
                  ) {
                    output = output.concat(
                      <div
                        className="w-100"
                        style={{
                          display: element["button" + i].get() ? "" : "none",
                        }}
                      >
                        <NodeListEditorTest nodes={element.children} />
                      </div>
                    );

                    return output;
                  }
                });
                if (output.length > 0) {
                  console.log(output);
                  return (
                    <div key={i} className="w-100">
                      <NodeNameEditor nameState={nodeState} />
                      {nodeState.buttons.get() !== undefined ? (
                        <MultiselectionModule state={nodeState} />
                      ) : (
                        ""
                      )}
                      {output}
                    </div>
                  );
                }
              }

              if (nodeState.findings.get() !== undefined) {
                console.log("condition 2")
                let output = [];
                console.log(nodeState.findings.get());
                nodeState.findings.map((finding) => {
                  return (output = output.concat(
                    <div className="m-2">
                      <FindingModule finding={finding} />
                    </div>
                  ));
                });
                return output;
              } else {
                <p>Fehler</p>;
              }

            })} */}
        </div>
      </Card.Body>
    </Card>
  );
}

// function NodeListEditorTest(props) {
//   // scoped state is optional for performance
//   // could have used props.nodes everywhere instead

//   const state = useStateHook(props.nodes);

//   return (
//     <Card>
//       <Card.Body>
//         <div style={{ paddingLeft: 20, borderLeft: "solid", marginTop: 10 }}>
//           {state.ornull &&
//             state.ornull.map((nodeState, i) => {
//               console.log(nodeState.buttons.get())
//               if (nodeState.buttons.get() !== undefined) {
//                 nodeState.buttons.map((element, i) => {
//                   if (element.children !== undefined) {
//                     return (
//                       <div key={i}>
//                         <NodeNameEditor nameState={nodeState} />
//                         <NodeListEditorTest nodes={element.children} />
//                       </div>
//                     );
//                   }
//                 });
//               }
//               console.log(state.path);

//               return (
//                 <div key={i}>
//                   <NodeNameEditor nameState={nodeState} />
//                   <NodeListEditorTest nodes={nodeState.children} />
//                 </div>
//               );
//             })}
//         </div>
//       </Card.Body>
//     </Card>
//   );
// }

// function FindingModule(props) {
//   const { discriptorData } = useData();
//   const [findingdiscriptorname, setFindingDiscriptorName] = useState();
//   const [findingdiscriptoranchor, setFindingDiscriptorAnchor] = useState();
//   const [findingdiscriptordata, setFindingDiscriptorData] = useState();
//   const [discriptorname, setDiscriptorName] = useState();

//   const finding = useStateHook(props.finding);

//   return (
//     <OverlayTrigger
//       trigger="click"
//       placement="auto"
//       rootClose={true}
//       overlay={
//         <Popover style={{ minWidth: "500px", minHeight: "400px" }}>
//           <Popover.Content>
//             <Form>
//               <Form.Control
//                 placeholder="Bezeichnung"
//                 defaultValue={finding.name.get()}
//                 onChange={(e) => {
//                   finding.name.set(e.target.value);
//                 }}
//               ></Form.Control>
//               <br />
//               <Form.Control
//                 placeholder="Output Syntax"
//                 defaultValue={
//                   finding.outputsyntax.get() !== undefined
//                     ? finding.outputsyntax.get()
//                     : ""
//                 }
//                 onChange={(e) =>
//                   finding.merge({
//                     outputsyntax: e.target.value,
//                   })
//                 }
//               ></Form.Control>
//               <br />
//               <InputGroup>
//                 <Form.Control
//                   placeholder="output value"
//                   defaultValue={finding.finding
//                     .map((element) => {
//                       if (element.name.get() === "output") {
//                         return element.output.get();
//                       } else console.log(element);
//                     })
//                     .join("")}
//                   onChange={(e) => {
//                     finding.finding.map((element) => {
//                       if (element.name.get() === "output") {
//                         element.output.set(e.target.value);
//                       }
//                     });
//                   }}
//                 ></Form.Control>
//                 <InputGroup.Append>
//                   <OverlayTrigger
//                     overlay={
//                       <Popover
//                         rootClose="true"
//                         id="popover-contained"
//                         onClick={(e) => e.stopPropagation}
//                       >
//                         <Popover.Content>mark as relevant</Popover.Content>
//                       </Popover>
//                     }
//                   >
//                     <InputGroup.Checkbox
//                       aria-label="Checkbox for following text input"
//                       onClick={(e) => {
//                         finding.finding.map((element) => {
//                           if (element.name.get() === "output") {
//                             element.conclusionoutputrelevant.set(
//                               e.target.checked
//                             );
//                           }
//                         });
//                       }}
//                     />
//                   </OverlayTrigger>
//                 </InputGroup.Append>
//               </InputGroup>
//               <br />
//               <Form.Control
//                 placeholder="Output für Beurteilung"
//                 defaultValue={finding.finding
//                   .map((element) => {
//                     if (element.name.get() === "output") {
//                       return element.outputconclusion.get();
//                     } else console.log(element);
//                   })
//                   .join("")}
//                 onChange={(e) => {
//                   finding.finding.map((element) => {
//                     if (element.name.get() === "output") {
//                       element.outputconclusion.set(e.target.value);
//                     }
//                   });
//                 }}
//               ></Form.Control>
//               <br />
//               <InputGroup>
//                 <Form.Control
//                   onChange={(e) => {
//                     setFindingDiscriptorName({
//                       discriptorname: e.target.value,
//                     });
//                     setDiscriptorName(e.target.value);
//                   }}
//                   as="select"
//                   placeholder="Template/Modulbezeichnung"
//                 >
//                   <option>auswählen...</option>
//                   {discriptorData
//                     ? discriptorData.map((element) => {
//                         return (
//                           <option value={element.name}>{element.name}</option>
//                         );
//                       })
//                     : ""}
//                 </Form.Control>

//                 <Form.Control
//                   placeholder="discriptor anchor"
//                   onChange={(e) => {
//                     setFindingDiscriptorAnchor({
//                       discriptoranchor: e.target.value,
//                     });
//                   }}
//                 ></Form.Control>
//                 <InputGroup.Append>
//                   <Button
//                     onClick={() => {
//                       if (
//                         findingdiscriptoranchor !== undefined &&
//                         findingdiscriptorname !== undefined
//                       ) {
//                         discriptorData.map((discriptor) => {
//                           if (discriptor.name === discriptorname) {
//                             finding.finding.merge([
//                               Object.assign(
//                                 findingdiscriptorname,
//                                 findingdiscriptoranchor,
//                                 { outputs: discriptor.data }
//                               ),
//                             ]);
//                           }
//                         });
//                       }
//                     }}
//                   >
//                     +
//                   </Button>
//                 </InputGroup.Append>
//               </InputGroup>
//               <br />
//               {finding.finding.get() !== undefined ? (
//                 <div style={{ maxHeight: "200px", overflow: "auto" }}>
//                   <Card>
//                     <Card.Body>
//                       {finding.finding.map((element) => {
//                         if (element.discriptorname.get() !== undefined) {
//                           return (
//                             <>
//                               <InputGroup>
//                                 <Form.Control
//                                   onChange={(e) => {
//                                     element.discriptorname.set(e.target.value);
//                                     discriptorData.map((discriptor) => {
//                                       if (discriptor.name === e.target.value) {
//                                         element.outputs.set(discriptor.data);
//                                       }
//                                     });
//                                   }}
//                                   defaultValue={element.discriptorname.get()}
//                                   as="select"
//                                   placeholder="Template/Modulbezeichnung"
//                                 >
//                                   <option>auswählen...</option>
//                                   {discriptorData
//                                     ? discriptorData.map((element) => {
//                                         return (
//                                           <option value={element.name}>
//                                             {element.name}
//                                           </option>
//                                         );
//                                       })
//                                     : ""}
//                                 </Form.Control>
//                                 <br />
//                                 <Form.Control
//                                   defaultValue={element.discriptoranchor.get()}
//                                   onChange={(e) =>
//                                     element.discriptoranchor.set(e.target.value)
//                                   }
//                                 ></Form.Control>
//                                 <InputGroup.Append>
//                                   <Button onClick={() => element.set(none)}>
//                                     -
//                                   </Button>
//                                 </InputGroup.Append>
//                               </InputGroup>
//                               <br />
//                             </>
//                           );
//                         }
//                       })}
//                     </Card.Body>
//                   </Card>
//                 </div>
//               ) : (
//                 ""
//               )}
//             </Form>
//           </Popover.Content>
//         </Popover>
//       }
//     >
//       <Button>{finding.name.get()}</Button>
//     </OverlayTrigger>
//   );
// }

// function FindingModule(props) {
//   const { discriptorData } = useData();
//   const [findingdiscriptorname, setFindingDiscriptorName] = useState();
//   const [findingdiscriptoranchor, setFindingDiscriptorAnchor] = useState();
//   const [findingdiscriptordata, setFindingDiscriptorData] = useState();
//   const [discriptorname, setDiscriptorName] = useState();
//   const [selectedFinding, setSelectedFinding] = useState();
//   const [newOutput, setNewOutput] = useState();

//   const [
//     selectedDiscriptorModality,
//     SetSelectedDiscriptorModality,
//   ] = useState();
//   const [selectedDiscriptorRegion, SetSelectedDiscriptorRegion] = useState();
//   const [usefilter, setUseFilter] = useState(false);
//   const [error, setError] = useState(false);
//   const [modalShow, setModalShow] = useState(false);
//   const [selectionError, setSelectionError] = useState(false);

//   const findings = useStateHook(props.findings);
//   findings.attach(Downgraded);
//   console.log(findings);
//   console.log(discriptorData);

//   const discriptorDataCopy = [].concat(discriptorData);

//   return (
//     <div
//       className="d-flex justify-content-spacearound w-100"
//       style={{ flexWrap: "wrap" }}
//     >
//       {findings.findings.get() !== undefined
//         ? findings.findings.map((finding, i) => {
//             //console.log(finding);

//             return (
//               <>
//                 <div style={{ position: "relative", width: "max-content" }}>
//                   <Button
//                     variant="danger"
//                     style={{
//                       width: "12px",
//                       height: "12px",
//                       padding: "0",
//                       borderRadius: "100%",
//                       position: "absolute",
//                       left: "2px",
//                       top: "2px",
//                       border: "none",
//                     }}
//                     size="sm"
//                     onClick={() => {
//                       if (findings.findings.get().length > 1) {
//                         finding.set(none);
//                       } else if (findings.findings.get().length === 1) {
//                         findings.set(none);
//                       }
//                     }}
//                   >
//                     <FontAwesomeIcon
//                       style={{
//                         fontSize: "0.5rem",
//                         width: "10px",
//                         height: "10px",
//                         padding: "0",
//                         borderRadius: "100%",
//                         position: "absolute",
//                         left: "1px",
//                         bottom: "1px",
//                         border: "none",
//                         color: "white",
//                         pointerEvents: "none",
//                       }}
//                       icon={faMinus}
//                     ></FontAwesomeIcon>
//                   </Button>
//                   {finding.helpmodule.get() !== undefined ? (
//                     <>
//                       <MyVerticallyCenteredModal
//                         show={finding.showModal.get()}
//                         onHide={() => finding.showModal.set(false)}
//                         element={finding}
//                       />{" "}
//                       <Button
//                         onClick={() => {
//                           if (finding.showModal.get() === undefined) {
//                             finding.showModal.set(true);
//                           } else if (
//                             finding.showModal.get() === true ||
//                             finding.showModal.get() === false
//                           ) {
//                             finding.showModal.set(!finding.showModal.get());
//                           }
//                         }}
//                         variant="dark"
//                         style={{
//                           fontSize: "0.7rem",
//                           width: "13px",
//                           height: "13px",
//                           padding: "0",
//                           borderRadius: "100%",
//                           position: "absolute",
//                           right: "4px",
//                           top: "2px",
//                           border: "none",
//                           backgroundColor: "lightgray",
//                           color: "#545b62",
//                         }}
//                         size="sm"
//                       >
//                         <FontAwesomeIcon
//                           icon={faQuestion}
//                           style={{
//                             fontSize: "0.5rem",
//                             width: "9px",
//                             height: "9px",
//                             padding: "0",
//                             borderRadius: "100%",
//                             position: "absolute",
//                             left: "2px",
//                             bottom: "2px",
//                             border: "none",
//                             color: "#545b62",
//                             pointerEvents: "none",
//                           }}
//                         ></FontAwesomeIcon>
//                       </Button>
//                     </>
//                   ) : (
//                     ""
//                   )}
//                   {/* <OverlayTrigger
//                     trigger="click"
//                     placement="auto"
//                     rootClose={true}
//                     overlay={
//                       <Popover
//                         style={{ minWidth: "500px", minHeight: "500px" }}
//                       >
//                         <Popover.Content>
//                           <Form>
//                             <Form.Control
//                               placeholder="Output Syntax"
//                               defaultValue={
//                                 finding.outputsyntax.get() !== undefined
//                                   ? finding.outputsyntax.get()
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 finding.merge({
//                                   outputsyntax: e.target.value,
//                                 })
//                               }
//                             ></Form.Control>
//                             <br />
//                             <Form.Control
//                               placeholder="output value"
//                               defaultValue={finding.finding
//                                 .map((element) => {
//                                   if (element.name.get() === "output") {
//                                     return element.output.get();
//                                   }
//                                 })
//                                 .join("")}
//                               onChange={(e) => {
//                                 finding.finding.map((element) => {
//                                   if (element.name.get() === "output") {
//                                     element.output.set(e.target.value);
//                                   }
//                                 });
//                               }}
//                             ></Form.Control>
//                             <br />
//                             <Button

//                             variant={finding.showAlways.get() ? "dark" : "primary"}
//                             onClick={() => {
//                               if(finding.showAlways.get() === undefined){
//                                 finding.showAlways.set(true)
//                               }
//                               else if(finding.showAlways.get() !== undefined){
//                                 finding.showAlways.set(!finding.showAlways.get())
//                               }
//                               }}>direkt anzeigen</Button>
//                             <br/>
//                             <br/>
//                             <InputGroup>
//                               <Form.Control
//                                 onChange={(e) => {
//                                   setFindingDiscriptorName({
//                                     discriptorname: e.target.value,
//                                   });
//                                   setDiscriptorName(e.target.value);
//                                 }}
//                                 as="select"
//                                 placeholder="Template/Modulbezeichnung"
//                               >
//                                 <option>auswählen...</option>
//                                 {discriptorData
//                                   ? discriptorData.map((element) => {
//                                       return (
//                                         <option value={element.name}>
//                                           {element.name}
//                                         </option>
//                                       );
//                                     })
//                                   : ""}
//                               </Form.Control>

//                               <Form.Control
//                                 placeholder="discriptor anchor"
//                                 onChange={(e) => {
//                                   setFindingDiscriptorAnchor({
//                                     discriptoranchor: e.target.value,
//                                   });
//                                 }}
//                               ></Form.Control>
//                               <InputGroup.Append>
//                                 <Button
//                                   onClick={() => {
//                                     if (
//                                       findingdiscriptoranchor !== undefined &&
//                                       findingdiscriptorname !== undefined
//                                     ) {
//                                       discriptorData.map((discriptor) => {

//                                         if (
//                                           discriptor.name === discriptorname
//                                         ) {
//                                           console.log(discriptor.data)
//                                           finding.finding.merge([
//                                             Object.assign(
//                                               findingdiscriptorname,
//                                               findingdiscriptoranchor,
//                                               { outputs: JSON.parse(
//                                                 JSON.stringify(discriptor.data)
//                                               ) }
//                                             ),
//                                           ]);
//                                         }
//                                       });
//                                     }
//                                   }}
//                                 >
//                                   +
//                                 </Button>
//                               </InputGroup.Append>
//                             </InputGroup>
//                             <br />
//                             {finding.finding.get() !== undefined ? (
//                               <Card>
//                                 <Card.Body>
//                                   {finding.finding.map((element) => {
//                                     if (
//                                       element.discriptorname.get() !== undefined
//                                     ) {
//                                       return (
//                                         <>
//                                           <InputGroup>
//                                             <Form.Control
//                                               onChange={(e) => {
//                                                 element.discriptorname.set(
//                                                   e.target.value
//                                                 );
//                                                 discriptorData.map((discriptor) => {
//                                                   if (
//                                                     discriptor.name === e.target.value
//                                                   ) {
//                                                     element.outputs.set([].concat(JSON.parse(
//                                                       JSON.stringify(discriptor.data)
//                                                     )))
//                                                   }
//                                                 });
//                                                 //   discriptorData.map(
//                                                 //     (discriptor) => {
//                                                 //       if (
//                                                 //         discriptor.name ===
//                                                 //         e.target.value
//                                                 //       ) {
//                                                 //         element.outputs.set(
//                                                 //           discriptor.data
//                                                 //         );
//                                                 //       }
//                                                 //     }
//                                                 //   );
//                                                 // }}
//                                               }}
//                                               defaultValue={element.discriptorname.get()}
//                                               as="select"
//                                               placeholder="Template/Modulbezeichnung"
//                                             >
//                                               <option>auswählen...</option>
//                                               {discriptorData
//                                                 ? discriptorData.map(
//                                                     (element) => {
//                                                       return (
//                                                         <option
//                                                           value={element.name}
//                                                         >
//                                                           {element.name}
//                                                         </option>
//                                                       );
//                                                     }
//                                                   )
//                                                 : ""}
//                                             </Form.Control>
//                                             <br />
//                                             <Form.Control
//                                               defaultValue={element.discriptoranchor.get()}
//                                               onChange={(e) =>
//                                                 element.discriptoranchor.set(
//                                                   e.target.value
//                                                 )
//                                               }
//                                             ></Form.Control>
//                                             <InputGroup.Append>
//                                               <Button
//                                                 onClick={() =>
//                                                   element.set(none)
//                                                 }
//                                               >
//                                                 -
//                                               </Button>
//                                             </InputGroup.Append>
//                                           </InputGroup>
//                                           <br />
//                                         </>
//                                       );
//                                     }
//                                   })}
//                                 </Card.Body>
//                               </Card>
//                             ) : (
//                               ""
//                             )}
//                           </Form>
//                         </Popover.Content>
//                       </Popover>
//                     }
//                   > */}
//                   <OverlayTrigger
//                     trigger="click"
//                     placement="auto"
//                     rootClose={modalShow ? false : true}
//                     overlay={
//                       <Popover
//                         style={{ minWidth: "500px", minHeight: "500px" }}
//                         onClick={(e) => e.stopPropagation}
//                       >
//                         <Popover.Content>
//                           <Form>
//                             <MultiSelectSort
//                               finding={finding.selectedoutputArray}
//                               selectedoutputArray={
//                                 finding.selectedoutputArray.get() !== undefined
//                                   ? finding.selectedoutputArray.get()
//                                   : []
//                               }
//                               outputArray={
//                                 finding.outputsyntaxArray.get() !== undefined
//                                   ? finding.outputsyntaxArray.get()
//                                   : []
//                               }
//                             />
//                             <br />
//                             <InputGroup>
//                               <Form.Control
//                                 placeholder="neuer Text"
//                                 id="FormOutput"
//                                 onChange={(e) => {
//                                   if (
//                                     finding.outputsyntaxArray.get() ===
//                                     undefined
//                                   ) {
//                                     setNewOutput(
//                                       Object.assign({
//                                         value: e.target.value + "0",
//                                         label: e.target.value,
//                                         type: "output",
//                                       })
//                                     );
//                                   } else {
//                                     setNewOutput(
//                                       Object.assign({
//                                         value:
//                                           e.target.value +
//                                           finding.outputsyntaxArray.length,
//                                         label: e.target.value,
//                                         type: "output",
//                                       })
//                                     );
//                                   }
//                                 }}
//                               ></Form.Control>
//                               <InputGroup.Append>
//                                 <Button
//                                   onClick={() => {
//                                     console.log(newOutput);
//                                     if (newOutput !== undefined) {
//                                       if (
//                                         finding.outputsyntaxArray.get() ===
//                                         undefined
//                                       ) {
//                                         finding.outputsyntaxArray.merge([
//                                           newOutput,
//                                         ]);
//                                       } else if (
//                                         finding.outputsyntaxArray.some(
//                                           (element) =>
//                                             element.get() === newOutput
//                                         )
//                                       ) {
//                                         if (
//                                           window.confirm(
//                                             "Wollen Sie diesen Text wirklich mehrfach verwenden?"
//                                           )
//                                         ) {
//                                           finding.outputsyntaxArray.merge([
//                                             {
//                                               value:
//                                                 newOutput.value +
//                                                 finding.outputsyntaxArray
//                                                   .length,
//                                               label: newOutput.label,
//                                               type: "output",
//                                             },
//                                           ]);
//                                         }
//                                       } else {
//                                         finding.outputsyntaxArray.merge([
//                                           newOutput,
//                                         ]);
//                                       }

//                                       setNewOutput();
//                                       document.getElementById(
//                                         "FormOutput"
//                                       ).value = "";
//                                     } else {
//                                       setError(true);
//                                       setTimeout(() => setError(false), 3000);
//                                     }
//                                   }}
//                                 >
//                                   +
//                                 </Button>
//                               </InputGroup.Append>
//                             </InputGroup>
//                             <br />
//                             {error ? (
//                               <Alert variant="danger">
//                                 Bitte Text eingeben
//                               </Alert>
//                             ) : (
//                               ""
//                             )}

//                             <Form.Row>
//                               <Form.Group as={Col} xs={0}>
//                                 <InputGroup>
//                                   <Form.Control
//                                     id="DiscriptorSelection"
//                                     onChange={(e) => {
//                                       setFindingDiscriptorName({
//                                         discriptorname: e.target.value,
//                                       });
//                                       setDiscriptorName(e.target.value);
//                                       // setNewOutput(
//                                       //   Object.assign({
//                                       //     value: e.target.value,
//                                       //     label: e.target.value,
//                                       //     type: "finding",
//                                       //   })
//                                       // )
//                                     }}
//                                     as="select"
//                                     placeholder="Template/Modulbezeichnung"
//                                   >
//                                     <option>auswählen...</option>
//                                     {discriptorData
//                                       ? discriptorData.map((element) => {
//                                           let output = [];
//                                           if (usefilter) {
//                                             if (
//                                               selectedDiscriptorModality !==
//                                                 undefined &&
//                                               selectedDiscriptorModality !==
//                                                 "" &&
//                                               selectedDiscriptorRegion !==
//                                                 undefined &&
//                                               selectedDiscriptorRegion !== ""
//                                             ) {
//                                               if (
//                                                 element.modality ===
//                                                   selectedDiscriptorModality &&
//                                                 element.region ===
//                                                   selectedDiscriptorRegion
//                                               )
//                                                 output = output.concat(
//                                                   <option value={element.name}>
//                                                     {element.name}
//                                                   </option>
//                                                 );
//                                             } else if (
//                                               selectedDiscriptorModality !==
//                                                 undefined &&
//                                               selectedDiscriptorModality !==
//                                                 "" &&
//                                               (selectedDiscriptorRegion ===
//                                                 undefined ||
//                                                 selectedDiscriptorRegion === "")
//                                             ) {
//                                               if (
//                                                 element.modality ===
//                                                 selectedDiscriptorModality
//                                               ) {
//                                                 output = output.concat(
//                                                   <option value={element.name}>
//                                                     {element.name}
//                                                   </option>
//                                                 );
//                                               }
//                                             } else if (
//                                               (selectedDiscriptorModality ===
//                                                 undefined ||
//                                                 selectedDiscriptorModality ===
//                                                   "") &&
//                                               selectedDiscriptorRegion !==
//                                                 undefined &&
//                                               selectedDiscriptorRegion !== ""
//                                             ) {
//                                               if (
//                                                 element.region ===
//                                                 selectedDiscriptorRegion
//                                               ) {
//                                                 output = output.concat(
//                                                   <option value={element.name}>
//                                                     {element.name}
//                                                   </option>
//                                                 );
//                                               }
//                                             }
//                                           } else {
//                                             output = output.concat(
//                                               <option value={element.name}>
//                                                 {element.name}
//                                               </option>
//                                             );
//                                           }
//                                           return output;
//                                         })
//                                       : ""}
//                                   </Form.Control>
//                                   <InputGroup.Append>
//                                     <Button
//                                       onClick={() => {
//                                         if (
//                                           finding.outputsyntaxArray.get() ===
//                                           undefined
//                                         ) {
//                                           finding.outputsyntaxArray.set([]);
//                                         }
//                                         if (
//                                           findingdiscriptorname !== undefined
//                                         ) {
//                                           //finding.outputsyntaxArray.map(element => console.log(element.label.get() === newOutput.label))
//                                           //console.log(newOutput.label)
//                                           if (
//                                             finding.outputsyntaxArray.length >
//                                               0 &&
//                                             finding.outputsyntaxArray.some(
//                                               (element) =>
//                                                 element.label.get() ===
//                                                 discriptorname
//                                             )
//                                           ) {
//                                             if (
//                                               window.confirm(
//                                                 "Wollen Sie diesen Discriptor wirklich mehrfach verwenden?"
//                                               )
//                                             ) {
//                                               console.log("confirmed");
//                                               discriptorData.map(
//                                                 (discriptor) => {
//                                                   if (
//                                                     discriptor.name ===
//                                                     discriptorname
//                                                   ) {
//                                                     finding.finding.merge([
//                                                       Object.assign(
//                                                         findingdiscriptorname,
//                                                         findingdiscriptoranchor,
//                                                         {
//                                                           outputs: [].concat(
//                                                             JSON.parse(
//                                                               JSON.stringify(
//                                                                 discriptor.data
//                                                               )
//                                                             )
//                                                           ),
//                                                         }
//                                                       ),
//                                                     ]);

//                                                     finding.outputsyntaxArray.merge(
//                                                       [
//                                                         {
//                                                           value: discriptorname,
//                                                           label: discriptorname,
//                                                           type: "finding",
//                                                         },
//                                                       ]
//                                                     );
//                                                   }
//                                                 }
//                                               );
//                                             }
//                                           } else {
//                                             discriptorData.map((discriptor) => {
//                                               if (
//                                                 discriptor.name ===
//                                                 discriptorname
//                                               ) {
//                                                 finding.finding.merge([
//                                                   Object.assign(
//                                                     findingdiscriptorname,
//                                                     findingdiscriptoranchor,
//                                                     {
//                                                       outputs: [].concat(
//                                                         JSON.parse(
//                                                           JSON.stringify(
//                                                             discriptor.data
//                                                           )
//                                                         )
//                                                       ),
//                                                     }
//                                                   ),
//                                                 ]);

//                                                 if (
//                                                   finding.outputsyntaxArray.get() ===
//                                                   undefined
//                                                 ) {
//                                                   finding.outputsyntaxArray.merge(
//                                                     [
//                                                       {
//                                                         value:
//                                                           discriptorname + "0",
//                                                         label: discriptorname,
//                                                         type: "finding",
//                                                       },
//                                                     ]
//                                                   );
//                                                 } else {
//                                                   finding.outputsyntaxArray.merge(
//                                                     [
//                                                       {
//                                                         value:
//                                                           discriptorname +
//                                                           finding
//                                                             .outputsyntaxArray
//                                                             .length,
//                                                         label: discriptorname,
//                                                         type: "finding",
//                                                       },
//                                                     ]
//                                                   );
//                                                 }
//                                               }
//                                             });
//                                           }
//                                         } else {
//                                           setSelectionError(true);
//                                           setTimeout(
//                                             () => setSelectionError(false),
//                                             3000
//                                           );
//                                         }

//                                         document.getElementById(
//                                           "DiscriptorSelection"
//                                         ).selectedIndex = 0;
//                                         setDiscriptorName();
//                                         setFindingDiscriptorName();
//                                       }}
//                                     >
//                                       +
//                                     </Button>
//                                     <InputGroup.Text>Filter</InputGroup.Text>
//                                     <InputGroup.Checkbox
//                                       aria-label="Checkbox for following text input"
//                                       onClick={(e) => {
//                                         setUseFilter(!usefilter);
//                                         // if(!usefilter){
//                                         //   SetSelectedDiscriptorModality()
//                                         //   SetSelectedDiscriptorRegion()
//                                         // }
//                                       }}
//                                     />
//                                   </InputGroup.Append>
//                                 </InputGroup>
//                               </Form.Group>
//                             </Form.Row>
//                             <Form.Row>
//                               <Form.Group
//                                 as={Col}
//                                 style={{ display: usefilter ? "" : "none" }}
//                               >
//                                 <Form.Control
//                                   onChange={(e) =>
//                                     SetSelectedDiscriptorModality(
//                                       e.target.value
//                                     )
//                                   }
//                                   as="select"
//                                   placeholder="Modalität"
//                                 >
//                                   <option>Modalität auswählen...</option>
//                                   <option value="allgemein">allgemein</option>
//                                   <option value="CT">CT</option>
//                                   <option value="MRT">MRT</option>
//                                   <option value="Röntgen">Röntgen</option>
//                                   <option value="Sonographie">
//                                     Sonographie
//                                   </option>
//                                   <option value="PET-CT">PET-CT</option>
//                                   <option value="IR">IR</option>
//                                 </Form.Control>
//                               </Form.Group>
//                               <Form.Group
//                                 as={Col}
//                                 style={{ display: usefilter ? "" : "none" }}
//                               >
//                                 <Form.Control
//                                   onChange={(e) =>
//                                     SetSelectedDiscriptorRegion(e.target.value)
//                                   }
//                                   as="select"
//                                   placeholder="Region"
//                                 >
//                                   <option>Region auswählen...</option>
//                                   <option value="allgemein">allgemein</option>
//                                   <option value="Ganzkörper">Ganzkörper</option>
//                                   <option value="Kopf">Kopf</option>
//                                   <option value="Hals">Hals</option>
//                                   <option value="Kopf/Hals">Kopf/Hals</option>
//                                   <option value="Thorax">Thorax</option>
//                                   <option value="Abdomen">Abdomen</option>
//                                   <option value="Thorax/Abdomen">
//                                     Thorax/Abdomen
//                                   </option>
//                                   <option value="Becken">Becken</option>
//                                   <option value="Abdomen/Becken">
//                                     Abdomen/Becken
//                                   </option>
//                                   <option value="Obere Extremität">
//                                     Obere Extremität
//                                   </option>
//                                   <option value="Untere Extremität">
//                                     Untere Extremität
//                                   </option>
//                                 </Form.Control>
//                               </Form.Group>
//                             </Form.Row>

//                             <br />
//                             {selectionError ? (
//                               <Alert variant="danger">
//                                 Bitte Discriptor auswählen
//                               </Alert>
//                             ) : (
//                               ""
//                             )}

//                             <Table striped bordered hover size="sm">
//                               {/* <thead>
//                                   <tr>
//                                     <th>Nr.</th>
//                                     <th>Name</th>
//                                     <th></th>
//                                   </tr>
//                                 </thead> */}
//                               <tbody>
//                                 {finding.outputsyntaxArray.get() !==
//                                   undefined &&
//                                 finding.outputsyntaxArray.length > 0
//                                   ? finding.outputsyntaxArray.map(
//                                       (element, index) => {
//                                         return (
//                                           <tr style={{ height: "50px" }}>
//                                             <td style={{ width: "30px" }}>
//                                               {index + 1}
//                                             </td>
//                                             <td>
//                                               {element.type.get() ===
//                                               "finding" ? (
//                                                 element.label.get()
//                                               ) : (
//                                                 <Form.Control
//                                                   onChange={(e) => {
//                                                     element.label.set(
//                                                       e.target.value
//                                                     );
//                                                     element.merge({
//                                                       value:
//                                                         e.target.value + index,
//                                                     });
//                                                     if (
//                                                       finding.selectedoutputArray.get() !==
//                                                         undefined &&
//                                                       finding
//                                                         .selectedoutputArray
//                                                         .length > 0
//                                                     ) {
//                                                       finding.selectedoutputArray.map(
//                                                         (selectedelement) => {
//                                                           if (
//                                                             selectedelement.label.get() ===
//                                                             element.label.get()
//                                                           ) {
//                                                             selectedelement.label.set(
//                                                               e.target.value
//                                                             );

//                                                             selectedelement.merge(
//                                                               {
//                                                                 value:
//                                                                   e.target
//                                                                     .value +
//                                                                   index,
//                                                               }
//                                                             );
//                                                           }
//                                                         }
//                                                       );
//                                                     }
//                                                   }}
//                                                   defaultValue={element.label.get()}
//                                                 ></Form.Control>
//                                               )}
//                                             </td>
//                                             <td style={{ width: "30px" }}>
//                                               <Button
//                                                 onClick={() => {
//                                                   if (
//                                                     finding.selectedoutputArray.get() !==
//                                                       undefined &&
//                                                     finding.selectedoutputArray
//                                                       .length > 0
//                                                   ) {
//                                                     finding.selectedoutputArray.map(
//                                                       (selectedelement) => {
//                                                         if (
//                                                           selectedelement.label.get() ===
//                                                           element.label.get()
//                                                         ) {
//                                                           selectedelement.set(
//                                                             none
//                                                           );
//                                                         }
//                                                       }
//                                                     );
//                                                   }

//                                                   finding.finding.map(
//                                                     (finding) => {
//                                                       console.log(finding);
//                                                       if (
//                                                         finding.discriptorname.get() ===
//                                                         element.label.get()
//                                                       ) {
//                                                         finding.set(none);
//                                                       }
//                                                     }
//                                                   );

//                                                   element.set(none);
//                                                 }}
//                                               >
//                                                 -
//                                               </Button>
//                                             </td>
//                                           </tr>
//                                         );
//                                       }
//                                     )
//                                   : ""}
//                               </tbody>
//                             </Table>

//                             <br />
//                             <Button
//                               variant={
//                                 finding.showAlways.get() ? "dark" : "primary"
//                               }
//                               onClick={() => {
//                                 if (finding.showAlways.get() === undefined) {
//                                   finding.showAlways.set(true);
//                                 } else if (
//                                   finding.showAlways.get() !== undefined
//                                 ) {
//                                   finding.showAlways.set(
//                                     !finding.showAlways.get()
//                                   );
//                                 }
//                               }}
//                             >
//                               direkt anzeigen
//                             </Button>
//                             <br />
//                             <Button
//                               className="mt-2"
//                               onClick={() => setModalShow(true)}
//                             >
//                               + Hilfe
//                             </Button>
//                             {/* </OverlayTrigger> */}
//                             <FileUploadModal
//                               show={modalShow}
//                               element={finding}
//                               onHide={() => setModalShow(false)}
//                             ></FileUploadModal>
//                           </Form>
//                         </Popover.Content>
//                       </Popover>
//                     }
//                   >
//                     <Button
//                       style={{
//                         width: "14px",
//                         height: "14px",
//                         padding: "0",
//                         borderRadius: "100%",
//                         position: "absolute",
//                         left: "1px",
//                         bottom: "2px",
//                         border: "none",
//                         backgroundColor: "white",
//                       }}
//                       size="sm"
//                       active
//                     >
//                       <FontAwesomeIcon
//                         style={{
//                           fontSize: "0.7rem",
//                           width: "12px",
//                           height: "12px",
//                           padding: "0",
//                           borderRadius: "100%",
//                           position: "absolute",
//                           left: "1px",
//                           bottom: "1px",
//                           border: "none",
//                           backgroundColor: "white",
//                           color: "#545b62",
//                           pointerEvents: "none",
//                         }}
//                         icon={faCog}
//                       ></FontAwesomeIcon>
//                     </Button>
//                   </OverlayTrigger>
//                   <Button
//                     onClick={() => {
//                       if (finding.checked.get() === undefined) {
//                         finding.checked.set(true);
//                       } else {
//                         finding.checked.set(!finding.checked.get());
//                       }
//                     }}
//                     className="m-1 mr-2"
//                     variant={finding.checked.get() ? "dark" : "primary"}
//                   >
//                     {finding.name.get() + (i + 1)}
//                   </Button>
//                 </div>

//                 {/* {finding.checked.get()
//                   ? finding.finding.map((element, i2) => {
//                       //console.log(finding.get());
//                       //console.log(element.get());
//                       if (
//                         element.name.get() === undefined &&
//                         finding.number.get() === i
//                       ) {
//                         return (
//                           <DiscriptorComponent
//                             key={JSON.stringify(i) + JSON.stringify(i2)}
//                             data={element.outputs}
//                             number={finding.number.get()}
//                           />
//                         );
//                       }
//                     })
//                   : ""} */}
//               </>
//             );
//           })
//         : ""}
//     </div>
//   );
// }

function MultiselectionModule(props) {
  const state = useStateHook(props.state);
  console.log("MULTI: ", state);

  const [buttonname, SetButtonName] = useState();
  const [buttonvalue, SetButtonValue] = useState();
  const [error, SetError] = useState();
  const [orientation, SetOrientation] = useState();
  const [file, SetFile] = useState();
  const { currentUser } = useAuth();
  const { discriptorData, fileupload, userData } = useData();
  const fileref = useRef();
  const [modalShow, setModalShow] = React.useState(false);
  const [findingdiscriptorname, setFindingDiscriptorName] = useState();
  const [findingdiscriptoranchor, setFindingDiscriptorAnchor] = useState();
  const [findingdiscriptordata, setFindingDiscriptorData] = useState();
  const [discriptorname, setDiscriptorName] = useState();
  const [findingname, setFindingName] = useState();

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      <Form>
        <InputGroup>
          <Form.Control
            onChange={(e) => SetButtonName(e.target.value)}
            type="input"
            placeholder="Button Bezeichnung "
          />
          {/* <Form.Control
            type="input"
            placeholder="Button output"
            onChange={(e) => SetButtonValue(e.target.value)}
          /> */}
          <InputGroup.Append>
            <Button
              onClick={() => {
                console.log(buttonname);
                console.log(buttonvalue);
                if (
                  buttonname !== undefined &&
                  buttonname !== "" //&&
                  // buttonvalue !== undefined &&
                  // buttonvalue !== ""
                ) {
                  if (
                    state.buttons.some(
                      (element) => element.buttonname.get() === buttonname
                    )
                  ) {
                    SetError("Name bereits vergeben");
                    setTimeout(() => SetError(), 2000);
                  } else {
                    state.buttons.set((buttons) =>
                      (buttons || []).concat({
                        ["button" + state.buttons.get().length]: false,
                        buttonname: buttonname,
                        buttonvalue: "",
                        findings: [],
                      })
                    );
                  }
                } else {
                  SetError(
                    "Fehler beim erstellen des Buttons. Bitte Name und Output eingeben."
                  );
                  setTimeout(() => SetError(), 2000);
                }
              }}
            >
              add
            </Button>
            <Button
              onClick={(e) => {
                if (buttonname !== "" && buttonname !== undefined) {
                  state.children.merge([
                    {
                      id: "Findings",

                      findings: [
                        {
                          name: buttonname,
                          finding: [
                            {
                              name: "output",
                              output: "",
                            },
                          ],
                          number: 0,
                        },
                      ],
                      children: [],
                    },
                  ]);
                } else {
                  SetError("Bitte Finding bennen");
                  setTimeout(() => SetError(), 2000);
                }

                e.stopPropagation();
              }}
            >
              + Finding
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>

      <div className="m-2 d-flex justify-content-space-around">
        {state.buttons.map((element, i) => {
          return (
            <div style={{ width: "max-content", position: "relative" }}>
              {element.helpmodule.get() !== undefined ? (
                <>
                  <MyVerticallyCenteredModal
                    show={element.showModal.get()}
                    onHide={() => element.showModal.set(false)}
                    element={element}
                  />{" "}
                  <Button
                    onClick={() => {
                      if (element.showModal.get() === undefined) {
                        element.showModal.set(true);
                      } else if (
                        element.showModal.get() === true ||
                        element.showModal.get() === false
                      ) {
                        element.showModal.set(!element.showModal.get());
                      }
                    }}
                    variant="dark"
                    style={{
                      fontSize: "0.7rem",
                      width: "18px",
                      height: "18px",
                      padding: "0",
                      borderRadius: "100%",
                      position: "absolute",
                      right: "-5px",
                      top: "-5px",
                      border: "none",
                      backgroundColor: "lightgray",
                      color: "#545b62",
                    }}
                    value={element.imagePath.get()}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={faQuestion}
                    ></FontAwesomeIcon>
                  </Button>
                </>
              ) : (
                ""
              )}
              {/* {(element.imagePath.get() !== undefined &&
                element.imagePath.get() !== "") ||
              (element.pdfPath.get() !== undefined &&
                element.pdfPath.get() !== "") ? (
                <>
                  {element.helpmodule.get() !== undefined ? (
                    <MyVerticallyCenteredModal
                      show={element.showModal.get()}
                      onHide={() => element.showModal.set(false)}
                      element={element}
                    />
                  ) : (
                    ""
                  )}
                  {element.imagePath.get() !== undefined &&
                  element.imagePath.get() !== "" ? (
                    <MyVerticallyCenteredModal
                      imagePath={element.imagePath.get()}
                      pdfPath="none"
                      show={element.showModal.get()}
                      onHide={() => element.showModal.set(false)}
                      element={element}
                    />
                  ) : (
                    ""
                  )}

                  {element.pdfPath.get() !== undefined &&
                  element.pdfPath.get() !== "" ? (
                    <MyVerticallyCenteredModal
                      pdfPath={element.pdfPath.get()}
                      imagePath="none"
                      show={element.showModal.get()}
                      onHide={() => element.showModal.set(false)}
                      element={element}
                    />
                  ) : (
                    ""
                  )}

                  <Button
                    onClick={() => {
                      if (element.showModal.get() === undefined) {
                        element.showModal.set(true);
                      } else if (
                        element.showModal.get() === true ||
                        element.showModal.get() === false
                      ) {
                        element.showModal.set(!element.showModal.get());
                      }
                    }}
                    variant="dark"
                    style={{
                      fontSize: "0.7rem",
                      width: "18px",
                      height: "18px",
                      padding: "0",
                      borderRadius: "100%",
                      position: "absolute",
                      right: "-5px",
                      top: "-5px",
                      border: "none",
                      backgroundColor: "lightgray",
                      color: "#545b62",
                    }}
                    value={element.imagePath.get()}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={faQuestion}
                    ></FontAwesomeIcon>
                  </Button>
                </>
              ) : (
                ""
              )} */}

              <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={modalShow ? false : true}
                overlay={
                  <Popover
                    id="popover-contained"
                    onClick={(e) => e.stopPropagation}
                  >
                    <Popover.Content>
                      <Form>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              onChange={(e) =>
                                element.buttonname.set(e.target.value)
                              }
                              type="input"
                              placeholder="Button Bezeichnung"
                              defaultValue={element.buttonname.get()}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                      <ButtonGroup
                        vertical
                        className="m-1 d-flex justify-content-center"
                      >
                        {/* Endpunkt deaktiviert, vermutlich nicht benötigt, da entsprechend in Findings implementiert */}
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={
                            <Popover
                              rootClose="true"
                              id="popover-contained"
                              onClick={(e) => e.stopPropagation}
                              style={{ minWidth: "500px" }}
                            >
                              <Popover.Content>
                                <InputGroup>
                                  <Form.Control
                                    as="textarea"
                                    onChange={(e) =>
                                      element.reportoutput.set(e.target.value)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    type="input"
                                    placeholder="Report Output"
                                    defaultValue={element.reportoutput.get()}
                                  />

                                  {/* <Form.Control
                                    as="textarea"
                                    onChange={(e) =>
                                      element.conclusionoutput.set(
                                        e.target.value
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    type="input"
                                    placeholder="Conclusion Output"
                                    defaultValue={element.conclusionoutput.get()}
                                    style={{
                                      display: element.endpoint.get()
                                        ? ""
                                        : "none",
                                    }}
                                  /> */}
                                  {/* <InputGroup.Append>
                                    <OverlayTrigger
                                      overlay={
                                        <Popover
                                          rootClose="true"
                                          id="popover-contained"
                                          onClick={(e) => e.stopPropagation}
                                        >
                                          <Popover.Content>
                                            mark as relevant
                                          </Popover.Content>
                                        </Popover>
                                      }
                                    >
                                      <InputGroup.Checkbox
                                        aria-label="Checkbox for following text input"
                                        onClick={(e) => {
                                          element.conclusionoutputrelevant.set(
                                            e.target.checked
                                          );
                                          e.stopPropagation();
                                        }}
                                      />
                                    </OverlayTrigger>
                                  </InputGroup.Append> */}
                                </InputGroup>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            variant={
                              element.endpoint.get()
                                ? "outline-primary"
                                : "primary"
                            }
                            onClick={() => {
                              if (element.endpoint.get() === undefined) {
                                element.endpoint.set(true);
                              } else {
                                element.endpoint.set(!element.endpoint.get());
                              }
                            }}
                          >
                            Endpunkt
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          rootClose={true}
                          overlay={
                            <Popover style={{ minWidth: "400px" }}>
                              <Popover.Content>
                                <Form>
                                  <InputGroup>
                                    <Form.Control
                                      onChange={(e) =>
                                        setFindingName(e.target.value)
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      placeholder="Bezeichnung"
                                    ></Form.Control>
                                    <InputGroup.Append>
                                      <Button
                                        onClick={(e) => {
                                          if (
                                            findingname !== "" &&
                                            findingname !== undefined
                                          ) {
                                            if (
                                              element.children.get() ===
                                              undefined
                                            ) {
                                              element.children.set([{findings: [
                                                {
                                                  checked: false,
                                                  name: findingname,

                                                  finding: [
                                                    {
                                                      name: "output",
                                                      output: "",
                                                    },
                                                  ],
                                                  number: 0,
                                                  outputsyntaxArray: [],
                                                  selectedoutputArray: [],
                                                }], id: "Findings", children: []},
                                              ]);
                                            } else if (
                                              element.children.get() !==
                                                undefined &&
                                              element.children.some(
                                                (childelement) =>
                                                  childelement.id.get() ===
                                                  "Findings"
                                              )
                                            ) {
                                              element.children.map(
                                                (childelement) => {
                                                  if (
                                                    childelement.id.get() ===
                                                    "Findings"
                                                  ) {
                                                    childelement.findings.merge(
                                                      [
                                                        {
                                                          checked: false,
                                                          name: findingname,

                                                          finding: [
                                                            {
                                                              name: "output",
                                                              output: "",
                                                            },
                                                          ],
                                                          number:
                                                            childelement
                                                              .findings.length,
                                                          outputsyntaxArray: [],
                                                          selectedoutputArray: [],
                                                        },
                                                      ]
                                                    );
                                                  }
                                                }
                                              );
                                            } else if (
                                              element.children.get() !==
                                                undefined &&
                                              !element.children.some(
                                                (element) =>
                                                  element.id.get() ===
                                                  "Findings"
                                              )
                                            ) {
                                              element.children.merge([
                                                {
                                                  id: "Findings",

                                                  findings: [
                                                    {
                                                      name: findingname,
                                                      finding: [
                                                        {
                                                          name: "output",
                                                          output: "",
                                                        },
                                                      ],
                                                      number: 0,
                                                    },
                                                  ],
                                                  children: [],
                                                },
                                              ]);
                                            }
                                          } else {
                                            SetError("Bitte Finding bennen");
                                            setTimeout(() => SetError(), 2000);
                                          }

                                          e.stopPropagation();

                                          // if (
                                          //   findingname !== "" &&
                                          //   findingname !== undefined
                                          // ) {
                                          //   element.children.merge([
                                          //     {
                                          //       id: "Findings",

                                          //       findings: [
                                          //         {
                                          //           name: findingname,
                                          //           finding: [
                                          //             {
                                          //               name: "output",
                                          //               output: "",
                                          //             },
                                          //           ],
                                          //           number: 0,
                                          //         },
                                          //       ],
                                          //       children: [],
                                          //     },
                                          //   ]);
                                          // } else {
                                          //   SetError("Bitte Finding bennen");
                                          //   setTimeout(() => SetError(), 2000);
                                          // }

                                          // e.stopPropagation();
                                        }}
                                      >
                                        +
                                      </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button>+ Finding</Button>
                        </OverlayTrigger>
                        <Button
                          onClick={() => {
                            if (element.children === undefined) {
                              element.set({
                                children: [
                                  {
                                    id: "DecisionTree",
                                    input: "",
                                    outputvalue: "",
                                    pdfPath: "",
                                    imagePath: "",
                                    endpoint: false,
                                    output: false,
                                    children: [],
                                  },
                                ],
                              });
                            } else if (element.children !== undefined) {
                              element.children.set((nodes) =>
                                (nodes || []).concat([
                                  {
                                    id: "DecisionTree",
                                    input: "",
                                    outputvalue: "",
                                    endpoint: false,
                                    output: false,
                                    children: [],
                                    buttons: [],
                                  },
                                ])
                              );
                            }
                          }}
                        >
                          + Knoten
                        </Button>

                        {/* Klassifikationen noch nicht implementiert, vielleicht nich separat benötigt */}
                        {/* <Button>+ Klassifikation</Button> */}

                        {/* Deaktiviert weil zu Findings verschoben, kann vermutlich gelöscht werden */}
                        {/* <OverlayTrigger
                          trigger="click"
                          rootClose="true"
                          overlay={
                            <Popover
                              rootClose="true"
                              id="popover-contained"
                              onClick={(e) => e.stopPropagation}
                            >
                              <Popover.Content>
                                <Form>
                                  <Form.Group>
                                    <Form.Control
                                      onChange={(e) => {
                                        element.discriptorname.set(
                                          e.target.value
                                        );
                                        element.usediscriptor.set(true);

                                        const data = discriptorData.filter(
                                          (element) => {
                                            console.log(element.name);
                                            console.log(e.target.value);
                                            if (
                                              element.name === e.target.value
                                            ) {
                                              console.log(element.data);
                                              return element.data;
                                            }
                                          }
                                        );
                                        console.log(data);
                                        element.discriptordata.set(
                                          data[0].data
                                        );
                                      }}
                                      as="select"
                                      placeholder="Template/Modulbezeichnung"
                                    >
                                      <option>auswählen...</option>
                                      {discriptorData
                                        ? discriptorData.map((element) => {
                                            return (
                                              <option value={element.name}>
                                                {element.name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </Form.Control>
                                  </Form.Group>
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            variant={
                              orientation ? "outline-primary" : "primary"
                            }
                            onClick={() => {}}
                          >
                            + Diskriptor
                          </Button>
                        </OverlayTrigger> */}

                        {/* <OverlayTrigger
                          trigger="hover"
                          placement="right"
                          overlay={
                            <Popover
                              id="popover-contained"
                              onClick={(e) => e.stopPropagation}
                              style={{ minWidth: "600px", minHeight: "500px" }}
                            >
                              <Popover.Content>
                                {/* <div
                                  className="m-2 p-2"
                                  style={{
                                    borderBottom: "solid 1px lightgray",
                                  }}
                                >
                                  <Form>
                                    <Form.Label>Link hinzufügen</Form.Label>

                                    <Form.Control
                                      onChange={(e) => {
                                        const urlRegEx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
                                        console.log(
                                          urlRegEx.test(
                                            String(e.target.value).toLowerCase()
                                          )
                                        );
                                        if (
                                          urlRegEx.test(
                                            String(e.target.value).toLowerCase()
                                          )
                                        ) {
                                          element.helpLink.set(e.target.value);
                                        }
                                      }}
                                      placeholder="Link"
                                    />
                                  </Form>
                                </div> */}

                        {/*<div
                                  className="m-2 p-2"
                                  style={{
                                    borderBottom: "solid 1px lightgray",
                                  }}
                                >
                                  <Tabs
                                    defaultActiveKey="fileupload"
                                    id="uncontrolled-tab-example"
                                    defaultValue={
                                      element.helpmodule.get() !== undefined
                                        ? element.helpmodule.get()
                                        : ""
                                    }
                                  >
                                    <Tab
                                      eventKey="fileupload"
                                      title="Datei hochladen"
                                    >
                                      <br />
                                      <Form>
                                        {/* <Form.Label>Datei hochladen</Form.Label> */}

                        {/*<Form.Control
                                          defaultValue={
                                            element.fileupload.get()
                                              ? element.fileupload.discription.get()
                                              : ""
                                          }
                                          placeholder="Bezeichnung/Titel"
                                          onChange={(e) =>
                                            element.fileupload.merge({
                                              discription: e.target.value,
                                            })
                                          }
                                        />
                                        <br />
                                        <Form.Control
                                          type="url"
                                          defaultValue={
                                            element.fileupload.get()
                                              ? element.fileupload.link.get()
                                              : ""
                                          }
                                          placeholder="weiterführender Link"
                                          onChange={(e) => {
                                            if (
                                              validator.isURL(e.target.value)
                                            ) {
                                              element.fileupload.merge({
                                                link: e.target.value,
                                              });
                                            }
                                          }}
                                        />
                                        <br />
                                        <Form.Control
                                          as="textarea"
                                          placeholder="zusätzlicher Text / Erklärung"
                                          defaultValue={
                                            element.fileupload.get() !==
                                            undefined
                                              ? element.fileupload.helpText.get()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            element.fileupload.merge({
                                              helpText: e.target.value,
                                            })
                                          }
                                        />
                                      </Form>
                                      <br />
                                      <ButtonGroup>
                                        <Button
                                          onClick={() =>
                                            fileref.current.click()
                                          }
                                          variant="dark"
                                        >
                                          Datei auswählen
                                        </Button>
                                        <input
                                          type="file"
                                          name="file"
                                          onChange={(e) =>
                                            SetFile(e.target.files[0])
                                          }
                                          style={{ display: "none" }}
                                          ref={fileref}
                                        />
                                        <Button
                                          onClick={async () => {
                                            const userId = currentUser.uid;
                                            const userInstitution =
                                              userData.institution;
                                            let discription = null;
                                            let fileLink = null;
                                            console.log(
                                              element.fileupload.discription.get()
                                            );
                                            if (
                                              element.fileupload.discription.get() !==
                                              undefined
                                            ) {
                                              discription = {
                                                discription: element.fileupload.discription.get(),
                                              };
                                            }

                                            if (
                                              element.fileupload.link.get() !==
                                              undefined
                                            ) {
                                              fileLink = {
                                                fileLink: element.fileupload.link.get(),
                                              };
                                            }

                                            const customMetadata = Object.assign(
                                              discription,
                                              fileLink
                                            );

                                            console.log(customMetadata);

                                            fileupload(file, customMetadata);

                                            if (
                                              file.type === "image/png" ||
                                              file.type === "image/jpg" ||
                                              file.type === "image/jpeg"
                                            ) {
                                              // gets the functions from storage refences the image storage in firebase by the children
                                              // gets the download url then sets the image from firebase as the value for the imgUrl key:
                                              storage
                                                .ref(
                                                  `/images/${userInstitution}/${userId}/`
                                                )
                                                .child(file.name)
                                                .getDownloadURL()
                                                .then(async (fireBaseUrl) => {
                                                  console.log(fireBaseUrl);
                                                  //imagePath = await fireBaseUrl;
                                                  element.fileupload.merge({
                                                    imagePath: await fireBaseUrl,
                                                  });
                                                });
                                            } else if (
                                              file.type === "application/pdf"
                                            ) {
                                              // gets the functions from storage refences the image storage in firebase by the children
                                              // gets the download url then sets the image from firebase as the value for the imgUrl key:
                                              storage
                                                .ref(
                                                  `/pdfs/${userInstitution}/${userId}/`
                                                )
                                                .child(file.name)
                                                .getDownloadURL()
                                                .then(async (fireBaseUrl) => {
                                                  console.log(fireBaseUrl);
                                                  //imagePath = await fireBaseUrl;
                                                  element.fileupload.merge({
                                                    pdfPath: await fireBaseUrl,
                                                  });
                                                });
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faUpload}
                                            size="sm"
                                          />
                                        </Button>
                                      </ButtonGroup>
                                    </Tab>
                                    <Tab
                                      eventKey="imagelink"
                                      title="Bild verlinken"
                                    >
                                      <br />
                                      <Form>
                                        <Form.Control
                                          onChange={(e) => {
                                            if (
                                              validator.isURL(e.target.value)
                                            ) {
                                              element.imagelink.merge({
                                                link: e.target.value,
                                              });
                                            }
                                          }}
                                          placeholder="URL des Bildes"
                                          defaultValue={
                                            element.imagelink.get()
                                              ? element.imagelink.link.get()
                                              : ""
                                          }
                                        />
                                        <br />
                                        <Form.Control
                                          defaultValue={
                                            element.imagelink.get()
                                              ? element.imagelink.discription.get()
                                              : ""
                                          }
                                          placeholder="Bezeichnung/Titel"
                                          onChange={(e) =>
                                            element.imagelink.merge({
                                              discription: e.target.value,
                                            })
                                          }
                                        />

                                        <br />
                                        <Form.Control
                                          as="textarea"
                                          placeholder="zusätzlicher Text / Erklärung"
                                          defaultValue={
                                            element.imagelink.get()
                                              ? element.imagelink.helpText.get()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            element.imagelink.merge({
                                              helpText: e.target.value,
                                            })
                                          }
                                        />
                                      </Form>
                                    </Tab>
                                    <Tab
                                      eventKey="pdflink"
                                      title="PDF verlinken"
                                    >
                                      <br />
                                      <Form>
                                        <Form.Control
                                          onChange={(e) => {
                                            if (
                                              validator.isURL(e.target.value)
                                            ) {
                                              element.pdflink.merge({
                                                link: e.target.value,
                                              });
                                            }
                                          }}
                                          placeholder="URL des PDFs"
                                          defaultValue={
                                            element.pdflink.get()
                                              ? element.pdflink.link.get()
                                              : ""
                                          }
                                        />
                                        <br />
                                        <Form.Control
                                          defaultValue={
                                            element.pdflink.get()
                                              ? element.pdflink.discription.get()
                                              : ""
                                          }
                                          placeholder="Bezeichnung/Titel"
                                          onChange={(e) =>
                                            element.pdflink.merge({
                                              discription: e.target.value,
                                            })
                                          }
                                        />

                                        <br />
                                        <Form.Control
                                          as="textarea"
                                          placeholder="zusätzlicher Text / Erklärung"
                                          defaultValue={
                                            element.pdflink.get()
                                              ? element.pdflink.helpText.get()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            element.pdflink.merge({
                                              helpText: e.target.value,
                                            })
                                          }
                                        />
                                      </Form>
                                    </Tab>
                                    <Tab
                                      eventKey="youtubevideolink"
                                      title="Youtube Video verlinken"
                                    >
                                      <br />
                                      <Form>
                                        <Form.Control
                                          onChange={(e) => {
                                            if (
                                              validator.isURL(e.target.value)
                                            ) {
                                              element.youtubevideolink.merge({
                                                link: e.target.value,
                                              });
                                            }
                                          }}
                                          placeholder="URL des Youtube Videos"
                                          defaultValue={
                                            element.youtubevideolink.get()
                                              ? element.youtubevideolink.link.get()
                                              : ""
                                          }
                                        />
                                        <br />
                                        <Form.Control
                                          defaultValue={
                                            element.youtubevideolink.get()
                                              ? element.youtubevideolink.discription.get()
                                              : ""
                                          }
                                          placeholder="Bezeichnung/Titel"
                                          onChange={(e) =>
                                            element.youtubevideolink.merge({
                                              discription: e.target.value,
                                            })
                                          }
                                        />

                                        <br />
                                        <Form.Control
                                          as="textarea"
                                          placeholder="zusätzlicher Text / Erklärung"
                                          defaultValue={
                                            element.youtubevideolink.get()
                                              ? element.youtubevideolink.helpText.get()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            element.youtubevideolink.merge({
                                              helpText: e.target.value,
                                            })
                                          }
                                        />
                                      </Form>
                                    </Tab>
                                    <Tab
                                      eventKey="webpagelink"
                                      title="Webseite verlinken"
                                    >
                                      <br />
                                      <Form>
                                        <Form.Control
                                          onChange={(e) => {
                                            if (
                                              validator.isURL(e.target.value)
                                            ) {
                                              element.webpagelink.merge({
                                                link: e.target.value,
                                              });
                                            }
                                          }}
                                          placeholder="URL der Webseite"
                                          defaultValue={
                                            element.webpagelink.get()
                                              ? element.webpagelink.discription.get()
                                              : ""
                                          }
                                        />
                                        <br />
                                        <Form.Control
                                          defaultValue={
                                            element.webpagelink.get()
                                              ? element.webpagelink.discription.get()
                                              : ""
                                          }
                                          placeholder="Bezeichnung/Titel"
                                          onChange={(e) =>
                                            element.webpagelink.merge({
                                              discription: e.target.value,
                                            })
                                          }
                                        />

                                        <br />
                                        <Form.Control
                                          as="textarea"
                                          placeholder="zusätzlicher Text / Erklärung"
                                          defaultValue={
                                            element.webpagelink.get()
                                              ? element.webpagelink.helpText.get()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            element.imagelink.merge({
                                              helpText: e.target.value,
                                            })
                                          }
                                        />
                                      </Form>
                                    </Tab>
                                    <Tab
                                      eventKey="helptext"
                                      title="Hilfetext hinzufügen"
                                    >
                                      <div className="m-2 p-2">
                                        <br />

                                        <Form.Control
                                          as="textarea"
                                          placeholder="Hilfetext"
                                          onChange={(e) =>
                                            element.helpText.set(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Tab>
                                  </Tabs>
                                </div>

                                <p>Hilfemodul auswählen</p>
                                <ToggleButtonGroup type="radio" name="options">
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="fileupload"
                                  >
                                    Datei
                                  </ToggleButton>
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="imagelink"
                                  >
                                    Bild
                                  </ToggleButton>
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="pdflink"
                                  >
                                    PDF
                                  </ToggleButton>
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="youtubevideolink"
                                  >
                                    Youtube
                                  </ToggleButton>
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="webpagelink"
                                  >
                                    Link
                                  </ToggleButton>
                                  <ToggleButton
                                    onChange={(e) => {
                                      element.helpmodule.set(e.target.value);
                                    }}
                                    value="helpText"
                                  >
                                    Hilfetext
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Popover.Content>
                            </Popover>
                          }
                        > */}
                        <Button onClick={() => setModalShow(true)}>
                          + Hilfe
                        </Button>
                        {/* </OverlayTrigger> */}
                        <FileUploadModal
                          show={modalShow}
                          element={element}
                          onHide={() => setModalShow(false)}
                        ></FileUploadModal>
                        <Button onClick={() => element.set(none)}>
                          löschen
                        </Button>
                      </ButtonGroup>
                    </Popover.Content>
                  </Popover>
                }
              >
                <Button
                  className="mr-1 mb-1 mt-1"
                  value={element.buttonvalue.get()}
                  variant={element["button" + i].get() ? "dark" : "primary"}
                  onClick={() => {
                    if (element["button" + i].get()) {
                      element["button" + i].set(false);
                    } else {
                      const totallength = state.buttons.get().length;
                      console.log(totallength);
                      var number;
                      for (number = 0; number < totallength; number++) {
                        console.log("HIER", number);
                        const merged = "button" + number;
                        console.log(state.buttons[number][merged].get());
                        state.buttons[number][merged].set(false);
                        console.log(state.buttons);
                      }
                      element["button" + i].set(!element["button" + i].get());
                    }
                  }}
                >
                  {element.buttonname.get()}
                </Button>
              </OverlayTrigger>
            </div>
          );
        })}
      </div>
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  let pdfurl = null;
  if (props.element.fileupload.get() !== undefined) {
    pdfurl = `https://cors-anywhere.herokuapp.com/${props.element.fileupload.pdfPath.get()}`;
  }

  let pdflinkurl = null;
  if (props.element.pdflink.get() !== undefined) {
    pdflinkurl = `https://cors-anywhere.herokuapp.com/${props.element.pdflink.link.get()}`;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      dialogClassName="modal-90w-90h"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.element.helpmodule.get() === "fileupload" &&
          props.element.fileupload.get() !== undefined &&
          props.element.fileupload.discription.get() !== undefined ? (
            <p>{props.element.fileupload.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "imagelink" &&
          props.element.imagelink.get() !== undefined &&
          props.element.imagelink.discription.get() !== undefined ? (
            <p>{props.element.imagelink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "pdflink" &&
          props.element.pdflink.get() !== undefined &&
          props.element.pdflink.discription.get() !== undefined ? (
            <p>{props.element.pdflink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "youtubevideolink" &&
          props.element.youtubevideolink.get() !== undefined &&
          props.element.youtubevideolink.discription.get() !== undefined ? (
            <p>{props.element.youtubevideolink.discription.get()}</p>
          ) : (
            ""
          )}
          {props.element.helpmodule.get() === "webpagelink" &&
          props.element.webpagelink.get() !== undefined &&
          props.element.webpagelink.discription.get() !== undefined ? (
            <p>{props.element.webpagelink.discription.get()}</p>
          ) : (
            ""
          )}
          {/* {props.element.helpmodule.get() === "helpText" &&
          props.element.helpText.get() !== undefined &&
          props.element.helpText.discription.get() !== undefined ? (
            <p>{props.element.helpText.discription.get()}</p>
          ) : (
            ""
          )} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px" }}>
        {props.element.helpmodule.get() === "fileupload" &&
        props.element.fileupload.get() !== undefined &&
        props.element.fileupload.imagePath.get() !== undefined ? (
          <a
            href={props.element.fileupload.link.get()}
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt={props.element.fileupload.discription.get()}
              style={{ maxHeight: "500px" }}
              src={
                (storage
                  .ref(props.element.fileupload.imagePath2.get())
                  .getDownloadURL(),
                console.log(
                  storage
                    .ref(props.element.fileupload.imagePath2.get())
                    .getDownloadURL()
                ))
              }
            ></img>
          </a>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "fileupload" &&
        props.element.fileupload.get() !== undefined &&
        props.element.fileupload.pdfPath.get() !== undefined ? (
          <>
            <div className="d-flex justify-content-space-around ">
              <ButtonGroup>
                <Button variant="dark" disabled>
                  Seite {pageNumber || (numPages ? 1 : "--")} von{" "}
                  {numPages || "--"}
                </Button>

                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="dark"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="dark"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
            <div
              className="main"
              style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
            >
              <Document
                file={storage
                  .ref(props.element.fileupload.pdfPath2.get())
                  .getDownloadURL()}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "imagelink" &&
        props.element.imagelink.get() !== undefined &&
        props.element.imagelink.link.get() !== undefined ? (
          <a
            href={props.element.imagelink.link.get()}
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt={props.element.imagelink.discription.get()}
              style={{ maxHeight: "500px" }}
              src={
                (storage
                  .ref(props.element.fileupload.imagePath2.get())
                  .getDownloadURL(),
                console.log(
                  storage
                    .ref(props.element.fileupload.imagePath2.get())
                    .getDownloadURL()
                ))
              }
            ></img>
          </a>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "pdflink" &&
        props.element.pdflink.get() !== undefined &&
        props.element.pdflink.link.get() !== undefined ? (
          <>
            <a
              href={props.element.pdflink.link.get()}
              rel="noreferrer"
              target="_blank"
            >
              {props.element.pdflink.link.get()}
            </a>

            <div className="d-flex justify-content-space-around ">
              <ButtonGroup>
                <Button variant="dark" disabled>
                  Seite {pageNumber || (numPages ? 1 : "--")} von{" "}
                  {numPages || "--"}
                </Button>

                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="dark"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="dark"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
            <div
              className="main"
              style={{ maxHeight: "450px", overflow: "auto" }}
            >
              <Document file={pdflinkurl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "youtubevideolink" &&
        props.element.youtubevideolink.get() !== undefined &&
        props.element.youtubevideolink.link.get() !== undefined ? (
          <div className="d-flex justify-content-center">
            <div>
              <iframe
                width="560"
                height="315"
                src={props.element.youtubevideolink.link.get()}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "webpagelink" &&
        props.element.webpagelink.get() !== undefined &&
        props.element.webpagelink.link.get() !== undefined ? (
          <>
            <a
              href={props.element.webpagelink.link.get()}
              rel="noreferrer"
              target="_blank"
            >
              {props.element.webpagelink.link.get()}
            </a>
            <iframe
              src={props.element.webpagelink.link.get()}
              frameborder="0"
              width="100%"
              height="50vh"
            ></iframe>
          </>
        ) : (
          ""
        )}

        {props.element.helpmodule.get() === "helpText" &&
        props.element.helpText.get() !== undefined ? (
          <p>{props.element.helpText.get()}</p>
        ) : (
          ""
        )}

        {/* {props.pdfPath === "none" ? (
          <a href={props.element.fileLink.get()} target="_blank">
            <img style={{ maxHeight: "500px" }} src={props.imagePath}></img>
          </a>
        ) : (
          ""
        )} */}

        {/* {props.imagePath === "none" ? (
          <>
            <div className="d-flex justify-content-space-around ">
              <ButtonGroup>
                <Button variant="dark" disabled>
                  Seite {pageNumber || (numPages ? 1 : "--")} von{" "}
                  {numPages || "--"}
                </Button>

                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="dark"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="dark"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
            <div
              className="main"
              style={{ maxHeight: "450px", overflow: "auto" }}
            >
              <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        ) : (
          ""
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col sm={10}>
              {props.element.helpmodule.get() === "fileupload" &&
              props.element.fileupload.get() !== undefined &&
              props.element.fileupload.helpText.get() !== undefined ? (
                <p>{props.element.fileupload.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "imagelink" &&
              props.element.imagelink.get() !== undefined &&
              props.element.imagelink.helpText.get() !== undefined ? (
                <p>{props.element.imagelink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "pdflink" &&
              props.element.pdflink.get() !== undefined &&
              props.element.pdflink.helpText.get() !== undefined ? (
                <p>{props.element.pdflink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "youtubevideolink" &&
              props.element.youtubevideolink.get() !== undefined &&
              props.element.youtubevideolink.helpText.get() !== undefined ? (
                <p>{props.element.youtubevideolink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "webpagelink" &&
              props.element.webpagelink.get() !== undefined &&
              props.element.webpagelink.helpText.get() !== undefined ? (
                <p>{props.element.webpagelink.helpText.get()}</p>
              ) : (
                ""
              )}
              {props.element.helpmodule.get() === "webpagelink" ? (
                <>
                  <p>Test</p>
                  <a
                    href={props.element.webpagelink.link.get()}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {props.element.webpagelink.link.get()}
                  </a>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col sm={2}>
              <Button onClick={props.onHide}>Close</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

function FileUploadModal(props) {
  const [file, SetFile] = useState();
  const { currentUser } = useAuth();
  const { fileupload, userData } = useData();
  const fileref = useRef();

  const element = props.element;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      dialogClassName="modal-90w-90h"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.element.fileDiscription.get()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px" }}>
        <>
          <div
            className="m-2 p-2"
            style={{
              borderBottom: "solid 1px lightgray",
            }}
          >
            <Tabs
              defaultActiveKey="fileupload"
              id="uncontrolled-tab-example"
              defaultValue={
                element.helpmodule.get() !== undefined
                  ? element.helpmodule.get()
                  : ""
              }
            >
              <Tab eventKey="fileupload" title="Datei hochladen">
                <br />
                <Form>
                  {/* <Form.Label>Datei hochladen</Form.Label> */}

                  <Form.Control
                    defaultValue={
                      element.fileupload.get()
                        ? element.fileupload.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.fileupload.merge({
                        discription: e.target.value,
                      })
                    }
                  />
                  <br />
                  <Form.Control
                    type="url"
                    defaultValue={
                      element.fileupload.get()
                        ? element.fileupload.link.get()
                        : ""
                    }
                    placeholder="weiterführender Link"
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.fileupload.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                  />
                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.fileupload.get() !== undefined
                        ? element.fileupload.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.fileupload.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
                <br />
                <ButtonGroup>
                  <Button
                    onClick={() => fileref.current.click()}
                    variant="dark"
                  >
                    Datei auswählen
                  </Button>
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => SetFile(e.target.files[0])}
                    style={{ display: "none" }}
                    ref={fileref}
                  />
                  <Button
                    onClick={async () => {
                      const userId = currentUser.uid;
                      const userInstitution = currentUser.institution;
                      let discription = null;
                      let fileLink = null;
                      console.log(element.fileupload.discription.get());
                      if (element.fileupload.discription.get() !== undefined) {
                        discription = {
                          discription: element.fileupload.discription.get(),
                        };
                      }

                      if (element.fileupload.link.get() !== undefined) {
                        fileLink = {
                          fileLink: element.fileupload.link.get(),
                        };
                      }

                      const customMetadata = Object.assign(
                        discription,
                        fileLink
                      );

                      console.log(customMetadata);

                      fileupload(file, customMetadata);

                      if (
                        file.type === "image/png" ||
                        file.type === "image/jpg" ||
                        file.type === "image/jpeg"
                      ) {
                        // gets the functions from storage refences the image storage in firebase by the children
                        // gets the download url then sets the image from firebase as the value for the imgUrl key:

                        storage
                          .ref(`/images/${userInstitution}/${userId}/`)
                          .child(file.name)
                          .getDownloadURL()
                          .then(async (fireBaseUrl) => {
                            console.log(fireBaseUrl);
                            //imagePath = await fireBaseUrl;
                            // let newurl = fireBaseUrl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // newurl = newurl.replace("%2F", "/")
                            // console.log(newurl)
                            // console.log(storage.refFromURL(fireBaseUrl))
                            console.log(
                              `/images/${userInstitution}/${userId}/${file.name}`
                            );
                            element.fileupload.merge({
                              imagePath: await fireBaseUrl,
                              imagePath2: `/images/${userInstitution}/${userId}/${file.name}`,
                            });
                          });
                      } else if (file.type === "application/pdf") {
                        // gets the functions from storage refences the image storage in firebase by the children
                        // gets the download url then sets the image from firebase as the value for the imgUrl key:

                        // storage
                        //   .ref(`/pdf/${userInstitution}/${userId}/`)
                        //   .child(file.name)
                        //   .getDownloadURL()
                        //   .then(async (fireBaseUrl) => {
                        //     console.log(fireBaseUrl);
                        //     //imagePath = await fireBaseUrl;
                        //     // let newurl = fireBaseUrl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // newurl = newurl.replace("%2F", "/")
                        //     // console.log(newurl)
                        //     // console.log(storage.refFromURL(fireBaseUrl))
                        //     console.log(
                        //       `/pdfs/${userInstitution}/${userId}/${file.name}`
                        //     );
                        element.fileupload.merge({
                          pdfPath: "test",
                          pdfPath2: `/pdfs/${userInstitution}/${userId}/${file.name}`,
                          // });
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} size="sm" />
                  </Button>
                </ButtonGroup>
              </Tab>
              <Tab eventKey="imagelink" title="Bild verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.imagelink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des Bildes"
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.imagelink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.imagelink.get()
                        ? element.imagelink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.imagelink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="pdflink" title="PDF verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.pdflink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des PDFs"
                    defaultValue={
                      element.pdflink.get() ? element.pdflink.link.get() : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.pdflink.get()
                        ? element.pdflink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.pdflink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.pdflink.get()
                        ? element.pdflink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.pdflink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="youtubevideolink" title="Youtube Video verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.youtubevideolink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL des Youtube Videos"
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.youtubevideolink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.youtubevideolink.get()
                        ? element.youtubevideolink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.youtubevideolink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="webpagelink" title="Webseite verlinken">
                <br />
                <Form>
                  <Form.Control
                    onChange={(e) => {
                      if (validator.isURL(e.target.value)) {
                        element.webpagelink.merge({
                          link: e.target.value,
                        });
                      }
                    }}
                    placeholder="URL der Webseite"
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.link.get()
                        : ""
                    }
                  />
                  <br />
                  <Form.Control
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.discription.get()
                        : ""
                    }
                    placeholder="Bezeichnung/Titel"
                    onChange={(e) =>
                      element.webpagelink.merge({
                        discription: e.target.value,
                      })
                    }
                  />

                  <br />
                  <Form.Control
                    as="textarea"
                    placeholder="zusätzlicher Text / Erklärung"
                    defaultValue={
                      element.webpagelink.get()
                        ? element.webpagelink.helpText.get()
                        : ""
                    }
                    onChange={(e) =>
                      element.webpagelink.merge({
                        helpText: e.target.value,
                      })
                    }
                  />
                </Form>
              </Tab>
              <Tab eventKey="helptext" title="Hilfetext hinzufügen">
                <div className="m-2 p-2">
                  <br />

                  <Form.Control
                    as="textarea"
                    placeholder="Hilfetext"
                    onChange={(e) => element.helpText.set(e.target.value)}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>

          <p>Hilfemodul auswählen</p>
          <ToggleButtonGroup type="radio" name="options">
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="fileupload"
            >
              Datei
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="imagelink"
            >
              Bild
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="pdflink"
            >
              PDF
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="youtubevideolink"
            >
              Youtube
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="webpagelink"
            >
              Link
            </ToggleButton>
            <ToggleButton
              onChange={(e) => {
                element.helpmodule.set(e.target.value);
              }}
              value="helpText"
            >
              Hilfetext
            </ToggleButton>
          </ToggleButtonGroup>
        </>

        {/* <Button
          onClick={async () => {
            fileupload(file);

            if (
              file.type === "image/png" ||
              file.type === "image/jpg" ||
              file.type === "image/jpeg"
            ) {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref("images")
                .child(file.name)
                .getDownloadURL()
                .then(async (fireBaseUrl) => {
                  console.log(fireBaseUrl);
                  //imagePath = await fireBaseUrl;
                  props.element.imagePath.set(await fireBaseUrl);
                });
            } else if (file.type === "application/pdf") {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref("pdfs")
                .child(file.name)
                .getDownloadURL()
                .then(async (fireBaseUrl) => {
                  console.log(fireBaseUrl);
                  //imagePath = await fireBaseUrl;
                  props.element.pdfPath.set(await fireBaseUrl);
                });
            }
          }}
        >
          <FontAwesomeIcon icon={faUpload} size="sm" />
        </Button>
        <input
          type="file"
          name="file"
          onChange={(e) => SetFile(e.target.files[0])}
          style={{ display: "none" }}
          ref={fileref}
        />
        <Button
          //name={this.props.indices}
          onClick={() => fileref.current.click()}
        >
          Datei auswählen
        </Button> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
