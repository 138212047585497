import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  InputGroup,
  OverlayTrigger,
  Popover,
  ButtonGroup,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { firestore } from "../firebase";
import NavbarComponent from "./NavbarComponent";
import { useState as useStateHook, none, Downgraded } from "@hookstate/core";
import { Untracked } from "@hookstate/untracked";

export default function DiscriptorComponent(props) {
  const { userData, discriptorData } = useData();
  const [error, setError] = useState("");
  const [success, SetSuccess] = useState();
  const [discriptorname, SetDiscriptorName] = useState();
  const [
    selectedDiscriptorTemplate,
    SetSelectedDiscriptorTemplate,
  ] = useState();
  const [cols, SetCols] = useState(0);
  const [rows, SetRows] = useState(0);
  const [singleselection, SetSingleSelection] = useState(false);
  const [multiselection, SetMultiSelection] = useState(false);

  const state = useStateHook(props.data);


  console.log(state);

  return (
    <div className="w-100 text-center mt-1">
      <div className="">
        <div className="w-100 d-flex justify-content-center" >
          {/* {border: "1px black solid", borderRadius:"5px"} */}
          <div className="w-100 p-1" style={{ maxWidth: "100%" }}>
            {error && (
              <>
                <Alert variant="danger">{error}</Alert>{" "}
                <Button onClick={() => setError()}>x</Button>
              </>
            )}
            {success && <Alert variant="success">{success}</Alert>}
            <div>
              <ButtonGroup style ={{width: "100%"}} vertical>
                {state.map((element, index) => {
                  let output = [];
                  output = output.concat(
                    <DiscriptorRow
                      data={element}
                      findings={props.findings}
                      number={props.number}
                    />
                  );
                  return output;
                })}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DiscriptorRow(props) {
  const [singleselection, SetSingleSelection] = useState(false);
  const [multiselection, SetMultiSelection] = useState(false);
  const [error, SetError] = useState();

  const state = useStateHook(props.data);
  console.log(state);

  let outputbutton = [];
  let outputform = [];
  let buttonoutput = [];
  let prependbuttons = [];

  state.data.map((subelement, i) => {
    if (subelement.input.get() !== undefined) {
      if (
        subelement.selection.get() === "singleselection" ||
        subelement.selection.get() === "multiselection"
      ) {
        outputbutton = outputbutton.concat(
          <Button
            variant={
              subelement[subelement.input.get() + i].get() ? "dark" : "primary"
            }
            size="sm"
            onClick={(e) => {
              if (
                props.data.data.some(
                  (element) => element.selection.get() === "singleselection"
                )
              ) {
                // var index = 0;
                // var len = props.data.data.length;
                // for (index = 0; index < len; index++) {
                //   const buttonname = props.data.data[index].input.get()+i
                //   props.data.data[index][buttonname].set(false);
                // }
                if (!subelement[subelement.input.get() + i].get()) {
                  state.data.map((element, i) => {
                    element[element.input.get() + i].set(false);
                  });
                  subelement[subelement.input.get() + i].set(
                    !subelement[subelement.input.get() + i].get()
                  );
                } else if (subelement[subelement.input.get() + i].get()) {
                  subelement[subelement.input.get() + i].set(false);
                }

                console.log(state);
              } else if (
                props.data.data.some(
                  (element) => element.selection.get() === "multiselection"
                )
              ) { 
                subelement[subelement.input.get() + i].set(!subelement[subelement.input.get() + i].get())
                

                console.log(state);
              }

            }}
            value={subelement.input.get()}
          >
            {" "}
            {subelement.input.get()}
          </Button>
        );
      } else if (subelement.selection.get() === "manualinput") {
        outputform = outputform.concat(
          <>
                <InputGroup.Prepend>
                  <InputGroup.Text>{subelement.input.get()}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type="input"
                style={{minWidth:"50px", flexWrap:"nowrap"}}
                onChange={(e) => {
                  subelement.manualinput.set(e.target.value)
                  if(state.selectedoutputArray.get() !== undefined && state.selectedoutputArray.length > 0){
                    state.selectedoutputArray.map((outputelement) => {
                      if(outputelement.index.get() === i){
                        outputelement.manualinput.set(e.target.value)
                      }
                    })
                  }
                  if(state.selectedformulaArray.get() !== undefined && state.selectedformulaArray.length > 0){
                    state.selectedformulaArray.map((outputelement) => {
                      if(outputelement.index.get() === i){
                        outputelement.manualinput.set(e.target.value)
                      }
                    })
                  }
                  if(state.outputsyntaxArray.get() !== undefined && state.outputsyntaxArray.length > 0){
                    state.outputsyntaxArray.map((outputelement) => {
                      if(outputelement.index.get() === i && outputelement.type.get() === "value"){
                        outputelement.manualinput.set(e.target.value)
                      }
                    })
                  }
                }}
                defaultValue={subelement.manualinput.get()}
                ></Form.Control>
              
            {/* <Button
            variant={
              subelement[subelement.input.get() + i].get() ? "dark" : "primary"
            }
            onClick={(e) => {
              if (
                props.data.data.some(
                  (element) => element.selection.get() === "singleselection"
                )
              ) {
                // var index = 0;
                // var len = props.data.data.length;
                // for (index = 0; index < len; index++) {
                //   const buttonname = props.data.data[index].input.get()+i
                //   props.data.data[index][buttonname].set(false);
                // }
                if (!subelement[subelement.input.get() + i].get()) {
                  state.data.map((element, i) => {
                    console.log(state.path);
                    element[element.input.get() + i].set(false);
                  });
                  subelement[subelement.input.get() + i].set(
                    !subelement[subelement.input.get() + i].get()
                  );
                } else if (subelement[subelement.input.get() + i].get()) {
                  subelement[subelement.input.get() + i].set(false);
                }

                console.log(state);
              }
            }}
            value={subelement.input.get()}
          >
            {" "}
            {subelement.input.get()}
          </Button> */}
          </>
        );
      }
    }
  });

  if(outputbutton.length > 0 ) {
    return <ButtonGroup style={{overflow: "auto"}} size="sm">{outputbutton}</ButtonGroup>

    //return <div className="noScrollBar" style={{overflow:"auto", width: "100%"}}><ButtonGroup style={{width: "100%"}} size="sm">{outputbutton}</ButtonGroup></div>
  }
  else if(outputform.length > 0 ) {
    return <div className="noScrollBar" style={{overflow:"auto", width: "100%"}}><Form><InputGroup style={{flexWrap: "nowrap", width:"100%"}} size="sm">{outputform}</InputGroup></Form></div>
  }
  else{
    return ""
  }
}
