import React, { useContext, useState, useEffect } from "react"


const CreatorContext = React.createContext()

export function useCreator() {
  return useContext(CreatorContext)
}

export function CreatorProvider({ children }) {
    const [creatorstate, setCreatorState] = useState([])
    
    function addAccordion () {
            //vll mit push.. irgendwie ein index oder eine id generieren die jedesmal ein nach oben geht
            
            const input = [
              {
                id: creatorstate.length,
                number: creatorstate.length,
                name: 'Test',
                isActive: false,
                component: [],
              },
            ]
            const currentaccordions = creatorstate;
            const addAccordion = [...currentaccordions, ...input];

            console.log(addAccordion)
            
            return setCreatorState(addAccordion)
            
    }

    const value = {
      creatorstate,
      addAccordion
    }
  
    return (
      <CreatorContext.Provider value={value}>
        {children}
      </CreatorContext.Provider>
    )
  }
