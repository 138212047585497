import { createState, useState, State } from '@hookstate/core';

export interface AccordionData {
  id: number;
  number: number;
  name: string;
  isActive: boolean;
  component?: Component;
}

export interface Component {
  id: number;
  name: string;
  children?: Node[]
}

export interface Node {
    input: string;
    outputvalue: string;
    pdfPath: string;
    imagePath: string;
    endpoint: boolean;
    output: boolean;
    children?: Node[];
  }

const globalstate: State<AccordionData[]> = createState([] as AccordionData[]);

export function useGlobalState() {
    // This function exposes the state directly.
    // i.e. the state is accessible directly outside of this module.
    // The state for settings in SettingsState.ts wraps the state by an interface.
    // Both options are valid and you need to use one or another,
    // depending on your circumstances. Apply your engineering judgement
    // to choose the best option. If unsure, exposing the state directly
    // like it is done below is a safe bet.        
    return useState(globalstate)
}

const globaloutputstate: State<AccordionData[]> = createState([] as AccordionData[])

export function useGlobalOutputState() {
  return useState(globaloutputstate)
}

const orientationstate = createState([
  {
    name: "body",
    pair1: ["kranial", "kaudal"],
    pair2: ["rechts", "links"],
    pair3: ["anterior", "posterior"]
  },
  {
    name: "head",

  }
])

export function useOrientationState() {
  return useState(orientationstate)
}



console.log(globalstate)
console.log(globaloutputstate)
