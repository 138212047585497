import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBVpSUOAGuAouiBuLu07GyLrDLygZ_GDJs",
  authDomain: "rad-standard.firebaseapp.com",
  databaseURL: "https://rad-standard.firebaseio.com",
  projectId: "rad-standard",
  storageBucket: "rad-standard.appspot.com",
  messagingSenderId: "465820210602",
  appId: "1:465820210602:web:6c19fbd9852252adea47d3"
});

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()
export const functions = app.functions()
export default app

/*
apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,*/