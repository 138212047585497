import React, { useState } from "react";
import { Card, Button, Alert, Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import { Link, useHistory } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";


export default function Profil() {
  const [error, setError] = useState("");
  const [role, setRole] = useState();
  const [email, setEmail] = useState();
  const { currentUser, logout, addInstitution, addRole } = useAuth();
  const { userData } = useData();
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Ausloggen fehlgeschlagen");
    }
  }

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />

        <div className="w-100 d-flex align-items-center justify-content-center" style={{minHeight: "400px" }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Profil</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <strong>Name:</strong> {userData.firstname+' '+userData.lastname}
              <br/>
              <strong>Institution:</strong> {userData.institution}
              <br/>
              <strong>Email:</strong> {currentUser.email}
              <br/>
              <strong>Role:</strong> {currentUser.role}

              <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
                Profile aktualisieren
              </Link>
              <br/> 
              
            </Card.Body>
             
            <Button variant="link" onClick={handleLogout}>
            Ausloggen
          </Button>
          </Card>

      </div>
    </div>
  );
}
