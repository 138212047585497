import React, { useState, useEffect, useReducer } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  InputGroup,
  OverlayTrigger,
  Popover,
  Col,
  Table,
} from "react-bootstrap";
import { useData } from "../contexts/DataContext";
import { firestore } from "../firebase";
import NavbarComponent from "./NavbarComponent";
import { useState as useStateHook, none, Downgraded } from "@hookstate/core";
import DiscriptorComponent from "./DiscriptorComponent";
import MultiSelectSort from "./testSortable";

export default function Discriptors() {
  const { userData, discriptorData } = useData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();
  const [discriptorname, SetDiscriptorName] = useState();
  const [
    selectedDiscriptorTemplate,
    SetSelectedDiscriptorTemplate,
  ] = useState();
  const [
    selectedDiscriptorModality,
    SetSelectedDiscriptorModality,
  ] = useState();
  const [selectedDiscriptorRegion, SetSelectedDiscriptorRegion] = useState();
  //   const [discriptortemplate, SetDiscriptorTemplate] = useState();
  // const [cols, SetCols] = useState(0);
  // const [rows, SetRows] = useState(0);
  // const [singleselection, SetSingleSelection] = useState(false);
  // const [multiselection, SetMultiSelection] = useState(false);
  const [modality, setModality] = useState();
  const [region, setRegion] = useState();
  const [showsave, setShowSave] = useState(false);
  const [showload, setShowLoad] = useState(false);
  const [usefilter, setUseFilter] = useState(false);

  function saveDiscriptor() {
    setError();
    setSuccess();
    const data = state.get();
    const authorid = userData.authid;
    const institution = userData.institution;
    console.log(state.get());

    let namecheck = null;
    console.log(discriptorname);

    discriptorData.map((element) => {
      console.log(element);
      if (element.name === discriptorname) {
        namecheck = element;
      }
    });

    console.log(namecheck);

    if (discriptorData.some((element) => element.name === discriptorname)) {
      const confirm = window.confirm(
        "Bezeichnung bereits vergeben. Template überschreiben?"
      );
      if (confirm && namecheck.authorid === userData.authid) {
        if (modality && region) {
          try {
            firestore.collection("discriptors").doc(namecheck.id).set({
              data: data,
              name: discriptorname,
              authorid: authorid,
              institution: institution,
              modality: modality,
              region: region,
            });

            setSuccess("Erfolgreich gespeichert");
            setShowSave(false);
            setTimeout(() => setSuccess(), 2000);
          } catch (error) {
            console.log(error);
            setError("Speichern Fehlgeschlagen " + error);
            setTimeout(() => setError(), 5000);
          }
        } else if (modality && !region) {
          setError("Bitte Region auswählen");
          setTimeout(() => setError(), 5000);
        } else if (!modality && region) {
          setError("Bitte Modalität auswählen");
          setTimeout(() => setError(), 5000);
        } else if (!modality && !region) {
          setError("Bitte Modalität und Region auswählen");
          setTimeout(() => setError(), 5000);
        }
      } else if (confirm && namecheck.authorid !== userData.authid) {
        setError(
          "Sie sind nicht die/der Autor*in dieser Diskriptoren und können sie dehalb nicht überschreiben"
        );
        setTimeout(() => setError(), 5000);
      } else {
        setError("Fehler. Diskriptoren nicht gespeichert.");
        setTimeout(() => setError(), 5000);
      }
    } else {
      if (modality && region) {
        try {
          firestore.collection("discriptors").doc().set({
            data: data,
            name: discriptorname,
            authorid: authorid,
            institution: institution,
            modality: modality,
            region: region,
          });

          setSuccess("Erfolgreich gespeichert");
          setShowSave(false);
          setTimeout(() => setSuccess(), 1500);
        } catch (error) {
          setError("Speichern Fehlgeschlagen " + error);
          setTimeout(() => setError(), 5000);
        }
      } else if (modality && !region) {
        setError("Bitte Region auswählen");
        setTimeout(() => setError(), 5000);
      } else if (!modality && region) {
        setError("Bitte Modalität auswählen");
        setTimeout(() => setError(), 5000);
      } else if (!modality && !region) {
        setError("Bitte Modalität und Region auswählen");
        setTimeout(() => setError(), 5000);
      }
    }

    // firestore
    //   .collection("discriptors")
    //   .doc(discriptorname)
    //   .get()
    //   .then((doc) => {
    //     namecheck = doc.data();
    //     // if (
    //     //   state[0].data[0].input.get() !== "" &&
    //     //   state[0].data[1].input.get() !== ""
    //     // )
    //     if (namecheck !== undefined) {
    //       const confirm = window.confirm(
    //         "Bezeichnung bereits vergeben. Template überschreiben?"
    //       );
    //       if (confirm && namecheck.authorid === userData.authid) {
    //         if (modality && region) {
    //           try {
    //             firestore.collection("discriptors").doc(discriptorname).set({
    //               data: data,
    //               name: discriptorname,
    //               authorid: authorid,
    //               institution: institution,
    //               modality: modality,
    //               region: region,
    //             });

    //             setSuccess("Erfolgreich gespeichert");
    //             setShowSave(false);
    //             setTimeout(() => setSuccess(), 2000);
    //           } catch (error) {
    //             console.log(error);
    //             setError("Speichern Fehlgeschlagen " + error);
    //             setTimeout(() => setError(), 5000);
    //           }
    //         } else if (modality && !region) {
    //           setError("Bitte Region auswählen");
    //           setTimeout(() => setError(), 5000);
    //         } else if (!modality && region) {
    //           setError("Bitte Modalität auswählen");
    //           setTimeout(() => setError(), 5000);
    //         } else if (!modality && !region) {
    //           setError("Bitte Modalität und Region auswählen");
    //           setTimeout(() => setError(), 5000);
    //         }
    //       } else if (confirm && namecheck.authorid === userData.authid) {
    //         setError(
    //           "Sie sind nicht die/der Autor*in dieser Diskriptoren und können sie dehalb nicht überschreiben"
    //         );
    //         setTimeout(() => setError(), 5000);
    //       } else {
    //         setError("Fehler. Diskriptoren nicht gespeichert.");
    //         setTimeout(() => setError(), 5000);
    //       }
    //     } else {
    //       if (modality && region) {
    //         try {
    //           firestore.collection("discriptors").doc(discriptorname).set({
    //             data: data,
    //             name: discriptorname,
    //             authorid: authorid,
    //             institution: institution,
    //             modality: modality,
    //             region: region,
    //           });

    //           setSuccess("Erfolgreich gespeichert");
    //           setShowSave(false);
    //           setTimeout(() => setSuccess(), 1500);
    //         } catch (error) {
    //           setError("Speichern Fehlgeschlagen " + error);
    //           setTimeout(() => setError(), 5000);
    //         }
    //       } else if (modality && !region) {
    //         setError("Bitte Region auswählen");
    //         setTimeout(() => setError(), 5000);
    //       } else if (!modality && region) {
    //         setError("Bitte Modalität auswählen");
    //         setTimeout(() => setError(), 5000);
    //       } else if (!modality && !region) {
    //         setError("Bitte Modalität und Region auswählen");
    //         setTimeout(() => setError(), 5000);
    //       }
    //     }
    // else {
    //   setError("Bitte mindestes einen Diskriptor hinzufügen");
    // }
    // });
  }

  function loadDiscriptor() {
    firestore
      .collection("discriptors")
      .doc(selectedDiscriptorTemplate)
      .get()
      .then((doc) => {
        state.set(doc.data().data);
        SetDiscriptorName(doc.data().name);
        setModality(doc.data().modality);
        setRegion(doc.data().region);
      });
  }

  const split = (expression, operator) => {
    const result = [];
    let braces = 0;
    let currentChunk = "";
    console.log(expression);
    for (let i = 0; i < expression.length; ++i) {
      const curCh = expression[i];
      if (curCh == "(") {
        braces++;
      } else if (curCh == ")") {
        braces--;
      }
      if (braces == 0 && operator == curCh) {
        result.push(currentChunk);
        currentChunk = "";
      } else currentChunk += curCh;
    }
    if (currentChunk != "") {
      result.push(currentChunk);
    }
    return result;
  };
  // this will only take strings containing * operator [ no + ]

  const parseDividedSeparatedExpression = (expression) => {
    const numbersString = split(expression, "/");
    const numbers = numbersString.map((noStr) => {
      console.log("Division " + noStr);
      // if (noStr[0] == '(') {
      //   console.log("TRUE")
      //   const expr = noStr.substr(1, noStr.length - 2);
      //   console.log(expr)
      //   // recursive call to the main function
      //   return parsePlusSeparatedExpression(expr);
      // }
      return +noStr;
    });
    const initialValue = 1.0;
    const result = numbers.reduce((acc, no) => acc / no);
    return result;
  };

  const parseMultiplicationSeparatedExpression = (expression) => {
    const numbersString = split(expression, "*");
    const numbers = numbersString.map((noStr) => {
      console.log("Multiplication " + noStr);
      //parseDividedSeparatedExpression(noStr)
      console.log(noStr.length);
      console.log(noStr.indexOf(")") + 1);
      if (noStr[0] == "(" && noStr.length === noStr.indexOf(")") + 1) {
        //console.log("TRUE")
        const expr = noStr.substr(1, noStr.indexOf(")") - 1);
        // recursive call to the main function
        return parsePlusSeparatedExpression(expr);
      } else if (noStr[0] == "(" && noStr.length !== noStr.indexOf(")") + 1) {
        return parsePlusSeparatedExpression(noStr);
      }
      return +noStr, parseDividedSeparatedExpression(noStr);
    });
    const initialValue = 1.0;
    const result = numbers.reduce((acc, no) => acc * no, initialValue);
    return result;
  };
  // both * -
  const parseMinusSeparatedExpression = (expression) => {
    const numbersString = split(expression, "-");
    console.log(numbersString);
    const numbers = numbersString.map((noStr) => {
      console.log("Substraction " + noStr);
      return parseMultiplicationSeparatedExpression(noStr);
    });
    const initialValue = numbers[0];
    const result = numbers.slice(1).reduce((acc, no) => acc - no, initialValue);
    return result;
  };
  // * - +
  const parsePlusSeparatedExpression = (expression) => {
    const numbersString = split(expression, "+");
    const numbers = numbersString.map((noStr) => {
      console.log("Addition " + noStr);
      return parseMinusSeparatedExpression(noStr);
    });
    const initialValue = 0.0;
    const result = numbers.reduce((acc, no) => acc + no, initialValue);
    return result;
  };

  const parse = (value) => {
    //const expressionNode = value;
    //const resultNode = document.getElementById('result');
    const expression = value;
    let array = expression.split("");
    if (array.length > 0) {
      array.map((element, i) => {
        if (element === "(" && !isNaN(parseInt(array[i - 1]))) {
          array.splice(i, 0, "*");
        } else if (element === ")" && !isNaN(parseInt(array[i + 1]))) {
          array.splice(i + 1, 0, "*");
        }

        if (element === "(" && array.lastIndexOf(")") < i) {
          array.splice(array.length, 0, ")");
        }
      });
    }
    const result = parsePlusSeparatedExpression(
      JSON.stringify(array).replace(/[,"\[\]]/g, ""),
      "+"
    );
    console.log(result);
    return String(result);
  };

  const state = useStateHook([{ data: [{ input: "" }, { input: "" }] }]);
  state.attach(Downgraded);
  console.log(state);
  console.log(discriptorname);

  return (
    <div className="w-100 text-center mt-2">
      <NavbarComponent />
      <div className="">
        <div className="w-100 d-flex justify-content-left">
          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => SetDiscriptorName(e.target.value)}
                        type="input"
                        placeholder="Template/Modulbezeichnung"
                      />
                      <br />
                      <Form.Label>Modalität</Form.Label>
                      <Form.Control
                        onClick={(e) => e.stopPropagation()}
                        as="select"
                        onChange={(e) => {
                          setModality(e.target.value);
                        }}
                      >
                        <option>Modalität auswählen...</option>
                        <option value="CT">CT</option>
                        <option value="MRT">MRT</option>
                        <option value="Röntgen">Röntgen</option>
                        <option value="Sonographie">Sonographie</option>
                        <option value="PET-CT">PET-CT</option>
                        <option value="IR">IR</option>
                      </Form.Control>
                      <br />
                      <Form.Label>Region</Form.Label>
                      <Form.Control
                        onClick={(e) => e.stopPropagation()}
                        as="select"
                        onChange={(e) => {
                          setRegion(e.target.value);
                        }}
                      >
                        <option>Region auswählen...</option>
                        <option value="Ganzkörper">Ganzkörper</option>
                        <option value="Kopf">Kopf</option>
                        <option value="Hals">Hals</option>
                        <option value="Kopf/Hals">Kopf/Hals</option>
                        <option value="Thorax">Thorax</option>
                        <option value="Abdomen">Abdomen</option>
                        <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                        <option value="Becken">Becken</option>
                        <option value="Abdomen/Becken">Abdomen/Becken</option>
                        <option value="Obere Extremität">
                          Obere Extremität
                        </option>
                        <option value="Untere Extremität">
                          Untere Extremität
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                  <Button className="ml-1 mt-1 mb-1" onClick={saveDiscriptor}>
                    Diskriptor Vorlage speichern
                  </Button>
                </Popover.Content>
              </Popover>
            }
          > */}
          <Button
            className="m-1"
            variant={showsave ? "dark" : "primary"}
            onClick={() => {
              if (showload) {
                setShowLoad(false);
              }
              setShowSave(!showsave);
            }}
          >
            Speichern
          </Button>
          {/* </OverlayTrigger> */}

          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id="popover-contained"
                onClick={(e) => e.stopPropagation}
              >
                <Popover.Content>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) =>
                          SetSelectedDiscriptorTemplate(e.target.value)
                        }
                        as="select"
                        placeholder="Template/Modulbezeichnung"
                      >
                        <option>auswählen...</option>
                        {discriptorData
                          ? discriptorData.map((element) => {
                              return (
                                <option value={element.name}>
                                  {element.name}
                                </option>
                              );
                            })
                          : ""}
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <Button className="ml-1" onClick={load}>
                    Laden
                  </Button>
                </Popover.Content>
              </Popover>
            }
          > */}
          <Button
            variant={showload ? "dark" : "primary"}
            className="m-1"
            onClick={() => {
              if (showsave) {
                setShowSave(false);
              }
              setShowLoad(!showload);
            }}
          >
            Laden
          </Button>
          {/* </OverlayTrigger> */}
        </div>
        <div
          className="w-100 mt-1 mb-1"
          style={{
            display: showsave ? "" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-2">
            <Form>
              <Form.Row>
                <Form.Group as={Col} xs={7}>
                  <Form.Label style={{ color: "white" }}>
                    Bezeichnung
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => SetDiscriptorName(e.target.value)}
                    type="input"
                    placeholder="Bezeichnung"
                    defaultValue={discriptorname}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label style={{ color: "white" }}>Modalität</Form.Label>
                  <Form.Control
                    onClick={(e) => e.stopPropagation()}
                    as="select"
                    onChange={(e) => {
                      setModality(e.target.value);
                    }}
                  >
                    <option>auswählen...</option>
                    <option
                      selected={modality === "allgemein" ? true : false}
                      value="allgemein"
                    >
                      allgemein
                    </option>
                    <option
                      selected={modality === "CT" ? true : false}
                      value="CT"
                    >
                      CT
                    </option>
                    <option
                      selected={modality === "MRT" ? true : false}
                      value="MRT"
                    >
                      MRT
                    </option>
                    <option
                      selected={modality === "Röntgen" ? true : false}
                      value="Röntgen"
                    >
                      Röntgen
                    </option>
                    <option
                      selected={modality === "Sonographie" ? true : false}
                      value="Sonographie"
                    >
                      Sonographie
                    </option>
                    <option
                      selected={modality === "PET-CT" ? true : false}
                      value="PET-CT"
                    >
                      PET-CT
                    </option>
                    <option
                      selected={modality === "IR" ? true : false}
                      value="IR"
                    >
                      IR
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label style={{ color: "white" }}>Region</Form.Label>
                  <Form.Control
                    onClick={(e) => e.stopPropagation()}
                    as="select"
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                  >
                    <option>auswählen...</option>
                    <option
                      selected={region === "allgemein" ? true : false}
                      value="allgemein"
                    >
                      allgemein
                    </option>
                    <option
                      selected={region === "Ganzkörper" ? true : false}
                      value="Ganzkörper"
                    >
                      Ganzkörper
                    </option>
                    <option
                      selected={region === "Kopf" ? true : false}
                      value="Kopf"
                    >
                      Kopf
                    </option>
                    <option
                      selected={region === "Hals" ? true : false}
                      value="Hals"
                    >
                      Hals
                    </option>
                    <option
                      selected={region === "Kopf/Hals" ? true : false}
                      value="Kopf/Hals"
                    >
                      Kopf/Hals
                    </option>
                    <option
                      selected={region === "Thorax" ? true : false}
                      value="Thorax"
                    >
                      Thorax
                    </option>
                    <option
                      selected={region === "Abdomen" ? true : false}
                      value="Abdomen"
                    >
                      Abdomen
                    </option>
                    <option
                      selected={region === "Thorax/Abdomen" ? true : false}
                      value="Thorax/Abdomen"
                    >
                      Thorax/Abdomen
                    </option>
                    <option
                      selected={region === "Becken" ? true : false}
                      value="Becken"
                    >
                      Becken
                    </option>
                    <option
                      selected={region === "Abdomen/Becken" ? true : false}
                      value="Abdomen/Becken"
                    >
                      Abdomen/Becken
                    </option>
                    <option
                      selected={region === "Obere Extremität" ? true : false}
                      value="Obere Extremität"
                    >
                      Obere Extremität
                    </option>
                    <option
                      selected={region === "Untere Extremität" ? true : false}
                      value="Untere Extremität"
                    >
                      Untere Extremität
                    </option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
            <Button className="ml-1 mt-1 mb-1" onClick={saveDiscriptor}>
              Diskriptor Vorlage speichern
            </Button>
          </div>
        </div>

        <div
          className="w-100 mt-1 mb-1"
          style={{
            display: showload ? "" : "none",
            minHeight: "100px",
            backgroundColor: "#333436",
            boxShadow: "0px 0px 20px 3px rgba(0,0,0,0.9) inset",
          }}
        >
          <div className="p-2">
            <Form>
              <Form.Row>
                <Form.Group as={Col} xs={6}>
                  <InputGroup>
                    <Form.Control
                      onChange={(e) =>
                        SetSelectedDiscriptorTemplate(e.target.value)
                      }
                      as="select"
                      placeholder="Template/Modulbezeichnung"
                    >
                      <option>auswählen...</option>
                      {discriptorData
                        ? discriptorData.map((element) => {
                            let output = [];
                            if (usefilter) {
                              if (
                                selectedDiscriptorModality !== undefined &&
                                selectedDiscriptorModality !== "" &&
                                selectedDiscriptorRegion !== undefined &&
                                selectedDiscriptorRegion !== ""
                              ) {
                                if (
                                  element.modality ===
                                    selectedDiscriptorModality &&
                                  element.region === selectedDiscriptorRegion
                                )
                                  output = output.concat(
                                    <option value={element.id}>
                                      {element.name}
                                    </option>
                                  );
                              } else if (
                                selectedDiscriptorModality !== undefined &&
                                selectedDiscriptorModality !== "" &&
                                (selectedDiscriptorRegion === undefined ||
                                  selectedDiscriptorRegion === "")
                              ) {
                                if (
                                  element.modality ===
                                  selectedDiscriptorModality
                                ) {
                                  output = output.concat(
                                    <option value={element.id}>
                                      {element.name}
                                    </option>
                                  );
                                }
                              } else if (
                                (selectedDiscriptorModality === undefined ||
                                  selectedDiscriptorModality === "") &&
                                selectedDiscriptorRegion !== undefined &&
                                selectedDiscriptorRegion !== ""
                              ) {
                                if (
                                  element.region === selectedDiscriptorRegion
                                ) {
                                  output = output.concat(
                                    <option value={element.id}>
                                      {element.name}
                                    </option>
                                  );
                                }
                              }
                            } else {
                              output = output.concat(
                                <option value={element.id}>
                                  {element.name}
                                </option>
                              );
                            }
                            return output;
                          })
                        : ""}
                    </Form.Control>
                    <InputGroup.Append>
                      <InputGroup.Text>Filter</InputGroup.Text>
                      <InputGroup.Checkbox
                        aria-label="Checkbox for following text input"
                        onClick={(e) => {
                          setUseFilter(!usefilter);
                          // if(!usefilter){
                          //   SetSelectedDiscriptorModality()
                          //   SetSelectedDiscriptorRegion()
                          // }
                        }}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  as={Col}
                  style={{ display: usefilter ? "" : "none" }}
                >
                  <Form.Control
                    onChange={(e) =>
                      SetSelectedDiscriptorModality(e.target.value)
                    }
                    as="select"
                    placeholder="Modalität"
                  >
                    <option>Modalität auswählen...</option>
                    <option value="CT">CT</option>
                    <option value="MRT">MRT</option>
                    <option value="Röntgen">Röntgen</option>
                    <option value="Sonographie">Sonographie</option>
                    <option value="PET-CT">PET-CT</option>
                    <option value="IR">IR</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  as={Col}
                  style={{ display: usefilter ? "" : "none" }}
                >
                  <Form.Control
                    onChange={(e) =>
                      SetSelectedDiscriptorRegion(e.target.value)
                    }
                    as="select"
                    placeholder="Region"
                  >
                    <option>Region auswählen...</option>
                    <option value="Ganzkörper">Ganzkörper</option>
                    <option value="Kopf">Kopf</option>
                    <option value="Hals">Hals</option>
                    <option value="Kopf/Hals">Kopf/Hals</option>
                    <option value="Thorax">Thorax</option>
                    <option value="Abdomen">Abdomen</option>
                    <option value="Thorax/Abdomen">Thorax/Abdomen</option>
                    <option value="Becken">Becken</option>
                    <option value="Abdomen/Becken">Abdomen/Becken</option>
                    <option value="Obere Extremität">Obere Extremität</option>
                    <option value="Untere Extremität">Untere Extremität</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
            <Button className="ml-1 mt-1 mb-1" onClick={loadDiscriptor}>
              Laden
            </Button>
          </div>
        </div>

        <div className="w-100">
          <Card style={{ zIndex: "1" }}>
            <Card.Body>
              {error && (
                <>
                  <Alert variant="danger">
                    {error} <Button onClick={() => setError()}>x</Button>
                  </Alert>{" "}
                </>
              )}
              {success && <Alert variant="success">{success}</Alert>}
              {/* <Form>
                <Form.Group>
                  <InputGroup>
                    <Button
                      onClick={() => {
                        let currenterror = false;
                        state[rows].map((element, i) => {
                          // console.log(element.input.get() === "" && element.input.get() !== undefined)
                          if (!singleselection && !multiselection) {
                            console.log("reached");
                            setError("Bitte Typ auswählen");
                            currenterror = true;
                            setTimeout(() => setError(), 3000);
                          } else if (
                            element.input.get() === "" &&
                            i < state[rows].get().length - 1
                          ) {
                            setError("Textfeld darf nicht leer sein");
                            currenterror = true;
                            setTimeout(() => setError(), 3000);
                          }
                        });
                        console.log(!currenterror);
                        if (!currenterror) {
                          SetRows(rows + 1);
                          SetCols(0);
                          SetSingleSelection(false);
                          SetMultiSelection(false);
                          state.merge([[]]);
                        }
                      }}
                    >
                      hinzufügen
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Form> */}

              <div>
                {state.map((element, index) => {
                  let output = [];
                  output = output.concat(
                    <DiscriptorRow
                      data={element}
                      selection={element.data[0].selection.get()}
                    />
                  );
                  return output;
                })}

                <Button
                  onClick={() => {
                    let error = [];
                    let errortext = [];
                    let errortype = [];
                    state.map((element, i) => {
                      element.data.map((subelement, i2) => {
                        if (subelement.input.get() === "") {
                          errortext = errortext.concat(
                            " Reihe " + (i + 1) + ", Spalte " + (i2 + 1)
                          );
                        }
                      });
                    });

                    state.map((element, index) => {
                      if (
                        !element.data.some(
                          (o) => o.selection.get() !== undefined
                          // o.singleselection.get() === true ||
                          // o.multiselection.get() === true
                        )
                      ) {
                        errortype = errortype.concat(
                          " Bitte Typ in Reihe " + (index + 1) + " auswählen"
                        );
                      }
                    });

                    if (errortext.length > 0 && errortype.length === 0) {
                      error =
                        "Folgenden Textfelder dürfen nicht leer sein:" +
                        errortext;
                    } else if (errortype.length > 0 && errortext.length === 0) {
                      error = errortype;
                    } else if (errortext.length > 0 && errortype.length > 0) {
                      error =
                        "Folgenden Textfelder dürfen nicht leer sein:" +
                        errortext +
                        " | " +
                        errortype;
                    }

                    console.log(error);
                    if (error.length > 0) {
                      setError(error);
                    } else if (error.length === 0) {
                      state.merge([{ data: [{ input: "" }, { input: "" }] }]);
                      setError();
                    }
                  }}
                >
                  hinzufügen
                </Button>
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justifiy-content-spacebetween mt-2">
            <div className="w-50 m-1">
              <Card>
                <Card.Body>
                  <DiscriptorComponent data={state} />
                </Card.Body>
              </Card>
            </div>
            <div className="w-50 m-1">
              <Card>
                <Card.Body>
                  <div><b>Vorschau der Ausgabe:</b></div>

                  {state.map((element, index) => {
                    console.log(element);
                    let output = [];
                    if (
                      element.selectedoutputArray.get() !== undefined &&
                      element.selectedoutputArray.length > 0
                    ) {
                      element.selectedoutputArray.map((outputelement) => {
                        console.log("Ebene 1");
                        if (outputelement.type.get() === "value") {
                          console.log("Ebene 2 Value");

                          return (output = output.concat(
                            outputelement.manualinput.get()
                          ));
                          // element.data.map((dataelement, indexdata) => {
                          //   console.log(dataelement.input.get())
                          //   console.log(outputelement.label.get())
                          //   console.log(dataelement.input.get() === outputelement.label.get())
                          //   if(indexdata === outputelement.index.get()){
                          //     console.log("Ebene 3 Input")
                          //     return output = output.concat(dataelement.manualinput.get())
                          //   }
                          // })
                        } else if (outputelement.type.get() === "manualvalue") {
                          return (output = output.concat(
                            outputelement.manualinput.get()
                          ));
                        } else if (
                          outputelement.type.get() === "name" ||
                          outputelement.type.get() === "output"
                        ) {
                          console.log("Ebene 2 Name");
                          return (output = output.concat(
                            outputelement.label.get()
                          ));
                        } else if (outputelement.type.get() === "formula") {
                          if (
                            element.selectedformulaArray.get() !== undefined &&
                            element.selectedformulaArray.length > 0
                          ) {
                            let result = null;
                            let testrechnung = "";
                            element.selectedformulaArray.map(
                              (formulaelement, i) => {
                      

                                if (formulaelement.type.get() === "value") {
                                  testrechnung = testrechnung.concat(
                                    JSON.stringify(formulaelement.label.get())
                                  );
                                } else if (
                                  formulaelement.type.get() === "operator"
                                ) {
                                  testrechnung = testrechnung.concat(
                                    JSON.stringify(formulaelement.label.get())
                                  );
                                } else if (
                                  formulaelement.type.get() === "manualvalue"
                                ) {
                                  testrechnung = testrechnung.concat(
                                    JSON.stringify(
                                      formulaelement.manualinput.get()
                                    )
                                  );
                                }
                                return result;
                              }
                            );
                            console.log(testrechnung);
                            console.log(testrechnung.replace(/"/g, ""));
                            const testergebnis = parse(
                              testrechnung.replace(/"/g, "")
                            );
                            console.log(testergebnis);
                            output = output.concat(testergebnis);
                          }
                        }
                      });
                    } else if (
                      element.data.some(
                        (subelement, i) =>
                          subelement[subelement.input.get() + i].get() &&
                          subelement.selection.get() === "multiselection"
                      )
                    ) {
                      let syntax = null;
                      if (element.outputsyntax.get() !== undefined) {
                        syntax = element.outputsyntax.get();
                      }
                      console.log(syntax);
                      let selected = 0;
                      let selectedarray = [];

                      element.data.map((element3, i) => {
                        if (
                          element3[element3.input.get() + i].get() &&
                          element3.selection.get() === "multiselection"
                        ) {
                          console.log(element3.input.get());
                          selected = selected + 1;
                          console.log(selected);
                        }
                      });

                      element.data.map((element3, i) => {
                        if (
                          element3[element3.input.get() + i].get() &&
                          element3.selection.get() === "multiselection"
                        ) {
                          console.log(element3.input.get());
                          selectedarray.push(i);
                        }
                      });

                      element.data.map((element3, i) => {
                        if (element3[element3.input.get() + i].get()) {
                          if (selected === 1) {
                            output = output.concat(element3.input.get());
                          } else if (
                            selected === 2 &&
                            i !== selectedarray[selectedarray.length - 1]
                          ) {
                            console.log(selectedarray.indexOf(i));
                            output = output.concat(element3.input.get());
                          } else if (
                            selected === 2 &&
                            i === selectedarray[selectedarray.length - 1]
                          ) {
                            output = output.concat(
                              element.lastseperator.get() + element3.input.get()
                            );
                          } else if (
                            selected > 2 &&
                            i !== selectedarray[selectedarray.length - 1] &&
                            i !== selectedarray[selectedarray.length - 2]
                          ) {
                            output = output.concat(
                              element3.input.get() + element.seperator.get()
                            );
                          } else if (
                            selected > 2 &&
                            i === selectedarray[selectedarray.length - 2]
                          ) {
                            output = output.concat(element3.input.get());
                          } else if (
                            selected > 2 &&
                            i === selectedarray[selectedarray.length - 1]
                          ) {
                            output = output.concat(
                              element.lastseperator.get() + element3.input.get()
                            );
                          }
                        }
                      });

                      //  })
                    } else if(element.data.some(
                      (subelement, i) =>
                        subelement[subelement.input.get() + i].get() &&
                        subelement.selection.get() === "singleselection"
                    )){

                      element.data.map((element2,i) => {
                        if(element2[element2.input.get()+i].get() && element2.selection.get() === "singleselection"){
                          output = output.concat(element2.input.get())
                        }
                      })
                      
                    }
                    return output;
                  })}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DiscriptorRow(props) {
  const state = useStateHook(props.data);
  const [singleselection, SetSingleSelection] = useState(false);
  const [multiselection, SetMultiSelection] = useState(false);

  const [manualinput, SetManualInput] = useState(false);
  const [newOutput, setNewOutput] = useState();
  const [outputerror, setOutputError] = useState(false);
  const [showEditFormula, setShowEditFormula] = useState(false);
  const [editFormula, setEditFormula] = useState(false);
  const [editOutputs, setEditOutputs] = useState(false);

  //const [error, SetError] = useState();

  let output = [];
  let buttonoutput = [];
  let prependbuttons = [];
  let outputsyntaxArray = [];
  let manualinputOutput = [];
  let multiselectionOutput = [];
  let outputsyntaxValues = [];

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (props.selection !== undefined) {
      if (props.selection === "singleselection") {
        SetSingleSelection(true);
      } else if (props.selection === "manualinput") {
        SetManualInput(true);
      }
    }
    return () => {};
  }, [props.selection]);

  useEffect(() => {
    console.log("UseEffect triggered");
    if (
      state.outputsyntaxArray.get() !== undefined &&
      state.outputsyntaxArray.length > 0
    ) {
      state.outputsyntaxArray.map((syntaxelement) => {
        if (syntaxelement.type.get() === "manualvalue") {
          if (state.outputsyntaxValues.get() === undefined) {
            state.outputsyntaxValues.merge(
              JSON.parse(JSON.stringify([syntaxelement.get()]))
            );
            forceUpdate();
          } else if (state.outputsyntaxValues.get() !== undefined) {
            if (
              !state.outputsyntaxValues.some(
                (element) => element.value.value === syntaxelement.value.value
              )
            ) {
              state.outputsyntaxValues.merge(
                JSON.parse(JSON.stringify([syntaxelement.get()]))
              );
              forceUpdate();
            }
          }
        }
      });
    }
    return () => {};
  }, [state.outputsyntaxArray]);

  useEffect(() => {
    if (
      state.selectedformulaArray.get() !== undefined &&
      state.selectedformulaArray.length > 0
    ) {
      if (
        !state.outputsyntaxArray.some(
          (element) => element.type.get() === "formula"
        )
      ) {
        state.outputsyntaxArray.merge([
          { value: "formula", label: "Ergebnis der Formel", type: "formula" },
        ]);
      }
    } else if (
      state.selectedformulaArray.get() !== undefined &&
      state.selectedformulaArray.length === 0
    ) {
      console.log("Hier");
      state.outputsyntaxArray.map((element) => {
        if (element.type.get() === "formula") {
          console.log("HIER");
          console.log(element);
          //alles funktioniert, nur element wird irgendwie nicht gelöscht
          element.set(none);
        }
      });
      state.selectedoutputArray.map((element) => {
        if (element.type.get() === "formula") {
          console.log("HIER");
          console.log(element);
          //alles funktioniert, nur element wird irgendwie nicht gelöscht
          element.set(none);
          forceUpdate();
        }
      });
    }
    return () => {};
  }, [state.selectedformulaArray]);

  // state.data.map((subelement, index) => {
  //   if(state.outputsyntaxArray.get() === undefined){
  //     state.outputsyntaxArray.merge([{
  //       value: subelement.input.get() + (index + 1),
  //       label: subelement.input.get() + (index + 1),
  //       index: index
  //     }, {
  //       value: "Wert" + (index + 1),
  //       label: "Wert" + (index + 1),
  //       index: index
  //     }]);
  // state.outputsyntaxArray.merge({
  //   value: "Wert" + (index + 1),
  //   label: "Wert" + (index + 1),
  // });
  // }})
  // else if (state.outputsyntaxArray.get() !== undefined){
  //   state.outputsyntaxArray.map((syntaxelement, index2) => {
  //     if(syntaxelement.index.get() === index && syntaxelement.value.value !== subelement.input.get() + (index + 1)){
  //       syntaxelement.set({
  //         value: subelement.input.get() + (index + 1),
  //         label: subelement.input.get() + (index + 1),
  //         index: syntaxelement.index.get()
  //       })
  //     }
  //   })
  // }

  // });

  // if(state.outputsyntaxArray.get() === undefined){
  //   state.outputsyntaxArray.set(outputsyntaxArray)
  // }

  // console.log(
  //   JSON.stringify(state.outputsyntaxArray.get()) !== JSON.stringify(outputsyntaxArray)
  // );
  // if (
  //   state.outputsyntaxArray.get() !== undefined &&
  //   JSON.stringify(state.outputsyntaxArray.get()) !== JSON.stringify(outputsyntaxArray)
  // ) {
  //   if(JSON.stringify(state.outputsyntaxArray.get()).length < JSON.stringify(outputsyntaxArray).length){
  //     state.outputsyntaxArray.set(outputsyntaxArray);
  //   }
  //   else if(JSON.stringify(state.outputsyntaxArray.get()).length > JSON.stringify(outputsyntaxArray).length){
  //     outputsyntaxArray = state.outputsyntaxArray.get()
  //   }

  // }

  state.data.map((subelement, i) => {
    if (subelement.input.get() !== undefined) {
      if (
        subelement.selection.get() === undefined ||
        subelement.selection.get() === "singleselection" ||
        subelement.selection.get() === "multiselection"
      ) {
        output = output.concat(
          <Form.Control
            type="input"
            style={{ minWidth: "100px" }}
            onChange={(e) => {
              //subelement.input.set(e.target.value);
              if (singleselection) {
                subelement.set({
                  [e.target.value + i]: false,
                  input: e.target.value,
                  selection: "singleselection",
                });
              } else if (multiselection) {
                subelement.set({
                  [e.target.value + i]: false,
                  input: e.target.value,
                  selection: "multiselection",
                });
              } else {
                subelement.set({
                  [e.target.value + i]: false,
                  input: e.target.value,
                });
              }
            }}
            defaultValue={subelement.input.get()}
            value={subelement.input.get()}
          />
        );
      } else if (subelement.selection.get() === "manualinput") {
        output = output.concat(
          <Form.Control
            type="input"
            style={{ minWidth: "100px" }}
            onChange={(e) => {
              subelement.set({
                [e.target.value + i]: false,
                input: e.target.value,
                selection: "manualinput",
                manualinput: "",
              });

              if (
                state.outputsyntaxArray.get() !== undefined &&
                state.outputsyntaxArray.length > 0
              ) {
                state.outputsyntaxArray.map((syntaxelement) => {
                  if (
                    syntaxelement.index.get() === i &&
                    syntaxelement.label.get() !== "Wert" + (i + 1)
                  ) {
                    syntaxelement.merge({ value: e.target.value + (i + 1) });
                    syntaxelement.label.set(e.target.value);
                  }
                });
              }

              if (
                state.selectedoutputArray.get() !== undefined &&
                state.selectedoutputArray.length > 0
              ) {
                state.selectedoutputArray.map((syntaxelement) => {
                  if (
                    syntaxelement.index.get() === i &&
                    syntaxelement.label.get() !== "Wert" + (i + 1)
                  ) {
                    syntaxelement.merge({ value: e.target.value + (i + 1) });
                    syntaxelement.label.set(e.target.value);
                  }
                });
              }
            }}
            defaultValue={subelement.input.get()}
            value={subelement.input.get()}
          />
        );
      }
    }

    // if(subelement.selection.get() !== undefined) {
    //   if(subelement.selection.get() === "singleselection"){
    //     SetSingleSelection(true)
    //   }
    //   else if(subelement.selection.get() === " multiselection") {
    //     SetMultiSelection(true)
    //   }
    // }

    buttonoutput = (
      <>
        <Button
          variant={
            subelement.selection.get() === "singleselection"
              ? "dark"
              : "primary"
          }
          onClick={() => {
            SetSingleSelection(!singleselection);
            state.data.map((subelement) => {
              if (subelement.selection.get() === undefined) {
                subelement.selection.set("singleselection");
              } else if (subelement.selection.get() === "singleselection") {
                subelement.selection.set(none);
              } else if (subelement.selection.get() === "multiselection") {
                subelement.selection.set("singleselection");
                if (state.seperator.get() !== undefined) {
                  state.seperator.set(none);
                  document.getElementById("seperator").value = "";
                }
                if (state.lastseperator.get() !== undefined) {
                  state.lastseperator.set(none);
                  document.getElementById("lastseperator").value = "";
                }
              } else if (subelement.selection.get() === "manualinput") {
                subelement.selection.set("singleselection");
                if (state.outputsyntaxArray.get() !== undefined) {
                  console.log("Array exist");
                  state.outputsyntaxArray.set(none);
                  console.log(state);
                }
                if (state.outputsyntaxValues.get() !== undefined) {
                  state.outputsyntaxValues.set(none);
                }
                if (state.selectedoutputArray.get() !== undefined) {
                  state.selectedoutputArray.set(none);
                }
              }
            });
            // if (multiselection) {
            //   SetMultiSelection(false);
            // }
            // SetSingleSelection(!singleselection);
            // if (!singleselection) {
            //   if (subelement.multiselection.get() !== undefined) {
            //     subelement.set(none);
            //   }

            //   if (subelement.selection.get() === undefined) {
            //     var index = 0;
            //     var len = state.data.length;
            //     for (index; index < len; index++) {
            //       state.data[index].merge({ selection: "singleselection" });
            //     }
            //   } else if (subelement.selection.get() === "singleselection") {
            //     console.log("nothing to do");
            //   }

            //   //state.data[state.data.length].set({singleselection: true});
            //   //state.set({singleselection: state.data.get()})
            // } else if (
            //   singleselection &&
            //   subelement.selection.get() !== undefined
            // ) {
            //   var index = 0;
            //   var len = state.data.length;
            //   for (index; index < len; index++) {
            //     state.data[index].selection.set(none);
            //   }
            // }
          }}
        >
          Einfachauswahl
        </Button>
        <Button
          variant={
            subelement.selection.get() === "multiselection" ? "dark" : "primary"
          }
          onClick={() => {
            SetMultiSelection(!multiselection);
            state.data.map((subelement) => {
              if (subelement.selection.get() === undefined) {
                subelement.selection.set("multiselection");
              } else if (subelement.selection.get() === "multiselection") {
                subelement.selection.set(none);
                if (state.seperator.get() !== undefined) {
                  state.seperator.set(none);
                  document.getElementById("seperator").value = "";
                }
                if (state.lastseperator.get() !== undefined) {
                  state.lastseperator.set(none);
                  document.getElementById("lastseperator").value = "";
                }
              } else if (subelement.selection.get() === "singleselection") {
                subelement.selection.set("multiselection");
              } else if (subelement.selection.get() === "manualinput") {
                subelement.selection.set("multiselection");
                if (state.outputsyntaxArray.get() !== undefined) {
                  console.log("Array exist");
                  state.outputsyntaxArray.set(none);
                  console.log(state);
                }
                if (state.outputsyntaxValues.get() !== undefined) {
                  state.outputsyntaxValues.set(none);
                }
                if (state.selectedoutputArray.get() !== undefined) {
                  state.selectedoutputArray.set(none);
                }
              }
            });

            // if (singleselection) {
            //   SetSingleSelection(false);
            // }
            // SetMultiSelection(!multiselection);
            // if (!multiselection) {
            //   // console.log(subelement.singleselection.get() !== undefined);
            //   // if (subelement.singleselection.get() !== undefined) {
            //   //   subelement.set(none);
            //   // }

            //   if (subelement.selection.get() !== undefined) {
            //     var index = 0;
            //     var len = state.data.length;
            //     for (index; index < len; index++) {
            //       state.data[index].selection.set("multiselection");
            //     }
            //   } else if (subelement.selection.get() === undefined) {
            //     var index = 0;
            //     var len = state.data.length;
            //     for (index; index < len; index++) {
            //       state.data[index].merge({ selection: "multiselection" });
            //     }
            //   }

            //   //state.data.merge([{ multiselection: true }]);
            // } else if (
            //   multiselection &&
            //   subelement.selection.get() !== undefined
            // ) {
            //   var index = 0;
            //   var len = state.data.length;
            //   for (index; index < len; index++) {
            //     state.data[index].selection.set(none);
            //   }
            // }
          }}
        >
          Mehrfachauswahl
        </Button>
        <Button
          variant={
            subelement.selection.get() === "manualinput" ? "dark" : "primary"
          }
          onClick={() => {
            SetManualInput(!manualinput);
            state.data.map((subelement, index) => {
              if (subelement.selection.get() === undefined) {
                subelement.selection.set("manualinput");
                subelement.manualinput.set("");
                state.outputsyntaxArray.merge([
                  {
                    value: subelement.input.get() + (index + 1),
                    label: subelement.input.get(),
                    type: "name",
                    index: index,
                  },
                  {
                    value: "Wert" + (index + 1),
                    label: "Wert" + (index + 1),
                    type: "manualvalue",
                    index: index,
                  },
                ]);
              } else if (subelement.selection.get() === "manualinput") {
                subelement.selection.set(none);
                subelement.manualinput.set(none);
                if (state.outputsyntaxArray.get() !== undefined) {
                  console.log("Array exist");
                  state.outputsyntaxArray.set(none);
                  console.log(state);
                }
                if (state.outputsyntaxValues.get() !== undefined) {
                  state.outputsyntaxValues.set(none);
                }
                if (state.selectedoutputArray.get() !== undefined) {
                  state.selectedoutputArray.set(none);
                }
              } else if (subelement.selection.get() === "singleselection") {
                subelement.selection.set("manualinput");
                subelement.manualinput.set("");
                state.outputsyntaxArray.merge([
                  {
                    value: subelement.input.get() + (index + 1),
                    label: subelement.input.get(),
                    type: "name",
                    index: index,
                  },
                  {
                    value: "Wert" + (index + 1),
                    label: "Wert" + (index + 1),
                    type: "manualvalue",
                    index: index,
                  },
                ]);
              } else if (subelement.selection.get() === "multiselection") {
                subelement.selection.set("manualinput");
                subelement.manualinput.set("");
                state.outputsyntaxArray.merge([
                  {
                    value: subelement.input.get() + (index + 1),
                    label: subelement.input.get(),
                    type: "name",
                    index: index,
                  },
                  {
                    value: "Wert" + (index + 1),
                    label: "Wert" + (index + 1),
                    type: "manualvalue",
                    index: index,
                  },
                ]);
                if (state.seperator.get() !== undefined) {
                  state.seperator.set(none);
                  document.getElementById("seperator").value = "";
                }
                if (state.lastseperator.get() !== undefined) {
                  state.lastseperator.set(none);
                  document.getElementById("lastseperator").value = "";
                }
              }

              // if(state.outputsyntaxArray.get() === undefined){
              //   state.outputsyntaxArray.merge([{value: subelement.input.get()+(index+1), label: subelement.input.get(), index: index}])
              // }
              // else if(state.outputsyntaxArray.get() !== undefined){
              //   if(state.outputsyntaxArray.length < ){
              //     state.outputsyntaxArray.merge([{value: subelement.input.get()+(index+1), label: subelement.input.get(), index: index}])

              //   }

              // }
            });
          }}
        >
          Eingabe
        </Button>
        {/* <Form.Control
          type="input"
          placeholder="Output Syntax"
          style={{
            display: subelement.selection.get() === "manualinput" ? "" : "none",
            borderRadius: "0px",
          }}
          onChange={(e) => {
            state.outputsyntax.set(e.target.value);
          }}
          defaultValue={state.outputsyntax.get()}
        ></Form.Control> */}
      </>
    );

    manualinputOutput = (
      <div
        className="mb-2"
        style={{
          display: subelement.selection.get() === "manualinput" ? "" : "none",
          borderRadius: "0px",
          minWidth: "200px",
        }}
      >
        <MultiSelectSort
          finding={state.selectedoutputArray}
          selectedoutputArray={
            state.selectedoutputArray.get() !== undefined
              ? state.selectedoutputArray.get()
              : []
          }
          outputArray={
            state.outputsyntaxArray.get() !== undefined
              ? state.outputsyntaxArray.get()
              : []
          }
        />
        <InputGroup className="mt-1">
          <Form.Control
            placeholder="Text oder Zeichen hinzufügen"
            id="FormOutput"
            onChange={(e) => {
              if (state.outputsyntaxArray.get() === undefined) {
                setNewOutput(
                  Object.assign({
                    value: e.target.value + "0",
                    label: e.target.value,
                    type: "output",
                  })
                );
              } else {
                setNewOutput(
                  Object.assign({
                    value: e.target.value + state.outputsyntaxArray.length,
                    label: e.target.value,
                    type: "output",
                  })
                );
              }
            }}
          ></Form.Control>
          <InputGroup.Append>
            <Button
              onClick={() => {
                console.log(newOutput);
                if (newOutput !== undefined) {
                  if (state.outputsyntaxArray.get() === undefined) {
                    state.outputsyntaxArray.merge([newOutput]);
                    state.selectedoutputArray.merge([newOutput]);
                  } else if (
                    state.outputsyntaxArray.some(
                      (element) => element.get() === newOutput
                    )
                  ) {
                    if (
                      window.confirm(
                        "Wollen Sie diesen Text wirklich mehrfach verwenden?"
                      )
                    ) {
                      state.outputsyntaxArray.merge([
                        {
                          value:
                            newOutput.value + state.outputsyntaxArray.length,
                          label: newOutput.label,
                          type: "output",
                        },
                      ]);
                      state.selectedoutputArray.merge([
                        {
                          value:
                            newOutput.value + state.outputsyntaxArray.length,
                          label: newOutput.label,
                          type: "output",
                        },
                      ]);
                    }
                  } else {
                    state.outputsyntaxArray.merge([newOutput]);
                    state.selectedoutputArray.merge([newOutput]);
                  }

                  setNewOutput();
                  document.getElementById("FormOutput").value = "";
                } else {
                  setOutputError(true);
                  setTimeout(() => setOutputError(false), 3000);
                }
              }}
            >
              +
            </Button>
            <Button
              variant={showEditFormula ? "dark" : "primary"}
              onClick={() => {
                setShowEditFormula(!showEditFormula);
              }}
            >
              Formel hinzufügen
            </Button>
            <Button
              variant={editOutputs ? "dark" : "primary"}
              onClick={() => {
                setEditOutputs(!editOutputs);
              }}
            >
              bearbeiten
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <div style={{ display: editOutputs ? "" : "none" }}>
          <Table striped bordered hover size="sm">
            {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
            <tbody>
              {state.outputsyntaxArray.get() !== undefined &&
              state.outputsyntaxArray.length > 0
                ? state.outputsyntaxArray.map((element, index) => {
                    if (element.type.get() === "output") {
                      return (
                        <tr style={{ height: "50px" }}>
                          <td style={{ width: "30px" }}>{index + 1}</td>
                          <td>
                            {element.type.get() === "finding" ? (
                              element.label.get()
                            ) : (
                              <Form.Control
                                onChange={(e) => {
                                  if (
                                    state.selectedoutputArray.get() !==
                                      undefined &&
                                    state.selectedoutputArray.length > 0
                                  ) {
                                    state.selectedoutputArray.map(
                                      (selectedelement) => {
                                        if (
                                          selectedelement.label.get() ===
                                          element.label.get()
                                        ) {
                                          console.log("SUCCESS");
                                          selectedelement.label.set(
                                            e.target.value
                                          );

                                          selectedelement.merge({
                                            value: e.target.value + index,
                                          });
                                        }
                                      }
                                    );
                                  }

                                  element.label.set(e.target.value);
                                  element.merge({
                                    value: e.target.value + index,
                                  });
                                }}
                                defaultValue={element.label.get()}
                              ></Form.Control>
                            )}
                          </td>
                          <td style={{ width: "30px" }}>
                            <Button
                              onClick={() => {
                                if (
                                  state.selectedoutputArray.get() !==
                                    undefined &&
                                  state.selectedoutputArray.length > 0
                                ) {
                                  state.selectedoutputArray.map(
                                    (selectedelement) => {
                                      if (
                                        selectedelement.label.get() ===
                                        element.label.get()
                                      ) {
                                        selectedelement.set(none);
                                      }
                                    }
                                  );
                                }
                                state.data.map((finding) => {
                                  console.log(finding);
                                  if (
                                    finding.discriptorname.get() ===
                                    element.label.get()
                                  ) {
                                    finding.set(none);
                                  }
                                });

                                element.set(none);
                              }}
                            >
                              -
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  })
                : ""}
            </tbody>
          </Table>
        </div>

        <div
          className="mt-2 mb-2"
          style={{ display: showEditFormula ? "" : "none" }}
        >
          <MultiSelectSort
            finding={state.selectedformulaArray}
            selectedoutputArray={
              state.selectedformulaArray.get() !== undefined
                ? state.selectedformulaArray.get()
                : []
            }
            outputArray={
              state.outputsyntaxValues.get() !== undefined
                ? state.outputsyntaxValues.get()
                : []
            }
          />
          <InputGroup className="mt-2">
            <InputGroup.Prepend>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "+" + state.outputsyntaxValues.length,
                      label: "+",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "+" + state.outputsyntaxValues.length,
                      label: "+",
                      type: "operator",
                    },
                  ]);
                }}
              >
                +
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "-" + state.outputsyntaxValues.length,
                      label: "-",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "-" + state.outputsyntaxValues.length,
                      label: "-",
                      type: "operator",
                    },
                  ]);
                }}
              >
                -
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "*" + state.outputsyntaxValues.length,
                      label: "*",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "*" + state.outputsyntaxValues.length,
                      label: "*",
                      type: "operator",
                    },
                  ]);
                }}
              >
                *
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "/" + state.outputsyntaxValues.length,
                      label: "/",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "/" + state.outputsyntaxValues.length,
                      label: "/",
                      type: "operator",
                    },
                  ]);
                }}
              >
                /
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "(" + state.outputsyntaxValues.length,
                      label: "(",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "(" + state.outputsyntaxValues.length,
                      label: "(",
                      type: "operator",
                    },
                  ]);
                }}
              >
                (
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: ")" + state.outputsyntaxValues.length,
                      label: ")",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: ")" + state.outputsyntaxValues.length,
                      label: ")",
                      type: "operator",
                    },
                  ]);
                }}
              >
                )
              </Button>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Wert hinzufügen"
              style={{ maxWidth: "150px" }}
              id="FormValue"
              onChange={(e) => {
                let newvalue = e.target.value.replace(",", ".");
                if (state.outputsyntaxValues.get() === undefined) {
                  setNewOutput(
                    Object.assign({
                      value: newvalue + "0",
                      label: newvalue,
                      type: "value",
                    })
                  );
                } else {
                  setNewOutput(
                    Object.assign({
                      value: newvalue + state.outputsyntaxValues.length,
                      label: newvalue,
                      type: "value",
                    })
                  );
                }
              }}
            ></Form.Control>
            <InputGroup.Append>
              <Button
                onClick={() => {
                  console.log(newOutput);
                  if (newOutput !== undefined) {
                    if (state.outputsyntaxValues.get() === undefined) {
                      state.outputsyntaxValues.merge([newOutput]);
                      state.selectedformulaArray.merge([newOutput]);
                    } else if (
                      state.outputsyntaxValues.some(
                        (element) => element.label.get() === newOutput.label
                      )
                    ) {
                      if (
                        window.confirm(
                          "Wollen Sie diesen Wert wirklich mehrfach verwenden?"
                        )
                      ) {
                        state.outputsyntaxValues.merge([
                          {
                            value:
                              newOutput.value + state.outputsyntaxValues.length,
                            label: newOutput.label,
                            type: "value",
                          },
                        ]);
                        state.selectedformulaArray.merge([
                          {
                            value:
                              newOutput.value + state.outputsyntaxValues.length,
                            label: newOutput.label,
                            type: "value",
                          },
                        ]);
                      }
                    } else {
                      state.outputsyntaxValues.merge([newOutput]);
                      state.selectedformulaArray.merge([newOutput]);
                    }

                    setNewOutput();
                    document.getElementById("FormValue").value = "";
                  } else {
                    setOutputError(true);
                    setTimeout(() => setOutputError(false), 3000);
                  }
                }}
              >
                +
              </Button>
              <Button
                variant={editFormula ? "dark" : "primary"}
                onClick={() => {
                  setEditFormula(!editFormula);
                }}
              >
                bearbeiten
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <div style={{ display: editFormula ? "" : "none" }}>
            <Table striped bordered hover size="sm">
              {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
              <tbody>
                {state.outputsyntaxValues.get() !== undefined &&
                state.outputsyntaxValues.length > 0
                  ? state.outputsyntaxValues.map((element, index) => {
                      if (
                        (element.type.get() === "value" &&
                          element.index.get() === undefined) ||
                        element.type.get() === "operator"
                      ) {
                        return (
                          <tr style={{ height: "50px" }}>
                            <td style={{ width: "30px" }}>{index + 1}</td>
                            <td>
                              {element.type.get() === "operator" ? (
                                element.label.get()
                              ) : (
                                <Form.Control
                                  onChange={(e) => {
                                    element.label.set(e.target.value);
                                    element.merge({
                                      value: e.target.value + index,
                                    });
                                    if (
                                      state.selectedoutputArray.get() !==
                                        undefined &&
                                      state.selectedoutputArray.length > 0
                                    ) {
                                      state.selectedoutputArray.map(
                                        (selectedelement) => {
                                          if (
                                            selectedelement.label.get() ===
                                            element.label.get()
                                          ) {
                                            selectedelement.label.set(
                                              e.target.value
                                            );

                                            selectedelement.merge({
                                              value: e.target.value + index,
                                            });
                                          }
                                        }
                                      );
                                    }
                                  }}
                                  defaultValue={element.label.get()}
                                ></Form.Control>
                              )}
                            </td>
                            <td style={{ width: "30px" }}>
                              <Button
                                onClick={() => {
                                  if (
                                    state.selectedformulaArray.get() !==
                                      undefined &&
                                    state.selectedformulaArray.length > 0
                                  ) {
                                    state.selectedformulaArray.map(
                                      (selectedelement) => {
                                        if (
                                          selectedelement.label.get() ===
                                          element.label.get()
                                        ) {
                                          selectedelement.set(none);
                                        }
                                      }
                                    );
                                  }

                                  state.data.map((finding) => {
                                    console.log(finding);
                                    if (
                                      finding.discriptorname.get() ===
                                      element.label.get()
                                    ) {
                                      finding.set(none);
                                    }
                                  });

                                  element.set(none);
                                }}
                              >
                                -
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    })
                  : ""}
              </tbody>
            </Table>
          </div>

          {/* <Form className="mt-2">
            <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Feld auswählen</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control as="select">
            <option>auswählen...</option>
              {state.data.map((element) => (
                
                <option>{element.input.get()}</option>
              ))}
            </Form.Control>
            </InputGroup>
          </Form> */}
        </div>
      </div>
    );

    multiselectionOutput = (
      <div
        className="mb-2"
        style={{
          display:
            subelement.selection.get() === "multiselection" ? "" : "none",
          borderRadius: "0px",
          minWidth: "200px",
        }}
      >
        <InputGroup className="mt-1">
          <Form.Control
            placeholder="Trennzeichen"
            id="seperator"
            onChange={(e) => {
              state.seperator.set(e.target.value);
            }}
          ></Form.Control>
          <Form.Control
            placeholder="letztes Trennzeichen/Wort"
            id="lastseperator"
            onChange={(e) => {
              state.lastseperator.set(e.target.value);
            }}
          ></Form.Control>
          <InputGroup.Append>
            <Button onClick={() => {}}>+</Button>

            <Button
              variant={editOutputs ? "dark" : "primary"}
              onClick={() => {}}
            >
              bearbeiten
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <div style={{ display: editOutputs ? "" : "none" }}>
          <Table striped bordered hover size="sm">
            {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
            <tbody>
              {state.outputsyntaxArray.get() !== undefined &&
              state.outputsyntaxArray.length > 0
                ? state.outputsyntaxArray.map((element, index) => {
                    if (element.type.get() === "output") {
                      return (
                        <tr style={{ height: "50px" }}>
                          <td style={{ width: "30px" }}>{index + 1}</td>
                          <td>
                            {element.type.get() === "finding" ? (
                              element.label.get()
                            ) : (
                              <Form.Control
                                onChange={(e) => {
                                  element.label.set(e.target.value);
                                  element.merge({
                                    value: e.target.value + index,
                                  });
                                  if (
                                    state.selectedoutputArray.get() !==
                                      undefined &&
                                    state.selectedoutputArray.length > 0
                                  ) {
                                    state.selectedoutputArray.map(
                                      (selectedelement) => {
                                        if (
                                          selectedelement.label.get() ===
                                          element.label.get()
                                        ) {
                                          selectedelement.label.set(
                                            e.target.value
                                          );

                                          selectedelement.merge({
                                            value: e.target.value + index,
                                          });
                                        }
                                      }
                                    );
                                  }
                                }}
                                defaultValue={element.label.get()}
                              ></Form.Control>
                            )}
                          </td>
                          <td style={{ width: "30px" }}>
                            <Button
                              onClick={() => {
                                if (
                                  state.selectedoutputArray.get() !==
                                    undefined &&
                                  state.selectedoutputArray.length > 0
                                ) {
                                  state.selectedoutputArray.map(
                                    (selectedelement) => {
                                      if (
                                        selectedelement.label.get() ===
                                        element.label.get()
                                      ) {
                                        selectedelement.set(none);
                                      }
                                    }
                                  );
                                }
                                state.data.map((finding) => {
                                  console.log(finding);
                                  if (
                                    finding.discriptorname.get() ===
                                    element.label.get()
                                  ) {
                                    finding.set(none);
                                  }
                                });

                                element.set(none);
                              }}
                            >
                              -
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  })
                : ""}
            </tbody>
          </Table>
        </div>

        <div
          className="mt-2 mb-2"
          style={{ display: showEditFormula ? "" : "none" }}
        >
          <MultiSelectSort
            finding={state.selectedformulaArray}
            selectedoutputArray={
              state.selectedformulaArray.get() !== undefined
                ? state.selectedformulaArray.get()
                : []
            }
            outputArray={
              state.outputsyntaxValues.get() !== undefined
                ? state.outputsyntaxValues.get()
                : []
            }
          />
          <InputGroup className="mt-2">
            <InputGroup.Prepend>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "+" + state.outputsyntaxValues.length,
                      label: "+",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "+" + state.outputsyntaxValues.length,
                      label: "+",
                      type: "operator",
                    },
                  ]);
                }}
              >
                +
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "-" + state.outputsyntaxValues.length,
                      label: "-",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "-" + state.outputsyntaxValues.length,
                      label: "-",
                      type: "operator",
                    },
                  ]);
                }}
              >
                -
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "*" + state.outputsyntaxValues.length,
                      label: "*",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "*" + state.outputsyntaxValues.length,
                      label: "*",
                      type: "operator",
                    },
                  ]);
                }}
              >
                *
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "/" + state.outputsyntaxValues.length,
                      label: "/",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "/" + state.outputsyntaxValues.length,
                      label: "/",
                      type: "operator",
                    },
                  ]);
                }}
              >
                /
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: "(" + state.outputsyntaxValues.length,
                      label: "(",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: "(" + state.outputsyntaxValues.length,
                      label: "(",
                      type: "operator",
                    },
                  ]);
                }}
              >
                (
              </Button>
              <Button
                onClick={() => {
                  state.selectedformulaArray.merge([
                    {
                      value: ")" + state.outputsyntaxValues.length,
                      label: ")",
                      type: "operator",
                    },
                  ]);
                  state.outputsyntaxValues.merge([
                    {
                      value: ")" + state.outputsyntaxValues.length,
                      label: ")",
                      type: "operator",
                    },
                  ]);
                }}
              >
                )
              </Button>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Wert hinzufügen"
              style={{ maxWidth: "150px" }}
              id="FormValue"
              onChange={(e) => {
                if (state.outputsyntaxValues.get() === undefined) {
                  setNewOutput(
                    Object.assign({
                      value: e.target.value + "0",
                      label: e.target.value,
                      type: "value",
                    })
                  );
                } else {
                  setNewOutput(
                    Object.assign({
                      value: e.target.value + state.outputsyntaxValues.length,
                      label: e.target.value,
                      type: "value",
                    })
                  );
                }
              }}
            ></Form.Control>
            <InputGroup.Append>
              <Button
                onClick={() => {
                  console.log(newOutput);
                  if (newOutput !== undefined) {
                    if (state.outputsyntaxValues.get() === undefined) {
                      state.outputsyntaxValues.merge([newOutput]);
                      state.selectedformulaArray.merge([newOutput]);
                    } else if (
                      state.outputsyntaxValues.some(
                        (element) => element.label.get() === newOutput.label
                      )
                    ) {
                      if (
                        window.confirm(
                          "Wollen Sie diesen Wert wirklich mehrfach verwenden?"
                        )
                      ) {
                        state.outputsyntaxValues.merge([
                          {
                            value:
                              newOutput.value + state.outputsyntaxValues.length,
                            label: newOutput.label,
                            type: "value",
                          },
                        ]);
                        state.selectedformulaArray.merge([
                          {
                            value:
                              newOutput.value + state.outputsyntaxValues.length,
                            label: newOutput.label,
                            type: "value",
                          },
                        ]);
                      }
                    } else {
                      state.outputsyntaxValues.merge([newOutput]);
                      state.selectedformulaArray.merge([newOutput]);
                    }

                    setNewOutput();
                    document.getElementById("FormValue").value = "";
                  } else {
                    setOutputError(true);
                    setTimeout(() => setOutputError(false), 3000);
                  }
                }}
              >
                +
              </Button>
              <Button
                variant={editFormula ? "dark" : "primary"}
                onClick={() => {
                  setEditFormula(!editFormula);
                }}
              >
                bearbeiten
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <div style={{ display: editFormula ? "" : "none" }}>
            <Table striped bordered hover size="sm">
              {/* <thead>
                                  <tr>
                                    <th>Nr.</th>
                                    <th>Name</th>
                                    <th></th>
                                  </tr>
                                </thead> */}
              <tbody>
                {state.outputsyntaxValues.get() !== undefined &&
                state.outputsyntaxValues.length > 0
                  ? state.outputsyntaxValues.map((element, index) => {
                      if (
                        (element.type.get() === "value" &&
                          element.index.get() === undefined) ||
                        element.type.get() === "operator"
                      ) {
                        return (
                          <tr style={{ height: "50px" }}>
                            <td style={{ width: "30px" }}>{index + 1}</td>
                            <td>
                              {element.type.get() === "operator" ? (
                                element.label.get()
                              ) : (
                                <Form.Control
                                  onChange={(e) => {
                                    element.label.set(e.target.value);
                                    element.merge({
                                      value: e.target.value + index,
                                    });
                                    if (
                                      state.selectedoutputArray.get() !==
                                        undefined &&
                                      state.selectedoutputArray.length > 0
                                    ) {
                                      state.selectedoutputArray.map(
                                        (selectedelement) => {
                                          if (
                                            selectedelement.label.get() ===
                                            element.label.get()
                                          ) {
                                            selectedelement.label.set(
                                              e.target.value
                                            );

                                            selectedelement.merge({
                                              value: e.target.value + index,
                                            });
                                          }
                                        }
                                      );
                                    }
                                  }}
                                  defaultValue={element.label.get()}
                                ></Form.Control>
                              )}
                            </td>
                            <td style={{ width: "30px" }}>
                              <Button
                                onClick={() => {
                                  if (
                                    state.selectedformulaArray.get() !==
                                      undefined &&
                                    state.selectedformulaArray.length > 0
                                  ) {
                                    state.selectedformulaArray.map(
                                      (selectedelement) => {
                                        if (
                                          selectedelement.label.get() ===
                                          element.label.get()
                                        ) {
                                          selectedelement.set(none);
                                        }
                                      }
                                    );
                                  }

                                  state.data.map((finding) => {
                                    console.log(finding);
                                    if (
                                      finding.discriptorname.get() ===
                                      element.label.get()
                                    ) {
                                      finding.set(none);
                                    }
                                  });

                                  element.set(none);
                                }}
                              >
                                -
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    })
                  : ""}
              </tbody>
            </Table>
          </div>

          {/* <Form className="mt-2">
            <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Feld auswählen</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control as="select">
            <option>auswählen...</option>
              {state.data.map((element) => (
                
                <option>{element.input.get()}</option>
              ))}
            </Form.Control>
            </InputGroup>
          </Form> */}
        </div>
      </div>
    );

    prependbuttons = (
      <>
        <Button
          onClick={() => {
            if (singleselection) {
              state.data.merge([{ input: "", selection: "singleselection" }]);
            } else if (multiselection) {
              state.data.merge([{ input: "", selection: "multiselection" }]);
            } else if (manualinput) {
              state.data.merge([
                { input: "", selection: "manualinput", manualinput: "" },
              ]);
              state.outputsyntaxArray.merge([
                {
                  value: "" + state.data.length,
                  label: "" + state.data.length,
                  type: "name",
                  index: state.data.length - 1,
                },
                {
                  value: "Wert" + state.data.length,
                  label: "Wert" + state.data.length,
                  type: "manualvalue",
                  index: state.data.length - 1,
                },
              ]);
            } else {
              state.data.merge([{ input: "" }]);
            }
          }}
        >
          +
        </Button>
        <Button
          onClick={() => {
            if (state.data.get().length > 1) {
              state.data[state.data.get().length - 1].set(none);

              if (state.selectedformulaArray.get() !== undefined) {
                state.selectedformulaArray.map((element) => {
                  if (element.index.get() === state.data.length) {
                    element.set(none);
                  }
                });
              }

              if (state.selectedoutputArray.get() !== undefined) {
                state.selectedoutputArray.map((element) => {
                  if (element.index.get() === state.data.length) {
                    element.set(none);
                  }
                });
              }

              if (state.outputsyntaxArray.get() !== undefined) {
                state.outputsyntaxArray.map((element, i) => {
                  if (element.index.get() === state.data.length) {
                    console.log("TRUE");
                    //Irgendein Bug lässt es mich nicht mit element.set(none) machen weil das darauffolgende element nicht mehr gemapt wird
                    //deshalb dieser workaround: Zwei mal das selbe weil der index sich nach dem ersten löschen ändert
                    state.outputsyntaxArray[i].set(none);
                    state.outputsyntaxArray[i].set(none);
                  }
                });
              }

              if (state.outputsyntaxValues.get() !== undefined) {
                state.outputsyntaxValues.map((element) => {
                  if (element.index.get() === state.data.length) {
                    element.set(none);
                  }
                });
              }
            }
          }}
        >
          -
        </Button>
      </>
    );
  });

  return (
    <>
      <Form>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>{prependbuttons}</InputGroup.Prepend>
            {output}
            <InputGroup.Append>
              {buttonoutput}
              {state.data.get().length > 0 ? (
                <Button
                  onClick={() => {
                    state.set(none);
                  }}
                >
                  -
                </Button>
              ) : (
                ""
              )}
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        {manualinputOutput}
        {multiselectionOutput}
      </Form>
    </>
  );
}
